import React, { Dispatch, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useDialog } from '../../contexts/DialogContext'
import { LobRoleNameEnum } from '../../enums/UserRoles'
import ApiCalls from '../../network/ApiCalls'
import * as adminActionCreators from '../../redux/actionCreators/adminActionCreators';
import { AdminAction } from '../../redux/actionTypes/AdminTypes'
import { VendorAction } from '../../redux/actionTypes/vendorTypes'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import { isPhoneValid } from '../../utils/util'
import PhoneTextInput from '../Inputs/PhoneInput'
import SelectInput from '../Inputs/SelectInput'
import { TextInput } from '../Inputs/TextInput'

type Props = {}

const AdminEditForm: React.FC<Props> = () => {
  const { adminEditDialog }: any = useSelector((state: RedusxAppState) => state.admin)
  const dispatch = useDispatch<Dispatch<AdminAction>>()
  const { showSuccessDialog } = useDialog()
  const [statusTypes, setStatusTypes] = useState<any>([])
  const [editRequest, setEditRequest] = useState({
    firstName: adminEditDialog.admin?.firstName,
    lastName: adminEditDialog.admin?.lastName,
    phoneNumber: adminEditDialog.admin?.phoneNumber,
    email: adminEditDialog.admin?.email,
    roleId: LobRoleNameEnum.ADMIN,
    accountStatusId: adminEditDialog.admin?.accountStatusId

  })
  const [errorText, setErrorText] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleUserEdit = async () => {
    try {

      setLoading(true)
      setErrorMessage(null)

      if (editRequest?.firstName && editRequest?.lastName && editRequest?.phoneNumber && editRequest?.email) {

        if (isPhoneValid(editRequest?.phoneNumber)) {

          await ApiCalls.UpdateAdmin(adminEditDialog.admin?.id, editRequest)
          setLoading(false)
          adminEditDialog.refetch();
          dispatch(adminActionCreators.closeEditAdminDialog())
          showSuccessDialog('Admin Updated.')
        }
        else {
          setLoading(false)
          setErrorMessage('Phone is not valid!')
        }
      }
      else {
        setLoading(false)
        setErrorMessage('All fields are required.')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }







  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>

          <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
          <TextInput
            onValueChange={(text: string) => setEditRequest({ ...editRequest, firstName: text })}
            defaultValue={editRequest?.firstName}
          />
          <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
          <TextInput
            onValueChange={(text: string) => setEditRequest({ ...editRequest, lastName: text })}
            defaultValue={editRequest?.lastName}
          />
          <label className="text-danger">{errorText}</label>
          <br />

          <label className='form-label fs-6 fw-bolder text-dark mt-2'>Email</label>
          <TextInput defaultValue={editRequest?.email} onValueChange={(text: string) => setEditRequest({ ...editRequest, email: text })} />

          <label className='form-label fs-6 fw-bolder text-dark mt-2'>Phone</label>
          <PhoneTextInput defaultValue={editRequest?.phoneNumber} onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} />

          <div className='row mt-6'>

            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={loading}
                onClick={handleUserEdit}
              >
                Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col-12 text-end'>
              <strong style={{ color: 'red' }}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default React.memo(AdminEditForm)