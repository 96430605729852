import * as Sentry from '@sentry/react'
import axios from 'axios'
import {IStaffAddress} from '../interfaces/Address'
import {AdminAcceptRequest, AdminRegisterRequest} from '../interfaces/Admins'
import {IHostEdit} from '../interfaces/Host'
import {IHostPropertyAdminInput, IHostPropertyInput} from '../interfaces/Property'
import {IService, IServiceGroup} from '../interfaces/ServiceType'
import {
  IAvailableVendorsForPropertyFromJob,
  ISaveVendorToProperty,
  IVendors,
} from './../interfaces/Vendors'
import {Endpoints} from './Endpoints'
import {
  AcceptStaffInviteRequest,
  HostRegisterRequest,
  LoginRequest,
  StaffAddRequest,
  StaffOfferedRequest,
} from './PostRequestModels'
import {AddressRequest} from './PostRequestModels/AddressRequests'
import {IChangePasswordStaff} from './PostRequestModels/ChangePasswordRequest'
import {
  ChecklistAddRequest,
  ChecklistEditRequest,
  ChecklistMainUpdateRequest,
  ChecklistQuestionEditRequest,
  ChecklistSectionAddRequest,
  ChecklistSectionEditRequest,
  ChecklistSetQuestionOrderRequest,
  ChecklistSetSectionOrderRequest,
} from './PostRequestModels/ChecklistRequests'
import {CityService, DelCityService, StaffCityService} from './PostRequestModels/CityService'
import {
  AssignStaffJobRequest,
  IJobCancelRequest,
  IScheduledJobRequest,
  IScheduledMarketplaceJobRequest,
  IScheduledMyTeamJobRequest,
  IScheduledTurnifyPlusJobRequest,
  JobNoteRequest,
  JobReviewRequest,
  JobServiceAddRequest,
  ManualJobRequest,
} from './PostRequestModels/JobRequests'
import {IPostOffDays} from './PostRequestModels/OffDaysRequests'
import {BanRequest, ExcludeVendor, PermissionRequest} from './PostRequestModels/Permission'
import {GuestyRequest, HostawayRequest, MyVrRequest} from './PostRequestModels/PropertyFromPMS'
import {IReAssign, IReVendorStaffAssign} from './PostRequestModels/ReAssignRequests'
import {IReStaffAssign} from './PostRequestModels/ReAssignTurnifyPlusRequests'
import {ForgotRequest} from './PostRequestModels/UserRequest'
import {
  IHostPropertyOfferedRateRequest,
  IHostPropertyOfferedServiceRequest,
  IHostPropertyVendorServiceRateRequest,
  IServicePrice,
  IVendorServiceRequest,
} from './PostRequestModels/VendorRequestForm'
import {DeclineVirtualInspect} from './PostRequestModels/VirtualInspectionRequest'

var httpClient = axios.create({
  httpsAgent: {
    rejectUnauthorized: false,
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

var httpClientFormData = axios.create({
  httpsAgent: {
    rejectUnauthorized: false,
  },
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
})
httpClient.defaults.timeout = 500000
httpClientFormData.defaults.timeout = 500000

httpClient.interceptors.response.use(
  (res) => res,
  (err) => {
    try {
      if (err?.response?.data?.statusCode === 500) {
        Sentry.captureException({
          responseData: {...err?.response?.data},
          requestData: JSON.parse(err?.response?.config?.data),
          url: err?.response?.config?.url,
        })
      }
    } catch (interceptorError) {
      //Do not sent it to Sentry
    } finally {
      return Promise.reject(err)
    }
  }
)

httpClientFormData.interceptors.response.use(
  (res) => res,
  (err) => {
    try {
      if (err?.response?.data?.statusCode === 500) {
        Sentry.captureException({
          responseData: {...err?.response?.data},
          requestData: JSON.parse(err?.response?.config?.data),
          url: err?.response?.config?.url,
        })
      }
    } catch (interceptorError) {
      //Do not sent it to Sentry
    } finally {
      return Promise.reject(err)
    }
  }
)

interface IApiCalls {}

class ApiCalls implements IApiCalls {
  private server_link: string
  public token: string
  public logout: () => void

  constructor() {
    //this.server_link = 'https://turnify.azurewebsites.net/api/' //Production
    this.server_link = 'https://trnfyapi.nono.company/api/' //Production2
    //this.server_link = "https://turnify-turnify-staging.azurewebsites.net/api/" // Dev
    //this.server_link = "http://3.135.239.85/api/";       //Test
    //this.server_link = 'http://localhost:5139/api/' //LOCAL
    //this.server_link = 'https://turnify-api-dev.nonoco.dev/api/' //Development

    this.token = 'not set'
    this.logout = () => {}
  }

  ressetToken = () => {
    const newHttpClient = axios.create({
      httpsAgent: {
        rejectUnauthorized: false,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    newHttpClient.defaults.timeout = 500000

    httpClient = newHttpClient
  }

  setToken = (token: string) => {
    httpClient.defaults.headers['Authorization'] = 'Bearer ' + token
    httpClientFormData.defaults.headers['Authorization'] = 'Bearer ' + token
    httpClient.interceptors.response.use(
      (res) => res,
      (err) => {
        try {
          if (err?.response?.data?.statusCode === 401) {
            this.logout()
          }
          if (err?.response?.data?.statusCode === 500) {
            Sentry.captureException({
              responseData: {...err?.response?.data},
              requestData: JSON.parse(err?.response?.config?.data),
              url: err?.response?.config?.url,
            })
          }
        } catch (interceptorError) {
          //Do not sent it to Sentry
        } finally {
          return Promise.reject(err)
        }
      }
    )
    httpClientFormData.interceptors.response.use(
      (res) => res,
      (err) => {
        try {
          if (err?.response?.data?.statusCode === 401) {
            this.logout()
          }
          if (err?.response?.data?.statusCode === 500) {
            Sentry.captureException({
              responseData: {...err?.response?.data},
              requestData: JSON.parse(err?.response?.config?.data),
              url: err?.response?.config?.url,
            })
          }
        } catch (interceptorError) {
          //Do not sent it to Sentry
        } finally {
          return Promise.reject(err)
        }
      }
    )
  }

  setLogout = (func: () => void) => {
    this.logout = func
  }

  //Auth
  login = (loginRequest: LoginRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.Login, loginRequest)
  }

  verifyUser = (token: string) => {
    return httpClient.post(this.server_link + Endpoints.Users.ConfirmAccount, {token: token})
  }

  getUserDetails = () => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetails)
  }

  getUserDetailsById = (userId: string) => {
    return httpClient.get(this.server_link + Endpoints.Users.GetUserDetailsById + userId)
  }

  forgotPassword = (data: ForgotRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.Forgotpassword, data)
  }

  resetPassword = (data: AcceptStaffInviteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Users.ResetPassword, data)
  }

  editUser = (data: any, userId: string) => {
    return httpClientFormData.put(this.server_link + Endpoints.Users.EditUser + userId, data)
  }

  putStaffPassword = (data: IChangePasswordStaff, staffId: number) => {
    return httpClient.put(this.server_link + Endpoints.Staff.StaffChangePassword + staffId, data)
  }

  getAccountStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Account.GetAccountStatusType)
  }

  findUserByEmail = (email: string) => {
    return httpClient.post(this.server_link + Endpoints.Users.FindByEmail + email)
  }

  hardDeleteUser = (userId: string) => {
    return httpClient.delete(this.server_link + Endpoints.Users.HardDeleteUser + userId)
  }

  //Hosts
  getAllHosts = () => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHosts)
  }

  //My Team Subscribers
  getMyTeamSubscribers = () => {
    return httpClient.post(this.server_link + Endpoints.Property.GetMyTeamSubscribers)
  }

  getHostById = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostById + hostId)
  }

  getHostUsers = (hostId: any) => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostUsers + hostId)
  }

  getHostStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Host.GetHostStatusTypes)
  }

  updateHost = (hostId?: number, data?: IHostEdit) => {
    return httpClient.put(this.server_link + Endpoints.Host.UpdateHost + hostId, data)
  }

  //Admins
  getAllAdmins = (accountStatusId: number) => {
    return httpClient.get(this.server_link + Endpoints.Admins.GetAllAdmins)
  }

  CreateAdmin = (data: AdminRegisterRequest) => {
    return httpClient.post(this.server_link + Endpoints.Admins.CreateAdmin, data)
  }

  UpdateAdmin = (id: number, data?: AdminRegisterRequest) => {
    return httpClient.put(this.server_link + Endpoints.Admins.UpdateAdmins + id, data)
  }

  acceptAdmin = (data: AdminAcceptRequest) => {
    return httpClient.post(this.server_link + Endpoints.Admins.AcceptAdmin, data)
  }

  //Vendors
  getVendorDetails = (vendorId?: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetVendorDetails + vendorId)
  }

  createVendor = (data: IVendors) => {
    return httpClient.post(this.server_link + Endpoints.Vendors.CreateVendor, data)
  }

  getAllVendors = () => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetAllVendors)
  }

  getPendingVendors = (statusTypeId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetPendingVendors + statusTypeId)
  }
  putStatusVendors = (vendorId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Vendors.PutVendorsStatus + vendorId, data)
  }
  getVendorById = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetVendorById + vendorId)
  }

  getSelectedVendors = (hostPropertyId?: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetSelectedVendors + hostPropertyId)
  }

  getAvailableVendors = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetAvailableVendors + hostPropertyId)
  }
  getAvailableVendorByJobId = (jobId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetAvailableVendorsByJobId + jobId)
  }
  getSuitableVendorByJobId = (jobId: number | null) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetSuitableVendorsByJobId + jobId)
  }
  getSuitableMarketplaceVendorByJobId = (jobId: number | null) => {
    return httpClient.get(
      this.server_link + Endpoints.Vendors.GetSuitableMarketplaceVendorsByJobId + jobId
    )
  }

  getAvailableVendorsForPropertyByJob = (jobId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Vendors.GetAvailableVendorsForProperty + jobId
    )
  }

  saveVendorServiceToProperty = (data: ISaveVendorToProperty) => {
    return httpClient.post(this.server_link + Endpoints.Vendors.SaveVendorServiceToProperty, data)
  }

  getAvailableStaff = (vendorId: number, jobId: number, duration: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Vendors.GetSuitableStaffByJobId +
        '?vendorId=' +
        vendorId +
        '&jobId=' +
        jobId +
        '&duration=' +
        duration
    )
  }

  getHostAvailableStaff = (jobId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetSuitableHostStaffByJobId + jobId)
  }

  getVendorServices = (vendorId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetVendorServices + vendorId)
  }

  getVendorServiceRates = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetVendorServiceRates + vendorId)
  }

  updateVendorServiceRate = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Vendors.UpdateVendorServiceRate, data)
  }

  deleteVendorServiceRate = (offerId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Vendors.DeleteVendorServiceRate + offerId)
  }

  deleteHostServiceRate = (offerId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Vendors.DeleteHostServiceRate + offerId)
  }

  getVendorServicesByPropertyId = (hostPropertyId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetSelectedVendors + hostPropertyId)
  }
  getVendorStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Vendors.GetVendorStatus)
  }
  putVendorDetails = (vendorId: number, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Vendors.PutVendorsStatus + vendorId, data)
  }
  putVendorEditStatus = (data: any, vendorId: any) => {
    return httpClient.put(this.server_link + Endpoints.Vendors.PutVendorEditStatus + vendorId, data)
  }
  getPermissionVendors = (hostPropertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetPermissionsStaffs + hostPropertyId)
  }

  //Vendors-Documents
  getVendorDocuments = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.VendorDocuments.GetVendorDocuments + vendorId
    )
  }
  deleteVendorDocument = (documentId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.VendorDocuments.DeleteVendorDocuments + documentId
    )
  }
  approveVendorDocument = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.VendorDocuments.ApproveVendorDocument, data)
  }

  //Vendor Reference
  getVendorReference = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Reference.GetReference + vendorId)
  }
  ///Vendor-Cities
  getVendorCityService = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.VendorServiceCities.GetVendorCities + vendorId
    )
  }
  createVendorCityService = (data: CityService) => {
    return httpClient.post(
      this.server_link + Endpoints.VendorServiceCities.CreateVendorCityService,
      data
    )
  }
  deleteVendorCityService = (zipId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.VendorServiceCities.DeleteVendorCityService + zipId
    )
  }
  addHost = (data: HostRegisterRequest) => {
    return httpClient.post(this.server_link + Endpoints.Host.AddHost, data)
  }
  ///Vendor-Violation
  getVendorViolation = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.VendorViolation.GetViolation + vendorId)
  }

  //Jobs
  getAllJobs = (params?: string) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobs + params)
  }

  getClientManagedAllJobs = (params?: string) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetClientManagedAllJobs + params)
  }

  getTurnifyManagedAllJobs = (params?: string) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetTurnifyManagedAll + params)
  }

  putSchedule = (data: AssignStaffJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.ScheduleAssignStaffToJob, data)
  }

  getJobDuration = (jobId: number, vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetJobDuration + '?vendorId=' + vendorId + '&jobId=' + jobId
    )
  }
  getJobsByHost = (hostId?: string | number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobs + '?hostId=' + hostId)
  }

  getLiveJobs = (
    cleaningProviderId: number,
    isMarketplace: boolean,
    cleaningPeriodStart: any,
    cleaningPeriodEnd: any,
    hasMpManagement: boolean
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetLiveJobs +
        '?cleaningProviderId=' +
        cleaningProviderId +
        '&isMarketplace=' +
        isMarketplace +
        '&cleaningPeriodStart=' +
        cleaningPeriodStart +
        '&cleaningPeriodEnd=' +
        cleaningPeriodEnd +
        '&hasMpManagement=' +
        hasMpManagement
    )
  }

  getClientManagedLiveJobs = (params?: string) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetClientManagedLiveJobs + params)
  }

  getTurnifyManagedLiveJobs = (params?: string) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetTurnifyManagedLiveJobs + params)
  }

  getIsLateStartJobsByProviderId = (lateCase: boolean, cleaningProviderId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetLateStartJobs +
        '?cleaningProviderId=' +
        cleaningProviderId +
        '&isLateStart=' +
        lateCase
    )
  }
  getIsLateFinishJobsByProviderId = (lateCase: boolean, cleaningProviderId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetLateFinishJobs +
        '?cleaningProviderId=' +
        cleaningProviderId +
        '&isLateFinish=' +
        lateCase
    )
  }
  getIsLateStartJobs = (lateCase: boolean) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetLateStartJobs + '?isLateStart=' + lateCase
    )
  }
  getMissingJobs = (lateCase: boolean) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetMissingJobs + '?isMissing=' + lateCase
    )
  }
  getGpsDiscrepancies = (gpsCase: boolean) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetGpsDiscrepanciesJobs + '?startAwayFromProperty=' + gpsCase
    )
  }
  getIsLateFinishJobs = (lateCase: boolean) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetLateFinishJobs + '?isLateFinish=' + lateCase
    )
  }
  getHostLogs = () => {
    return httpClient.get(this.server_link + Endpoints.Host.GetLogs)
  }

  getJobsByStatus = (
    jobStatusId: number,
    jobProviderId: number,
    isMarketplace: boolean,
    cleaningPeriodStart: any,
    cleaningPeriodEnd: any,
    hasMpManagement: boolean
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobsByStatus +
        'cleaningProviderId=' +
        jobProviderId +
        '&jobStatusTypeId=' +
        jobStatusId +
        '&isMarketplace=' +
        isMarketplace +
        '&cleaningPeriodStart=' +
        cleaningPeriodStart +
        '&cleaningPeriodEnd=' +
        cleaningPeriodEnd +
        '&hasMpManagement=' +
        hasMpManagement
    )
  }

  getJobsByStatusId = (hostId: number, jobStatusId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetJobsByStaffId + hostId + '&jobStatusTypeId=' + jobStatusId
    )
  }

  getJobsByStaffId = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsByStaffId + hostId)
  }
  getJobsDailyByStaffId = (userId: number | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsDailyByStaffId + userId)
  }
  getJobsByVendorId = (vendorId: number, cleaningPeriodStart?: any, cleaningPeriodEnd?: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobsByVendorId +
        vendorId +
        (cleaningPeriodStart ? '&cleaningPeriodStart=' + cleaningPeriodStart : '') +
        (cleaningPeriodEnd ? '&cleaningPeriodEnd=' + cleaningPeriodEnd : '')
    )
  }
  getJobsByUserId = (userId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobsByUserId + userId)
  }

  getJobsByPropertyId = (
    hostPropertyId: number,
    cleaningPeriodStart: any,
    cleaningPeriodEnd: any
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetJobsByPropertyId +
        hostPropertyId +
        '&cleaningPeriodStart=' +
        cleaningPeriodStart +
        '&cleaningPeriodEnd=' +
        cleaningPeriodEnd
    )
  }

  addJob = (data: ManualJobRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJob, data)
  }
  getPropertyRelatedJobs = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.AddJob +
        '?hostPropertyId=' +
        hostPropertyId +
        '&jobStatusTypeId=1' +
        '&jobStatusTypeId=2' +
        '&jobStatusTypeId=3' +
        '&jobStatusTypeId=4'
    )
  }

  getJobServices = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobServices + jobId)
  }

  getJobDetails = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobDetails + jobId)
  }
  putForceFinish = (jobId: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.jobForceFinish + jobId)
  }

  addJobService = (data: JobServiceAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobService, data)
  }
  putReAssign = (data: IReAssign) => {
    return httpClient.put(this.server_link + Endpoints.Job.ChangeAssignJob, data)
  }
  deleteJobService = (serviceId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobService + serviceId)
  }

  getJobNotes = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobNotes + jobId)
  }

  addJobNote = (data: JobNoteRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobNote, data)
  }

  getJobTasks = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobTasks + jobId)
  }

  getJobStaffs = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobStaffs + jobId)
  }

  addJobTasks = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobTask, data)
  }

  updateJobTask = (jobTaskId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobTask + jobTaskId, data)
  }

  deleteJobTask = (jobTaskId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobTask + jobTaskId)
  }
  getAllJobIssue = () => {
    return httpClient.get(this.server_link + Endpoints.Job.GetAllIssue)
  }
  getAllJobIssueByProperty = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetAllIssueByPropertyId + propertyId)
  }

  getJobIssues = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetJobIssues + jobId)
  }
  resolveIssue = (jobIssueId: number) => {
    return httpClient.put(this.server_link + Endpoints.Job.ResolveIssue + jobIssueId)
  }

  declineIssue = (jobIssueId: number) => {
    return httpClient.put(this.server_link + Endpoints.Job.DeclineIssue + jobIssueId)
  }

  lateCheckOutIssue = (jobIssueTypeId: number) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetLateIssues + jobIssueTypeId)
  }
  addJobIssue = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobIssue, data)
  }

  updateJobIssue = (jobIssueId: any, data: any) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobIssue + jobIssueId, data)
  }

  deleteJobIssue = (jobTaskId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobIssue + jobTaskId)
  }

  getChecklistPhotos = (jobId: any) => {
    return httpClient.get(this.server_link + Endpoints.Job.GetChecklistPhotos + jobId)
  }

  getJobReview = (jobId: any, serviceTypeId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Job.GetJobReview + jobId + '&serviceTypeId=' + serviceTypeId
    )
  }

  addJobReview = (data: JobReviewRequest) => {
    return httpClient.post(this.server_link + Endpoints.Job.AddJobReview, data)
  }
  deleteJobReview = (reviewId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteReview + reviewId)
  }

  cancelJob = (data: IJobCancelRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.CancelJob + data.jobId + '/cancel', data)
  }

  getStaffsForAddJob = (
    vendorId?: number | string,
    roleId?: number | string,
    propertyId?: number | string,
    cleaningPeriodStart?: string
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetStaffsForAddJob +
        vendorId +
        '&roleId=' +
        roleId +
        '&hostPropertyId=' +
        propertyId +
        '&scheduledStart=' +
        cleaningPeriodStart
    )
  }

  getStaffForAssignJob = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffsForAddJob + vendorId)
  }

  getJobCancelTypes = () => {
    return httpClient.get(this.server_link + Endpoints.JobCancelTypes.GetJobCancelTypes)
  }

  scheduleJob = (data: IScheduledJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.ScheduleJob, data)
  }
  scheduleMyTeamJob = (data: IScheduledMyTeamJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.ScheduleMyTeamJob, data)
  }
  scheduleTurnifyPlusJob = (data: IScheduledTurnifyPlusJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.ScheduleTurnifyPlusJob, data)
  }
  scheduleMarketplaceJob = (data: IScheduledMarketplaceJobRequest) => {
    return httpClient.put(this.server_link + Endpoints.Job.ScheduleMarketplaceJob, data)
  }
  getOffDayEffectedJob = (userId: number, offDay: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Job.GetAllJobs +
        '?userId=' +
        userId +
        '&jobStatusTypeId=2&offDay=' +
        offDay
    )
  }
  putReSchedule = (data: IReVendorStaffAssign) => {
    return httpClient.put(this.server_link + Endpoints.Job.ReSchedule, data)
  }
  putReScheduleMyTeam = (data: IReStaffAssign) => {
    return httpClient.put(this.server_link + Endpoints.Job.ReScheduleMyTeam, data)
  }
  putReScheduleTurnifyPlus = (data: IReStaffAssign) => {
    return httpClient.put(this.server_link + Endpoints.Job.ReScheduleTurnifyPlus, data)
  }
  putReScheduleMarketplace = (data: IReVendorStaffAssign) => {
    return httpClient.put(this.server_link + Endpoints.Job.ReScheduleMarketplaceJob, data)
  }

  //Turnovers

  getTurnoversByPropertyId = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetTurnovers + propertyId)
  }
  getFutureTurnovers = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetFutureTurnovers + hostId)
  }

  getPastTurnovers = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Turnovers.GetPastTurnovers + hostId)
  }

  //PMS
  getPMSTypes = () => {
    return httpClient.get(this.server_link + Endpoints.PMS.GetPMSTypes)
  }

  getPropertiesFromGuesty = (data: GuestyRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromGuesty, data)
  }

  getPropertiesFromHostify = (data: HostawayRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromHostify, data)
  }

  getPropertiesFromMyVr = (data: MyVrRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPropertiesFromMyVr, data)
  }

  postHostPropertyList = (data: IHostPropertyInput[]) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostHostProperties, data)
  }

  getAllProperties = () => {
    return httpClient.get(this.server_link + Endpoints.Property.GetAllProperties)
  }

  getSearchedProperties = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Property.GetSearchedProperties, data)
  }

  getHostPropertiesByStatus = (hostId: number, statusId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetProperties +
        hostId +
        '&hostPropertyStatusTypeId=' +
        statusId
    )
  }

  getHostProperties = (hostId?: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetProperties + hostId)
  }

  getHostPropertiesByProvider = (hostId: any, cleaningProviderId: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetProperties +
        hostId +
        '&cleaningProviderId=' +
        cleaningProviderId
    )
  }

  getPropertiesByStatusId = (
    statusId: number | null,
    cleaningProviderId: any = null,
    isMarketplace: any = null
  ) => {
    if (cleaningProviderId != null) {
      var query = statusId + '&cleaningProviderId=' + cleaningProviderId
      if (isMarketplace != null) {
        query =
          statusId + '&cleaningProviderId=' + cleaningProviderId + '&isMarketplace=' + isMarketplace
      }
      return httpClient.get(this.server_link + Endpoints.Property.GetPropertiesByStatusId + query)
    } else {
      return httpClient.get(
        this.server_link + Endpoints.Property.GetPropertiesByStatusId + statusId
      )
    }
  }

  getPropertyById = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetPropertyById + propertyId)
  }

  getPendingProperties = () => {
    return httpClient.post(this.server_link + Endpoints.Property.GetPendingProperties)
  }

  getAddJobPropertiesByAddress = (key: string | null) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetPropertiesByAddress + key)
  }

  postHostProperty = (data: IHostPropertyInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostHostProperty, data)
  }

  createHostProperty = (data: IHostPropertyAdminInput) => {
    return httpClient.post(this.server_link + Endpoints.Property.CreateHostProperty, data)
  }

  updateHostProperty = (hostPropertyId: number, data: IHostPropertyInput) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.UpdateHostProperty + hostPropertyId,
      data
    )
  }

  getHostPropertyOtherQuestion = () => {
    return httpClient.get(this.server_link + Endpoints.Property.HostPropertyOtherQuestion)
  }

  getHostPropertyById = (propertyId?: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetHostProperty + propertyId)
  }

  getHostPropertyInvoices = (propertyId?: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostPropertyInvoices + propertyId
    )
  }

  getPropertyIsAutoEnabled = (propertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetPropertyIsAutoEnabled + propertyId
    )
  }

  getAllowedProperties = (staffId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.AllowedProperties + staffId)
  }

  getBannedProperties = (userId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.BannedProperties + userId + '/properties'
    )
  }

  getAcceptedProperties = (hostPropertyId: any) => {
    return httpClient.get(this.server_link + Endpoints.Property.AcceptedProperties + hostPropertyId)
  }

  addPropertyPermission = (data: PermissionRequest[]) => {
    return httpClient.post(this.server_link + Endpoints.Property.AddPropertyPermission, data)
  }

  banUserFromProperty = (data: BanRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.BanUserFromProperty, data)
  }
  putPrirorityProperty = (data: any) => {
    return httpClient.put(this.server_link + Endpoints.Property.PutPriorityProperty, data)
  }

  getExcludedStaffByPropertyId = (propertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetExcludedStaff + propertyId + '/users'
    )
  }
  deleteExcludeStaff = (excludeId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Property.DeleteExcludedStaff + excludeId)
  }

  getAvailablePropertiesByVendorId = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.GetVendorProperties + vendorId)
  }
  getExcludeVendor = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetExcludeVendor + vendorId + '/properties'
    )
  }
  postExcludeVendor = (data: ExcludeVendor) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostExcludeVendor, data)
  }
  deleteExcludeVendor = (excludeId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Property.DeleteExcludeVendor + excludeId)
  }
  deletePropertyPermission = (permissionId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeletePropertyPermission + permissionId
    )
  }

  deleteBanUserFromProperty = (permissionId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeleteBanUserFromProperty + permissionId
    )
  }

  editPropertyStatus = (propertyId: any, statusId: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Property.EditPropertyStatus + propertyId + '/edit-status',
      {hostPropertyId: parseInt(propertyId), hostPropertyStatusTypeId: statusId}
    )
  }

  getHostPropertyServiceOffers = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetHostPropertyServiceOffers +
        '?hostPropertyId=' +
        hostPropertyId
    )
  }

  deleteHostPropertyServiceOffer = (id: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeleteHostPropertyServiceOffers + id
    )
  }

  deleteHostPropertyServiceRate = (id: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeleteHostPropertyServiceRate + id
    )
  }

  getHostServiceOffersAbleVendors = (offerId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostServiceOffersAbleVendors + offerId
    )
  }

  getHostServiceRateAbleVendors = (offerId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostServiceRateAbleVendors + offerId
    )
  }

  updateHostPropertyServiceRate = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.UpdateHostPropertyServiceRate,
      data
    )
  }

  getHostServiceOffersAbleMarketplaceVendors = (offerId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostServiceOffersAbleMarketplaceVendors + offerId
    )
  }

  getHostPropertyServiceOffersByVendorId = (
    hostPropertyId: number | string | undefined,
    vendorId: number | string | null | undefined,
    scheduledStart: number | string | null | undefined
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Property.GetHostPropertyServiceOffers +
        '?hostPropertyId=' +
        hostPropertyId +
        '&vendorId=' +
        vendorId +
        '&scheduledStart=' +
        scheduledStart
    )
  }

  getPropertyServiceOffers = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.HostPropertyAvailableServices + hostPropertyId
    )
  }

  addHostPropertyServiceRate = (data: IHostPropertyOfferedRateRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.AddHostPropertyServiceRate, data)
  }

  addHostPropertyVendorServiceRate = (data: IHostPropertyVendorServiceRateRequest) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.AddHostPropertyVendorServiceRate,
      data
    )
  }

  addHostPropertyServiceOffer = (data: IHostPropertyOfferedServiceRequest) => {
    return httpClient.post(this.server_link + Endpoints.Property.AddHostPropertyServiceOffer, data)
  }
  postRefreshPro = (data: {isWeekly: boolean; hostPropertyId: number}) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostRefreshJobSyncPro, data)
  }
  postRefreshMyTeam = (data: {isWeekly: boolean; hostPropertyId: number}) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostRefreshJobSyncMyteam, data)
  }
  postRefreshPlus = (data: {isWeekly: boolean; hostPropertyId: number}) => {
    return httpClient.post(this.server_link + Endpoints.Property.PostRefreshJobSyncPlus, data)
  }
  postRefreshCalendar = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.PostRefreshCalendar + propertyId)
  }
  postResetPropertyJobs = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.PostResetJobs + propertyId)
  }
  postResetHostPropertyJobs = (propertyId: number) => {
    return httpClient.get(this.server_link + Endpoints.Property.PostResetHostJobs + propertyId)
  }

  //Job Force Finish
  getJobForceFinish = () => {
    return httpClient.get(this.server_link + Endpoints.JobForceFinishRequest.GetForce)
  }
  postApproveForce = (jobId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.JobForceFinishRequest.PostApproveForce + jobId
    )
  }
  postDeclineForce = (jobId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.JobForceFinishRequest.PostDeclineForce + jobId
    )
  }

  //Countries
  getCountries = () => {
    return httpClient.get(this.server_link + Endpoints.Countries.GetCountries)
  }

  //Cleaning Providers
  getCleaningProviders = () => {
    return httpClient.get(this.server_link + Endpoints.CleaningProviders.GetCleaningProviders)
  }

  //Access Types
  getAccessTypes = () => {
    return httpClient.get(this.server_link + Endpoints.AccessTypes.GetAccessTypes)
  }

  //Checklists
  getMasterChecklists = () => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetMasterChecklists)
  }

  getHostMasterChecklists = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.Checklist.GetMasterChecklists + '?hostId=' + hostId
    )
  }

  getHostPropertyChecklists = (hostPropertyId?: number, roleId?: any, serviceTypeId?: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Checklist.GetHostPropertyChecklists +
        hostPropertyId +
        (serviceTypeId ? `&serviceTypeId=${serviceTypeId}` : '') +
        (roleId ? `&roleId=${roleId}` : '')
    )
  }
  postHostPropertyChecklist = (data: ChecklistAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostHostPropertyChecklist, data)
  }

  editChecklist = (data: ChecklistEditRequest, hostPropertyChecklistId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.EditChecklist + hostPropertyChecklistId,
      data
    )
  }

  updateChecklistMainInfo = (
    data: ChecklistMainUpdateRequest,
    hostPropertyChecklistId?: number
  ) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.UpdateMainChecklist + hostPropertyChecklistId,
      data
    )
  }

  getChecklistItems = (checklistId: number) => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetChecklistItems + checklistId)
  }

  addChecklistSection = (data: ChecklistSectionAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.AddChecklistSection, data)
  }

  getChecklistSectionTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Checklist.GetChecklistSectionTypes)
  }

  postCloneCheckLists = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Checklist.PostCloneChecklist, data)
  }

  updateFutureJobsChecklistsByProperty = (data: any) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.UpdateFutureJobsChecklistsByProperty,
      data
    )
  }

  setSectionOrder = (data: ChecklistSetSectionOrderRequest[], checklistId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.SetSectionOrder + checklistId + '/sections/order',
      data
    )
  }

  setQuestionOrder = (data: ChecklistSetQuestionOrderRequest[], sectionId?: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Checklist.SetSectionOrder + sectionId + '/questions/order',
      data
    )
  }

  editSection = (data: ChecklistSectionEditRequest, sectionId?: number) => {
    return httpClient.put(this.server_link + Endpoints.Checklist.EditSection + sectionId, data)
  }

  editQuestion = (data: ChecklistQuestionEditRequest, questionId?: number) => {
    return httpClient.put(this.server_link + Endpoints.Checklist.EditQuestion + questionId, data)
  }

  addQuestion = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Checklist.AddQuestion, data)
  }

  editQuestionPhoto = (data: any, samplePhotoId: any) => {
    return httpClientFormData.put(
      this.server_link + Endpoints.Checklist.EditQuestionPhoto + samplePhotoId,
      data
    )
  }

  newQuestionPhoto = (data: any) => {
    return httpClientFormData.post(this.server_link + Endpoints.Checklist.NewQuestionPhoto, data)
  }

  deleteChecklist = (checklistId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteChecklist + checklistId)
  }
  deleteSamplePhoto = (samplePhotoId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Checklist.DeleteSamplePhoto + samplePhotoId
    )
  }

  deleteChecklistSection = (sectionId?: number) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteSection + sectionId)
  }

  deleteSectionQuestion = (questionId?: number) => {
    return httpClient.delete(this.server_link + Endpoints.Checklist.DeleteQuestion + questionId)
  }

  //Vendor-user-document
  getVendorUserDocumentTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetUserDocumentTypes)
  }
  getVendorUserDocuments = (userId?: number, vendorId?: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetUserDocuments +
        'userId=' +
        userId +
        '&vendorId=' +
        vendorId
    )
  }
  createVendorUserDocuments = (data: FormData) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateUserDocument, data)
  }
  deleteUserDocuments = (vendorUserDocumentId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.Staff.DeleteUserDocument + vendorUserDocumentId
    )
  }
  //Staff

  getStaffByHostId = (hostId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffsByHostId + hostId)
  }

  getStaffByVendorId = (vendorId: number | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetStaffsByVendorId + vendorId)
  }

  createStaffByHostId = (data: StaffAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateStaffByHostId, data)
  }

  createStaffByVendorId = (data: StaffAddRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.CreateStaffByVendorId, data)
  }

  getStaffEmailNotVerified = (vendorId: number | undefined, accountStatusId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.GetStaffsByVendorId +
        vendorId +
        '&accountStatusId=' +
        accountStatusId
    )
  }
  getHostStaffDetails = (hostUserId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetHostStaffDetails + hostUserId)
  }

  getVendorStaffDetails = (vendorUserId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetVendorStaffDetails + vendorUserId)
  }

  addStaffOfferedService = (data: StaffOfferedRequest) => {
    return httpClient.post(this.server_link + Endpoints.Staff.AddStaffOfferedService, data)
  }

  updateStaffOfferedService = (offeredServiceId: any, data: StaffOfferedRequest) => {
    return httpClient.put(
      this.server_link + Endpoints.Staff.UpdateStaffOfferedService + offeredServiceId,
      data
    )
  }

  deleteStaffOfferedService = (offeredServiceId: any) => {
    return httpClient.delete(
      this.server_link + Endpoints.Staff.DeleteStaffOfferedservice + offeredServiceId
    )
  }
  getStaffCityService = (userId: number, vendorId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Staff.StaffServiceCities +
        '?userId=' +
        userId +
        '&vendorId=' +
        vendorId
    )
  }
  postStaffCityService = (data: StaffCityService) => {
    return httpClient.post(this.server_link + Endpoints.Staff.StaffServiceCities, data)
  }
  deleteStaffCityService = (data: StaffCityService) => {
    return httpClient.delete(this.server_link + Endpoints.Staff.StaffServiceCities, {data})
  }
  getOutOfServiceCity = (vendorId: number) => {
    return httpClient.get(this.server_link + Endpoints.Staff.GetOutOfServiceCities + vendorId)
  }

  //Staff Status
  getStaffStatusTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Staff.StaffStatusTypes)
  }
  getPendingStaff = (vendorId: number, accountStatusId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Staff.GetPendingStaffs + 'accountStatusId=' + accountStatusId
    )
  }
  updateVendorStaffStatus = (data: any, userId: number) => {
    return httpClient.put(
      this.server_link + Endpoints.Staff.UpdateVendorStaffStatus + '/' + userId,
      data
    )
  }
  updateStaffStatus = (data: any, userId: any) => {
    return httpClient.put(this.server_link + Endpoints.Staff.UpdateStaffStatus + '/' + userId, data)
  }
  declineVendorStaffStatus = (data: any) => {
    return httpClient.put(
      this.server_link + Endpoints.Staff.UpdateVendorStaffStatus + '/' + data.userId,
      data
    )
  }

  //Roles
  getStaffRoles = () => {
    return httpClient.get(this.server_link + Endpoints.Roles.GetStaffRoles)
  }

  //Service
  getServiceTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Service.GetServiceTypes)
  }
  CreateServiceTypes = (data: IService) => {
    return httpClient.post(this.server_link + Endpoints.Service.PostServiceType, data)
  }
  UpdateServiceTypes = (serviceId: any, data: IService) => {
    return httpClient.put(this.server_link + Endpoints.Service.PutServiceType + serviceId, data)
  }

  deleteServiceTypes = (serviceId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Service.DeleteServiceType + serviceId)
  }

  //Service Group
  getServiceGroups = () => {
    return httpClient.get(this.server_link + Endpoints.ServiceGroup.GetServiceGroups)
  }
  getServiceGroupsWithServiceTypes = () => {
    return httpClient.get(
      this.server_link + Endpoints.ServiceGroup.GetServiceGroupsWithServiceTypes
    )
  }
  CreateServiceGroup = (data: IServiceGroup) => {
    return httpClient.post(this.server_link + Endpoints.ServiceGroup.PostServiceGroup, data)
  }
  UpdateServiceGroup = (serviceGroupId: any, data: IServiceGroup) => {
    return httpClient.put(
      this.server_link + Endpoints.ServiceGroup.PutServiceGroup + serviceGroupId,
      data
    )
  }

  deleteServiceGroup = (serviceGroupId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.ServiceGroup.DeleteServiceGroup + serviceGroupId
    )
  }

  //Answer Types
  getChecklistAnswerTypes = () => {
    return httpClient.get(this.server_link + Endpoints.ChecklistAnswerTypes.GetChecklistAnswerTypes)
  }

  //Languages
  getLanguages = () => {
    return httpClient.get(this.server_link + Endpoints.Languages.GetLanguages)
  }

  //Address
  getAddressesByUserId = (userId: any) => {
    return httpClient.get(this.server_link + Endpoints.Address.GetAddressByUserId + userId)
  }

  createAddress = (userId: any, data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Address.CreateAddress + userId + '/address',
      data
    )
  }

  updateAddress = (userId: any, addressId: any, data: AddressRequest | IStaffAddress) => {
    return httpClient.put(
      this.server_link + Endpoints.Address.UpdateAddress + userId + '/address/' + addressId,
      data
    )
  }

  deleteAddress = (addressId: any) => {
    return httpClient.delete(this.server_link + Endpoints.Address.DeleteAddress + addressId)
  }

  getAddressTypes = () => {
    return httpClient.get(this.server_link + Endpoints.Address.GetAddressTypes)
  }

  //Offered Services
  getOfferedServices = (userId: any) => {
    return httpClient.get(this.server_link + Endpoints.OfferedServices.GetOfferedServices + userId)
  }

  getOfferedServicesByHostId = (hostId: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OfferedServices.GetOfferedServicesByHostId + hostId
    )
  }

  getOfferedStaffs = (hostId: any, hostPropertyId: any, serviceTypeId: any, roleId: any) => {
    return httpClient.get(
      this.server_link +
        Endpoints.OfferedServices.GetOfferedStaffs +
        serviceTypeId +
        '&roleId=' +
        roleId +
        '&hostId=' +
        hostId +
        '&hostPropertyId=' +
        hostPropertyId
    )
  }
  getVendorOfferedServices = (vendorId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.VendorOfferedServices.GetVendorOfferedService +
        '?vendorId=' +
        vendorId
    )
  }
  getVendorOfferedServicesForJob = (
    vendorId?: number,
    serviceTypeId?: number,
    hostPropertyId?: number
  ) => {
    return httpClient.get(
      this.server_link +
        Endpoints.VendorOfferedServices.GetVendorOffersWithProperty +
        '?hostPropertyId=' +
        hostPropertyId +
        '&vendorId=' +
        vendorId +
        '&serviceTypeId=' +
        serviceTypeId
    )
  }

  postVendorServices = (data: IVendorServiceRequest) => {
    return httpClient.post(
      this.server_link + Endpoints.VendorOfferedServices.CreateVendorOfferedService,
      data
    )
  }

  editVendorServices = (id: any, data: IServicePrice) => {
    return httpClient.put(
      this.server_link + Endpoints.VendorOfferedServices.CreateVendorOfferedService + '/' + id,
      data
    )
  }

  deleteVendorService = (id: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.VendorOfferedServices.DeleteVendorOffered + id
    )
  }

  deleteVendorServicePrice = (id: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.VendorOfferedServices.DeleteVendorOfferedService + id
    )
  }

  getAvailableVendorServices = (hostPropertyId: number, serviceTypeId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.OfferedServices.GetAvailableVendorServices +
        '?hostPropertyId=' +
        hostPropertyId +
        '&serviceTypeId=' +
        serviceTypeId
    )
  }

  getAvailableMarketplaceVendorServices = (hostPropertyId: number, serviceTypeId: number) => {
    return httpClient.get(
      this.server_link +
        Endpoints.OfferedServices.GetAvailableMarketplaceVendorServices +
        '?hostPropertyId=' +
        hostPropertyId +
        '&serviceTypeId=' +
        serviceTypeId
    )
  }

  getOfferedService = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.OfferedServices.GetOfferedServiceType + hostPropertyId
    )
  }

  getMarketplaceOfferedService = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.OfferedServices.GetMarketplaceOfferedServiceType + hostPropertyId
    )
  }
  //Off Days
  getStaffOffDays = (staffId: any, roleId?: any) => {
    return httpClient.get(this.server_link + Endpoints.OffDays.GetStaffOffDays + staffId)
  }
  postStaffOffDays = (data: IPostOffDays) => {
    return httpClient.post(this.server_link + Endpoints.OffDays.PostStaffOffDays, data)
  }
  deleteStaffOffDays = (offDaysId: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.OffDays.DeleteStaffOffDays + '/' + offDaysId
    )
  }

  getStaffOffTime = (staffId: any, roleId?: any) => {
    return httpClient.get(
      this.server_link + Endpoints.OffDays.GetStaffOffTime + staffId + '&roleId=' + roleId
    )
  }
  postStaffOffTime = (data: any, roleId?: any) => {
    return httpClient.post(this.server_link + Endpoints.OffDays.PostStaffOffTime, data)
  }
  deleteStaffOffTime = (offTimeId: any, roleId?: any) => {
    return httpClient.delete(this.server_link + Endpoints.OffDays.PostStaffOffTime + offTimeId)
  }

  //Rate Types
  getRateTypes = () => {
    return httpClient.get(this.server_link + Endpoints.JobRatingTypes.GetJobRatingTypes)
  }

  getGlobalRateTypes = () => {
    return httpClient.get(this.server_link + Endpoints.RateType.GetAll)
  }

  //Billings
  getAllBillings = () => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetAllBillings)
  }

  getBillingsByHostId = (hostId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingsByHostId + hostId)
  }

  getBillingsByJobId = (jobId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingsByHostId + jobId)
  }

  getBillingDetails = (billingId: number | string) => {
    return httpClient.get(this.server_link + Endpoints.Billing.GetBillingDetail + billingId)
  }

  getBillingDetailsForMyTeam = (billingId: number | string) => {
    return httpClient.get(
      this.server_link + Endpoints.Billing.GetBillingDetailForMyTeam + billingId
    )
  }

  refundBilling = (billingId: number, data: any) => {
    return httpClient.patch(
      this.server_link + Endpoints.Billing.RefundBilling + billingId + '/refund',
      data
    )
  }

  chargeBilling = (billingId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.Billing.ChargeBilling + billingId + '/charge'
    )
  }

  chargeMyTeamBilling = (billingId: number) => {
    return httpClient.post(
      this.server_link + Endpoints.Billing.ChargeBilling + billingId + '/charge-myteam'
    )
  }

  //DashBoard
  getDashboardContent = () => {
    return httpClient.get(this.server_link + Endpoints.DashboardContent.GetDashboardContent)
  }

  //Virtual Inspections((
  getHasVirtualInspectionsJobs = () => {
    return httpClient.get(this.server_link + Endpoints.Job.GetVirtualInspection)
  }
  getVirtualInspections = (jobId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.VirtualInspection.GetVirtualInspections + jobId
    )
  }

  getVirtualInspectionsByJobId = (id: any) => {
    return httpClient.get(
      this.server_link + Endpoints.VirtualInspection.GetVirtualInspectionsByJobId + id
    )
  }

  postVirtualInspections = (jobServiceId: number) => {
    return httpClient.post(this.server_link + Endpoints.VirtualInspection.PostVirtualInspections, {
      jobServiceId,
    })
  }
  putApproveVirtualInspections = (jobId: number) => {
    return httpClient.put(
      this.server_link +
        Endpoints.VirtualInspection.PutApproveVirtualInspections +
        jobId +
        '/approve'
    )
  }
  putDeclineVirtualInspections = (data: DeclineVirtualInspect) => {
    return httpClient.put(
      this.server_link + Endpoints.VirtualInspection.PutDeclineVirtualInspections + 'decline',
      data
    )
  }

  //Search
  getSearch = (searchText: string | null) => {
    return httpClient.get(this.server_link + Endpoints.Search.GetSearch + searchText)
  }

  //Host Search
  getHostSearch = (searchText: string | null) => {
    return httpClient.get(this.server_link + Endpoints.Search.GetHostSearch + searchText)
  }

  //Update Notes
  updateJobNote = (noteId: string | number, note: string) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobNote + noteId, {note})
  }
  //Delete Notes
  deleteJobNote = (noteId: number) => {
    return httpClient.delete(this.server_link + Endpoints.Job.DeleteJobNote + noteId)
  }

  updateJobServiceNote = (noteId: string | number, note: string) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobServiceNote + noteId, {note})
  }

  updateJobTaskNote = (noteId: string | number, note: string) => {
    return httpClient.put(this.server_link + Endpoints.Job.UpdateJobTaskNote + noteId, {note})
  }

  //ICal Validation
  validateICal = (link: string) => {
    return httpClient.post(this.server_link + Endpoints.ICalValidation.ValidateICall, {link})
  }

  //Notification
  getNotifications = () => {
    return httpClient.get(this.server_link + Endpoints.Notification.GetNotifications)
  }

  readNotification = (data: number[]) => {
    return httpClient.put(this.server_link + Endpoints.Notification.ReadNotification, {
      notificationIds: [...data],
    })
  }

  //Settings
  getPaymentFees = () => {
    return httpClient.get(this.server_link + Endpoints.Settings.GetPaymentFees)
  }

  updatePaymentFee = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Settings.UpdatePaymentFee, data)
  }

  getVendorSubscriptionPacks = () => {
    return httpClient.get(this.server_link + Endpoints.Settings.GetVendorSubscriptionPacks)
  }

  updateVendorSubscriptionPack = (data: any) => {
    return httpClient.put(this.server_link + Endpoints.Settings.UpdateVendorSubscriptionPack, data)
  }

  //Marketplace
  getMarketplaceRequests = () => {
    return httpClient.get(this.server_link + Endpoints.Marketplace.GetRequests)
  }

  getMarketplaceManagedProperties = () => {
    return httpClient.get(this.server_link + Endpoints.Property.GetMarketplaceManagedProperties)
  }

  getMarketplaceRequestDetail = (requestId: any) => {
    return httpClient.get(this.server_link + Endpoints.Marketplace.GetRequestDetail + requestId)
  }

  getPropertiesJobRates = () => {
    return httpClient.get(this.server_link + Endpoints.Property.GetJobRates)
  }

  getAllHostPropertyServiceRates = () => {
    return httpClient.get(this.server_link + Endpoints.Property.GetAllHostPropertyServiceRates)
  }

  getPropertyOffers = (hostPropertyId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Marketplace.GetPropertyOffers + hostPropertyId
    )
  }

  getPropertyOffersByService = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Marketplace.GetPropertyOffersByService,
      data
    )
  }

  getHostPropertyMarketplaceServices = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Marketplace.GetPropertyMarketplaceServices + hostPropertyId
    )
  }
  getHostPropertyAcceptedMarketplaceServices = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link +
        Endpoints.Marketplace.GetPropertyAcceptedMarketplaceServices +
        hostPropertyId
    )
  }

  getMarketplaceVendorsByJobId = (jobId: number | null | undefined) => {
    return httpClient.get(this.server_link + Endpoints.Marketplace.GetVendorsByJobId + jobId)
  }

  addPropertyOffer = (data: any) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.AddPropertyOffer, data)
  }

  deleteMarketplaceOffer = (data: number[]) => {
    return httpClient.post(this.server_link + Endpoints.Marketplace.DeleteMarketplaceOffers, data)
  }

  //New Service Rate System
  getHostPropertyServiceRates = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostPropertyServiceRates + hostPropertyId
    )
  }

  getHostPropertyServiceRateList = (hostPropertyId: number | string | undefined) => {
    return httpClient.get(
      this.server_link + Endpoints.Property.GetHostPropertyServiceRateList + hostPropertyId
    )
  }

  getHostPropertyServiceRateOffersWithVariables = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.GetHostPropertyServiceRatesOffersWithVariables,
      data
    )
  }

  updateHostPropertyOfferedRate = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.UpdateHostPropertyServiceRate,
      data
    )
  }

  updateHostPropertyOfferedRateOffer = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.UpdateHostPropertyServiceRateOffer,
      data
    )
  }

  updateHostPropertyOfferedRateOfferedPrice = (data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Property.UpdateHostPropertyServiceRateOfferedRate,
      data
    )
  }

  deleteHostPropertyOfferedRateOffer = (id: number) => {
    return httpClient.delete(
      this.server_link + Endpoints.Property.DeleteHostPropertyServiceRateOffer + id
    )
  }

  answerOfferPending = (propertyId: any, statusId: number) => {
    return httpClient.put(
      this.server_link +
        Endpoints.Property.EditPropertyStatus +
        propertyId +
        '/answer-offer-pending',
      {hostPropertyId: parseInt(propertyId), hostPropertyStatusTypeId: statusId}
    )
  }

  //Subscription
  getSubscriptionPacks = () => {
    return httpClient.get(this.server_link + Endpoints.Subscription.GetPacks)
  }

  subscribeByAdmin = (vendorId: number, data: any) => {
    return httpClient.post(
      this.server_link + Endpoints.Subscription.SubscribeByAdmin + vendorId,
      data
    )
  }
  getVendorSubscriptions = (vendorId: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Subscription.GetVendorSubscriptions + vendorId
    )
  }
  getVendorSubscriptionInvoiceDetail = (invoiceNum: number) => {
    return httpClient.get(
      this.server_link + Endpoints.Subscription.GetVendorSubscriptionInvoiceDetail + invoiceNum
    )
  }
  chargeSubscriptionInvoice = (invoiceNum: number) => {
    return httpClient.post(
      this.server_link + Endpoints.Subscription.ChargeSubscriptionInvoice + invoiceNum
    )
  }
  cancelSubscription = (id: number) => {
    return httpClient.get(this.server_link + Endpoints.Subscription.CancelSubscription + id)
  }
}

export default new ApiCalls()
