import React, { Dispatch, useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Editing, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import UpdateButton from '../../../umut-components/Buttons/UpdateButton';
import { IHostPropertyCheckList } from '../../../interfaces/Checklist';
import ViewButton from '../../../umut-components/Buttons/ViewButton';
import { ChecklistEditRequest } from '../../../network/PostRequestModels/ChecklistRequests';
import ApiCalls from '../../../network/ApiCalls';
import { useDialog } from '../../../contexts/DialogContext';
import { useDispatch } from 'react-redux';
import { ChecklistAction } from '../../../redux/actionTypes/masterChecklistTypes';
import * as checklistActionCreator from '../../../redux/actionCreators/masterChecklistActionCreators';
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
import { encryptText, onExporting, setDxFilterPlaceholder } from '../../../utils/util';
import ChecklistButton from '../../../umut-components/Buttons/ChecklistButton';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';
import DeleteButton from '../../../umut-components/Buttons/DeleteButton';
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes';
import { closeDeleteDialog, openDeleteDialog } from '../../../redux/actionCreators/deleteDialogActionCreators';

type Props = {
    data: IHostPropertyCheckList[],
    refetchChecklists: () => void
}

const ChecklistTable: React.FC<Props> = ({ data, refetchChecklists }) => {
    const history = useHistory();
    const { showSuccessDialog, showFailureDialog } = useDialog();
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();
    const deleteDispatch = useDispatch<Dispatch<DeleteActions>>();
    const tableRef = useRef<DataGrid>(null)
    const renderSectionButton = (cellValue: any) => {
        return (
            <ChecklistButton iconWidth="20" iconHeight="20" viewFn={() => { history.push({ pathname: '/master-checklist-sections/' + encryptText(cellValue?.data?.id) }) }} />
        )
    }

    const renderEditButton = (cellValue: any) => {
        return (
            <UpdateButton iconWidth="20" iconHeight="20" updateFn={() => { dispatch(checklistActionCreator.openChecklistEditDialog(cellValue?.data)) }} />
        )
    }

    const renderDeleteButton = (cellValue: any) => {
        const deleteAction = async () => {
            try {
                await ApiCalls.deleteChecklist(cellValue?.data?.id);
                refetchChecklists();
                deleteDispatch(closeDeleteDialog());
                showSuccessDialog('Checklist removed!');
            }
            catch (err: any) {
                showFailureDialog(err?.response?.data?.message);
            }
        }
        const deleteButtonClick = async () => {
            deleteDispatch(openDeleteDialog(deleteAction, `Do you want to remove checklist ${cellValue?.data?.name?.en}?`, "Remove Checklist"));
        }

        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={deleteButtonClick} />
        )
    }

    const renderTooltip = (props: any, role: any) => (
        <Tooltip id="button-tooltip" {...props}>
            This is the default checklist for {role}
        </Tooltip>
    );

    const renderDefaultButton = (cellValue: any) => {
        return (
            <div className='d-flex justify-content-center'>
                {cellValue?.data?.isDefault ?
                    <OverlayTrigger
                        placement='top'
                        overlay={props => renderTooltip(props, cellValue?.data?.serviceTypeName + ' ' + cellValue?.data?.roleName + "/" + cellValue?.data?.lobRoleName)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark-fill" viewBox="0 0 16 16">
                            <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                        </svg>
                    </OverlayTrigger> : null}
            </div>
        )
    }

    const calculateRoleValue = (cellValue: any) => {
        return cellValue.roleName + "/" + cellValue.lobRoleName
    }

    const calculateChecklistName = (cellValue: any) => {
        return cellValue?.name?.en ? cellValue?.name?.en : cellValue?.name[Object.keys(cellValue?.name)[0]]
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Checklists" />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="checklistTable"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Checklists")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Checklists: {0}" column='name' summaryType='count' />
                </Summary>

                <Column width={50} cellRender={renderDefaultButton} cssClass='cls' />
                <Column dataField="name" calculateCellValue={calculateChecklistName} caption="Checklist Name" minWidth={150} cssClass='cls' />
                <Column dataField="serviceTypeName" caption="Service" minWidth={150} cssClass='cls' />
                <Column dataField='roleName' caption="Role" minWidth={150} cssClass='cls' />
                <Column dataField='lobRoleName' caption="Lob Role" minWidth={150} cssClass='cls' />
                <Column width={100} cellRender={renderSectionButton} cssClass='cls' />
                <Column width={100} cellRender={renderDeleteButton} cssClass='cls' />
            </DataGrid>

        </div>
    )
}

export default React.memo(ChecklistTable);

//                 <Column width={100} cellRender={renderEditButton} cssClass='cls' />