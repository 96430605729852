import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  Pager,
} from 'devextreme-react/data-grid'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  getJobDateFilter,
  toConvertedDate,
  setDxFilterPlaceholder,
  decryptText,
  toConvertedDateTz,
  generateRandomNumber,
} from '../../utils/util'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {JobStatus, JobStatusObj, JobStatusType} from '../../enums/JobEnum'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import {useHistory, useParams} from 'react-router'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import config from '../../config/config.json'
import dayjs from 'dayjs'
import DatePicker from '../../umut-components/Inputs/DatePicker'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  jobStatusId: any
  type: 'turnify-managed' | 'client-managed'
}

const JobsTable: React.FC<Props> = ({jobStatusId, type}) => {
  const {showFailureDialog} = useDialog()
  const [keyNum, setKeyNum] = useState(generateRandomNumber(1000000, 9999999))
  const [cleaningPeriodStart, setCleaningPeriodStart] = useState(
    dayjs().add(-3, 'day').format('YYYY-MM-DD')
  )
  const [cleaningPeriodEnd, setCleaningPeriodEnd] = useState(
    dayjs().add(30, 'day').format('YYYY-MM-DD')
  )
  const [jobList, setJobList] = useState([])
  const [jobsLoading, setJobsLoading] = useState<boolean>(false)

  let {providerName}: any = useParams()

  const tableRef = useRef<DataGrid>(null)
  const {selectedJobTab, selectedMyJobTab} = useSelector((state: RedusxAppState) => state.job)

  const {data, isLoading, error, refetch} = useQuery(
    ['Get Jobs By Status Id', jobStatusId, type, cleaningPeriodStart, cleaningPeriodEnd, keyNum],
    () =>
      type == 'turnify-managed'
        ? ApiCalls.getTurnifyManagedAllJobs(
            '?jobStatusIds=' +
              jobStatusId +
              '&cleaningPeriodStart=' +
              cleaningPeriodStart +
              '&cleaningPeriodEnd=' +
              cleaningPeriodEnd
          )
        : ApiCalls.getClientManagedAllJobs(
            '?jobStatusIds=' +
              jobStatusId +
              '&cleaningPeriodStart=' +
              cleaningPeriodStart +
              '&cleaningPeriodEnd=' +
              cleaningPeriodEnd
          ),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (!isLoading && data?.data != undefined && data?.data?.data) {
      setJobList(data?.data?.data)
      setJobsLoading(false)
    } else {
      setJobList([])
      setJobsLoading(true)
    }
  }, [data, jobsLoading])

  const toAdminDetail = (cellData: any) => {
    if (cellData?.column?.name !== 'delete-button' && cellData?.data?.id)
      window.open(`/job-details/` + encryptText(cellData?.data?.id))
  }
  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    const isForceFinish = cellData?.data?.isForceFinish

    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started'
          ? 'In Progress'
          : jobStatusType == 'Completed' && isForceFinish
          ? 'Completed (FF)'
          : jobStatusType}
      </strong>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDateTz(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const renderProvider = (cellValue: any) => {
    if (cellValue?.cleaningProviderId == 1) {
      return 'My Team'
    }
    if (cellValue?.cleaningProviderId == 3) {
      return 'Turnify+'
    }
    if (cellValue?.cleaningProviderId == 2) {
      if (cellValue?.isMarketplace) {
        if (cellValue?.hasMpManagement) {
          return 'MP+'
        } else {
          return 'MP'
        }
      } else {
        return 'PRO'
      }
    }
  }

  const renderCreateUser = (cellValue: any) => {
    if (cellValue?.jobStatusTypeId === 6) {
      if (cellValue?.cancelUser?.id) {
        return cellValue?.cancelUser?.firstName + ' ' + cellValue?.cancelUser?.lastName
      } else {
        return 'System'
      }
    } else {
      if (cellValue?.createUser?.id) {
        return cellValue?.createUser?.firstName + ' ' + cellValue?.createUser?.lastName
      } else {
        return 'System'
      }
    }
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDateTz(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  const isManuallyCreatedJob = (cellValue: any) => {
    return cellValue?.data.isManuallyCreated ? (
      <p>
        {cellValue.data.hostPropertyAddress}
        <span style={{color: '#ff5d5d'}}> ⓜ</span>
      </p>
    ) : (
      cellValue.data.hostPropertyAddress
    )
  }

  const calculateCleaningPeriodStart = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodEnd = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodEnd, cellValue.hostPropertyCityTimeZone)
  }

  const changeStartDate = (date: any) => {
    setCleaningPeriodStart(dayjs(date).format('YYYY-MM-DD'))
  }

  const changeEndDate = (date: any) => {
    setCleaningPeriodEnd(dayjs(date).format('YYYY-MM-DD'))
  }

  const searchFilteredJobs = () => {
    if (dayjs(cleaningPeriodStart).isAfter(cleaningPeriodEnd)) {
      showFailureDialog('Start date should lower than end date!')
    } else {
      const newnum = generateRandomNumber(1000000, 9999999)
      setKeyNum(newnum)
      setJobList([])
      setJobsLoading(true)
      refetch()
    }
  }

  const renderStatusReason = (cellValue: any) => {
    if (cellValue?.data?.pendingReason) {
      return <span>{cellValue?.data?.pendingReason}</span>
    }
    return <span className='text-muted'>-</span>
  }

  if (error) {
    return <div className='mt-6'>Error</div>
  }

  if (!jobsLoading && jobList?.length === 0) {
    return (
      <>
        <div className='mt-4'>
          <div className='row'>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  searchFilteredJobs()
                }}
                disabled={jobsLoading}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <EmptyTable title='No Jobs' />
      </>
    )
  }

  if (!jobsLoading && jobList.length > 0) {
    return (
      <div className='position-relative'>
        <div className='mt-4'>
          <div className='row'>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
            </div>
            <div className='col-lg-2 col-12 mb-2'>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  searchFilteredJobs()
                }}
                disabled={jobsLoading}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div style={{zIndex: 1}} className='position-absolute top-15'>
          <span
            className='fs-8 text-primary cursor-pointer'
            onClick={() => tableRef.current?.instance.clearFilter()}
          >
            Clear Filter
          </span>
        </div>
        {!jobsLoading && (
          <DataGrid
            id={'jobTable' + jobStatusId}
            keyExpr='id'
            onRowPrepared={(e) => {
              e.rowElement.classList.add('data-grid-row')
            }}
            dataSource={jobList}
            showBorders={false}
            ref={tableRef}
            showColumnLines={false}
            showRowLines={true}
            hoverStateEnabled={true}
            onEditorPreparing={setDxFilterPlaceholder}
            height={'calc(63vh - 60px)'}
            allowColumnResizing={true}
            selection={{mode: 'single'}}
            className='mt-6'
            onCellClick={toAdminDetail}
            onExporting={(e) => onExporting(e, 'Jobs')}
          >
            <Export enabled />
            <SearchPanel visible={true} />
            <FilterRow visible={true} showOperationChooser={false} />
            <HeaderFilter visible={true} />
            <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
            <Paging defaultPageSize={50} />
            {/*  <Scrolling rowRenderingMode='virtual'></Scrolling>
                <Paging defaultPageSize={10} /> */}

            <Summary>
              <TotalItem
                cssClass='absolute-right'
                displayFormat='Total Jobs: {0}'
                column='hostPropertyAddress'
                summaryType='count'
              />
            </Summary>

            <Column
              dataField='hostPropertyAddress'
              cellRender={isManuallyCreatedJob}
              minWidth={450}
              caption='Property'
              cssClass='cls'
            />
            <Column dataField='hostName' minWidth={300} caption='Client' cssClass='cls' />

            <Column
              visible={
                jobStatusId === JobStatusObj['Scheduled'] + ',' + JobStatusObj['Cancelled'] ||
                jobStatusId === JobStatusObj['Started'] ||
                jobStatusId === JobStatusObj['Completed']
              }
              dataField='scheduledStart'
              caption='Scheduled Start'
              dataType='date'
              selectedFilterOperation='between'
              format={config.date_time_format_dev_extreme}
              calculateCellValue={calculateScheduledStart}
              minWidth={150}
              cssClass='cls'
            >
              <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
            </Column>

            <Column
              visible={jobStatusId === JobStatusObj['Pending']}
              dataField='cleaningPeriodStart'
              caption='Expected Start'
              dataType='date'
              selectedFilterOperation='between'
              format={config.date_time_format_dev_extreme}
              calculateCellValue={calculateCleaningPeriodStart}
              minWidth={180}
              cssClass='cls'
            >
              <HeaderFilter dataSource={getJobDateFilter('cleaningPeriodStart')} />
            </Column>

            <Column
              dataField='jobStatusTypeName'
              alignment='center'
              caption='Status'
              cellRender={displayStatusType}
              minWidth={250}
              cssClass='cls'
            />
            <Column
              visible={jobStatusId === JobStatusObj['Pending']}
              dataField='pendingReason'
              cellRender={renderStatusReason}
              caption='Description'
              minWidth={200}
              cssClass='cls'
            />

            {selectedJobTab !== 'Pending' && providerName == 'turnify' && (
              <Column dataField='vendorName' caption='Vendor' minWidth={200} cssClass='cls' />
            )}
            <Column
              visible={jobStatusId === JobStatusObj['Scheduled'] + ',' + JobStatusObj['Cancelled']}
              dataField='jobStaff'
              caption='Staff'
              minWidth={200}
              cssClass='cls'
            />

            <Column
              visible={
                jobStatusId === JobStatusObj['Started'] || jobStatusId === JobStatusObj['Completed']
              }
              dataField='startedOn'
              caption='Started at'
              dataType='date'
              format={config.date_time_format_dev_extreme}
              selectedFilterOperation='between'
              calculateCellValue={calculateStartedOn}
              minWidth={180}
              cssClass='cls'
            >
              <HeaderFilter dataSource={getDateFilter('startedOn')} />
            </Column>

            <Column
              visible={
                jobStatusId === JobStatusObj['Scheduled'] + ',' + JobStatusObj['Cancelled'] ||
                jobStatusId === JobStatusObj['Started'] ||
                jobStatusId === JobStatusObj['Completed']
              }
              dataField='scheduledFinish'
              caption='Scheduled Finish'
              dataType='date'
              selectedFilterOperation='between'
              format={config.date_time_format_dev_extreme}
              calculateCellValue={calculateScheduledFinish}
              minWidth={180}
              cssClass='cls'
            >
              <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
            </Column>

            <Column
              visible={jobStatusId === JobStatusObj['Pending']}
              dataField='cleaningPeriodEnd'
              caption='Expected Finish'
              dataType='date'
              selectedFilterOperation='between'
              format={config.date_time_format_dev_extreme}
              calculateCellValue={calculateCleaningPeriodEnd}
              minWidth={180}
              cssClass='cls'
            >
              <HeaderFilter dataSource={getDateFilter('cleaningPeriodEnd')} />
            </Column>

            <Column
              visible={jobStatusId === JobStatusObj['Completed']}
              dataField='finishedOn'
              caption='Finished at'
              selectedFilterOperation='between'
              dataType='date'
              format={config.date_time_format_dev_extreme}
              calculateCellValue={calculateFinishedOn}
              minWidth={190}
              cssClass='cls'
            >
              <HeaderFilter dataSource={getDateFilter('finishedOn')} />
            </Column>
            <Column dataField='hostName' caption='Client Name' minWidth={200} cssClass='cls' />
            <Column
              visible={jobStatusId === JobStatusObj['Completed']}
              dataType='boolean'
              caption='Forced Finish'
              alignment='center'
              dataField='isForceFinish'
            />

            <Column dataField='hostPropertyCityName' caption='City' minWidth={150} cssClass='cls' />
            <Column
              dataField='hostPropertyStateName'
              caption='State'
              minWidth={200}
              cssClass='cls'
            />
            <Column
              dataField='hostPropertyCountryName'
              caption='Country'
              minWidth={200}
              cssClass='cls'
            />
            <Column
              dataField='cleaningProviderId'
              calculateCellValue={renderProvider}
              caption='Provider'
              minWidth={200}
              cssClass='cls'
            />
            <Column
              visible={jobStatusId === JobStatusObj['Scheduled'] + ',' + JobStatusObj['Cancelled']}
              dataField='user'
              caption='Created / Cancelled By'
              calculateCellValue={renderCreateUser}
              minWidth={200}
              cssClass='cls'
            />
          </DataGrid>
        )}
      </div>
    )
  }

  return (
    <div className='mt-6'>
      <SkeletonTableLoading />
    </div>
  )
}

export default JobsTable

// <Column width={110} alignment='center' cellRender={displayEditButton} cssClass='cls' />
