import React, {Dispatch, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {CloseVendorOfferDialog} from '../../../../../redux/actionCreators/vendorActionCreators'
import {VendorAction} from '../../../../../redux/actionTypes/vendorTypes'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import VendorServiceForm from '../../../../../umut-components/Forms/VendorServiceForm'

type Props = {}

const VendorOfferedDialog: React.FC<Props> = () => {
  const {vendorOfferDialog} = useSelector((state: RedusxAppState) => state.vendor)
  const dispatch = useDispatch<Dispatch<VendorAction>>()

  const handleClose = () => {
    dispatch(CloseVendorOfferDialog())
  }

  return (
    <Modal show={vendorOfferDialog.modalOpen} onHide={handleClose} centered size='lg'>
      <ModalHeader closeButton>
        <Modal.Title>Add Service</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {/* <VendorOfferForm /> */}
        <VendorServiceForm />
      </Modal.Body>
    </Modal>
  )
}

export default VendorOfferedDialog
