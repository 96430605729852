import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch, useState} from 'react'
import {FC} from 'react'
import {IVendorHostPropertyRates} from '../../../../../interfaces/Property'
import {TextInput} from '../../../../../umut-components/Inputs/TextInput'
import ApiCalls from '../../../../../network/ApiCalls'
import {useDialog} from '../../../../../contexts/DialogContext'

type Props = {
  data: IVendorHostPropertyRates
  visible: boolean
  handleClose: () => void
  refetch: () => void
}
const VendorServiceRateEditModal: FC<Props> = ({data, visible, handleClose, refetch}) => {
  const [rateData, setRateData] = useState<IVendorHostPropertyRates>(data)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [errorMsg, setErrorMsg] = useState('')

  const changeOfferedRate = (val: number) => {
    const rawData = Object.assign({}, rateData)
    rawData.offeredRate = val
    setRateData(rawData)
  }

  const updateServiceRate = async () => {
    setErrorMsg('')
    try {
      const data = {
        id: rateData.offerId,
        offeredRate: rateData.offeredRate,
      }
      await ApiCalls.updateHostPropertyOfferedRateOfferedPrice(data)
      showSuccessDialog("Vendor's service offer has been successfully updated.")
      refetch()
      handleClose()
    } catch (error: any) {
      setErrorMsg(error?.response?.data?.message)
    }
  }

  const deleteServiceRate = async () => {
    setErrorMsg('')
    try {
      await ApiCalls.deleteHostPropertyOfferedRateOffer(rateData.offerId)
      showSuccessDialog("Vendor's service offer has been successfully deleted.")
      refetch()
      handleClose()
    } catch (error: any) {
      setErrorMsg(error?.response?.data?.message || 'An error occur during deleting offered rate!')
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Edit Vendor Service Rate</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>Property</label>
            <TextInput
              onValueChange={(text: string) => {
                /**rate */
              }}
              defaultValue={rateData.address}
              disabled={true}
            />
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>Client</label>
            <TextInput
              onValueChange={(text: string) => {
                /**rate */
              }}
              defaultValue={rateData.client}
              disabled={true}
            />
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>Service Type</label>
            <TextInput
              onValueChange={(text: string) => {
                /**rate */
              }}
              defaultValue={rateData.serviceType}
              disabled={true}
            />
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>Duration (min.)</label>
            <TextInput
              onValueChange={(text: string) => {
                /**rate */
              }}
              defaultValue={rateData.duration}
              disabled={true}
            />
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>Offered Rate ($)</label>
            <TextInput
              type='number'
              step={0.01}
              onValueChange={(val: string) => {
                changeOfferedRate(+val)
              }}
              defaultValue={rateData.offeredRate}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {errorMsg != '' && <span className='text-danger'>{errorMsg}</span>}
        <div className='row'>
          <div className='col-12'>
            <button
              type='button'
              className='btn btn-link text-muted me-6'
              onClick={() => {
                handleClose()
              }}
            >
              Close
            </button>
            <button type='button' className='btn btn-danger me-3' onClick={deleteServiceRate}>
              Delete
            </button>
            <button type='button' className='btn btn-success' onClick={updateServiceRate}>
              Update
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default VendorServiceRateEditModal
