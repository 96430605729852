import React, {useState} from 'react'

interface ITextInput {
  onValueChange: (text: string) => void
  placeholder?: string
  defaultValue?: string | number | null
  classNames?: string
  type?: string
  min?: string
  max?: string
  step?: number
  disabled?: boolean
  errorText?: string
  characterLimit?: number
}
export const TextInput: React.FC<ITextInput> = ({
  onValueChange,
  placeholder,
  defaultValue,
  classNames,
  type,
  min,
  max,
  disabled,
  errorText,
  step,
}) => {
  const [textValue, setTextValue] = useState(defaultValue ? defaultValue : '')

  const textValueChange = (event: any) => {
    setTextValue(event.target.value)
    onValueChange(event.target.value)
  }

  return (
    <div>
      <input
        value={textValue}
        onChange={textValueChange}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        className={'form-control ' + classNames}
        type={type ? type : 'text'}
        autoComplete='off'
        disabled={disabled}
        required
      />
      {errorText && <small className='text-danger'>{errorText}</small>}
    </div>
  )
}
