import React, { Dispatch, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap-v5';
import { PageTitle } from '../../../../_metronic/layout/core';
import StaffTab from '../../staff/StaffTab';
import HostEditDialog from '../hosts/HostEditDialog';
import HistoryTab from './Tabs/HistoryTab/HistoryTab';
import ProfileTab from './Tabs/ProfileTab';
import PropertyTab from './Tabs/PropertyTab/PropertyTab';

type TabNames =
    | 'staffs'
    | 'properties'
    | 'history'

const HostDetails: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<TabNames>('staffs');

    const handleChangeTab = (key: any) => {
        setSelectedTab(key);
    }

    return (
        <>
            <PageTitle>Client Detail</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card className="shadow">
                    <Card.Header>
                        <ProfileTab />
                    </Card.Header>
                    <Card.Body>
                        <Tabs defaultActiveKey="staffs" id="uncontrolled-tab-example" className="mb-3" onSelect={handleChangeTab}>
                            <Tab eventKey="staffs" title="Staff">
                                {selectedTab === 'staffs' && <StaffTab />}
                            </Tab>
                            <Tab eventKey="properties" title="Properties">
                                {selectedTab === 'properties' && <PropertyTab />}
                            </Tab>
                            <Tab eventKey="history" title="History">
                                {selectedTab === 'history' && <HistoryTab />}
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
            <HostEditDialog />
        </>
    )
}

export default HostDetails;