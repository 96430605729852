import React, { Dispatch } from 'react';
import { Tab } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import ApiCalls from '../../../../network/ApiCalls';
import { StaffAction } from '../../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../../../redux/actionCreators/staffActionCreators'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading';
import ServiceTable from './ServiceTable';
import OfferedDialog from './OfferedDialog';

const OfferedServices: React.FC = () => {
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const { data: offeredServiceData, isLoading: servicesLoading, error: servicesError, refetch: offeredRefetch } = useQuery(['Offered Services', staffDetail.staff?.userId], () => ApiCalls.getOfferedServices(staffDetail.staff?.userId), { enabled: staffDetail.staff?.userId ? true : false, cacheTime: 500000, refetchOnWindowFocus: false });

    if (servicesLoading) {
        return <div><SkeletonTableLoading /></div>
    }

    return (
        <div>
            <ServiceTable data={offeredServiceData?.data?.data} refetch={offeredRefetch} />
            {staffDetail.staff?.hostId &&
                <div className="col-12 d-flex justify-content-end p-7">
                    <button className="btn btn-success" onClick={() => { dispatch(staffActionCreator.openStaffOfferedAddDialog(offeredRefetch)) }} style={{ height: '100%' }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add Offered
                    </button>
                </div>}
            <OfferedDialog />
        </div>
    )
}

export default OfferedServices;