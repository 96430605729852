import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  Sorting,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import config from '../../../../config/config.json'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import {
  convertUTCDateToLocalDate,
  toConvertedDate,
  toConvertedOnlyDate,
  encryptText,
  setDxFilterPlaceholder,
  onExporting,
  getDateFilter,
} from '../../../../utils/util'

type Props = {
  data: any
  pendingButtonActive?: boolean
  refetch: () => void
  proFilterActive?: boolean
  plusFilterActive?: boolean
  myTeamFilterActive?: boolean
}

const OfferPendingTable: React.FC<Props> = ({
  data,
  pendingButtonActive,
  refetch,
  proFilterActive,
  plusFilterActive,
  myTeamFilterActive,
}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)

  const calculateDateTimeValue = (cellValue: any) => {
    return cellValue?.nextCheckIn
      ? new Date(cellValue?.nextCheckIn)
      : cellValue?.nextCheckOn
      ? new Date(cellValue?.nextCheckOn)
      : null
  }

  const calculateDateTime = (cellValue: any) => {
    return cellValue?.nextCheckIn
      ? convertUTCDateToLocalDate(cellValue?.nextCheckIn)
      : cellValue?.nextCheckOn
      ? new Date(Date.parse(cellValue?.nextCheckOn))
      : '--'
  }

  const displayNextCheckIn = (cellValue: any) => {
    return cellValue?.nextCheckIn
      ? toConvertedDate(cellValue?.nextCheckIn, cellValue?.timeZone)
      : '--'
  }

  const displayNextCheckOut = (cellValue: any) => {
    return cellValue?.nextCheckOut
      ? toConvertedDate(cellValue?.nextCheckOut, cellValue?.timeZone)
      : '--'
  }

  const calculateNextCheckIn = (cellValue: any) => {
    return cellValue?.nextCheckIn
      ? toConvertedDate(cellValue?.nextCheckIn, cellValue?.timeZone)
      : '--'
  }

  const calculateNextCheckOut = (cellValue: any) => {
    return cellValue?.nextCheckOut
      ? toConvertedDate(cellValue?.nextCheckOut, cellValue?.timeZone)
      : '--'
  }

  const calculateLastCleanedOn = (cellValue: any) => {
    return cellValue?.lastCleanDate
      ? toConvertedOnlyDate(cellValue?.lastCleanDate, cellValue?.timeZone)
      : '--'
  }

  const calculateProviderName = (cellValue: any) => {
    if (cellValue?.hasPlusQuoteRequest || cellValue?.cleaningProviderName == 'Turnify+') {
      return 'Turnify+'
    }
    if (cellValue?.hasProQuoteRequest || cellValue?.cleaningProviderName == 'Turnify PRO') {
      if (cellValue?.isMarketplace) {
        return 'Marketplace'
      } else {
        return 'Turnify PRO'
      }
    }

    return 'My Team'
  }

  const toPropertyDetail = (cellData: any) => {
    if (cellData?.rowType == 'data') {
      history.push({
        pathname: '/properties/offer-pending/' + encryptText(cellData?.data?.id),
      })
    }
  }

  const getClassName = (data: any) => {
    var classname = 'text-warning'
    if (data.hostPropertyStatusTypeName === 'Property Fields Not Set') {
      classname = 'text-danger'
    }
    if (
      data.hostPropertyStatusTypeName === 'Active' &&
      (data.hasProQuoteRequest || data.hasPlusQuoteRequest)
    ) {
      classname = 'text-warning'
    }
    if (
      data.hostPropertyStatusTypeName === 'Active' &&
      !data.hasProQuoteRequest &&
      !data.hasPlusQuoteRequest
    ) {
      classname = 'text-success'
    }
    if (data.hostPropertyStatusTypeName === 'Declined') {
      classname = 'text-danger'
    }

    return classname
  }

  const getStatusTypeName = (data: any) => {
    var typename = 'Offer Pending'

    return typename
  }

  const calculatePropertyStatus = (cellValue: any) => {
    return (
      <strong className={getClassName(cellValue?.data)}>
        {getStatusTypeName(cellValue?.data)}
      </strong>
    )
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Properties' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='propertyTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(70vh)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toPropertyDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Properties')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Properties: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column dataField='name' caption='Property' minWidth={450} cssClass='cls' />
        <Column dataField='hostName' caption='Client' minWidth={300} cssClass='cls' />
        <Column dataField='cityName' caption='City' minWidth={150} cssClass='cls' />
        <Column dataField='stateName' caption='State' minWidth={150} cssClass='cls' />
        {!pendingButtonActive && (
          <Column dataField='countryName' caption='Country' minWidth={150} cssClass='cls' />
        )}
        <Column
          dataField='cleaningProviderName'
          caption='Provider'
          minWidth={150}
          calculateCellValue={calculateProviderName}
          cssClass='cls'
          alignment='center'
          defaultFilterValues={proFilterActive && ['Turnify PRO', 'Turnify+', 'My Team']}
        />

        {/* <Column
          dataField='hostName'
          caption='Host'
          minWidth={150}
          cssClass='cls'
          alignment='center'

        /> */}
        {!pendingButtonActive && (
          <Column
            dataField='lastCleanDate'
            caption='Last Cleaned On'
            calculateCellValue={calculateLastCleanedOn}
            selectedFilterOperation='between'
            dataType='date'
            minWidth={150}
            cssClass='cls'
          >
            <HeaderFilter dataSource={getDateFilter('lastCleanDate')} />
          </Column>
        )}

        {pendingButtonActive && (
          <Column
            dataField='hostPropertyStatusTypeName'
            caption='Status'
            cellRender={calculatePropertyStatus}
            minWidth={150}
            cssClass='cls'
            alignment='center'
          />
        )}
        {
          <Column
            dataField='nextCheckIn'
            caption='Next Check-In'
            width={180}
            calculateCellValue={calculateNextCheckIn}
            calculateDisplayValue={displayNextCheckIn}
            dataType='date'
            selectedFilterOperation='between'
            format={config.date_time_format_dev_extreme}
            cssClass='cls'
          >
            <HeaderFilter dataSource={getDateFilter('nextCheckIn')} />
          </Column>
        }
        <Column
          dataField='nextCheckOn'
          caption='Next Check-Out'
          width={180}
          calculateCellValue={calculateNextCheckOut}
          calculateDisplayValue={displayNextCheckOut}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('nextCheckOn')} />
        </Column>
      </DataGrid>
    </div>
  )
}

export default React.memo(OfferPendingTable)
