import React, { Dispatch, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CloseVendorEditDialog, CloseVendorEditOfferDialog, CloseVendorOfferDialog } from '../../../../../redux/actionCreators/vendorActionCreators';
import { VendorAction } from '../../../../../redux/actionTypes/vendorTypes';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import VendorOfferEditForm from '../../../../../umut-components/Forms/VendorOfferEditForm';
import VendorOfferForm from '../../../../../umut-components/Forms/VendorOfferForm';

type Props = {
}

const VendorEditOfferedDialog: React.FC<Props> = () => {
    const { vendorEditOfferDialog } = useSelector((state: RedusxAppState) => state.vendor);
    const dispatch = useDispatch<Dispatch<VendorAction>>();

    const handleClose = () => {
        dispatch(CloseVendorEditOfferDialog());
    }

    return (
        <Modal show={vendorEditOfferDialog.modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Edit Service</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <VendorOfferEditForm />
            </Modal.Body>
        </Modal>
    )
}

export default VendorEditOfferedDialog;