import React, { Dispatch, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import notify from 'devextreme/ui/notify';
import ApiCalls from '../../../../../network/ApiCalls';
import { GetHostPropertyResponse } from '../../../../../network/NetworkResponses/NetworkResponses';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog';
import { decryptText } from '../../../../../utils/util';
import PropertyTable from '../../../../properties/PropertyTable';

type Props = {
}

const PropertyTab: React.FC<Props> = () => {
    let { hostId }: any = useParams();
    const [showResetJobsModal, setShowResetJobsModal] = useState(false);
    const [loading, setLoading] = useState(false);

    hostId = decryptText(hostId);
    const { data, isLoading, error, refetch } = useQuery<GetHostPropertyResponse>(['Get Host Properties', hostId], () => ApiCalls.getHostProperties(hostId), { cacheTime: 10000, retry: false, refetchOnWindowFocus: false });
    const propertyCount = data?.data.count || 0;

    const handleResetJobs = async () => {
        setLoading(false);

        if(propertyCount > 0) {
            await ApiCalls.postResetHostPropertyJobs(hostId).then((d) => {
                setLoading(false);
                setShowResetJobsModal(false);
                notify({
                    message: d?.data?.success ? 'All jobs and calendar records belonging to the host properties have been reset.' : 'An error occurred while reset jobs',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, d?.data?.success ? 'success' : 'error', 5000);
            }).catch(() => {
                setShowResetJobsModal(false);
                setLoading(false);
                notify({
                    message: 'An error occurred while reset jobs and calendars',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 5000);
            })
        } else {
            setLoading(false);
        }
    }

    if (isLoading) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            {propertyCount > 0 && 
            <Button disabled={loading} onClick={() => {setShowResetJobsModal(true)}} variant={"danger"} className="btn-sm h-30px mb-10 mt-5">{loading ? <Spinner className="align-self-center" animation="border" /> : 'Reset Jobs'}</Button>
            }
            <PropertyTable data={data?.data?.data} refetch={refetch} />
            <DeleteDialog />

            <Modal show={showResetJobsModal} onHide={() => {setShowResetJobsModal(false)}} centered>
                <Modal.Body>
                    <h4>Are you sure you want to reset all future jobs and calendar records from all properties?</h4>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-end'>
                    <button className='btn btn-link text-warning me-3' onClick={() => {setShowResetJobsModal(false)}}>Cancel</button>
                    <button className='btn btn-danger' onClick={handleResetJobs}>Reset Jobs</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PropertyTab;