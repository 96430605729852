import {AxiosResponse} from 'axios'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../_metronic/layout/core'
import {IPaymentFee} from '../../interfaces/PaymentFee'
import ApiCalls from '../../network/ApiCalls'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {IVendorSubscriptionPacks} from '../../interfaces/Vendors'
import {useState} from 'react'
import VendorSubscriptionPackEditModal from './VendorSubscriptionPackEditModal'

const VendorSubscriptionPacks = () => {
  const [selectedType, setSelectedType] = useState('monthly')
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedPack, setSelectedPack] = useState<IVendorSubscriptionPacks>()

  const {
    data: packs,
    isLoading,
    error,
    refetch: refetchPacks,
  } = useQuery<AxiosResponse<{data: Array<IVendorSubscriptionPacks>}>>(
    ['Get Vendor Subscription Packs'],
    () => ApiCalls.getVendorSubscriptionPacks(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const findPackPrice = (prices: any, type: string) => {
    var find = prices.find((p: any) => p.type == type)
    if (find != undefined) {
      return find.subscriptionFee
    }
  }

  const editPack = (pack: IVendorSubscriptionPacks) => {
    setSelectedPack(pack)
    setShowEditModal(true)
  }

  return (
    <>
      <PageTitle>Vendor Subscription Packs</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title>
            <div className='d-flex flex-column mt-5'>
              <div className='mb-13 text-center'>
                <h1 className='fs-2hx fw-bold mb-5'>Edit Vendor Subscription Packs</h1>

                <div className='text-gray-400 fw-semibold fs-5'>
                  You can edit vendor subscription packages in this area.{' '}
                  <span className='text-danger'>
                    The changes you make will not affect the existing subscriptions.
                  </span>
                </div>
              </div>
              <div
                className='nav-group nav-group-outline mx-auto mb-15'
                data-kt-buttons='true'
                data-kt-initialized='1'
              >
                <button
                  onClick={() => {
                    setSelectedType('monthly')
                  }}
                  className={
                    selectedType == 'monthly'
                      ? 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active'
                      : 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2'
                  }
                >
                  Monthly
                </button>

                <button
                  onClick={() => {
                    setSelectedType('annual')
                  }}
                  className={
                    selectedType == 'annual'
                      ? 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active'
                      : 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2'
                  }
                  data-kt-plan='annual'
                >
                  Annual
                </button>
              </div>
            </div>
          </Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <div className='row'>
                {packs?.data?.data?.map((pack, index) => {
                  return (
                    <div className='col-xl-4 col-md-6 col-12' key={'pack-' + index}>
                      <div className='d-flex h-100 align-items-center'>
                        <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                          <div className='mb-7 text-center'>
                            <h1 className='text-dark mb-5 fw-bolder'>{pack.name}</h1>

                            <div className='text-center'>
                              <span className='mb-2 text-primary'>$</span>

                              <span
                                className='fs-3x fw-bold text-primary'
                                data-kt-plan-price-month='39'
                                data-kt-plan-price-annual='399'
                              >
                                {findPackPrice(pack.vendorSubscriptionPackPrices, selectedType)}
                              </span>

                              <span className='fs-7 fw-semibold opacity-50'>
                                /<span data-kt-element='period'>{selectedType}</span>
                              </span>
                            </div>
                          </div>
                          <div className='w-100 mb-10'>
                            <div dangerouslySetInnerHTML={{__html: pack.description}}></div>
                          </div>
                          <button
                            className='btn btn-sm btn-primary'
                            onClick={() => {
                              editPack(pack)
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </Card.Body>
        </Card>
        <VendorSubscriptionPackEditModal
          pack={selectedPack}
          modalOpen={showEditModal}
          handleClose={() => setShowEditModal(false)}
          refetch={refetchPacks}
        />
      </div>
    </>
  )
}
export default VendorSubscriptionPacks
