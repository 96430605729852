import React, {Dispatch, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  ColumnHeaderFilter,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import VendorRateDetailModal from '../../../../pages/vendors/VendorRateDetail/VendorRateDetailModal'
import {VendorAction} from '../../../../redux/actionTypes/vendorTypes'
import {onExporting} from '../../../../utils/util'
import EmptyTable from '../../../Icons/EmptyTable'
import PropertyServiceRateDetailModal from './PropertyServiceRateDetailModal'
import {IGetAllPropertyRate} from '../../../../interfaces/Property'
import {Card} from 'react-bootstrap-v5'

type Props = {
  data: IGetAllPropertyRate[]
  refetch: () => void
}

const PropertyServiceRateList: React.FC<Props> = ({data, refetch}) => {
  const tableRef = useRef<DataGrid>(null)
  const [showRateDetail, setShowRateDetail] = useState(false)
  const [selectedRate, setSelectedRate] = useState<IGetAllPropertyRate>()

  const handleClose = () => {
    setShowRateDetail(false)
    setSelectedRate(undefined)
    refetch()
  }

  const calculateVendorRate = (cellValue: any) => {
    return '$' + cellValue.vendorRate
  }

  const calculateHostRate = (cellValue: any) => {
    return '$' + cellValue.hostRate
  }

  const editVendorRate = (e: any) => {
    setSelectedRate(e.data)
    setShowRateDetail(true)
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Vendors Rate' />
  }
  return (
    <>
      <Card className='mb-3'>
        <Card.Body>
          <div className='position-relative'>
            <div style={{zIndex: 1}} className='position-absolute top-15'>
              <span
                className='fs-8 text-primary cursor-pointer'
                onClick={() => tableRef.current?.instance.clearFilter()}
              >
                Clear Filter
              </span>
            </div>
            <DataGrid
              id='vendorsRateListTable'
              keyExpr='id'
              onRowPrepared={(e) => {
                e.rowElement.classList.add('data-grid-row')
              }}
              dataSource={data}
              showBorders={false}
              ref={tableRef}
              showColumnLines={false}
              showRowLines={true}
              hoverStateEnabled={true}
              height={'calc(64vh - 60px)'}
              allowColumnResizing={true}
              selection={{mode: 'single'}}
              onRowClick={(e) => editVendorRate(e)}
              onExporting={(e) => onExporting(e, 'Vendors Rate List')}
            >
              <Export enabled />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} />
              <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
              <Paging defaultPageSize={50} />
              <Summary>
                <TotalItem
                  cssClass='absolute-right'
                  displayFormat='Total Rate: {0}'
                  column='id'
                  summaryType='count'
                />
              </Summary>
              <Column
                dataField='serviceTypeName'
                caption='Service Type'
                dataType='string'
                minWidth={195}
                cssClass='cls'
              />
              <Column
                dataField='checklistName'
                caption='Checklist'
                dataType='string'
                minWidth={250}
                cssClass='cls'
              />
              <Column
                dataField='hostRate'
                caption='Client Rate'
                dataType='string'
                calculateCellValue={calculateHostRate}
                minWidth={195}
                cssClass='cls'
              />
              <Column
                dataField='vendorName'
                caption='Vendor'
                dataType='string'
                minWidth={195}
                cssClass='cls'
              />
              <Column
                dataField='vendorRate'
                caption='Vendor Rate'
                dataType='string'
                calculateCellValue={calculateVendorRate}
                minWidth={195}
                cssClass='cls'
              />
            </DataGrid>
          </div>
          {showRateDetail && (
            <PropertyServiceRateDetailModal
              visible={showRateDetail}
              handleClose={handleClose}
              data={selectedRate!}
            />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default React.memo(PropertyServiceRateList)
