import React from 'react';
import { useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';
import { toAmazonUrl } from '../../../../utils/util';

const ProfileTab: React.FC = () => {
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);

    return (
        <div className='d-flex p-6 ps-0 flex-wrap'>
            <div className='d-flex me-6'>
                <ProfilePhotoTemp width={75} height={75} fontSize={25} borderRadius='50%' profilePhotoPath={staffDetail?.staff?.profilePhotoPath} firstName={staffDetail?.staff?.firstName ?? ''} lastName={staffDetail?.staff?.lastName ?? ''} />
            </div>
            <div className='row'>
                <div className='col-lg-12 h-auto'>
                    <span className={`badge badge-${staffDetail?.staff?.accountStatusName ? "success" : "danger"} mb-1`}>{staffDetail?.staff?.accountStatusName}</span>
                </div>
                <div className='col-lg-12 h-auto'>
                    <strong>{staffDetail?.staff?.firstName + ' ' + staffDetail?.staff?.lastName}</strong>
                </div>
                <div className='col-lg-12 h-auto'>
                    <strong className='text-muted'>{staffDetail?.staff?.email}</strong>
                </div>
                <div className='col-lg-12 h-auto'>
                    <strong className='text-muted'>{staffDetail?.staff?.phoneNumber}</strong>
                </div>
            </div>
        </div>
    )
}

export default ProfileTab;