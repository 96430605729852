import {
    Column,
    DataGrid,
    HeaderFilter,
    Paging,
    Scrolling,
} from 'devextreme-react/data-grid'
import { FC } from 'react'
import { IJobDetail } from '../../../../interfaces/Job'
import { convertUTCDateToLocalDate, getDateFilter, setDxFilterPlaceholder, toConvertedDate } from '../../../../utils/util'
import config from '../../../../config/config.json';

type Props = {
    data: IJobDetail[]
}
const OffDayEffectedJobTable: FC<Props> = ({ data }) => {

    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledStart, cellValue?.hostPropertyCityTimeZone);
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledFinish, cellValue?.hostPropertyCityTimeZone);
    }

    return (
        <>
            <DataGrid
                id='jobTable'
                keyExpr='jobId'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                allowColumnResizing={true}
                onEditorPreparing={setDxFilterPlaceholder}
                selection={{ mode: 'single' }}
            >

                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />

                <Column dataField="hostPropertyAddress" minWidth={450} caption='Property' cssClass='cls' />
                <Column dataField="scheduledStart" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledStart} minWidth={150} cssClass='cls'  >
                    <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
                </Column>
                <Column dataField="scheduledFinish" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledFinish} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
                </Column>
                <Column dataField='duration' caption='duration' minWidth={200} cssClass='cls' />


            </DataGrid>
        </>
    )
}

export default OffDayEffectedJobTable
