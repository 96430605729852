import {FC, useEffect, useState} from 'react'
import {IVendorSubscriptionPackPrice, IVendorSubscriptionPacks} from '../../interfaces/Vendors'
import {Button, Modal, Spinner} from 'react-bootstrap-v5'
import HtmlEditor, {Toolbar, Item} from 'devextreme-react/html-editor'
import ApiCalls from '../../network/ApiCalls'
import {useDialog} from '../../contexts/DialogContext'

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '24pt', '36pt']

type Props = {
  pack?: IVendorSubscriptionPacks
  modalOpen: boolean
  handleClose: () => void
  refetch: () => void
}
const VendorSubscriptionPackEditModal: FC<Props> = ({pack, modalOpen, handleClose, refetch}) => {
  const [packInfo, setPackInfo] = useState(pack)
  const [monthlyPack, setMonthlyPack] = useState<IVendorSubscriptionPackPrice>()
  const [annualPack, setAnnualPack] = useState<IVendorSubscriptionPackPrice>()
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const {showSuccessDialog} = useDialog()

  useEffect(() => {
    if (pack != undefined) {
      const findMonthly = pack.vendorSubscriptionPackPrices.find((vs) => vs.type == 'monthly')
      const findAnnual = pack.vendorSubscriptionPackPrices.find((vs) => vs.type == 'annual')

      if (findMonthly) {
        setMonthlyPack(findMonthly)
      }

      if (findAnnual) {
        setAnnualPack(findAnnual)
      }

      setPackInfo(pack)
    }
  }, [pack])

  const changePackTitle = (txt: string) => {
    let info = Object.assign({}, packInfo)
    if (info != undefined) {
      info.name = txt
      setPackInfo(info)
    }
  }

  const changePackDescription = (txt: string) => {
    let info = Object.assign({}, packInfo)
    if (info != undefined) {
      info.description = txt
      setPackInfo(info)
    }
  }

  const setSubscriptionFee = (type: string, value: number) => {
    if (type == 'monthly') {
      let data = Object.assign({}, monthlyPack)
      data.subscriptionFee = value
      setMonthlyPack(data)
    }
    if (type == 'annual') {
      let data = Object.assign({}, annualPack)
      data.subscriptionFee = value
      setAnnualPack(data)
    }
  }

  const setStaffFee = (type: string, value: number) => {
    if (type == 'monthly') {
      let data = Object.assign({}, monthlyPack)
      data!.staffFee = value
      setMonthlyPack(data)
    }
    if (type == 'annual') {
      let data = Object.assign({}, annualPack)
      data!.staffFee = value
      setAnnualPack(data)
    }
  }

  const setFreeStaff = (type: string, value: number) => {
    if (type == 'monthly') {
      let data = Object.assign({}, monthlyPack)
      data!.freeStaff = value
      setMonthlyPack(data)
    }
    if (type == 'annual') {
      let data = Object.assign({}, annualPack)
      data!.freeStaff = value
      setAnnualPack(data)
    }
  }

  const handleUpdate = async () => {
    var newPack = Object.assign({}, packInfo)
    var monthlyData = newPack.vendorSubscriptionPackPrices.find((vs) => vs.type == 'monthly')
    var monthlyDataIndex = newPack.vendorSubscriptionPackPrices.findIndex(
      (vs) => vs.type == 'monthly'
    )
    var annualData = newPack.vendorSubscriptionPackPrices.find((vs) => vs.type == 'annual')
    var annualDataIndex = newPack.vendorSubscriptionPackPrices.findIndex(
      (vs) => vs.type == 'annual'
    )

    monthlyData = Object.assign({}, monthlyPack)
    newPack.vendorSubscriptionPackPrices[monthlyDataIndex] = monthlyData

    annualData = Object.assign({}, annualPack)
    newPack.vendorSubscriptionPackPrices[annualDataIndex] = annualData

    setPackInfo(newPack)

    const data = {}
    setLoading(true)
    try {
      await ApiCalls.updateVendorSubscriptionPack(newPack)
      setLoading(false)
      setErrorMsg('')
      refetch()
      handleClose()
      showSuccessDialog('Package updated successfully.')
    } catch (error) {
      setLoading(false)
      setErrorMsg('An error occurred while updating the package.')
    }
  }

  return (
    <Modal show={modalOpen} onHide={handleClose} centered size={'xl'}>
      <Modal.Header>
        <Modal.Title className='text-danger'>{packInfo?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h5>General Info</h5>
            <div className='form-group mb-3'>
              <label>Title</label>
              <input
                type='text'
                className='form-control'
                placeholder='Title'
                defaultValue={packInfo?.name}
                onChange={(e) => changePackTitle(e.target.value)}
              />
            </div>
            <div className='form-group mb-3'>
              <label>Description</label>
              <HtmlEditor
                height='300px'
                defaultValue={pack?.description}
                onValueChange={(e) => {
                  changePackDescription(e)
                }}
              >
                <Toolbar multiline={true}>
                  <Item name='undo' />
                  <Item name='redo' />
                  <Item name='separator' />
                  <Item name='size' acceptedValues={sizeValues} />
                  <Item name='separator' />
                  <Item name='bold' />
                  <Item name='italic' />
                  <Item name='strike' />
                  <Item name='underline' />
                  <Item name='separator' />
                  <Item name='alignLeft' />
                  <Item name='alignCenter' />
                  <Item name='alignRight' />
                  <Item name='alignJustify' />
                  <Item name='separator' />
                  <Item name='orderedList' />
                  <Item name='bulletList' />
                  <Item name='separator' />
                  <Item name='color' />
                  <Item name='background' />
                  <Item name='separator' />
                  <Item name='link' />
                  <Item name='image' />
                  <Item name='separator' />
                  <Item name='clear' />
                  <Item name='codeBlock' />
                  <Item name='blockquote' />
                  <Item name='separator' />
                  <Item name='insertTable' />
                  <Item name='deleteTable' />
                  <Item name='insertRowAbove' />
                  <Item name='insertRowBelow' />
                  <Item name='deleteRow' />
                  <Item name='insertColumnLeft' />
                  <Item name='insertColumnRight' />
                  <Item name='deleteColumn' />
                </Toolbar>
              </HtmlEditor>
            </div>
          </div>
          <div className='col-md-6 col-12 mb-5'>
            <h5>Monthly Pack Prices</h5>
            <div className='form-group mb-3'>
              <label>Subscription Fee ($)</label>
              <input
                type='number'
                step={0.01}
                className='form-control'
                placeholder='Subscription Fee'
                value={monthlyPack?.subscriptionFee}
                onChange={(e) => {
                  setSubscriptionFee('monthly', Number(e.target.value))
                }}
              />
            </div>
            <div className='form-group mb-3'>
              <label>Staff Fee ($)</label>
              <input
                type='number'
                step={0.01}
                className='form-control'
                placeholder='Staff Fee'
                value={monthlyPack?.staffFee}
                onChange={(e) => {
                  setStaffFee('monthly', Number(e.target.value))
                }}
              />
            </div>
            <div className='form-group mb-3'>
              <label>Free Staff</label>
              <input
                type='text'
                className='form-control'
                placeholder='Free Staff'
                value={monthlyPack?.freeStaff}
                onChange={(e) => {
                  setFreeStaff('monthly', Number(e.target.value))
                }}
              />
            </div>
          </div>
          <div className='col-md-6 col-12 mb-5'>
            <h5>Annual Pack Prices</h5>
            <div className='form-group mb-3'>
              <label>Subscription Fee ($)</label>
              <input
                type='number'
                step={0.01}
                className='form-control'
                placeholder='Subscription Fee'
                value={annualPack?.subscriptionFee}
                onChange={(e) => {
                  setSubscriptionFee('annual', Number(e.target.value))
                }}
              />
            </div>
            <div className='form-group mb-3'>
              <label>Staff Fee ($)</label>
              <input
                type='number'
                step={0.01}
                className='form-control'
                placeholder='Staff Fee'
                value={annualPack?.staffFee}
                onChange={(e) => {
                  setStaffFee('annual', Number(e.target.value))
                }}
              />
            </div>
            <div className='form-group mb-3'>
              <label>Free Staff</label>
              <input
                type='text'
                className='form-control'
                placeholder='Free Staff'
                value={annualPack?.freeStaff}
                onChange={(e) => {
                  setFreeStaff('annual', Number(e.target.value))
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {errorMsg != '' && <div className='text-danger me-3'>{errorMsg}</div>}
        <button type='button' className='btn btn-light' onClick={handleClose}>
          Cancel
        </button>
        <Button
          variant='primary'
          className='btn btn-danger'
          disabled={loading}
          onClick={handleUpdate}
        >
          Save {loading ? <Spinner animation='border' size='sm' /> : null}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default VendorSubscriptionPackEditModal
