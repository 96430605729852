import {Dialog, DialogTitle, DialogContent, debounce} from '@material-ui/core'
import dayjs from 'dayjs'
import {FC, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import StepWizard from 'react-step-wizard'
import {IStaff} from '../../../../interfaces/Staff'
import {IGetAllVendors} from '../../../../interfaces/Vendors'
import ApiCalls from '../../../../network/ApiCalls'
import {IReVendorStaffAssign} from '../../../../network/PostRequestModels/ReAssignRequests'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import ArrowButton from '../../../../umut-components/Buttons/ArrowButton'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import DateTimePicker from '../../../../umut-components/Inputs/DateTimePicker'
import {TextInput} from '../../../../umut-components/Inputs/TextInput'
import {convertUTCDateToLocalDate, decryptText, toRequestTimeFormat} from '../../../../utils/util'
import StaffBox from './StaffBox'
import SuitableVendorsTable from './SuitableVendorsTable'
import VendorBox from './VendorBox'

type Props = {
  show: boolean
  closeModal: () => void
}
const AssingmentVendorModal: FC<Props> = ({show, closeModal}) => {
  let {jobId}: any = useParams()
  jobId = decryptText(jobId)
  const {jobDetail}: any = useSelector((state: RedusxAppState) => state.job)
  const [reqLoading, setReqLoading] = useState<boolean>(false)
  const [scheduleStart, setScheduleStart] = useState(
    jobDetail?.cleaningPeriodStart
      ? toRequestTimeFormat(jobDetail?.hostProperty!.city.timezone, jobDetail?.cleaningPeriodStart)
      : ''
  )
  const [selectedVendor, setSelectedVendor] = useState<any>(undefined)
  const [erroMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [staffList, setStaffList] = useState<Array<number>>([])
  const [searchText, setSearchText] = useState<string>('')

  const {
    data: vendors,
    isLoading,
    error,
    refetch: refetchVendors,
  } = useQuery(
    ['Get Available Vendors', jobDetail?.id],
    () => ApiCalls.getSuitableVendorByJobId(jobDetail?.id),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: jobDetail?.hostPropertyId ? true : false,
    }
  )

  const queryClient = useQueryClient()
  const {mutateAsync} = useMutation(
    (data) =>
      ApiCalls.putReSchedule({
        jobId: jobDetail?.id,
        vendorId: selectedVendor?.id,
        cleanerIds: staffList,
        scheduledStart: scheduleStart,
      }),
    {onSuccess: () => queryClient.invalidateQueries(['Get Job Detail', jobId])}
  )

  const saveReAssign = async () => {
    try {
      let reAssignRequest: IReVendorStaffAssign = {
        jobId: jobDetail?.id,
        vendorId: selectedVendor?.id,
        cleanerIds: staffList,
        scheduledStart: scheduleStart,
      }
      if (reAssignRequest) {
        setReqLoading(true)
        mutateAsync()
          .then((res) => {
            setStaffList([])
            closeModal()
            setReqLoading(false)
          })
          .catch((err) => {
            setReqLoading(false)
            setErrorMessage(err?.response?.data?.message)
          })
      }
    } catch (err: any) {
      setReqLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        maxWidth={'lg'}
        fullWidth={true}
        style={{overflowY: 'unset'}}
      >
        <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
          Available Vendor
          <button type='button' className='close' onClick={closeModal}>
            <span aria-hidden='true'>×</span>
            <span className='sr-only'>Close</span>
          </button>
        </DialogTitle>
        <DialogContent style={{overflowY: 'scroll'}} className='h-700px'>
          <SuitableVendorsTable data={vendors?.data} refetch={refetchVendors} />
        </DialogContent>
        {/* <DialogContent className='border-top d-flex justify-content-between py-10'>
                    {wizardStep == 1 ? <ArrowButton func={() => { setWizardStep(0); setStaffList([]) }} type="back" classNames="btn btn-danger h-50px" /> : <span></span>}
                    {staffList?.length !== 0 &&
                        <div>
                            <strong className="text-muted">Schedule start time</strong>
                            <DateTimePicker dateOutOfRangeMessage="Time cannot be earlier than job start time." onChange={(date: any) => { setScheduleStart(date) }} min={dayjs(convertUTCDateToLocalDate(jobDetail?.cleaningPeriodStart, jobDetail?.hostProperty!.city.timezone)).toString()} defaultValue={dayjs(convertUTCDateToLocalDate(jobDetail?.cleaningPeriodStart, jobDetail?.hostProperty!.city.timezone)).toString()} />
                            <small className='text-muted'>Job starts at {convertUTCDateToLocalDate(jobDetail?.cleaningPeriodStart, jobDetail?.hostProperty!.city.timezone)}</small>
                        </div>
                    }
                    <div className="d-flex flex-column">
                        {wizardStep == 0 ? <ArrowButton disabled={selectedVendor == null} func={() => setWizardStep(1)} type="forward" /> :

                            <button type="button" disabled={reqLoading || staffList.length == 0} onClick={saveReAssign} className="btn btn-success mt-2">
                                ✔ Save Assignment {reqLoading ? <Spinner animation='border' size='sm' /> : null}
                            </button>}
                        <strong className="text-danger mt-2">{errorMessage}</strong>
                    </div>
                </DialogContent> */}
      </Dialog>
    </>
  )
}

export default AssingmentVendorModal

// removed from dialogcontent

{
  /* <StepWizard>
{wizardStep == 0 ?
    <div className='row gap-10 d-flex justify-content-center align-content-center'>

        {isLoading ? <Spinner animation="border" /> :
            vendors?.data?.data.map(
                (item: IGetAllVendors) =>
                (
                    <>
                        <VendorBox item={item} selectedVendor={selectedVendor} setSelectedVendor={setSelectedVendor} />
                    </>
                )
            )}
        {vendors?.data?.data?.length === 0 && <EmptyTable title='No suitable vendor found' />}
    </div>
    :
    <div className='row gap-10 d-flex justify-content-center'>
        <div className="d-flex justify-content-end mt-5">
            <TextInput onValueChange={debounceFunc} classNames='sticky sticky-top border border-light' placeholder='Search' />
        </div>
        {searchedStaffs?.length == 0 ? < EmptyTable title='No suitable staff found' />
            :
            (searchedStaffs.map(
                (item: IStaff) =>
                (
                    <>
                        <StaffBox item={item} staffList={staffList} setStaffList={setStaffList} deleteByIdStaffList={deleteByIdStaffList} />
                    </>
                )
            ))}
    </div>
}
</StepWizard> */
}
