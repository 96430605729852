/* eslint-disable react/jsx-no-target-blank */
import {useQuery} from 'react-query'
import ApiCalls from '../../../../app/network/ApiCalls'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Dashboard'
        fontIcon={'bi-app-indicator'}
        icon='/media/icons/duotone/Tools/Pantone.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItemWithSub icon='/media/icons/duotone/Design/Brush.svg' title='Live' to='/live'>
        <AsideMenuItem to='/live/turnify-managed' title='Turnify Managed' />
        <AsideMenuItem to='/live/client-managed' title='Client Managed' />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItemWithSub to='/jobs' title='Jobs' icon='/media/icons/duotone/Design/Layers.svg'>
        <AsideMenuItem to='/jobs/turnify-managed' title='Turnify Managed' />
        <AsideMenuItem to='/jobs/client-managed' title='Client Managed' />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/virtual-inspections'
        icon='/media/icons/duotone/Weather/Suset2.svg'
        title='Virtual Inspections'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/turnify-messenger'
        title='Turnify Messenger'
        fontIcon='bi-chat'
        icon='/media/icons/duotone/Communication/Chat.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/hosts'
        title='Clients'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Home/Building.svg'
      >
        <AsideMenuItem to='/hosts' title='All Clients' />
        <AsideMenuItem to='/my-team-subscribers' title='My Team Subscribers' />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/properties'
        icon='/media/icons/duotone/Home/Home.svg'
        title='Properties'
      >
        <AsideMenuItem to='/properties/turnify' title='TurnifPRO' />
        <AsideMenuItem to='/properties/turnify-plus' title='Turnify+' />
        <AsideMenuItem to='/properties/marketplace' title='TurnifyMarketplace' />
        <AsideMenuItem to='/properties/my-team' title='My Team' />
        {/* <AsideMenuItem to='/properties/active' title='Active Properties' /> */}
        {/* <AsideMenuItem to='/properties/offer-pending' title='Offer Pending' /> */}
        <AsideMenuItem to='/properties/offer-pending' title='Offer Pending' />
        <AsideMenuItem to='/properties/add-a-property' title='Add a Property' />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/vendors'
        icon='/media/icons/duotone/Code/Compiling.svg'
        title='Vendors'
      >
        <AsideMenuItem to='/vendors' title='All Vendors' />
        <AsideMenuItem to='/pending-vendors' title='Pending Vendors' />
        <AsideMenuItem to='/pending-staff' title='Pending Vendor Staffs' />
        <AsideMenuItem to='/vendors-rate-list' title='Rate List' />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>

      <AsideMenuItem
        to='/marketplace-requests'
        icon='/media/icons/duotone/Shopping/Sort3.svg'
        title='Marketplace'
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>

      <AsideMenuItem
        to='/billings'
        title='Billing'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Shopping/Ticket.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/turnify-messenger'
        title='Turnify Messenger'
        fontIcon='bi-chat'
        icon='/media/icons/duotone/Communication/Chat.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      {/* <AsideMenuItem
        to='/services'
        title='Manage Services'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Home/Towel.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/master-checklists'
        title='Master Checklists'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Files/File-done.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/hard-delete-user'
        title='Hard Delete User'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Code/Stop.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/admins'
        title='Admins'
        fontIcon='bi-layers'
        icon='/media/icons/duotone/Communication/Contact1.svg'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      {/* <AsideMenuItem
        to='/reports'
        title='Reports'
        fontIcon='bi-layers'
        icon="/media/icons/duotone/Code/Warning-2.svg"
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      <AsideMenuItemWithSub
        to='/settings'
        title='Settings'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotone/Code/Settings4.svg'
      >
        {/* <AsideMenuItem to='/settings/system-config' title='System Configuration' />
        <AsideMenuItem to='/settings/sub-settings' title='Subscription Settings' />
        <AsideMenuItem to='/settings/pms-providers' title='PMS Providers' />
        <AsideMenuItem to='/settings/checklist-templates' title='Checklist Templates' />
        <AsideMenuItem to='/settings/stripe' title='Stripe Settings' />
        <AsideMenuItem to='/settings/notification' title='Notification Settings' />
        <AsideMenuItem to='/settings/billing-invoicing' title='Billing and Invoicing' />
        <AsideMenuItem to='/settings/dispatch' title='Dispatch Settings' />
        <AsideMenuItem to='/settings/master-data' title='Master Data' />
        <AsideMenuItem to='/settings/legal-docs' title='Legal Documents' /> */}
        <AsideMenuItem to='/service-groups' title='Service Groups' />
        <AsideMenuItem to='/services' title='Services' />
        <AsideMenuItem to='/master-checklists' title='Master Checklists' />
        <AsideMenuItem to='/hard-delete-user' title='Hard Delete User' />
        <AsideMenuItem to='/admins' title='Admins' />
        <AsideMenuItem to='/settings/payment-fee-settings' title='Payment Fee Settings' />
        <AsideMenuItem to='/vendor-subscription-packs' title='Vendor Subscription Packs' />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div>
      {/* <AsideMenuItem to='/logs' icon='/media/icons/duotone/Design/Layers.svg' title='Logs' />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator'></div>
        </div>
      </div> */}
      <AsideMenuItem
        to='/logout'
        title={'Logout'}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotone/Navigation/Sign-out.svg'
      />
    </>
  )
}
