import React, {Dispatch, useEffect} from 'react'
import StepWizard from 'react-step-wizard'
import {Card} from 'react-bootstrap-v5'
import PropertyStep from './Steps/PropertyStep'
import ServiceStep from './Steps/ServiceStep'
import {useDispatch, useSelector} from 'react-redux'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators'
import AssignStep from './Steps/AssignStep'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import MarketplaceServiceStep from './Steps/MarketplaceServiceStep'
import MarketplaceAssignStep from './Steps/MarketplaceAssignStep'

type Props = {propertyId?: number; isMarketplace?: boolean}

const JobWizard: React.FC<Props> = ({propertyId, isMarketplace}) => {
  const dispatch = useDispatch<Dispatch<JobAction>>()

  useEffect(() => {
    return () => {
      dispatch(jobActionCreator.setJobForm(null))
    }
  }, [])

  return (
    <>
      <Card>
        <Card.Body className='p-3'>
          <StepWizard transitions={{enterRight: '', enterLeft: '', exitRight: '', exitLeft: ''}}>
            <PropertyStep propertyId={propertyId} />
            {!isMarketplace ? <ServiceStep /> : <MarketplaceServiceStep />}
            {!isMarketplace ? <AssignStep /> : <MarketplaceAssignStep />}
          </StepWizard>
        </Card.Body>
      </Card>
    </>
  )
}

export default JobWizard
