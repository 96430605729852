import {Switch, TextField} from '@material-ui/core'
import React, {Dispatch, RefObject, useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import ApiCalls from '../../network/ApiCalls'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import Select, {components} from 'react-select'
import {IJobService, IStaff, IJobAddForm} from '../../interfaces/Staff'
import {TextInput} from '../Inputs/TextInput'
import {IChecklistItem, IHostPropertyCheckList} from '../../interfaces/Checklist'
import {
  JobService,
  JobServiceAddRequest,
  ManualJobRequest,
} from '../../network/PostRequestModels/JobRequests'
import {useDialog} from '../../contexts/DialogContext'
import {Spinner} from 'react-bootstrap-v5'
import ArrowButton from '../Buttons/ArrowButton'
import {selectStyles} from '../../config/selectStyle'
import {AxiosResponse} from 'axios'
import {
  IPropertyAvailableServices,
  StaffOfferedService,
  VendorOfferedService,
} from '../../interfaces/Job'
import EmptyData from '../Icons/EmptyData'
import {encryptText} from '../../utils/util'
import {
  IListResponse,
  IVendorOfferedServicesResponse,
} from '../../network/NetworkResponses/NetworkResponses'
import {IHostServiceOffer, IService} from '../../interfaces/ServiceType'
import {IVendorOfferedService} from '../../interfaces/Vendors'

type Props = {
  nextStep?: () => void
  previousStep?: () => void
}

type ServiceSelectType = {
  label: string
  value?: IService
}

type ServiceVendorSelectType = {
  label: string
  value?: IVendorOfferedService
}

type ErrorTextProps = {
  hostPropertyId: any
}

type VendorErrorTextProps = {
  vendorId: any
}

type QuestionErrorTextProps = {
  checklistId: any
}

const ChecklistErrorText: React.FC<ErrorTextProps> = ({hostPropertyId}) => {
  return (
    <strong className='text-danger w-100 text-break'>
      Cannot found checklist for this service type and role.{' '}
      <a target='_blank' href={`/checklists/${encryptText(hostPropertyId)}`}>
        Please add checklist first.
      </a>
    </strong>
  )
}

const VendorOfferedErrorText: React.FC<VendorErrorTextProps> = ({vendorId}) => {
  return (
    <strong className='text-danger w-100 text-break'>
      Cannot found vendor service.{' '}
      <a target='_blank' href={`/vendor-details/${encryptText(vendorId)}`}>
        Please add vendor offered service first.
      </a>
    </strong>
  )
}

const ChecklistQuestionErrorText: React.FC<QuestionErrorTextProps> = ({checklistId}) => {
  return (
    <strong className='text-danger w-100 text-break'>
      Cannot found checklist item for this checklist.{' '}
      <a target='_blank' href={`/checklist-sections/${encryptText(checklistId)}`}>
        Please add checklist item first.
      </a>
    </strong>
  )
}

const JobServiceAddForm: React.FC<Props> = ({}) => {
  const {showSuccessDialog} = useDialog()
  const checklistRef = useRef<any>(null)
  const vendorRef = useRef<any>(null)
  const serviceRef = useRef<any>(null)
  const {jobDetail, serviceAddDialog} = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [errorText, setErrorText] = useState<string | null>(null)
  const [checklistErrorText, setChecklistErrorText] = useState<any>(null)
  const [checklistQuestionErrorText, setChecklistQuestionErrorText] = useState<any>(null)
  const [vendorOfferedErrorText, setVendorOfferedErrorText] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedChecklist, setSelectedChecklist] = useState<IHostPropertyCheckList | undefined>(
    undefined
  )
  const [services, setServices] = useState<ServiceSelectType[]>([])
  const [selectedService, setSelectedService] = useState<IHostServiceOffer | undefined>(undefined)
  const [vendorServices, setVendorServices] = useState<ServiceVendorSelectType[]>([])
  const [selectedVendorService, setSelectedVendorService] = useState<
    IVendorOfferedService | undefined
  >(undefined)
  const [note, setNote] = useState<string>()
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const {
    data: serviceData,
    isLoading: serviceLoading,
    error: serviceError,
  } = useQuery(
    ['Get Service Types', jobDetail?.hostPropertyId],
    () => ApiCalls.getHostPropertyServiceOffers(jobDetail?.hostPropertyId),
    {cacheTime: 500000}
  )
  const {
    data: vendorServicesData,
    isFetching: vendorServicesLoading,
    error: vendorServicesError,
  } = useQuery<AxiosResponse<IVendorOfferedServicesResponse>>(
    ['Get Vendor Services ', jobDetail?.vendorId, jobDetail?.hostPropertyId, selectedService],
    () =>
      ApiCalls.getVendorOfferedServicesForJob(
        jobDetail?.vendorId,
        selectedService?.serviceTypeId,
        jobDetail?.hostPropertyId
      ),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: jobDetail?.vendorId && selectedService && jobDetail?.hostPropertyId ? true : false,
    }
  )
  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    isFetching: checklistFetching,
    refetch: refetchChecklists,
  } = useQuery(
    ['Get Checklist for Job', selectedService],
    () =>
      ApiCalls.getHostPropertyChecklists(
        jobDetail?.hostPropertyId,
        jobDetail?.roleId,
        selectedService?.serviceTypeId
      ),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: jobDetail?.hostPropertyId && jobDetail.roleId && selectedService ? true : false,
    }
  )

  const handleChangeService = (value: any) => {
    setSelectedService(value?.value)
  }

  const handleChangeVendorService = (value: any) => {
    setSelectedVendorService(value?.value)
  }

  const handleChangeNote = (text: string) => {
    setNote(text)
  }

  const handleChangeChecklist = (value: any) => {
    setChecklistQuestionErrorText(
      parseInt(value?.value?.questionCount) === 0 ? (
        <ChecklistQuestionErrorText checklistId={value?.value?.id} />
      ) : null
    )
    value?.value && setSelectedChecklist(value.value)
  }

  const addService = async () => {
    try {
      setLoading(true)
      setErrorText(null)
      let newService: JobServiceAddRequest = {
        vendorOfferedServiceId: selectedVendorService?.id,
        serviceNote: note,
        serviceTypeId: selectedService?.serviceTypeId,
        hostPropertyChecklistId: selectedChecklist?.id,
        jobId: jobDetail?.id,
      }
      await ApiCalls.addJobService(newService)
      setLoading(false)
      serviceAddDialog.refetch()
      dispatch(jobActionCreator.closeServiceAddDialog())
    } catch (err: any) {
      setLoading(false)
      setErrorText(err?.response?.data?.message)
    }
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No suitable service found!</span>
      </components.NoOptionsMessage>
    )
  }

  useEffect(() => {
    if (serviceData) {
      const existingServices = serviceAddDialog?.services?.map((item: any) => item?.serviceTypeName)
      const filterServices = serviceData.data.data.filter(
        (service: any) => !existingServices?.includes(service.serviceTypeName)
      )
      setServices(
        filterServices.map((service: any) => ({
          label: `${service.serviceTypeName} - $${service.offeredServicePrice}`,
          value: service,
        }))
      )
    }
  }, [serviceData])

  useEffect(() => {
    if (vendorServicesData) {
      setVendorServices(
        vendorServicesData.data.data.map((service) => ({
          label: `${service.serviceType.name} - ${service.duration} mins. - ${service.currencyType.symbolicName}${service.servicePrice}`,
          value: service,
        }))
      )
      setVendorOfferedErrorText(
        vendorServicesData.data.data.length === 0 ? (
          <VendorOfferedErrorText vendorId={jobDetail?.vendorId} />
        ) : null
      )
    }
  }, [vendorServicesData])

  useEffect(() => {
    if (checklistData) {
      setChecklists(
        checklistData?.data.map((checklist: IChecklistItem) => {
          return {
            value: checklist,
            label: checklist.name?.en
              ? checklist.name?.en
              : checklist.name[Object.keys(checklist.name)[0]],
          }
        })
      )
      setChecklistErrorText(
        checklistData?.data?.length > 0 ? null : (
          <ChecklistErrorText hostPropertyId={jobDetail?.hostPropertyId} />
        )
      )
      checklistRef.current?.select?.clearValue()
    }
  }, [checklistData])

  useEffect(() => {
    checklistRef.current?.select.clearValue()
    vendorRef.current?.select.clearValue()
    setSelectedChecklist(undefined)
    setSelectedVendorService(undefined)
  }, [selectedService])

  if (!jobDetail?.vendorId) {
    return (
      <EmptyData emptyText="This job will be done by property owner's cleaners. You cannot add service." />
    )
  }

  return (
    <div className='row d-flex flex-wrap'>
      <div className='col-12'>
        <div className='row'>
          <label className='form-label fs-6 fw-bolder text-dark'>Select Service</label>
          <div className='col-12'>
            <Select
              style={selectStyles}
              isLoading={serviceLoading}
              ref={serviceRef}
              onChange={handleChangeService}
              options={services}
              components={{NoOptionsMessage}}
            />
            {selectedService && (
              <small className='mt-3 text-muted'>{selectedService.serviceTypeDescription}</small>
            )}
          </div>
          {selectedService && (
            <>
              <label className='form-label fs-6 fw-bolder text-dark mt-6'>Vendor Services</label>
              <div className='col-12'>
                <Select
                  style={selectStyles}
                  isLoading={vendorServicesLoading}
                  ref={vendorRef}
                  onChange={handleChangeVendorService}
                  options={vendorServices}
                />
              </div>
              <label className='form-label fs-6 fw-bolder text-dark mt-6'>Checklist</label>
              <div className='col-12'>
                {checklistFetching ? (
                  <div>Please Wait...</div>
                ) : (
                  <Select
                    styles={selectStyles}
                    isLoading={checklistLoading}
                    ref={checklistRef}
                    onChange={handleChangeChecklist}
                    options={checklists}
                  />
                )}
              </div>
              {checklistErrorText ? checklistErrorText : null}
              {checklistQuestionErrorText}

              <label className='form-label fs-6 fw-bolder text-dark mt-6'>Note (Optional)</label>
              <div className='col-12'>
                <TextInput onValueChange={handleChangeNote} />
              </div>
            </>
          )}
        </div>
      </div>
      {+jobDetail.cleaningProviderId === 2 && (
        <div className='d-flex justify-content-end mt-6'>
          <button
            type='button'
            className='btn btn-success'
            onClick={addService}
            disabled={loading || !selectedVendorService || checklistErrorText}
          >
            Save Service {loading && <Spinner animation='border' size='sm' />}
          </button>
        </div>
      )}
      <div className='d-flex justify-content-end mt-3'>
        <strong className='text-danger'>{errorText}</strong>
      </div>
    </div>
  )
}

export default JobServiceAddForm
