import * as violationActionCreators from '../../../../../redux/actionCreators/violationActionCreators'
import  { Dispatch, FC } from 'react'
import { useHistory } from 'react-router-dom';
import { encryptText, toAmazonUrl } from '../../../../../utils/util';
import ProfilePhotoTemp from '../../../../../umut-components/Icons/ProfilePhotoTemp';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { useDispatch } from 'react-redux';
import { ViolationDetailActions } from '../../../../../redux/actionTypes/violationTypes';

type Props = {
    modalData: any
}

const ViolationReview: FC<Props> = ({ modalData }) => {
    const history = useHistory()
    const showViewDispatch = useDispatch<Dispatch<ViolationDetailActions>>()
    
    return (
        <div>
            <div className="card mb-xxl-8">
                <div className="card-body pb-0">
                    <div className="d-flex align-items-center mb-5">
                        <div className="d-flex align-items-center flex-grow-1">
                            <div className="symbol symbol-55px me-5 ">
                                {modalData?.photoProfilePath ? <img src={modalData?.profilePhotoPath ? toAmazonUrl(modalData?.profilePhotoPath) : toAbsoluteUrl('/media/icons/noImage.png')} alt={modalData?.creatorFirstName} /> :
                                    <ProfilePhotoTemp firstName={modalData?.creatorFirstName} lastName={modalData?.creatorLastName} width={45} height={45} borderRadius='20%' />}
                            </div>
                            <div className="d-flex flex-column">
                                <p className="text-gray-800 text-hover-primary fs-5 fw-bolder">{modalData?.creatorFirstName} {modalData?.creatorLastName}</p>
                            </div>
                        </div>

                        <div className="my-0">
                            <button type="button" onClick={() => {showViewDispatch(violationActionCreators.closeViolationView()); history.push(`/job-details/${encryptText(modalData?.jobId)}`) }} className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary border" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                <i className="bi bi-clipboard-data"></i>
                            </button>

                        </div>
                    </div>
                    <p className="text-gray-800 fw-normal mb-5">{modalData?.description}</p>
                </div>

            </div>
        </div>
    )
}

export default ViolationReview


