import Select from 'react-select';
import { FC, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap-v5"
import ApiCalls from "../../../network/ApiCalls"
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { TextInput } from '../../../umut-components/Inputs/TextInput';


type Props = {
  show: Boolean
  handleClose: () => void
  refetch: () => void
  data: any
}


const DeclineCheckDialog: FC<Props> = ({ show, handleClose, data, refetch }) => {
  const [loading, setLoading] = useState(false)
  const [selectedNote, setSelectedNote] = useState<string | undefined>(undefined);
  const handleConfirmation = async () => {
    try {
      if (data) {
        setLoading(true)
        await ApiCalls.postDeclineForce(data?.data?.id)
        setLoading(false)
        refetch()
        handleClose()
      }
    } catch (error) {
      setLoading(false)
      handleClose();
      console.error(error)
    }
  }
  const handleCloseModal = () => {
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Job Verification</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to decline the Job?
      </Modal.Body>

      <div className="d-flex w-100">
        <TextInput classNames='mb-3 w-350px ms-5' placeholder="Decline note" type="text" onValueChange={setSelectedNote} />
      </div>


      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleCloseModal} >
          Cancel
        </button>
        <Button variant="primary" className="btn btn-danger" disabled={loading} onClick={handleConfirmation}>Decline {loading ? <Spinner animation='border' size='sm' /> : null}</Button>

      </Modal.Footer>
    </Modal>
  )
}

export default DeclineCheckDialog
