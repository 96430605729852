import { IVendorService } from "../../interfaces/Vendors";
import * as actions from "../actionTypes/vendorTypes";

export interface VendorState {
    modalOpen: boolean | null
    vendor: any
    vendorOfferDialog: {
        modalOpen: boolean,
        refetch: () => void
    },
    vendorEditOfferDialog: {
        modalOpen: boolean,
        vendorService: IVendorService | null
    },
    vendorPropertyDialog: {
        modalOpen: boolean
        refetch: () => void,
        accessType: string | null

    }

}

const initialState: VendorState = {
    modalOpen: null,
    vendor: null,
    vendorOfferDialog: {
        modalOpen: false,
        refetch: () => { }
    },
    vendorEditOfferDialog: {
        modalOpen: false,
        vendorService: null
    },
    vendorPropertyDialog: {
        modalOpen: false,
        refetch: () => { },
        accessType: null

    }
}

export default function vendorReducer(
    state: VendorState = initialState,
    action: actions.VendorAction
): VendorState {
    switch (action.type) {
        case actions.OPEN_VENDOR_EDIT_DIALOG:
            return { ...state, modalOpen: true, vendor: action.vendor }
        case actions.CLOSE_VENDOR_EDIT_DIALOG:
            return { ...state, modalOpen: false }
        case actions.OPEN_VENDOR_OFFER_DIALOG:
            return { ...state, vendorOfferDialog: { modalOpen: true, refetch: action.refetch } }
        case actions.CLOSE_VENDOR_OFFER_DIALOG:
            return { ...state, vendorOfferDialog: { modalOpen: false, refetch: () => { } } }
        case actions.OPEN_VENDOR_EDIT_OFFER_DIALOG:
            return { ...state, vendorEditOfferDialog: { modalOpen: true, vendorService: action.vendorService } }
        case actions.CLOSE_VENDOR_EDIT_OFFER_DIALOG:
            return { ...state, vendorEditOfferDialog: { modalOpen: false, vendorService: null } }
        case actions.OPEN_VENDOR_PROPERTY_DIALOG:
            return { ...state, vendorPropertyDialog: { modalOpen: true, refetch: action.refetch, accessType: action.accessType } }
        case actions.CLOSE_VENDOR_PROPERTY_DIALOG:
            return { ...state, vendorPropertyDialog: { modalOpen: false, refetch: () => { }, accessType: null } }
        default:
            return state;
    }
}