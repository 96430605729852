import {DataGrid} from 'devextreme-react'
import {Export, Scrolling, Paging, Summary, TotalItem, Column} from 'devextreme-react/data-grid'
import {FC, useRef, useState} from 'react'
import {IPaymentFee} from '../../../interfaces/PaymentFee'
import {setDxFilterPlaceholder} from '../../../utils/util'
import PaymentFeeDetailDialog from './PaymentFeeDetailDialog'

type Props = {
  data?: IPaymentFee[]
  refetchData: () => void
}
const PaymentFeeTable: FC<Props> = ({data, refetchData}) => {
  const tableRef = useRef<DataGrid>(null)
  const [showFeeDetail, setShowFeeDetail] = useState<boolean>(false)
  const [selectedFee, setSelectedFee] = useState<IPaymentFee | undefined>()

  const displayFeeValue = (cellData: any) => {
    if (cellData.data.isPercentage) {
      return '%' + cellData.data.fee
    } else {
      return '$' + cellData.data.fee
    }
  }

  const displayProvider = (cellData: any) => {
    if (cellData.data.providerType == 'Connect') {
      return 'Turnify Connect'
    } else {
      return 'Turnify PRO'
    }
  }

  const editPaymentFee = (cellValue: any) => {
    if (cellValue.data) {
      setSelectedFee(cellValue.data)
      setShowFeeDetail(true)
    }
  }

  const closeDetail = () => {
    setShowFeeDetail(false)
    setSelectedFee(undefined)
  }

  return (
    <div>
      <DataGrid
        id={'paymentFeeTable'}
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        onEditorPreparing={setDxFilterPlaceholder}
        height={'calc(63vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        className='mt-6'
        onCellClick={editPaymentFee}
      >
        <Export enabled={false} />
        <Scrolling showScrollbar='always' />
        <Paging enabled={false} />

        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Record: {0}'
            column='id'
            summaryType='count'
          />
        </Summary>

        <Column dataField='title' caption='Name' cssClass='cls' />
        <Column
          dataField='providerType'
          caption='Provider'
          cellRender={displayProvider}
          cssClass='cls'
        />
        <Column
          dataField='fee'
          caption='Fee'
          cellRender={displayFeeValue}
          minWidth={450}
          cssClass='cls'
        />
        <Column />
      </DataGrid>
      <PaymentFeeDetailDialog
        isVisible={showFeeDetail}
        data={selectedFee}
        refetch={refetchData}
        handleClose={() => {
          closeDetail()
        }}
      />
    </div>
  )
}

export default PaymentFeeTable
