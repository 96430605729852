import { Dispatch, FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators';
import { Modal, Spinner } from 'react-bootstrap-v5'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ApiCalls from '../../network/ApiCalls';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { NumberInput } from '../Inputs/NumberInput';
import { decryptText } from '../../utils/util';
import { IServicePrice, IVendorServiceRequest } from '../../network/PostRequestModels/VendorRequestForm';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { CloseVendorEditOfferDialog } from '../../redux/actionCreators/vendorActionCreators';
import { VendorAction } from '../../redux/actionTypes/vendorTypes';
import SelectInput from '../Inputs/SelectInput';
import { IService } from '../../interfaces/ServiceType';

type Props = {
}

const VendorOfferEditForm: FC<Props> = ({ }) => {
    let { vendorId }: any = useParams();
    vendorId = vendorId ? decryptText(vendorId) : undefined;
    const { vendorEditOfferDialog } = useSelector((state: RedusxAppState) => state.vendor);
    const dispatch = useDispatch<Dispatch<VendorAction>>();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [types, setTypes] = useState([]);
    const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null | undefined>(vendorEditOfferDialog.vendorService?.serviceType.description);
    const [unitSectionActive, setUnitSectionActive] = useState<boolean>(true);
    const { data: serviceTypes, isLoading, error } = useQuery('Offered Services Types', () => ApiCalls.getServiceTypes(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const [serviceEditRequest, setServiceEditRequest] = useState<IServicePrice>({} as IServicePrice);

    const handleSubmit = async () => {
        const numberError = (serviceEditRequest.duration !== undefined && serviceEditRequest.duration < 1) || (serviceEditRequest.servicePrice !== undefined && serviceEditRequest.servicePrice < 0);
        if (!numberError) {
            setErrorMessage(undefined);
            setLoading(true);
            handleSubmitMutate()
                .then(res => {
                    setLoading(false);
                    dispatch(CloseVendorEditOfferDialog());
                })
                .catch(err => {
                    setLoading(false);
                    setErrorMessage(err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message);
                });
        }
        else {
            setErrorMessage('The duration for a service can not be less than 1 minute');
        }
    }

    const queryClient = useQueryClient();
    const { mutateAsync: handleSubmitMutate } = useMutation(() => ApiCalls.editVendorServices(vendorEditOfferDialog.vendorService?.id, { ...serviceEditRequest, unitSize: unitSectionActive ? serviceEditRequest.unitSize : undefined }), { onSuccess: () => queryClient.invalidateQueries(['Vendor Offered Services', vendorId]) });

    const changeServiceTypeId = (value: number) => {
        setServiceEditRequest({ ...serviceEditRequest, serviceTypeId: value });
    }

    const changeDuration = (newValue: number) => {
        setServiceEditRequest({ ...serviceEditRequest, duration: newValue })
    }

    const changePrice = (newValue: number) => {
        setServiceEditRequest({ ...serviceEditRequest, servicePrice: newValue })

    }

    const changeUnit = (newValue: number) => {
        setServiceEditRequest({ ...serviceEditRequest, unitSize: newValue })
    }

    useEffect(() => {
        if (serviceTypes) {
            setTypes(serviceTypes?.data?.data.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [serviceTypes])

    useEffect(() => {
        if (serviceEditRequest.serviceTypeId && serviceTypes?.data?.data) {
            const selectedService: IService = serviceTypes?.data?.data?.find((service: IService) => service.id === serviceEditRequest.serviceTypeId);
            setSelectedServiceDescription(selectedService.description);
            setUnitSectionActive(selectedService.unitSizeRequired);
        }
    }, [serviceEditRequest.serviceTypeId, serviceTypes])

    useEffect(() => {
        if (vendorEditOfferDialog.vendorService?.serviceType.id && serviceTypes?.data?.data) {
            const selectedService: IService = serviceTypes?.data?.data?.find((service: IService) => service.id === vendorEditOfferDialog.vendorService?.serviceType.id);
            setUnitSectionActive(selectedService.unitSizeRequired);
        }
    }, [vendorEditOfferDialog.vendorService, serviceTypes])

    return (
        <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>
                    <div className='row mt-6'>
                        <div className='col-12 d-flex flex-column'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Service Type</label>
                            <SelectInput disabled={!!vendorEditOfferDialog?.vendorService?.serviceType?.id} defaultValue={vendorEditOfferDialog.vendorService?.serviceType.id} options={types} onValueChanged={changeServiceTypeId} />
                            {selectedServiceDescription && <span className='text-muted m-1'>{selectedServiceDescription}</span>}
                        </div>

                        {unitSectionActive && <div className='col-12 d-flex flex-column mt-6'>
                            <label className='form-label text-dark'>Number of Bedrooms</label>
                            <div className='d-flex align-items-center'>
                                <img alt="unit" src={toAbsoluteUrl("/media/icons/duotone/Home/Door-open.svg")} className="me-3" style={{ width: 25, height: 25 }} />
                                <NumberInput min={0} max={1000} defaultValue={vendorEditOfferDialog.vendorService?.unitSize} onValueChange={e => changeUnit(e)} />
                            </div>
                            {serviceEditRequest.unitSize !== undefined && serviceEditRequest.unitSize === 0 && <div className='text-end'><span className='text-muted fs-6'>Studio</span></div>}
                            {serviceEditRequest.unitSize === undefined && vendorEditOfferDialog.vendorService?.unitSize === 0 && <div className='text-end'><span className='text-muted fs-6'>Studio</span></div>}
                        </div>}
                        <div className='col-12 d-flex flex-column mt-6'>
                            <label className='form-label text-dark'>Duration (mins.)</label>
                            <div className='d-flex align-items-center'>
                                <img alt="clock" src={toAbsoluteUrl("/media/icons/duotone/Home/Clock.svg")} className="me-3" style={{ width: 25, height: 25 }} />
                                <NumberInput min={15} max={1000} defaultValue={vendorEditOfferDialog.vendorService?.duration} onValueChange={e => changeDuration(e)} />
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-column mt-6'>
                            <label className='form-label text-dark'>Service Price</label>
                            <div className='d-flex align-items-center'>
                                <img alt="dollar" src={toAbsoluteUrl("/media/icons/duotone/Shopping/Dollar.svg")} className="me-3" style={{ width: 25, height: 25 }} />
                                <NumberInput min={1} max={1000} defaultValue={vendorEditOfferDialog.vendorService?.servicePrice} onValueChange={e => changePrice(e)} />
                            </div>
                        </div>
                    </div>

                    <strong className='w-100 text-danger'></strong>

                    <div className='col mt-8 '>
                        <div className='d-flex col gap-3 justify-content-end'>
                            <button type='button' className='btn btn-success ' disabled={loading} onClick={handleSubmit}>Save Service {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default VendorOfferEditForm
