import React from 'react'
import { IGetAllVendors } from '../../../../../interfaces/Vendors';
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog';
import AllowedProperties from './AllowedProperties';
import BannedProperties from './BannedProperties';
import VendorPropertyDialog from './VendorPropertyDialog';

type Props={
    data:IGetAllVendors
}
const PropertyTab: React.FC<Props> = ({data}) => {
    return (
        <div className='row'>
            {/* <div className='col-lg-6'>
                <AllowedProperties />
            </div> */}
            <div className='col-lg-12'>
                <BannedProperties />
            </div>
            <VendorPropertyDialog data={data}/>
            <DeleteDialog />
        </div>
    )
}

export default PropertyTab;