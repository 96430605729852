import React, { Dispatch, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { JobStatusObj } from '../../../../enums/JobEnum';
import { IJob, IJobNote } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import DeleteDialog from '../../../../umut-components/Modals/DeleteDialog';
import { decryptText } from '../../../../utils/util';
import NoteAddDialog from './NoteAddDialog';
import NoteItem from './NoteItem';

type Props = {
    jobEventsActive: boolean;
}

const NotesTab: React.FC<Props> = ({ jobEventsActive }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { jobDetail } = useSelector((state: RedusxAppState) => state.job);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [modalType, setModalType] = useState<'job-note' | 'task-note' | 'service-note'>('job-note');
    const [modalNote, setModalNote] = useState<IJobNote | null>(null);
    const { data, isLoading, error, refetch } = useQuery(['Get Job Notes By Id', jobId], () => ApiCalls.getJobNotes(jobId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false });

    const handleCloseModal = () => {
        setAddModalOpen(false);
    }

    const handleSuccess = () => {
        setAddModalOpen(false);
        refetch();
    }

    const openModal = (noteType: 'job-note' | 'task-note' | 'service-note', note: IJobNote | null) => {
        setModalType(noteType);
        setModalNote(note);
        setAddModalOpen(true);
    }

    if (isLoading) {
        return <div className='d-flex justify-content-center p-6'>Loading...</div>
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <div className='row'>
                <div className='w-100 d-flex flex-column mt-10 ms-5'>

                    <div className='col d-flex flex-wrap' style={{ maxHeight: 'calc(50vh - 60px)', overflowY: 'auto', overflowX: 'hidden' }}>
                        {data?.data?.jobNotesResult?.length === 0 && <div className='d-flex align-items-center text-muted fs-4'>No Job Notes Found</div>}
                        {data?.data?.jobNotesResult?.map((note: IJobNote) => <div className="col-sm-12 w-450px col-lg-3 ms-5">
                            <NoteItem openModal={openModal} note={note} noteType='job' refetch={refetch} />
                        </div>
                        )}
                    </div>
                    <div className='w-100 d-flex justify-content-end p-3 ps-0 pe-0 mb-6'>
                        <button type='button' className={`btn btn-success btn-sm ${jobEventsActive ? '' : 'invisible'}`} onClick={() => { setAddModalOpen(true) }}>+ Add Note</button>
                    </div>
                </div>
            </div>
            {addModalOpen && <NoteAddDialog modalOpen={addModalOpen} handleClose={handleCloseModal} handleSuccess={handleSuccess} modalType={modalType} modalNote={modalNote} />}
            <DeleteDialog />

        </>
    )
}

export default NotesTab;