import React, {useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {encryptText, onExporting, setDxFilterPlaceholder} from '../../utils/util'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {useHistory} from 'react-router-dom'

type Props = {
  data: any
}

const MarketplaceRequestsTable: React.FC<Props> = ({data}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)

  const toOfferDetail = (cellData: any) => {
    history.push('marketplace-request-detail/' + encryptText(cellData?.data?.id))
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Requested Offer' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='propertyTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(70vh)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toOfferDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Properties')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Requests: {0}'
            column='address'
            summaryType='count'
          />
        </Summary>

        <Column dataField='address' caption='Property' minWidth={450} cssClass='cls' />
        <Column dataField='hostName' caption='Client' minWidth={300} cssClass='cls' />
        <Column dataField='cityName' caption='City' minWidth={150} cssClass='cls' />
        <Column dataField='stateName' caption='State' minWidth={150} cssClass='cls' />
        <Column dataField='countryName' caption='Country' minWidth={150} cssClass='cls' />
      </DataGrid>
    </div>
  )
}

export default React.memo(MarketplaceRequestsTable)
