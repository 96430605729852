import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { PageTitle } from '../../../../_metronic/layout/core'
import ApiCalls from '../../../network/ApiCalls'
import { RedusxAppState } from '../../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import PendingStaffDocuments from './PendingStaffDocuments'
import StaffStatusTable from './PendingStaffsTable'


const StaffStatus = () => {
    const {
        user: {
          userSection: { vendorId },
        },
      } = useSelector((state: RedusxAppState) => state.user)
    const {
        data,
        isLoading,
        error: servicesError,
        refetch
    } = useQuery(['Get Pending Staffs'], () => ApiCalls.getPendingStaff(vendorId,5), {
        cacheTime: 10000,
        refetchOnWindowFocus: false,
    })

    return (
        <>
            <PageTitle>Pending Staff</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card >
                    <Card.Body >

                        {isLoading ? <SkeletonTableLoading /> : <StaffStatusTable refetch={refetch} data={data?.data?.data} />}
                        <PendingStaffDocuments />
                    </Card.Body>

                </Card>
            </div>
        </>
    )
}

export default StaffStatus
