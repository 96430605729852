import clsx from 'clsx'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RedusxAppState } from '../../../../app/redux/reducers/rootReducer'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search, SearchModal } from '../../../partials'
import { useLayout } from '../../core';
import { useHistory } from 'react-router'
import { toAmazonUrl } from '../../../../app/utils/util'
import ProfilePhotoTemp from '../../../../app/umut-components/Icons/ProfilePhotoTemp'
import SearchInput from '../../../../app/umut-components/Inputs/SearchInput'
import Notification from '../../../../app/umut-components/Notification/Notification'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { config } = useLayout();
  const { user } = useSelector((state: RedusxAppState) => state.user);
  const history = useHistory();

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <SearchInput />
      </div>

      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Notification />
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
        onClick={() => history.push('/profile')}
      >
        {/* begin::Toggle */}
        <div
          className={clsx('d-flex align-items-center cursor-pointer symbol ', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi, &nbsp;</span>
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{user?.user?.firstName}</span>
          <ProfilePhotoTemp height="40px" width="40px" borderRadius="0.475rem" classNames='m-3' profilePhotoPath={user?.user?.profilePhotoPath} firstName={user?.user?.firstName} lastName={user?.user?.lastName} />
        </div>
        {/*<HeaderUserMenu />*/}
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotone/Text/Toggle-Right.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }

/*
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotone/Communication/Group-chat.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div>

 */