import { all, fork } from "redux-saga/effects";
import addressSaga from "./addressSaga";
import checklistSaga from "./checklistSaga";
import masterChecklistSaga from "./masterChecklistSaga";
import hostPropertySaga from "./hostPropertySaga";
import staffSaga from "./staffSaga";
import userSaga from "./userSaga";
export default function* rootSaga() {
    yield all([fork(userSaga), fork(hostPropertySaga), fork(checklistSaga), fork(masterChecklistSaga), fork(staffSaga), fork(addressSaga)]);
}
