import * as adminActionCreators from '../../redux/actionCreators/adminActionCreators';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import VendorEditForm from '../../umut-components/Forms/VendorEditForm';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'react';
import { HostAction } from '../../redux/actionTypes/hostTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { VendorAction } from '../../redux/actionTypes/vendorTypes';
import { AdminAction } from '../../redux/actionTypes/AdminTypes';
import AdminEditForm from '../../umut-components/Forms/AdminEditForm';


const AdminEditDialog = () => {
    const {adminEditDialog:{modalOpen}} =  useSelector((state: RedusxAppState) => state.admin);
    const dispatch = useDispatch<Dispatch<AdminAction>>();

    const handleClose = () => {
        dispatch(adminActionCreators.closeEditAdminDialog())
    }
    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Admin Edit</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <AdminEditForm />
            </Modal.Body>
        </Modal>
    )
}

export default AdminEditDialog
