import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import React, {FC} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../../_metronic/layout/core'
import ApiCalls from '../../../network/ApiCalls'
import {LiveJobResponse} from '../../../network/NetworkResponses/NetworkResponses'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import PropertyTable from '../../properties/PropertyTable'

type Props = {
  provider: 'my-team' | 'turnify' | 'turnify-plus' | 'marketplace'
}

const PropertyList: FC<Props> = ({provider}) => {
  const localTime = dayjs().utc().format()
  var isMarketplace = false
  if (provider == 'marketplace') {
    isMarketplace = true
  }

  const {
    data: propertyData,
    isFetching: propertyLoading,
    error: propertyError,
    refetch: propertyRefetch,
  } = useQuery<AxiosResponse<LiveJobResponse>>(
    ['Get Live Jobs', provider],
    () =>
      ApiCalls.getPropertiesByStatusId(
        1,
        provider === 'my-team' ? 1 : provider === 'turnify-plus' ? 3 : 2,
        isMarketplace
      ),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const pageTitle = `Properties (
        ${provider === 'my-team' ? 'My Team' : ''}
        ${provider === 'turnify' ? 'TurnifyPRO' : ''}
        ${provider === 'turnify-plus' ? 'Turnify+' : ''}
        ${provider === 'marketplace' ? 'Marketplace' : ''}
    )`

  if (propertyError) {
    return <div>Error</div>
  }

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <Card>
        <Card.Body>
          {propertyLoading ? (
            <SkeletonTableLoading />
          ) : (
            propertyData && (
              <PropertyTable data={propertyData?.data?.data} refetch={propertyRefetch} />
            )
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default PropertyList
