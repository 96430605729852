import { AxiosResponse } from 'axios';
import { Column, DataGrid, Export, FilterRow, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Dispatch, FC, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDialog } from '../../../contexts/DialogContext';
import { IGetIssue } from '../../../interfaces/Issue';
import { IJobIssue } from '../../../interfaces/Job';
import ApiCalls from '../../../network/ApiCalls';
import { IListResponse } from '../../../network/NetworkResponses/NetworkResponses';
import { openIssueDialog } from '../../../redux/actionCreators/jobActionCreators';
import { JobAction } from '../../../redux/actionTypes/jobTypes';
import ApproveButton from '../../../umut-components/Buttons/ApproveButton';
import DeclineButton from '../../../umut-components/Buttons/DeclineButton';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';
import ProfilePhotoTemp from '../../../umut-components/Icons/ProfilePhotoTemp';
import { convertUTCDateToLocalDate, getDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../../utils/util';
import config from '../../../config/config.json';

type Props = {
    data: IJobIssue[],

}
const UnresolverReport: FC<Props> = ({ data }) => {
    const dispatch = useDispatch<Dispatch<JobAction>>();
    const tableRef = useRef<DataGrid>(null)

    const toIssueDetail = (cellValue: any) => {
        if (cellValue?.data?.id) {
            dispatch(openIssueDialog(cellValue?.data));
        }
    }

    const calculateReporter = (cellValue: any) => {
        return <div className='d-flex flex-row align-items-center'>
            <ProfilePhotoTemp firstName={cellValue?.data?.reporter?.firstName} lastName={cellValue?.data?.reporter?.lastName} profilePhotoPath={cellValue?.data?.reporter?.profilePhotoPath} borderRadius='50%' width={40} height={40} />
            <span className='ms-3'>{cellValue?.data?.reporter?.firstName + ' ' + cellValue?.data?.reporter?.lastName}</span>
        </div>
    }

    const calculateReportedOn = (cellValue: any) => {
        return toConvertedDate(cellValue?.reporter?.reportOn);
    }

    if (data.length === 0) {
        return <EmptyTable title='No Issues' />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="reported-issues"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                ref={tableRef}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onCellClick={toIssueDetail}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Force Jobs")}
            >
                <Export enabled />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Issues: {0}" column='reporter.firstName' summaryType='count' />
                </Summary>

                <Column dataField="reporter.firstName" cellRender={calculateReporter} caption='Reported By' minWidth={250} dataType='string' cssClass='cls' />
                <Column dataField="note" caption='Note' dataType='string' alignment="center" width={500} cssClass='cls' />
                <Column dataField="jobIssueTypeName" caption='Type' dataType='string' minWidth={400} cssClass='cls' />
                <Column dataField="hostPropertyName" caption='Property' dataType='string' minWidth={400} cssClass='cls' />
                <Column dataField="jobIssueStatusTypeName" caption='Status' dataType='string' alignment="center" width={400} cssClass='cls' />
                <Column dataField="reporter.reportOn" caption='Reported On' calculateCellValue={calculateReportedOn} dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} width={150} cssClass='cls'>
                    <HeaderFilter dataSource={getDateFilter('reporter.reportOn')} />
                </Column>
            </DataGrid>
        </div >
    )
}

export default UnresolverReport
