import * as actions from '../actionTypes/serviceDetailActionType'
import { IService } from '../../interfaces/ServiceType'

export interface serviceDetailState {
  referenceState: {
    ModalOpen: boolean
    ModalData: IService | null
  }
  documentsState: {
    ModalOpen: boolean
    ModalData: any | null
  }
}
const initialState: serviceDetailState = {
  referenceState: {
    ModalOpen: false,
    ModalData: null,
  },
  documentsState: {
    ModalOpen: false,
    ModalData: null,
  },
}
export default function vendorDetailReducer(
  state: serviceDetailState = initialState,
  action: actions.ServiceDetailAction
) {
  switch (action.type) {
    case actions.REFERENCE_OPEN_VIEW:
      return { ...state, referenceState: { ModalOpen: true, ModalData: action.service } }
    case actions.REFERENCE_CLOSE_VIEW:
      return { ...state, referenceState: { ModalOpen: false, ModalData: null } }
    case actions.DOCUMENT_OPEN_VIEW:
      return { ...state, documentsState: { ModalOpen: true, ModalData: action.documentTypes } }
    case actions.DOCUMENT_CLOSE_VIEW:
      return { ...state, documentsState: { ModalOpen: false, ModalData: null } }
    default:
      return state
  }
}