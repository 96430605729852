import React, { useState } from 'react'
import { Dispatch, useRef } from 'react'
import {
    Column,
    DataGrid,
    HeaderFilter,
    Paging,
    Scrolling,
    Export,
    SearchPanel,
    Summary,
    TotalItem,
    ColumnHeaderFilter,
} from 'devextreme-react/data-grid'
import { IAdmins } from '../../interfaces/Admins'
import { useDispatch } from 'react-redux'
import { AdminAction } from '../../redux/actionTypes/AdminTypes'
import { useHistory } from 'react-router'
import { onExporting } from '../../utils/util'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import UpdateButton from '../../umut-components/Buttons/UpdateButton'
import * as adminActionCreators from '../../redux/actionCreators/adminActionCreators';
import ApiCalls from '../../network/ApiCalls'
import { closeDeleteDialog, openDeleteDialog } from '../../redux/actionCreators/deleteDialogActionCreators'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import { DeleteActions } from '../../redux/actionTypes/deleteDialogTypes'
import { useDialog } from '../../contexts/DialogContext'
import { AccountStatusTypes } from '../../enums/AccountEnum'
import { Spinner } from 'react-bootstrap-v5'

type Props = {
    data: IAdmins[],
    refetch: () => void

}

const AdminsTable: React.FC<Props> = ({ data, refetch }) => {
    const { showSuccessDialog, showFailureDialog } = useDialog();
    const [reqloading, setreqloading] = useState({ boolean: false, id: 0 })
    const dispatch = useDispatch<Dispatch<AdminAction>>();
    const tableRef = useRef<DataGrid>(null)
    const deleteDispatch = useDispatch<Dispatch<DeleteActions>>();

    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + " " + cellValue?.lastName;
    }
    const renderEditButton = (cellValue: any) => {
        return (
            <UpdateButton
                iconWidth='20'
                iconHeight='20'
                updateFn={() => {
                    dispatch(adminActionCreators.openAdminEditDialog(refetch, cellValue?.data))
                }
                }
            />
        )
    }

    const renderChangeStatus = async (id: number, status: number) => {
        try {
            setreqloading({boolean:true,id:id})
            await ApiCalls.UpdateAdmin(id, { accountStatusId: status == AccountStatusTypes.INACTIVE ? AccountStatusTypes.ACTIVE : AccountStatusTypes.INACTIVE });
            await refetch();
            setreqloading({boolean:false,id:id})
            deleteDispatch(closeDeleteDialog());
            showSuccessDialog(status == AccountStatusTypes.ACTIVE ? 'Admin removed!' : 'Admin Activated');
        }
        catch (err: any) {
            setreqloading({boolean:false,id:id})
            showFailureDialog(err?.response?.data?.message);
        }
    }

    const renderStatusButton = (cellValue: any) => {
        const accountStatusId = cellValue?.data?.accountStatusId;
        switch (accountStatusId) {
            case AccountStatusTypes.DOCUMENT_PENDING:
                return <strong>Waiting for document approval</strong>
            case AccountStatusTypes.EMAIL_NOT_VERIFIED:
                return <strong>Waiting for email approval</strong>
            case AccountStatusTypes.EMAIL_VERIFIED:
                return <strong>Waiting for document upload</strong>
            case AccountStatusTypes.REJECTED:
                return <strong>Waiting for document upload</strong>
            case AccountStatusTypes.INACTIVE:
                return <button className='btn btn-success btn-sm' onClick={() => { renderChangeStatus(cellValue?.data.id, AccountStatusTypes.INACTIVE) }}>Activate Admin {reqloading.boolean && reqloading.id == cellValue?.data.id && <Spinner animation="border" size="sm" />}</button>
            case AccountStatusTypes.ACTIVE:
                return <button className='btn btn-danger btn-sm ' onClick={() => { renderChangeStatus(cellValue?.data.id, AccountStatusTypes.ACTIVE) }}>Deactivate Admin {reqloading.boolean && reqloading.id == cellValue?.data.id && <Spinner animation="border" size="sm" />}</button>
            default:
                return <strong></strong>
        }
    }

    if (data?.length === 0) {
        return <EmptyTable title="No Admins" />
    }
    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id='adminsTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(64vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Admins")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Admins: {0}" column='name' summaryType='count' />
                </Summary>

                <Column dataField="firstName" calculateCellValue={calculateFullName} caption='Fullname' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField='email' dataType='string' minWidth={300} cssClass='cls' />
                <Column dataField='phoneNumber' dataType='string' minWidth={180} cssClass='cls' />
                <Column dataField='roleName' caption="Role" dataType='string' minWidth={180} cssClass='cls' />
                <Column dataField="accountStatusName" caption="Status" dataType="string" minWidth={180} cssClass='cls' />
                <Column name="edit-button" width={100} cellRender={renderEditButton} cssClass='cls' />
                <Column name='status-button' minWidth={150} alignment="center" cellRender={renderStatusButton} cssClass='cls' />


            </DataGrid>
        </div>
    )
}
export default React.memo(AdminsTable)
