import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import React, {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useHistory, useParams} from 'react-router'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDialog} from '../../../contexts/DialogContext'
import {IDetail, IBillingDetail} from '../../../interfaces/Billing'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import PdfPrinter from '../../../umut-components/PdfPrinter/PdfPrinter'
import {decryptText, encryptText} from '../../../utils/util'

type InvoiceItemProps = {
  detail: IDetail
  hostPropertyName: string
  billingDate: string
}

const InvoiceItem: React.FC<InvoiceItemProps> = ({detail, hostPropertyName, billingDate}) => {
  return (
    <div className={'row p-3 rounded'}>
      <div className='col-6 d-flex flex-column'>
        <small>{detail.serviceTypeName}</small>
        <small>{dayjs(billingDate).format('MM/DD/YYYY')}</small>

        <small>({hostPropertyName})</small>
      </div>
      <div className='col-2'>
        <small>${detail.unitPrice}</small>
      </div>
      <div className='col-2'>
        <small>{detail.quantity}</small>
      </div>
      <div className='col-2'>
        <small>
          ${detail.unitPrice && detail.quantity ? detail.unitPrice * detail.quantity : null}
        </small>
      </div>
    </div>
  )
}

const MyTeamSubscriberDetailInvoiceDetail: React.FC = () => {
  const ref = useRef(null)
  const history = useHistory()
  let {billingId}: any = useParams()
  billingId = billingId ? decryptText(billingId) : undefined
  const [modalOpen, setModalOpen] = useState(false)
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [inProcess, setInProcess] = useState(false)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const {
    data: billingDetailData,
    isLoading: billingDetailLoading,
    error: billingDetailError,
  } = useQuery<AxiosResponse<IBillingDetail>>(
    ['Billing Detail ', billingId],
    () => ApiCalls.getBillingDetailsForMyTeam(billingId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: billingId ? true : false}
  )

  const closeModal = () => {
    history.goBack()
  }

  useEffect(() => {
    setModalOpen(billingId ? true : false)
  }, [billingId])

  const chargeInvoice = async () => {
    try {
      //Try to charge bill with ID
      setInProcess(true)
      await ApiCalls.chargeMyTeamBilling(billingId)
      setTimeout(() => {
        setInProcess(false)
        showSuccessDialog(
          'Payment for invoice number ' +
            billingDetailData?.data.invoiceNumber +
            ' has been received.'
        )
      }, 800)
    } catch (error) {
      //There is an error occur
      setInProcess(false)
      showFailureDialog('An error occur during charging invoice! ')
    }
  }

  return (
    <Modal show={modalOpen} onHide={closeModal} size='lg' centered>
      <Modal.Body>
        {billingDetailLoading ? (
          <SkeletonTableLoading />
        ) : (
          <div className='ps-6' ref={ref}>
            <div className='row'>
              <div className='col-6 d-flex flex-column'>
                <img
                  alt='turnify'
                  style={{width: 200}}
                  src={toAbsoluteUrl('/media/logos/turnify-logo.png')}
                />
                <strong className='mt-3'>TidyHost</strong>
                <small className='mt-3'>
                  150 Orchard Court, Blue Bell, PA 19422 <br />
                  concierge@turnify.com <br />
                  (833)TDY-HOST
                </small>
                <strong className='mt-3'>Invoice Number</strong>
                <small>{billingDetailData?.data.invoiceNumber}</small>
              </div>
              <div className='col-6 d-flex flex-column align-items-end'>
                <small className='badge badge-primary mt-3' style={{width: 'fit-content'}}>
                  {billingDetailData?.data.hostInvoiceStatusTypeName}
                </small>
              </div>
            </div>
            <div className='row mt-6 p-3 bg-light rounded d-flex flex-column'>
              <strong>TO: </strong>
              <small>{billingDetailData?.data.nameSurname}</small>
              <small>{billingDetailData?.data.billingEmail}</small>
              <small>{billingDetailData?.data.billingAddress}</small>
            </div>
            <div className='row mt-6 p-3 rounded bg-light'>
              <div className='col-6'>
                <strong>Description</strong>
              </div>
              <div className='col-2'>
                <strong>Unit Price ($)</strong>
              </div>
              <div className='col-2'>
                <strong>QTY</strong>
              </div>
              <div className='col-2'>
                <strong>Total</strong>
              </div>
            </div>
            {billingDetailData?.data.details.map((detail) => (
              <InvoiceItem
                detail={detail}
                hostPropertyName={billingDetailData.data.hostPropertyName}
                billingDate={billingDetailData.data.billingDate}
              />
            ))}
            <div className='row p-3 rounded bg-light'>
              <div className='col-6' />
              <div className='col-2' />
              <div className='col-2'>
                <strong>Subtotal</strong>
              </div>
              <div className='col-2'>
                <small>${billingDetailData?.data.totalGrossAmount}</small>
              </div>
            </div>
            <div className='row p-3 rounded'>
              <div className='col-6' />
              <div className='col-2' />
              <div className='col-2'>
                <strong>Tax</strong>
              </div>
              <div className='col-2'>
                <small>${billingDetailData?.data.totalTaxAmount}</small>
              </div>
            </div>
            <div className='row p-3 rounded bg-light'>
              <div className='col-6' />
              <div className='col-2' />
              <div className='col-2'>
                <strong>Grand Total</strong>
              </div>
              <div className='col-2'>
                <small>${billingDetailData?.data.totalNetAmount}</small>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        className='d-flex justify-content-between py-3 '
        style={{backgroundColor: '#000033'}}
      >
        <div>
          <button
            className='btn btn-sm btn-success ms-2'
            disabled={inProcess}
            onClick={() => {
              chargeInvoice()
            }}
          >
            Charge
          </button>
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-link text-white me-6'
            disabled={inProcess}
            onClick={closeModal}
          >
            Close
          </button>
          <PdfPrinter targetRef={ref} filename='billing.pdf'>
            {({toPdf}: any) => (
              <button
                type='button'
                className='btn btn-sm btn-warning'
                disabled={inProcess}
                onClick={toPdf}
              >
                Download
              </button>
            )}
          </PdfPrinter>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default MyTeamSubscriberDetailInvoiceDetail
