export const OPEN_DELETE_DIALOG = "OPEN_DELETE_DIALOG";
export interface OpenDeleteDialog {
    type: typeof OPEN_DELETE_DIALOG,
    deleteFn: () => Promise<void>,
    bodyText: string,
    title: string
}

export const CLOSE_DELETE_DIALOG = "CLOSE_DELETE_DIALOG";
export interface CloseDeleteDialog {
    type: typeof CLOSE_DELETE_DIALOG,
}

export type DeleteActions =
    | OpenDeleteDialog
    | CloseDeleteDialog