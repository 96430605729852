import { Column, DataGrid, Export, FilterRow, HeaderFilter, MasterDetail, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { FC, useRef } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { JobStatusType } from '../../enums/JobEnum';
import ApiCalls from '../../network/ApiCalls';
import ViewButton from '../../umut-components/Buttons/ViewButton';
import EmptyTable from '../../umut-components/Icons/EmptyTable';
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading';
import { encryptText, convertUTCDateToLocalDate, onExporting, getDateFilter, toConvertedDate, setDxFilterPlaceholder } from '../../utils/util';
import config from '../../config/config.json';
type Props = {
    jobProviderId?: number
}
const JobLateFinish: FC<Props> = ({ jobProviderId }) => {
    const { data, isLoading, error } = useQuery(['Get isLate Finish Jobs', jobProviderId], () => jobProviderId ? ApiCalls.getIsLateFinishJobsByProviderId(true, jobProviderId) : ApiCalls.getIsLateFinishJobs(true), { cacheTime: 500000, refetchOnWindowFocus: false });
    const history = useHistory();

    const displayStatusType = (cellData: any) => {
        const jobStatusType = cellData?.data?.jobStatusTypeName;
        return (<strong className={`text-${jobStatusType === JobStatusType.COMPLETED ? "success" : jobStatusType === JobStatusType.PENDING ? "warning" : "danger"}`}>{jobStatusType === "Started" ? "In Progress" : jobStatusType}</strong>)
    }


    const tableRef = useRef<DataGrid>(null)
    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue?.scheduledStart, cellValue.hostPropertyCityTimeZone);
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue?.scheduledFinish, cellValue.hostPropertyCityTimeZone);
    }
    const calculateStartedOn = (cellValue: any) => {
        return toConvertedDate(cellValue?.startedOn, cellValue.hostPropertyCityTimeZone);
    }
    const calculateIsFinished = (cellValue: any) => {
        return toConvertedDate(cellValue?.finishedOn, cellValue.hostPropertyCityTimeZone);
    }
    const isManuallyCreatedJob = (cellValue: any) => {
        return cellValue?.data.isManuallyCreated ? <p>{cellValue.data.hostPropertyAddress}<span style={{ color: "#ff5d5d" }}> ⓜ</span></p> : cellValue.data.hostPropertyAddress
    }

    const toJobDetail = (cellValue: any) => {
        if (cellValue?.data?.id) {
            // history.push({
            //     pathname: `/job-details/` + `${encryptText(cellValue?.data?.id)}`

            // })
            window.open(`/job-details/` + `${encryptText(cellValue?.data?.id)}`)
        }
    }


    if (isLoading) {
        return <div className='mt-6'><SkeletonTableLoading /></div>
    }

    if (error) {
        return <div className='mt-6'>Error</div>
    }

    if (data?.data?.data?.length === 0) {
        return <EmptyTable title="No Jobs" />
    }
    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id={"isLateJobTable"}
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data?.data?.data}
                showBorders={false}
                showColumnLines={false}

                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(63vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                className='mt-6'
                onCellClick={toJobDetail}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, "Jobs")}
            >
                <Export enabled />
                <SearchPanel visible={true} />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Jobs: {0}" column='hostPropertyAddress' summaryType='count' />
                </Summary>

                <Column dataField="hostPropertyAddress" cellRender={isManuallyCreatedJob} minWidth={450} caption="Property" cssClass='cls' />
                <Column dataField="scheduledStart" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledFinish} minWidth={150} cssClass='cls'  >
                    <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
                </Column>
                <Column dataField="jobStatusTypeName" alignment='center' caption="Status" cellRender={displayStatusType} minWidth={150} cssClass='cls' />
                {/* <Column dataField="vendorName" caption="Vendor" minWidth={200} cssClass='cls' /> */}
                <Column visible={jobProviderId === 2} dataField="vendorName" caption="Cleaner" minWidth={200} cssClass='cls' />
                <Column dataField="startedOn" caption="started at" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateStartedOn} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('startedOn')} />
                </Column>
                <Column dataField="scheduledFinish" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledStart} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
                </Column>
                <Column dataField="finishedOn" caption="finished at" dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateIsFinished} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('finishedOn')} />
                </Column>
                <Column dataField="hostName" caption='Client Name' minWidth={200} cssClass='cls' />
                <Column dataField="hostPropertyCityName" caption="City" minWidth={150} cssClass='cls' />
                <Column dataField="hostPropertyStateName" caption="State" minWidth={200} cssClass='cls' />
                <Column dataField="hostPropertyCountryName" caption="Country" minWidth={200} cssClass='cls' />




            </DataGrid>

        </div>
    )

}

export default JobLateFinish
