import * as actions from "../actionTypes/deleteDialogTypes";

export function openDeleteDialog(deleteFn: () => Promise<void>, bodyText: string, title: string): actions.OpenDeleteDialog {
    return {
        type: actions.OPEN_DELETE_DIALOG,
        deleteFn,
        bodyText,
        title
    }
}

export function closeDeleteDialog(): actions.CloseDeleteDialog {
    return {
        type: actions.CLOSE_DELETE_DIALOG,
    }
}