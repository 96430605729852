import React from 'react';
import { Tab } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ApiCalls from '../../../../network/ApiCalls';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading';
import { decryptText } from '../../../../utils/util';
import JobTable from './JobTable';

const Jobs: React.FC = () => {
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    let { hostUserId, vendorUserId }: any = useParams();
    hostUserId = hostUserId ? decryptText(hostUserId) : undefined;
    vendorUserId = vendorUserId ? decryptText(vendorUserId) : undefined;
    const { data: jobData, isLoading: jobLoading, error: jobError } = useQuery(['Job History', staffDetail.staff?.userId], () => staffDetail.staff?.userId ? ApiCalls.getJobsByUserId(staffDetail.staff?.userId) : null, { cacheTime: 500000, refetchOnWindowFocus: false, enabled: staffDetail.staff?.userId ? true : false });

    if (jobLoading) {
        return <div><SkeletonTableLoading /></div>
    }

    return (
        <div className='p-6 pt-0'>
            <JobTable data={jobData?.data?.data} />
        </div>
    )
}

export default Jobs;