import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {
  decryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  toConvertedDate,
  setDxFilterPlaceholder,
} from '../../../../utils/util'
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton'
import {useHistory, useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {DeleteActions} from '../../../../redux/actionTypes/deleteDialogTypes'
import * as deleteDialogActionCreators from '../../../../redux/actionCreators/deleteDialogActionCreators'
import ApiCalls from '../../../../network/ApiCalls'
import ReviewTab from './ReviewTab/ReviewTab'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import {useDialog} from '../../../../contexts/DialogContext'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {JobStatusType} from '../../../../enums/JobEnum'
import config from '../../../../config/config.json'

type Props = {
  data: any
  refetch: () => void
  jobEventsActive: boolean
}

const ServiceTable: React.FC<Props> = ({data, refetch, jobEventsActive}) => {
  let {jobId}: any = useParams()
  jobId = decryptText(jobId)
  const {showFailureDialog} = useDialog()
  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)

  const tableRef = useRef<DataGrid>(null)
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<DeleteActions>>()

  const displayDeleteButton = (cellData: any) => {
    return (
      <DeleteButton
        iconWidth='20'
        iconHeight='20'
        deleteFn={() => {
          dispatch(
            deleteDialogActionCreators.openDeleteDialog(
              async () => {
                try {
                  await ApiCalls.deleteJobService(cellData?.data?.id)
                  dispatch(deleteDialogActionCreators.closeDeleteDialog())
                  refetch()
                } catch (err: any) {
                  dispatch(deleteDialogActionCreators.closeDeleteDialog())
                  showFailureDialog(err?.response?.data?.message)
                }
              },
              `Are you sure you want to delete ${cellData?.data?.serviceTypeName}?`,
              'Delete Service?'
            )
          )
        }}
      />
    )
  }

  const displayStatus = (cellData: any) => {
    return (
      <strong
        className={`text-${
          cellData?.data?.jobServiceStatusTypeName === 'Pending'
            ? 'warning'
            : cellData?.data?.jobServiceStatusTypeName === 'Completed'
            ? 'success'
            : 'dark'
        }`}
      >
        {cellData?.data?.jobServiceStatusTypeName}
      </strong>
    )
  }

  const calculateStartOn = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, jobDetail?.hostProperty!.city.timezone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, jobDetail?.hostProperty!.city.timezone)
  }
  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, jobDetail?.hostProperty!.city.timezone)
  }
  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, jobDetail?.hostProperty!.city.timezone)
  }
  const calculateStaffFullName = (cellValue: any) => {
    return cellValue?.staff?.firstName + ' ' + cellValue?.staff?.lastName
  }

  const renderStaffInfo = (cellValue: any) => {
    return cellValue?.data?.staff?.firstName ? (
      <div className='d-flex flex-row align-items-center'>
        <ProfilePhotoTemp
          borderRadius='50%'
          width={30}
          height={30}
          firstName={cellValue?.data?.staff?.firstName}
          lastName={cellValue?.data?.staff?.lastName}
          profilePhotoPath={cellValue?.data?.staff?.profilePhotoPath}
        />
        <small className='ms-2'>
          {cellValue?.data?.staff?.firstName + ' ' + cellValue?.data?.staff?.lastName}
        </small>
      </div>
    ) : null
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Services' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id={'services' + jobId}
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(65vh - 60px)'}
        className='mt-6'
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Job Services')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={10} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Services: {0}'
            column='serviceTypeName'
            summaryType='count'
          />
        </Summary>

        <Column dataField='serviceTypeName' caption='Type' minWidth={250} cssClass='cls' />
        <Column
          dataField='jobServiceStatusTypeName'
          cellRender={displayStatus}
          caption='Status'
          minWidth={250}
          cssClass='cls'
        />
        <Column dataField='note' minWidth={200} cssClass='cls' />
        <Column
          visible={+jobDetail!.cleaningProviderId == 1 || +jobDetail!.cleaningProviderId == 3}
          caption='Staff'
          minWidth={250}
          calculateCellValue={calculateStaffFullName}
          cellRender={renderStaffInfo}
        />
        <Column
          visible={
            jobDetail?.jobStatusType!.name !== JobStatusType.PENDING &&
            jobDetail?.jobStatusType!.name !== JobStatusType.SCHEDULED
          }
          dataField='startedOn'
          caption='Started At'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateStartOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('startedOn')} />
        </Column>
        <Column
          visible={
            jobDetail?.jobStatusType!.name !== JobStatusType.PENDING &&
            jobDetail?.jobStatusType!.name !== JobStatusType.SCHEDULED
          }
          dataField='finishedOn'
          caption='Finished At'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateFinishedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>
        {jobEventsActive && (
          <Column
            visible={data?.length > 1}
            width={110}
            alignment='center'
            cellRender={displayDeleteButton}
            cssClass='cls'
          />
        )}
        <MasterDetail
          autoExpandAll={true}
          enabled={true}
          render={(data: any) => <ReviewTab jobEventsActive={jobEventsActive} data={data} />}
        />
      </DataGrid>
    </div>
  )
}

export default ServiceTable
