import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {useSelector} from 'react-redux'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDialog} from '../../../../contexts/DialogContext'
import {IGetAllVendorsRate} from '../../../../interfaces/Vendors'
import ApiCalls from '../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {NumberInput} from '../../../Inputs/NumberInput'
import {TextInput} from '../../../Inputs/TextInput'

type Props = {
  visible: boolean
  handleClose: () => void
  data: IGetAllVendorsRate
}

const PropertyServiceRateDetailModal: React.FC<Props> = ({data, visible, handleClose}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [rateData, setRateData] = useState<IGetAllVendorsRate>(data)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {showFailureDialog} = useDialog()

  const onValChange = (v: string) => {
    //console.log('onValChange', v)
  }

  const changeVendorRate = (v: any) => {
    var raw = Object.assign({}, rateData)

    setErrorMessage('')
    if (v > +raw.hostRate) {
      setErrorMessage('Vendor rate cannot be greater than client rate')
    } else {
      raw.vendorRate = v
      setRateData(raw)
    }
  }

  const changeClientRate = (v: any) => {
    var raw = Object.assign({}, rateData)

    setErrorMessage('')
    if (v < +raw.vendorRate) {
      setErrorMessage('Client rate cannot be lower than vendor rate')
    } else {
      raw.hostRate = v
      setRateData(raw)
    }
  }

  const changeVendorDuration = (v: any) => {
    var raw = Object.assign({}, rateData)

    raw.duration = v
    setRateData(raw)
  }

  const handleUpdate = async () => {
    try {
      const data = {
        rateId: rateData.id,
        offerId: rateData.offerId,
        vendorId: rateData.vendorId,
        duration: rateData.duration,
        vendorRate: rateData.vendorRate,
        hostRate: rateData.hostRate,
      }
      await ApiCalls.updateVendorServiceRate(data)
      handleClose()
    } catch (error: any) {
      setErrorMessage(
        error?.response?.data?.message || 'An error occur during updating service rate'
      )
    }
  }

  const handleDelete = async () => {
    try {
      if (rateData.offerId == 0) {
        await ApiCalls.deleteHostServiceRate(rateData.id)
      } else {
        await ApiCalls.deleteVendorServiceRate(rateData.offerId)
      }
      handleClose()
    } catch (error: any) {
      setErrorMessage(
        error?.response?.data?.message || 'An error occur during deleting service rate'
      )
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered size='xl'>
      <ModalHeader closeButton>
        <Modal.Title>Vendor Rate Detail</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-12'>
            <div className='row mt-6'>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Property</label>
                <TextInput
                  onValueChange={onValChange}
                  disabled={true}
                  defaultValue={data.hostPropertyName}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Client</label>
                <TextInput
                  onValueChange={onValChange}
                  disabled={true}
                  defaultValue={data.hostName}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Vendor</label>
                <TextInput
                  onValueChange={onValChange}
                  disabled={true}
                  defaultValue={data.vendorName}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Service Type</label>
                <TextInput
                  onValueChange={onValChange}
                  disabled={true}
                  defaultValue={data.serviceTypeName}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Checklist</label>
                <TextInput
                  onValueChange={onValChange}
                  disabled={true}
                  defaultValue={data.checklistName}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Client Rate ($)</label>
                <NumberInput
                  min={1}
                  max={5000}
                  defaultValue={data.hostRate}
                  onValueChange={(e) => changeClientRate(e)}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Vendor Rate ($)</label>
                <NumberInput
                  min={1}
                  max={5000}
                  defaultValue={data.vendorRate}
                  onValueChange={(e) => changeVendorRate(e)}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Duration (min.)</label>
                <NumberInput
                  min={1}
                  max={1000}
                  defaultValue={data.duration}
                  onValueChange={(e) => changeVendorDuration(e)}
                />
              </div>
            </div>

            <strong className='w-100 text-danger'></strong>

            <div className='col mt-8 '>
              <div className='d-flex col gap-3 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-danger '
                  disabled={loading}
                  onClick={handleDelete}
                >
                  Delete {loading ? <Spinner animation='border' size='sm' /> : null}
                </button>
                <button
                  type='button'
                  className='btn btn-success '
                  disabled={loading}
                  onClick={handleUpdate}
                >
                  Update {loading ? <Spinner animation='border' size='sm' /> : null}
                </button>
              </div>
              <div className='col text-end'>
                <strong style={{color: 'red'}}>{errorMessage}</strong>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PropertyServiceRateDetailModal
