import React, { Dispatch, SetStateAction, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

type Props = {
    text: string,
    setText: Dispatch<SetStateAction<string>>,
    handleSelectAddress?: (e: string) => void,
    handleSelectPlaceId?: (e: string) => void,
    handleOnError?: () => void,
    disabled?: boolean
}

const AutocompleteMap: React.FC<Props> = ({ text, setText, handleSelectAddress, handleSelectPlaceId, handleOnError, disabled }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
        <PlacesAutocomplete value={text} onChange={setText} onSelect={(a, b) => { handleSelectPlaceId?.(b); handleSelectAddress?.(a) }} onError={console.log}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='position-relative'>
                    <input
                        {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'form-control',
                        })}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => {
                            handleOnError && handleOnError();
                            setIsFocused(false);
                        }}
                        disabled={disabled}
                    />
                    <div className="autocomplete-dropdown-container w-100" style={{ display: text !== '' ? isFocused ? '' : 'none' : 'none' }}>
                        {loading && <div className='d-flex p-3 justify-content-center'><Spinner animation='border' /></div>}

                        {!loading && suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                        {suggestions.map.length === 0 && <div className='d-flex justify-content-center p-3 text-muted'>No result found</div>}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

export default AutocompleteMap
