import {AxiosResponse} from 'axios'
import React, {useEffect} from 'react'
import {useState} from 'react'
import {Card, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import ApiCalls from '../../../network/ApiCalls'
import {IListResponse} from '../../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import {decryptText} from '../../../utils/util'
import ChecklistAddDialog from './MasterChecklistAddDialog'
import ChecklistEditDialog from './MasterChecklistEditDialog'
import ChecklistTable from './MasterChecklistTable'

const PropertyChecklists: React.FC = () => {
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const [checklistAddModalOpen, setChecklistAddModalOpen] = useState(false)
  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery<AxiosResponse<IHostPropertyCheckList[]>>(
    'Get Checklist By Property Id',
    () => ApiCalls.getMasterChecklists(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const handleCloseChecklistAddModal = () => {
    setChecklistAddModalOpen(false)
  }

  const handleAddPropertySuccess = () => {
    setChecklistAddModalOpen(false)
    refetchChecklists()
  }

  useEffect(() => {
    if (checklistData) {
      setChecklists(checklistData?.data)
    }
  }, [checklistData])

  return (
    <>
      <PageTitle>Master Checklist</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body>
            <div className='mb-10'>
              <p className='text-danger fs-5 fw-bold'>
                The Master Checklist screen is intended for broad checklists that apply to multiple
                properties or clients. Please refrain from adding specific checklists for individual
                clients or properties here. These should be added under the respective property's
                checklists.
              </p>
            </div>
            {checklistLoading ? (
              <div className='d-flex justify-content-center'>
                <Spinner animation='border' />
              </div>
            ) : checklists.length > 0 ? (
              <ChecklistTable data={checklists} refetchChecklists={refetchChecklists} />
            ) : (
              <EmptyTable title='No Master Checklists Found' />
            )}
          </Card.Body>
          <Card.Footer className='d-flex align-items-center justify-content-between'>
            <div />
            <button
              className='btn btn-success'
              style={{height: '100%'}}
              onClick={() => {
                setChecklistAddModalOpen(true)
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Checklist
            </button>
          </Card.Footer>
        </Card>
      </div>
      <ChecklistAddDialog
        modalOpen={checklistAddModalOpen}
        handleClose={handleCloseChecklistAddModal}
        handleAddPropertySuccess={handleAddPropertySuccess}
      />
      <ChecklistEditDialog refetchChecklists={refetchChecklists} />
      <DeleteDialog />
    </>
  )
}

export default PropertyChecklists
