import { IOfferedService, IStaff, IStaffDetail } from "../../interfaces/Staff";

export const GET_STAFF_LIST = "GET_STAFF_LIST";
export interface GetStaffList {
    type: typeof GET_STAFF_LIST,
    hostId: number
}

export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export interface GetStaffListSuccess {
    type: typeof GET_STAFF_LIST_SUCCESS,
    staffList: IStaff[]
}

export const GET_STAFF_LIST_FAILURE = "GET_STAFF_LIST_FAILURE";
export interface GetStaffListFailure {
    type: typeof GET_STAFF_LIST_FAILURE,
    errorText: string
}

export const GET_HOST_STAFF_DETAIL = "GET_HOST_STAFF_DETAIL";
export interface GetHostStaffDetail {
    type: typeof GET_HOST_STAFF_DETAIL,
    hostUserId: number
}

export const GET_VENDOR_STAFF_DETAIL = "GET_VENDOR_STAFF_DETAIL";
export interface GetVendorStaffDetail {
    type: typeof GET_VENDOR_STAFF_DETAIL,
    vendorUserId: number
}

export const GET_STAFF_DETAIL_SUCCESS = "GET_STAFF_DETAIL_SUCCESS";
export interface GetStaffDetailSuccess {
    type: typeof GET_STAFF_DETAIL_SUCCESS,
    staff: IStaffDetail
}

export const GET_STAFF_DETAIL_FAILURE = "GET_STAFF_DETAIL_FAILURE";
export interface GetStaffDetailFailure {
    type: typeof GET_STAFF_DETAIL_FAILURE,
    errorText: string
}

export const OPEN_STAFF_ADD_DIALOG = "OPEN_STAFF_ADD_DIALOG";
export interface OpenStaffAddDialog {
    type: typeof OPEN_STAFF_ADD_DIALOG,
    refetch: () => void
}

export const CLOSE_STAFF_ADD_DIALOG = "CLOSE_STAFF_ADD_DIALOG";
export interface CloseStaffAddDialog {
    type: typeof CLOSE_STAFF_ADD_DIALOG,
}

export const OPEN_STAFF_EDIT_DIALOG = "OPEN_STAFF_EDIT_DIALOG";
export interface OpenStaffEditDialog {
    type: typeof OPEN_STAFF_EDIT_DIALOG,
    refetch: () => void,
    staff: IStaff
}

export const CLOSE_STAFF_EDIT_DIALOG = "CLOSE_STAFF_EDIT_DIALOG";
export interface CloseStaffEditDialog {
    type: typeof CLOSE_STAFF_EDIT_DIALOG,
}

export const OPEN_STAFF_OFFERED_ADD_DIALOG = "OPEN_STAFF_OFFERED_ADD_DIALOG";
export interface OpenStaffOfferedAddDialog {
    type: typeof OPEN_STAFF_OFFERED_ADD_DIALOG,
    refetch: () => void,
    staffOfferedService?: IOfferedService
}

export const CLOSE_STAFF_OFFERED_ADD_DIALOG = "CLOSE_STAFF_OFFERED_ADD_DIALOG";
export interface CloseStaffOfferedAddDialog {
    type: typeof CLOSE_STAFF_OFFERED_ADD_DIALOG,
}

export const OPEN_STAFF_PROPERTY_DIALOG = "OPEN_STAFF_PROPERTY_DIALOG";
export interface OpenStaffPropertyDialog {
    type: typeof OPEN_STAFF_PROPERTY_DIALOG,
    refetch: () => void,
    formType: "allowed" | "banned"
}

export const CLOSE_STAFF_PROPERTY_DIALOG = "CLOSE_STAFF_PROPERTY_DIALOG";
export interface CloseStaffPropertyDialog {
    type: typeof CLOSE_STAFF_PROPERTY_DIALOG,
}
export const OPEN_STAFF_DOCUMENT_DIALOG = "OPEN_STAFF_DOCUMENT_DIALOG";
export interface OpenStaffDocumentDialog {
    type: typeof OPEN_STAFF_DOCUMENT_DIALOG,
    staff: IStaff
}

export const CLOSE_STAFF_DOCUMENT_DIALOG = "OPEN_STAFF_DOCUMENT_DIALOG";
export interface CloseStaffDocumentDialog {
    type: typeof CLOSE_STAFF_DOCUMENT_DIALOG,
}
export type StaffAction =
    | OpenStaffAddDialog
    | CloseStaffAddDialog
    | OpenStaffEditDialog
    | CloseStaffEditDialog
    | GetStaffList
    | GetStaffListSuccess
    | GetStaffListFailure
    | GetHostStaffDetail
    | GetVendorStaffDetail
    | GetStaffDetailSuccess
    | GetStaffDetailFailure
    | OpenStaffOfferedAddDialog
    | CloseStaffOfferedAddDialog
    | OpenStaffPropertyDialog
    | CloseStaffPropertyDialog