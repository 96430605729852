import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import config from '../../../config/config.json'
import {encryptText, onExporting, setDxFilterPlaceholder, formatOnlyDate} from '../../../utils/util'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import {useDialog} from '../../../contexts/DialogContext'
import {useDispatch} from 'react-redux'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'

type Props = {
  data: any
  pendingButtonActive?: boolean
  refetch: () => void
  proFilterActive?: boolean
  plusFilterActive?: boolean
  myTeamFilterActive?: boolean
}

const MyTeamSubscribersTable: React.FC<Props> = ({
  data,
  pendingButtonActive,
  refetch,
  proFilterActive,
  plusFilterActive,
  myTeamFilterActive,
}) => {
  const tableRef = useRef<DataGrid>(null)

  const calculateSubmitDate = (cellValue: any) => {
    return formatOnlyDate(cellValue?.dateSubmitted)
  }

  const calculatePaymentDate = (cellValue: any) => {
    return formatOnlyDate(cellValue?.nextPayment)
  }

  const calculateHasCard = (cellValue: any) => {
    if (cellValue?.hasCard) {
      return 'TRUE'
    } else {
      return 'FALSE'
    }
  }

  const toPropertyDetail = (cellData: any) => {
    if (cellData.rowType == 'data') {
      window.open('/my-team-subscriber-detail/' + encryptText(cellData?.data?.id))
    }
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Properties' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='subscriberTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(70vh)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toPropertyDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'MyTeamSubscribers')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Properties: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>
        <Column dataField='name' caption='Property' minWidth={450} cssClass='cls' />
        <Column dataField='hostName' caption='Client' minWidth={300} cssClass='cls' />
        <Column dataField='cityName' caption='City' minWidth={150} cssClass='cls' />
        <Column dataField='stateName' caption='State' minWidth={150} cssClass='cls' />
        <Column
          dataField='dateSubmitted'
          caption='Subscription Date'
          minWidth={150}
          format={config.date_format}
          calculateCellValue={calculateSubmitDate}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='totalPayment'
          caption='Total Paid'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='nextPayment'
          caption='Next Payment'
          minWidth={150}
          format={config.date_format}
          calculateCellValue={calculatePaymentDate}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='hasCard'
          caption='Has Card'
          calculateCellValue={calculateHasCard}
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='hostPropertyStatusTypeName'
          caption='Status'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
      </DataGrid>
    </div>
  )
}

export default React.memo(MyTeamSubscribersTable)
