import React, { Dispatch, useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import { JobAction } from '../../redux/actionTypes/jobTypes'
import { closeInspectionDetail } from '../../redux/actionCreators/jobActionCreators'
import ApproveButton from '../../umut-components/Buttons/ApproveButton'
import DeclineButton from '../../umut-components/Buttons/DeclineButton'
import ApiCalls from '../../network/ApiCalls'
import { useDialog } from '../../contexts/DialogContext'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { DeclineVirtualInspect } from '../../network/PostRequestModels/VirtualInspectionRequest'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { IVirtualInspection } from '../../interfaces/VirtualInspection'
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp'


const InspectionDetailDialog = () => {
  const { showSuccessDialog } = useDialog()
  const {
    inspectionDetailDialog: { modalOpen, refetch, virtualInspectionId },
  } = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [loading, setLoading] = useState<boolean>(false)
  const [declineRequest, setDeclineRequest] = useState<DeclineVirtualInspect>({
    jobVirtualInspectionId: virtualInspectionId,
    notes: ''
  })
  const [errorMessage, setErrorMessage] = useState<string>()
  const { data: virtualInspectionData, isLoading: virtualInspectionLoading, error: virtualInspectionError, refetch: inspectionRefetch } = useQuery<AxiosResponse<IVirtualInspection>>(['Get Virtual Inspections', virtualInspectionId], () => ApiCalls.getVirtualInspectionsByJobId(virtualInspectionId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: virtualInspectionId ? true : false });


  const handleApprove = async () => {
    if (virtualInspectionData?.data)
      try {
        setLoading(true)
        await ApiCalls.putApproveVirtualInspections(virtualInspectionData?.data?.id)
        setLoading(false)
        showSuccessDialog("Inspection approved!")
        refetch()
        closeModal()
      } catch (error: any) {
        setLoading(false)
        refetch()
        setErrorMessage(error?.response?.data?.message)
      }
  }
  const handleDecline = async () => {
    if (virtualInspectionData?.data)
      try {
        setLoading(true)
        await ApiCalls.putDeclineVirtualInspections(declineRequest)
        setLoading(false)
        refetch()
        closeModal()
        showSuccessDialog("Inspection Declined!")
      } catch (error: any) {
        setLoading(false)
        refetch()
        setErrorMessage(error?.response?.data?.message)
        console.error(error)
      }
  }
  const closeModal = () => {
    dispatch(closeInspectionDetail())
    setErrorMessage('')
  }
  useEffect(() => {
    setDeclineRequest({ ...declineRequest, jobVirtualInspectionId: virtualInspectionId })
  }, [virtualInspectionId])

  if (virtualInspectionError) {
    return (<div>Error...</div>)
  }

  return (
    <Modal show={modalOpen} onHide={closeModal} size='lg' centered closeButton>
      <ModalHeader closeButton>
        <Modal.Title>Virtual Inspection Detail</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {virtualInspectionLoading ? <Spinner animation="border" /> :
          <><div className='row gap-5 justify-content-center'>
            <div className='col-lg-5 col-12 d-flex flex-column'>
              <strong className='text-primary'>Sample Photo</strong>
              <div className=' shadow-sm '>
                <img
                  alt='question-sample'
                  className='w-100'
                  src={virtualInspectionData?.data?.samplePhotoURL ? virtualInspectionData?.data?.samplePhotoURL : toAbsoluteUrl('/media/icons/noEmptyPhoto.png')}
                  style={{ height: 400, objectFit: 'contain' }} />
              </div>
            </div>
            <div className='col-lg-5 col-12 d-flex flex-column'>
              <strong className='text-primary'>Answer Photo</strong>
              <div className='border shadow-sm '>
                <img
                  alt='question-answer'
                  className='w-100'
                  src={virtualInspectionData?.data?.questionAnswerPhotoURL ? virtualInspectionData?.data?.questionAnswerPhotoURL : toAbsoluteUrl('/media/icons/noEmptyPhoto.png')}
                  style={{ height: 400, objectFit: 'contain' }} />
              </div>
            </div>
          </div><div className="pt-5 d-flex justify-content-center flex-column w-100 gap-5 border-top mt-5">
              <div className="d-flex">
                <div>
                  {virtualInspectionData &&
                    <ProfilePhotoTemp
                      width={50}
                      height={50}
                      borderRadius='50%'
                      firstName={virtualInspectionData?.data?.userFirstName}
                      lastName={virtualInspectionData?.data?.userLastName}
                      profilePhotoPath={virtualInspectionData?.data?.userProfilePhotoURL} />}

                </div>
                <strong className='ms-3 d-flex align-items-center'>
                  {virtualInspectionData?.data?.userFirstName} {virtualInspectionData?.data?.userLastName}
                </strong>
              </div>
              <div className='d-flex p-4 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'>
                <div className='d-flex flex-column'>
                  <span className=''>{virtualInspectionData?.data?.questionDescription?.en ?? virtualInspectionData?.data?.questionDescription[Object.keys(virtualInspectionData?.data?.questionDescription)[0]]}</span>
                  <small className='text-muted'>-{virtualInspectionData?.data?.questionAnswer}</small>
                </div>
              </div>
            </div></>

        }
      </Modal.Body>
      <Modal.Footer>
        {
          virtualInspectionData?.data?.questionAnswer === undefined ?
            <strong className='text-danger'>Checklist item is not answered yet.</strong>
            :
            <>
              <div className='col-12 text-end'>
                <strong style={{ color: 'red' }}>{errorMessage}</strong>
              </div>
              <DeclineButton
                disabled={loading}
                declineFn={() => {
                  handleDecline()
                }}
              />
              <ApproveButton
                spinner={loading}
                disabled={loading}
                approveFn={() => {
                  handleApprove()
                }}
              />
            </>
        }
      </Modal.Footer>
    </Modal >
  )
}

export default InspectionDetailDialog
