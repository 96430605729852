import React from 'react'

type Props = {
  classNames?: string
  updateFn: () => void
  type?: 'submit' | 'button'
  param?: any
  iconWidth?: string
  iconHeight?: string
}

const UpdateChecklistForJobsButton: React.FC<Props> = ({
  classNames,
  type,
  param,
  updateFn,
  iconWidth,
  iconHeight,
}) => {
  return (
    <button
      type={type ? type : 'button'}
      className={'btn btn-light-info ' + classNames}
      onClick={() => {
        updateFn()
      }}
      data-toggle='tooltip'
      title='Update Checklist for Future Jobs'
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={iconWidth ? iconWidth : '24'}
        height={iconHeight ? iconHeight : '24'}
        viewBox='0 0 24 24'
        version='1.1'
      >
        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          <rect
            x='0'
            y='0'
            width={iconWidth ? iconWidth : '24'}
            height={iconHeight ? iconHeight : '24'}
          />
          <path
            d='M10.9630156,7.5 L11.0475062,7.5 C11.3043819,7.5 11.5194647,7.69464724 11.5450248,7.95024814 L12,12.5 L15.2480695,14.3560397 C15.403857,14.4450611 15.5,14.6107328 15.5,14.7901613 L15.5,15 C15.5,15.2109164 15.3290185,15.3818979 15.1181021,15.3818979 C15.0841582,15.3818979 15.0503659,15.3773725 15.0176181,15.3684413 L10.3986612,14.1087258 C10.1672824,14.0456225 10.0132986,13.8271186 10.0316926,13.5879956 L10.4644883,7.96165175 C10.4845267,7.70115317 10.7017474,7.5 10.9630156,7.5 Z'
            fill='currentColor'
          />
          <path
            d='M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z'
            fill='currentColor'
            opacity='0.3'
          />
        </g>
      </svg>
    </button>
  )
}

export default UpdateChecklistForJobsButton
