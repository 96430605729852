import {useQuery} from 'react-query'

import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {FC} from 'react'
import {useHistory} from 'react-router-dom'
import config from '../../../../../config/config.json'
import ApiCalls from '../../../../../network/ApiCalls'
import {encryptText, toConvertedDate, onExporting, getDateFilter} from '../../../../../utils/util'

type Props = {
  propertyId: number
  timeZone: string
}

const TurnoversStep: FC<Props> = ({propertyId, timeZone}) => {
  const history = useHistory()
  const {data, isLoading, error} = useQuery(['Get By propertiesId Turnovers', propertyId], () =>
    ApiCalls.getTurnoversByPropertyId(propertyId)
  )

  const toJobDetail = (cellData: any) => {
    if (cellData?.data?.jobId) {
      history.push({
        pathname: `/job-details/` + encryptText(cellData?.data?.jobId),
      })
    }
  }

  const displayNextCheckIn = (cellValue: any) => {
    return cellValue?.checkIn ? toConvertedDate(cellValue?.checkIn, timeZone) : '--'
  }

  const displayNextCheckOut = (cellValue: any) => {
    return cellValue?.checkOut ? toConvertedDate(cellValue?.checkOut, timeZone) : '--'
  }

  return (
    <div>
      <DataGrid
        id={'propertyTurnovers' + propertyId}
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data?.data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        onCellClick={toJobDetail}
        hoverStateEnabled={true}
        height={'calc(65vh - 60px)'}
        selection={{mode: 'single'}}
        className='mt-6'
        onExporting={(e) => onExporting(e, 'Turnovers')}
      >
        <Export enabled />
        <SearchPanel visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={10} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Turnovers: {0}'
            column='jobId'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='checkIn'
          caption='Check-In'
          minWidth={50}
          calculateCellValue={displayNextCheckIn}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('nextCheckIn')} />
        </Column>
        <Column
          dataField='checkOn'
          caption='Check-Out'
          minWidth={50}
          calculateCellValue={displayNextCheckOut}
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('nextCheckOn')} />
        </Column>
        <Column
          dataField='jobId'
          caption='jobId'
          dataType='string'
          minWidth={50}
          width={500}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default TurnoversStep
