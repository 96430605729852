import React, {Dispatch, FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IService, IServiceGroup} from '../../interfaces/ServiceType'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import * as serviceGroupDetailActionCreators from '../../redux/actionCreators/serviceGroupDetailActionCreators'
import {TextInput} from '../../umut-components/Inputs/TextInput'
import ApiCalls from '../../network/ApiCalls'
import {Spinner} from 'react-bootstrap-v5'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import SwitchBox from '../../umut-components/Inputs/SwitchBox'
import {encryptText} from '../../utils/util'
import {ServiceGroupDetailAction} from '../../redux/actionTypes/serviceGroupDetailActionType'

type Props = {
  selectedServiceGroup: any
  refetchGroups: () => void
}
const ServiceGroupForm: FC<Props> = ({selectedServiceGroup, refetchGroups}) => {
  const {
    user: {
      userSection: {userId},
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const [serviceGroup, setServiceGroup] = useState<IServiceGroup>({
    name: selectedServiceGroup?.name,
    description: selectedServiceGroup?.description,
    isActive: selectedServiceGroup?.isActive,
    id: selectedServiceGroup?.id,
  } as IService)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const showViewDispatch = useDispatch<Dispatch<ServiceGroupDetailAction>>()

  const handleCloseModal = () => {
    showViewDispatch(serviceGroupDetailActionCreators.closeServiceGroupView())
  }
  const handleSubmit = async () => {
    try {
      setLoading(true)
      selectedServiceGroup !== null
        ? await ApiCalls.UpdateServiceGroup(selectedServiceGroup?.id, serviceGroup)
        : await ApiCalls.CreateServiceGroup(serviceGroup)
      refetchGroups()
      setLoading(false)
      handleCloseModal()
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error?.response?.data?.errors?.[0]?.message)
    }
  }
  useEffect(() => {
    setServiceGroup({...serviceGroup})
  }, [])

  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            Name {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput
            disabled={selectedServiceGroup}
            onValueChange={(text: string) => setServiceGroup({...serviceGroup, name: text})}
            defaultValue={serviceGroup?.name}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Description {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput
            onValueChange={(text: string) => setServiceGroup({...serviceGroup, description: text})}
            defaultValue={serviceGroup?.description}
          />
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Is Active? {<span style={{color: 'red'}}>*</span>}
          </label>
          <SwitchBox
            text={'If it is false, hosts can not see services.'}
            mutedText={true}
            onCheckboxChange={(check) => {
              setServiceGroup({...serviceGroup, isActive: check})
            }}
            defaultChecked={serviceGroup?.isActive === undefined ? false : serviceGroup.isActive}
          />
          <div className='col mt-8 '>
            <div className='d-flex col gap-3 justify-content-end'>
              <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                Cancel
              </button>

              <button
                type='button'
                className='btn btn-success '
                disabled={loading || !serviceGroup?.name || !serviceGroup?.description}
                onClick={handleSubmit}
              >
                Save {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col text-end'>
              <strong style={{color: 'red'}}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ServiceGroupForm
