import React, { Dispatch } from 'react';
import { Card, Spinner } from 'react-bootstrap-v5';
import { useDispatch, useSelector } from 'react-redux';
import { ChecklistAction } from '../../../../redux/actionTypes/checklistTypes';
import * as checklistActionCreator from '../../../../redux/actionCreators/checklistActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import QuestionBoard from './QuestionBoard';
import SectionQuestion from './Question';
import { useState } from 'react';
import { IQuestion, ISection } from '../../../../interfaces/Checklist';
import { ChecklistSetQuestionOrderRequest } from '../../../../network/PostRequestModels/ChecklistRequests';
import ApiCalls from '../../../../network/ApiCalls';
import { useDialog } from '../../../../contexts/DialogContext';
import { PageTitle } from '../../../../../_metronic/layout/core';
import DeleteDialog from '../../../../umut-components/Modals/DeleteDialog';

type Props = {
}

const SectionQuestions: React.FC<Props> = () => {
    const { questionCollapse } = useSelector((state: RedusxAppState) => state.checklist);
    const { showSuccessDialog } = useDialog();
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();
    const [saveChangeActive, setSaveChangesActive] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        const sourceIndex = source.index;
        const destinationIndex = destination?.index;
        if (sourceIndex !== undefined && destinationIndex !== undefined && sourceIndex !== destinationIndex) {
            setSaveChangesActive(true);
            let newQuestions = questionCollapse.selectedSection?.questions ? [...questionCollapse.selectedSection?.questions] : [];
            const [removedQuestion] = newQuestions.splice(sourceIndex, 1);
            newQuestions.splice(destinationIndex, 0, removedQuestion);
            dispatch(checklistActionCreator.setQuestionOrder(questionCollapse.selectedSection ? { ...questionCollapse.selectedSection, questions: newQuestions } : {} as ISection));
        }
    }

    const handleChangeQuestionList = async () => {
        setSendLoading(true);
        try {
            const questionOrderRequest: ChecklistSetQuestionOrderRequest[] = questionCollapse?.selectedSection?.questions ? questionCollapse?.selectedSection?.questions.map((question, index): ChecklistSetQuestionOrderRequest => {
                return {
                    id: question.id,
                    orderIndex: index + 1
                }
            }) : [];
            await ApiCalls.setQuestionOrder(questionOrderRequest, questionCollapse?.selectedSection?.id);
            setSaveChangesActive(false);
            setSendLoading(false);
            showSuccessDialog('Orders are changed successfully.');
        }
        catch (err) {
            setSaveChangesActive(false);
            setSendLoading(false);
        }
    }

    return (
        <>
            <div className='row g-5 g-xl-8'>
                <Card className='p-0'>
                    <Card.Body style={{ padding: 0, paddingTop: 8, paddingLeft: 8, paddingRight: 8 }}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <strong className='text-muted p-4 mb-6'>{questionCollapse.selectedSection && questionCollapse.selectedSection?.questions.length > 0 ? 'Drag and drop checklist items to change order' : 'There is no checklist item assigned for this room yet. Start adding your checklists.'}</strong>
                            <QuestionBoard droppableId='question-container'>
                                {questionCollapse.selectedSection?.questions?.map((question, index) => <SectionQuestion question={question} index={index} />)}
                            </QuestionBoard>
                        </DragDropContext>
                    </Card.Body>
                    {
                        saveChangeActive ?
                            <Card.Footer className='d-flex justify-content-end'>
                                <button className="btn btn-success" disabled={sendLoading} style={{ height: '100%' }} onClick={handleChangeQuestionList}>
                                    Save Checklist Items Orders {sendLoading ? <Spinner animation='border' size='sm' /> : null}
                                </button>
                            </Card.Footer>
                            :
                            <Card.Footer className='d-flex justify-content-end'>
                                <button className="btn btn-success" style={{ height: '100%' }} onClick={() => dispatch(checklistActionCreator.openQuestionAddDialog(questionCollapse.selectedSection))}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                    Add New Checklist Item
                                </button>
                            </Card.Footer>
                    }
                </Card >
                <DeleteDialog />

            </div>
        </>
    )
}

export default SectionQuestions;