import {IServiceGroup} from '../../interfaces/ServiceType'

export const SERVICE_GROUP_OPEN_VIEW = 'SERVICE_GROUP_OPEN_VIEW'

export interface OpenServiceGroupViewAction {
  type: typeof SERVICE_GROUP_OPEN_VIEW
  serviceGroup: IServiceGroup | null
}
export const SERVICE_GROUP_CLOSE_VIEW = 'SERVICE_GROUP_CLOSE_VIEW'

export interface CloseServiceGroupViewAction {
  type: typeof SERVICE_GROUP_CLOSE_VIEW
}
export type ServiceGroupDetailAction = OpenServiceGroupViewAction | CloseServiceGroupViewAction
