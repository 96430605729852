import React, { Dispatch, useEffect, useRef } from 'react';
import { useState } from 'react';
import * as hostActionCreators from '../../../../src/app/redux/actionCreators/hostActionCreators';
import { Spinner } from 'react-bootstrap-v5';
import ApiCalls from '../../network/ApiCalls';
import { TextInput } from '../Inputs/TextInput';
import { useDialog } from '../../contexts/DialogContext';
import { UserEditRequest } from '../../network/PostRequestModels/UserRequest';
import { useDispatch, useSelector } from 'react-redux';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { useQuery } from 'react-query';
import SelectInput from '../Inputs/SelectInput';
import { HostAction } from '../../redux/actionTypes/hostTypes';
import { HostStatusObj } from '../../enums/HostStatusEnum';
import PhoneTextInput from '../Inputs/PhoneInput';


const HostEditForm = () => {
    const { hostEditDialog } = useSelector((state: RedusxAppState) => state.host);
    const dispatch = useDispatch<Dispatch<HostAction>>();

    const { showSuccessDialog } = useDialog();
    const [statusTypes, setStatusTypes] = useState<any>([])
    const [selectedStatus, setSelectedStatus] = useState<number | undefined>(undefined)
    const [editRequest, setEditRequest] = useState<UserEditRequest>({
        firstName: hostEditDialog.host?.name,
        phoneNumber: hostEditDialog.host?.phoneNumber,
    } as UserEditRequest);
    const { data, isLoading, error } = useQuery(['Get Host Types'], () => ApiCalls.getHostStatusTypes(), { cacheTime: 500000 })
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);


    const handleUserEdit = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            const { firstName, phoneNumber } = editRequest;
            if (firstName && phoneNumber) {
                if (phoneNumber) {
                    await ApiCalls.updateHost(hostEditDialog?.host?.id, { name: editRequest.firstName, phoneNumber: editRequest.phoneNumber, hostStatusTypeId: selectedStatus });
                    setLoading(false);
                    hostEditDialog.refetch();
                    showSuccessDialog('Host Updated.');
                    dispatch(hostActionCreators.closeEditHostDialog());
                }
                else {
                    setLoading(false)
                    setErrorMessage('Phone is not valid!');
                }
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    useEffect(() => {
        if (data) {
            setStatusTypes(data?.data?.data.map((type: any) => ({ label: type.name, value: type.id })))
        }

    }, [data])

    useEffect(() => {
        if (hostEditDialog.host && (hostEditDialog.host.hostStatusTypeName === "Active" || hostEditDialog.host.hostStatusTypeName === "Passive" || hostEditDialog.host.hostStatusTypeName === "Banned")) {
            setSelectedStatus(HostStatusObj[hostEditDialog.host.hostStatusTypeName])
        }
    }, [hostEditDialog])


    return (
        <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>

                    <label className='form-label fs-6 fw-bolder text-dark'>Select Status</label>
                    <SelectInput
                        defaultValue={
                            hostEditDialog?.host?.hostStatusTypeName === "Active" || hostEditDialog?.host?.hostStatusTypeName === "Passive" || hostEditDialog?.host?.hostStatusTypeName === "Banned" ?
                                HostStatusObj[hostEditDialog?.host.hostStatusTypeName] : undefined
                        }
                        options={statusTypes}
                        onValueChanged={(value) => {
                            setSelectedStatus(value)
                        }}
                    />


                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Host Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, firstName: text })} defaultValue={editRequest?.firstName} />



                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Phone</label>
                    <PhoneTextInput defaultValue={editRequest?.phoneNumber} onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} />

                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleUserEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(HostEditForm);