import React, {Dispatch, FC} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {IService} from '../../interfaces/ServiceType'
import {useDispatch} from 'react-redux'
import {ServiceDetailAction} from '../../redux/actionTypes/serviceDetailActionType'
import {onExporting} from '../../utils/util'
import UpdateButton from '../../umut-components/Buttons/UpdateButton'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import {DeleteActions} from '../../redux/actionTypes/deleteDialogTypes'
import * as serviceDetailActionCreators from '../../redux/actionCreators/serviceDetailActionCreators'
import ApiCalls from '../../network/ApiCalls'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../redux/actionCreators/deleteDialogActionCreators'
import {useDialog} from '../../contexts/DialogContext'
import DeleteDialog from '../../umut-components/Modals/DeleteDialog'

type Props = {
  data?: IService[]
  refetchReferences: () => void
}
const ServiceReferenceTable: FC<Props> = ({data, refetchReferences}) => {
  const showViewDispatch = useDispatch<Dispatch<ServiceDetailAction>>()
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()
  const {showFailureDialog, showSuccessDialog} = useDialog()

  const displayDeleteButton = (cellData: any) => {
    const deleteAction = async () => {
      try {
        await ApiCalls.deleteServiceTypes(cellData?.data?.id)
        refetchReferences()
        deleteDispatch(closeDeleteDialog())
        showSuccessDialog('Service Deleted!')
      } catch (err: any) {
        showFailureDialog(err?.response?.data?.message)
      }
    }

    const deleteButtonClick = async () => {
      deleteDispatch(
        openDeleteDialog(
          deleteAction,
          `Do you want to delete this service?`,
          `Delete  ` + `"${cellData?.data?.name}"` + `  Service`
        )
      )
    }

    return <DeleteButton iconHeight='20' iconWidth='20' deleteFn={deleteButtonClick} />
  }

  const displayEditButton = (cellData: any) => {
    return (
      <UpdateButton
        iconWidth='20'
        iconHeight='20'
        updateFn={() =>
          showViewDispatch(serviceDetailActionCreators.openReferenceView(cellData?.data))
        }
      />
    )
  }
  if (data) {
    return (
      <div>
        <DataGrid
          id='referenceTable'
          keyExpr='name'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row')
          }}
          dataSource={data}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          hoverStateEnabled={true}
          height={'calc(60vh - 60px)'}
          allowColumnResizing={true}
          selection={{mode: 'single'}}
          onExporting={(e) => onExporting(e, 'Service References')}
        >
          <Export enabled={true} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          <Scrolling showScrollbar='always' />
          <Paging enabled={false} />

          <Summary>
            <TotalItem
              cssClass='absolute-right'
              displayFormat='Total Services: {0}'
              column='name'
              summaryType='count'
            />
          </Summary>

          <Column dataField='name' minWidth={350} cssClass='cls' />
          <Column dataField='serviceGroup.name' minWidth={250} cssClass='cls' />
          <Column dataField='description' caption='Description' minWidth={150} cssClass='cls' />
          <Column
            dataField='unitSizeRequired'
            caption='Unit Size Required'
            minWidth={150}
            cssClass='cls'
          />
          <Column dataField='isActive' caption='Active' minWidth={150} cssClass='cls' />
          <Column width={110} alignment='center' cellRender={displayEditButton} cssClass='cls' />
          <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
        </DataGrid>
        <DeleteDialog />
      </div>
    )
  }
  return <></>
}
export default ServiceReferenceTable
