import React from 'react'
import AllowedProperties from './AllowedProperties';
import BannedProperties from './BannedProperties';
import StaffPropertyDialog from './StaffPropertyDialog';

const PropertyTab: React.FC = () => {
    return (
        <div className='row'>
            <div className='col-lg-6'>
                <AllowedProperties />
            </div>
            <div className='col-lg-6'>
                <BannedProperties />
            </div>
            <StaffPropertyDialog />
        </div>
    )
}

export default PropertyTab;