import {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import VendorsRateListTable from './VendorsRateListTable'
import VendorRateListAddNewRate from './VendorRateListAddNewRate'

const VendorsRateList = () => {
  const [showAddRateListModal, setShowAddRateListModal] = useState(false)

  const handleClose = () => {
    setShowAddRateListModal(false)
  }

  const {isLoading, data, error, refetch} = useQuery(
    ['Get Vendors Rate List'],
    () => ApiCalls.getAllHostPropertyServiceRates(),
    {cacheTime: 50000}
  )

  return (
    <>
      <PageTitle>Vendors Rate List</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <VendorsRateListTable refetch={refetch} data={data?.data?.data} />
            )}
          </Card.Body>
          <Card.Footer>
            <div className='d-flex col gap-3 justify-content-end'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  setShowAddRateListModal(true)
                }}
              >
                +Add New Service Rate
              </button>
            </div>
          </Card.Footer>
        </Card>
      </div>
      {showAddRateListModal && (
        <VendorRateListAddNewRate visible={showAddRateListModal} handleClose={handleClose} />
      )}
    </>
  )
}

export default VendorsRateList
