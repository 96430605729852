import React, {Dispatch, FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IService, IServiceGroup} from '../../interfaces/ServiceType'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {ServiceDetailAction} from '../../redux/actionTypes/serviceDetailActionType'
import * as serviceDetailActionCreator from '../../redux/actionCreators/serviceDetailActionCreators'
import {TextInput} from '../../umut-components/Inputs/TextInput'
import ApiCalls from '../../network/ApiCalls'
import {Spinner} from 'react-bootstrap-v5'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import SwitchBox from '../../umut-components/Inputs/SwitchBox'
import {encryptText} from '../../utils/util'
import {useQuery} from 'react-query'
import {AxiosResponse} from 'axios'

type Props = {
  selectedService: any
  refetchReferences: () => void
  unitSizeRequired: boolean
}
const ServiceReferenceForm: FC<Props> = ({
  selectedService,
  refetchReferences,
  unitSizeRequired,
}) => {
  const {
    data: serviceGroups,
    isLoading: serviceGroupsLoading,
    error: serviceGroupsError,
  } = useQuery<AxiosResponse<{data: Array<IServiceGroup>}>>(
    ['Get Service Groups'],
    () => ApiCalls.getServiceGroups(),
    {
      refetchOnWindowFocus: false,
    }
  )
  const {
    user: {
      userSection: {userId},
    },
  } = useSelector((state: RedusxAppState) => state.user)
  const [serviceGroupList, setServiceGroupList] = useState<any>([])
  const [service, setService] = useState<IService>({
    name: selectedService?.name,
    description: selectedService?.description,
    unitSizeRequired: selectedService?.unitSizeRequired,
    serviceGroupId: selectedService?.serviceGroupId,
    isActive: selectedService?.isActive,
    id: selectedService?.id,
  } as IService)
  const [unitSize, setUnitSize] = useState<any>(unitSizeRequired ? unitSizeRequired : false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const showViewDispatch = useDispatch<Dispatch<ServiceDetailAction>>()

  useEffect(() => {
    if (!serviceGroupsLoading && serviceGroups) {
      const list: any = []
      serviceGroups?.data?.data.map((sg) => {
        list.push({label: sg.name, value: sg.id})
      })
      setServiceGroupList(list)
    }
  }, [serviceGroupsLoading, serviceGroups])

  const serviceGroupChanged = (value: any) => {
    const raw = Object.assign({}, service)
    raw.serviceGroupId = value
    setService(raw)
  }

  const handleCloseModal = () => {
    showViewDispatch(serviceDetailActionCreator.closeReferenceView())
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      selectedService !== null
        ? await ApiCalls.UpdateServiceTypes(selectedService?.id, service)
        : await ApiCalls.CreateServiceTypes(service)
      refetchReferences()
      setLoading(false)
      handleCloseModal()
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error?.response?.data?.errors?.[0]?.message)
    }
  }
  useEffect(() => {
    if (service.unitSizeRequired) {
    } else {
      setService({...service, unitSizeRequired: false})
    }
  }, [])

  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-12'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            Name {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput
            disabled={selectedService}
            onValueChange={(text: string) => setService({...service, name: text})}
            defaultValue={service?.name}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Description {<span style={{color: 'red'}}>*</span>}
          </label>
          <TextInput
            onValueChange={(text: string) => setService({...service, description: text})}
            defaultValue={service?.description}
          />
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Service Group</label>
          <SelectInput
            options={serviceGroupList}
            onValueChanged={serviceGroupChanged}
            defaultValue={selectedService?.serviceGroupId}
          />
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Unit Size {<span style={{color: 'red'}}>*</span>}
          </label>
          <SwitchBox
            text={'If it is opened, number of bedrooms will be shown.'}
            mutedText={true}
            onCheckboxChange={(check) => {
              setService({...service, unitSizeRequired: check})
            }}
            defaultChecked={
              service?.unitSizeRequired === undefined ? false : service.unitSizeRequired
            }
            disabled={service.id == 105}
          />
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>
            Is Active? {<span style={{color: 'red'}}>*</span>}
          </label>
          <SwitchBox
            text={'If it is false, hosts can not see services.'}
            mutedText={true}
            onCheckboxChange={(check) => {
              setService({...service, isActive: check})
            }}
            defaultChecked={service?.isActive === undefined ? false : service.isActive}
            disabled={service.id == 105}
          />
          {service.id != 105 && (
            <div className='col mt-8 '>
              <div className='d-flex col gap-3 justify-content-end'>
                <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                  Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-success '
                  disabled={loading || !service?.name || !service?.description}
                  onClick={handleSubmit}
                >
                  Save {loading ? <Spinner animation='border' size='sm' /> : null}
                </button>
              </div>
              <div className='col text-end'>
                <strong style={{color: 'red'}}>{errorMessage}</strong>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default ServiceReferenceForm
