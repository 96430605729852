import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
} from 'devextreme-react/data-grid'
import {Dispatch, FC, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {IOffered} from '../../../../../interfaces/Offered'
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable'
import {onExporting} from '../../../../../utils/util'
import {IVendorHostPropertyRates} from '../../../../../interfaces/Property'
import VendorServiceRateEditModal from './VendorServiceRateEditModal'

type Props = {
  data: IVendorHostPropertyRates[] | undefined
  refetch: () => void
}
const VendorServiceRateTable: FC<Props> = ({data, refetch}) => {
  const [selectedRate, setSelectedRate] = useState<IVendorHostPropertyRates | undefined>()
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)

  const calculateDuration = (cellData: any) => {
    return cellData?.duration + ' Minute'
  }

  const calculateSuite = (cellData: any) => {
    return cellData?.suite ? cellData?.suite : '-'
  }

  const tableRef = useRef<DataGrid>(null)
  const calculatePrice = (cellData: any) => {
    return '$' + cellData?.offeredRate
  }

  const editServiceRate = (rowData: any) => {
    setSelectedRate(rowData.data)
    setShowUpdateModal(true)
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Offered' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='propertyTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(54vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onRowClick={editServiceRate}
        onExporting={(e) => onExporting(e, 'Vendor Offers')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={10} />

        <Column dataField='address' caption='Property' minWidth={250} cssClass='cls' />
        <Column
          dataField='suite'
          caption='suite'
          alignment='center'
          calculateCellValue={calculateSuite}
          minWidth={120}
          cssClass='cls'
        />
        <Column dataField='client' caption='Client' width={200} cssClass='cls' />
        <Column dataField='serviceType' caption='Service Type' width={200} cssClass='cls' />
        <Column
          dataField='duration'
          caption='Duration (min.)'
          calculateCellValue={calculateDuration}
          minWidth={150}
          cssClass='cls'
        />
        <Column
          dataField='offeredRate'
          caption='Price ($)'
          calculateCellValue={calculatePrice}
          minWidth={150}
          cssClass='cls'
        />
      </DataGrid>
      {showUpdateModal && (
        <VendorServiceRateEditModal
          visible={showUpdateModal}
          data={selectedRate!}
          refetch={refetch}
          handleClose={() => {
            setShowUpdateModal(false)
            setSelectedRate(undefined)
          }}
        />
      )}
    </div>
  )
}
export default VendorServiceRateTable
