import React, { Dispatch } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import ApiCalls from '../../network/ApiCalls';
import { TextInput } from '../Inputs/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useDialog } from '../../contexts/DialogContext';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators';
import { StaffAddRequest } from '../../network/PostRequestModels';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import Select from 'react-select';
import { selectStyles } from '../../config/selectStyle';
import { useParams } from 'react-router';
import PhoneTextInput from '../Inputs/PhoneInput';
import { decryptText, getLocationByAddress, getLocationByAddressForStaff, isPhoneValid } from '../../utils/util';
import AutocompleteMap from '../Inputs/AutocompleteMap';
import { ILocation } from '../../interfaces/Property';
import { IStaffAddress } from '../../interfaces/Address';

type Props = {
}

const StaffForm: React.FC<Props> = () => {
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    let { hostId, vendorId }: any = useParams();
    hostId = hostId ? decryptText(hostId) : undefined;
    vendorId = vendorId ? decryptText(vendorId) : undefined;

    const { showSuccessDialog } = useDialog();
    const { staffAddDialog } = useSelector((state: RedusxAppState) => state.staff);


    const [staffRequest, setStaffRequest] = useState<StaffAddRequest>({} as StaffAddRequest);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<ILocation>({ address: ' ' });
    const [roles, setRoles] = useState([]);
    const [text, setText] = useState<string>('');
    const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)


    const { data: roleTypesData, isLoading: roleTypesLoading, error: roleTypesError } = useQuery('Get Roles', () => ApiCalls.getStaffRoles(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: countries } = useQuery('Get Countries', () => ApiCalls.getCountries(), {
        cacheTime: 50000,
        retry: false,
        refetchOnWindowFocus: false,
    })

    const handleSendStaff = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            if (staffRequest.phoneNumber && fullLocation) {
                const finalRequest: StaffAddRequest = { ...staffRequest, userAddressCreateDto: { ...fullLocation, userAddressTypeId: 2 }, phoneNumber: staffRequest.phoneNumber, hostId: hostId ? parseInt(hostId) : undefined, vendorId: vendorId ? parseInt(vendorId) : undefined };
                const { email, firstName, lastName, phoneNumber, roleId, userAddressCreateDto } = finalRequest;
                if (email && firstName && lastName && phoneNumber && roleId && !emailErrorMessage && userAddressCreateDto) {
                    hostId && await ApiCalls.createStaffByHostId(finalRequest);
                    vendorId && await ApiCalls.createStaffByVendorId(finalRequest);
                    staffAddDialog.refetch();
                    dispatch(staffActionCreator.closeStaffAddDialog());
                    setLoading(false);
                    showSuccessDialog('Staff Added.');
                }
                else {
                    setLoading(false)
                    setErrorMessage('All fields are required.');
                }
            }
            else {
                setLoading(false)
                setErrorMessage('Phone is not valid!');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const onEmailChange = (email: string) => {
        let pattern = /^\S+@\S+\.\S+$/;
        if (pattern.test(email)) {
            setEmailErrorMessage(null);
        } else {
            setEmailErrorMessage('Please enter valid email.');
        }
        setStaffRequest({ ...staffRequest, email });
    }
    const handleSelectAddress = (e: string) => {
        setSelectedLocation({ ...selectedLocation, address: e });
        setText(e)
    }
    const handleAutocompleteError = () => {
        setText(selectedLocation.address ?? '');
    }

    useEffect(() => {
        getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
    }, [selectedLocation])
    
    useEffect(() => {
        if (roleTypesData) {
            setRoles(roleTypesData?.data?.filter((role: any) => role.lobRoleName === (hostId ? "Host" : "Vendor")).map((role: any) => ({ label: role.roleName, value: role.roleId })));
        }
    }, [roleTypesData])


    if (roleTypesLoading) {
        return <div className='d-flex justify-content-center'><Spinner animation='border' /></div>
    }

    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                    <TextInput onValueChange={onEmailChange} defaultValue={staffRequest.email} />
                    <strong className='d-flex fw-bold break-word text-danger'>{emailErrorMessage}</strong>

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>First Name</label>
                    <TextInput onValueChange={(text: string) => setStaffRequest({ ...staffRequest, firstName: text })} defaultValue={staffRequest.firstName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Last Name</label>
                    <TextInput onValueChange={(text: string) => setStaffRequest({ ...staffRequest, lastName: text })} defaultValue={staffRequest.lastName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Address {<span style={{ color: "red" }}>*</span>}</label>
                    <AutocompleteMap text={text} setText={setText} handleSelectAddress={handleSelectAddress} handleOnError={handleAutocompleteError} />
                    {(!selectedLocation.address || fullLocation?.zipCode == "") && <><strong className='text-danger fw-6'>Please enter a valid address</strong><br /></>}
                    {fullLocation?.cityName == "" && <><strong className='text-danger fw-6'>City information not found. Please change your address!</strong><br /></>}

                    <div className='row mt-5'>
                        <div className='fv-row col-12 '>
                            <label className='form-label fs-6 fw-bolder text-dark'>Phone</label>
                            <PhoneTextInput onChange={(text: string) => setStaffRequest({ ...staffRequest, phoneNumber: text })} />
                        </div>
                    </div>


                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Select Role</label>
                    <Select styles={selectStyles} defaultValue={{ value: staffRequest.roleId }} options={roles} onChange={(value) => setStaffRequest({ ...staffRequest, roleId: value ? value.value : null })} />

                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleSendStaff}>Add Staff {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(StaffForm);