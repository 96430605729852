import React, {Dispatch, FC, useEffect} from 'react'
import {useState} from 'react'
import LocationStep from './StepTabs/LocationStep'
import AutomationStep from './StepTabs/AutomationStep'
import PropertyDetailStep from './StepTabs/PropertyDetailStep'
import RoomBedStep from './StepTabs/RoomBedStep'
import {
  ButtonToolbar,
  Card,
  Modal,
  OverlayTrigger,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {useQuery} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import PropertyOfferedStep from './StepTabs/PropertyOfferedStep'
import TurnoversStep from './StepTabs/TurnoversStep'
import {AxiosResponse} from 'axios'
import {useDialog} from '../../../../contexts/DialogContext'
import {HostPropertyStatus} from '../../../../enums/PropertyEnum'
import {IHostPropertyInput, IPropertyIsAutoEnabled} from '../../../../interfaces/Property'
import ApiCalls from '../../../../network/ApiCalls'
import {OpenDeletePropertyModal} from '../../../../redux/actionCreators/hostPropertyActionCreators'
import {HostPropertyAction} from '../../../../redux/actionTypes/hostPropertyTypes'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import {encryptText} from '../../../../utils/util'
import ChecklistStep from './StepTabs/ChecklistStep'

type Props = {
  propertyId: number
  type: 'pms' | 'manual'
  propertyType?: 'active' | 'pending'
  editModalClose?: () => void
  editDisabled?: boolean
}

type TabNames =
  | 'property-detail'
  | 'automation'
  | 'checklists'
  | 'property-offered-services'
  | 'turnovers'

const OfferPendingTabs: React.FC<Props> = ({
  propertyId,
  type,
  editModalClose,
  editDisabled,
  propertyType,
}) => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const [selectedTab, setSelectedTab] = useState<TabNames | null>('property-detail')
  const [loading, setLoading] = useState(false)
  const [showResetJobsModal, setShowResetJobsModal] = useState(false)
  const [savedValues, setSavedValues] = useState<IHostPropertyInput | null>(null)
  const [missingField, setMissingField] = useState<boolean>(false)

  const {
    data: hostPropertyData,
    error: hostPropertyError,
    isLoading: hostPropertyLoading,
    isFetching: hostPropertyFetching,
  } = useQuery(
    ['Get Host Property By Id', propertyId],
    () => ApiCalls.getHostPropertyById(propertyId ? propertyId : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: propertyId ? true : false}
  )
  const {
    data: isAutoEnabled,
    error: isAutoEnabledError,
    isLoading: isAutoEnabledLoading,
  } = useQuery<AxiosResponse<IPropertyIsAutoEnabled>>(
    ['Get is Auto Enabled', propertyId],
    () => ApiCalls.getPropertyIsAutoEnabled(propertyId ? propertyId : 0),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: propertyId ? true : false}
  )

  const handleChangeHostProperty = (changedValues: IHostPropertyInput) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  useEffect(() => {
    if (hostPropertyData) {
      setSavedValues({
        ...hostPropertyData?.data?.hostProperty,
        ...hostPropertyData?.data,
        hostProperty: undefined,
        numberOfBathrooms: parseFloat(
          hostPropertyData?.data?.numberOfBathrooms ? hostPropertyData?.data?.numberOfBathrooms : 0
        ),
      })
    }
  }, [hostPropertyData])

  useEffect(() => {
    setMissingField(
      hostPropertyData?.data?.hostProperty?.hostPropertyStatusTypeId ===
        HostPropertyStatus['Property Fields Not Set']
    )
  }, [hostPropertyData])

  if (hostPropertyLoading || hostPropertyFetching) {
    return (
      <div className='d-flex justify-content-center flex-column'>
        <Spinner className='align-self-center' animation='border' />
      </div>
    )
  }

  if (propertyId && hostPropertyError) {
    return (
      <div className='d-flex justify-content-center flex-column'>
        <strong>Property cannot found.</strong>
        <button className='btn btn-danger mt-6' onClick={editModalClose}>
          Back to Property List
        </button>
      </div>
    )
  }

  return (
    <>
      <Card>
        <Card.Body>
          {propertyId && (
            <div className='d-flex justify-content-between'>
              <div className='ms-2 pb-2 flex'>
                <Link
                  to={`/host-details/${encryptText(hostPropertyData?.data?.hostProperty?.hostId)}`}
                >
                  <span className='badge badge-light-primary py-2'>
                    {hostPropertyData?.data?.hostName}
                  </span>
                </Link>
                <span className='badge badge-info py-2 ms-3'>
                  {hostPropertyData?.data?.hostProperty?.name}
                </span>
              </div>
              <div className='d-flex'>
                <ButtonToolbar className='me-6'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-`} style={{width: 300}} className='d-flex flex-wrap'>
                        {isAutoEnabledLoading ? (
                          <Spinner animation='border' />
                        ) : (
                          <>
                            <strong className='mb-5 fs-6'>
                              Host Status:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data.hostStatus == 'Active'
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Host Property Status:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data.hostPropertyStatus == 'Active'
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Automatic Schedule:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data.isAutomaticSchedule
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Available Vendor:{' '}
                              <span>{isAutoEnabled?.data.availableVendorByZipCodeCount}</span>
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              has PMS:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data?.hostPropertyPms?.isValid
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              has ICAL:{' '}
                              {
                                <span>
                                  <i
                                    className={`bi bi-${
                                      isAutoEnabled?.data?.hostPropertyIcals?.some(
                                        (item: any) => item.isValid == true
                                      )
                                        ? 'check-lg text-success'
                                        : 'x-lg text-danger'
                                    }`}
                                  ></i>
                                </span>
                              }
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Service Offer: <span>{isAutoEnabled?.data.serviceOfferCount}</span>
                            </strong>
                            <br />
                            <strong className='mb-5 fs-6'>
                              Calender : <span>{isAutoEnabled?.data.calendarCount}</span>
                            </strong>
                            <br />
                          </>
                        )}
                      </Tooltip>
                    }
                  >
                    <span className='svg-icon svg-icon-primary svg-icon-2x d-flex align-items-center ms-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        version='1.1'
                      >
                        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                          <rect x='0' y='0' width='24' height='24' />
                          <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                          <rect fill='#000000' x='11' y='10' width='2' height='7' rx='1' />
                          <rect fill='#000000' x='11' y='7' width='2' height='2' rx='1' />
                        </g>
                      </svg>
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
                <button
                  className='btn btn-danger btn-sm me-6'
                  onClick={() => dispatch(OpenDeletePropertyModal(propertyId))}
                >
                  Delete Property
                </button>
              </div>
            </div>
          )}
          <Tabs
            defaultActiveKey='property-detail'
            id='uncontrolled-tab-example'
            className='mb-3 p-3'
            onSelect={handleChangeTab}
          >
            <Tab eventKey='property-detail' title='Property Detail'>
              <LocationStep
                missingField={true}
                editMode={true}
                location={{
                  address: hostPropertyData?.data?.hostProperty?.address,
                  city: hostPropertyData?.data?.hostProperty?.city,
                  state: hostPropertyData?.data?.hostProperty?.state,
                  country: hostPropertyData?.data?.hostProperty?.country,
                  latitude: hostPropertyData?.data?.hostProperty?.latitude,
                  longitude: hostPropertyData?.data?.hostProperty?.longitude,
                  suite: hostPropertyData?.data?.hostProperty?.suite,
                  zipCode: hostPropertyData?.data?.hostProperty?.zipCode,
                }}
                staticMapUrl={hostPropertyData?.data?.hostProperty?.staticMapUrl}
                pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                handleChangeHostProperty={handleChangeHostProperty}
              />

              <div className='p-3 m-3'>
                <RoomBedStep
                  disabled={hostPropertyData?.data?.hostProperty?.cleaningProviderId === 2}
                  editMode={true}
                  handleChangeHostProperty={handleChangeHostProperty}
                  hostPropertyBedrooms={hostPropertyData?.data?.hostPropertyBedrooms}
                  numberOfBeds={hostPropertyData?.data?.hostProperty?.numberOfBeds}
                  numberOfBedrooms={hostPropertyData?.data?.hostProperty?.numberOfBedrooms}
                  numberOfBathrooms={parseFloat(
                    hostPropertyData?.data?.hostProperty?.numberOfBathrooms
                  )}
                  pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                />
              </div>

              <PropertyDetailStep
                missingField={true}
                editMode={true}
                propertyType={propertyType}
                values={{
                  parkingDescription: hostPropertyData?.data?.hostProperty?.parkingDescription,
                  cleaningProviderId: hostPropertyData?.data?.hostProperty?.cleaningProviderId,
                  isMarketplace: hostPropertyData?.data.hostProperty.isMarketplace,
                  hasDryer: hostPropertyData?.data?.hostProperty?.hasDryer,
                  hasLaundry: hostPropertyData?.data?.hostProperty?.hasLaundry,
                  hasParking: hostPropertyData?.data?.hostProperty?.hasParking,
                  hasStorage: hostPropertyData?.data?.hostProperty?.hasStorage,
                  sameDayBooking: hostPropertyData?.data?.hostProperty?.sameDayBooking,
                  numberOfCleaner: hostPropertyData?.data?.hostProperty?.numberOfCleaner,
                  hasProQuoteRequest: hostPropertyData?.data?.hostProperty.hasProQuoteRequest,
                  hasPlusQuoteRequest: hostPropertyData?.data?.hostProperty.hasPlusQuoteRequest,
                }}
                hostPropertyAccess={hostPropertyData?.data?.hostPropertyAccess}
                hostPropertyOthers={hostPropertyData?.data?.other}
                hostPropertyStorage={hostPropertyData?.data?.hostPropertyStorages}
                handleChangeHostProperty={handleChangeHostProperty}
              />
            </Tab>
            <Tab eventKey='automation' title='Automation Settings'>
              <AutomationStep
                missingField={missingField}
                editMode={true}
                type={type}
                providerId={hostPropertyData?.data?.hostProperty?.cleaningProviderId}
                hostPropertyICals={hostPropertyData?.data?.hostPropertyICals}
                sameDayBooking={hostPropertyData?.data?.hostProperty?.sameDayBooking}
                hostPropertyAutomation={{
                  ...hostPropertyData?.data?.hostPropertyAutomation,
                  isAutomaticCalendarSync:
                    hostPropertyData?.data.hostPropertyAutomation.isAutomaticSchedule,
                }}
                pmsReferenceId={hostPropertyData?.data?.hostProperty?.pmsReferenceId}
                handleChangeHostProperty={handleChangeHostProperty}
              />
            </Tab>
            <Tab eventKey='checklists' title='Checklists'>
              {selectedTab === 'checklists' && <ChecklistStep propertyId={propertyId} />}
            </Tab>
            <Tab eventKey='property-offered-services' title='Offered Services'>
              {selectedTab === 'property-offered-services' && <PropertyOfferedStep />}
            </Tab>
            <Tab eventKey='turnovers' title='Turnovers '>
              {selectedTab === 'turnovers' && hostPropertyData && (
                <TurnoversStep
                  propertyId={propertyId}
                  timeZone={hostPropertyData?.data?.hostProperty?.timeZone}
                />
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  )
}

export default OfferPendingTabs
