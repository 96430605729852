import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import AddChecklist from '../../../_metronic/layout/components/add-property/AddChecklist'
import StaffForm from '../Forms/StaffForm'
import AddPropertyTypeSelection from '../Modals/AddPropertyTypeSelection'

type Props = {
  width?: string | number
  height?: string | number
  title?: string
  classNames?: string
  marginTop?: number | string
  type?: string
}

const EmptyTable: React.FC<Props> = ({width, height, title, type, classNames, marginTop}) => {
  const [addProperty, setAddProperty] = useState(false)
  const [addStaff, setAddStaff] = useState(false)
  const [addChecklist, setAddChecklist] = useState(false)
  const [addManager, setAddManager] = useState(false)
  const [addPayment, setAddPayment] = useState(false)

  var description =
    "You don't have any property yet. Click on the bellow button to add your first property on Turnify."
  if (type == 'staff') {
    description =
      "You don't have any staff yet. Click on the bellow button to add your first staff on Turnify."
  }
  if (type == 'checklist') {
    description =
      "You don't have any checklist yet. Click on the bellow button to add your first checklist on Turnify."
  }
  if (type == 'payment') {
    description =
      "You don't have any payment card yet. Click on the bellow button to add your first payment card on Turnify."
  }

  const showModal = (type: string) => {
    if (type == 'property') {
      setAddProperty(true)
    }
    if (type == 'staff') {
      setAddStaff(true)
    }
    if (type == 'checklist') {
      setAddChecklist(true)
    }
    if (type == 'payment') {
      setAddPayment(true)
    }
  }

  const getButtonLabel = (type: string) => {
    if (type == 'property') {
      return 'Property'
    }
    if (type == 'staff') {
      return 'Staff'
    }
    if (type == 'checklist') {
      return 'Checklist'
    }
    if (type == 'payment') {
      return 'Payment Card'
    }
    return ''
  }

  return (
    <>
      <div
        style={{marginTop: marginTop ?? 50}}
        className={
          'd-flex align-items-center justify-content-center flex-column w-100' + classNames
        }
      >
        <div style={{width: '24%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
          {type && <strong className='text-muted'>{description}</strong>}
        </div>
        {type && (
          <button
            className='btn btn-success'
            style={{height: '100%', marginTop: 16, marginBottom: 20}}
            onClick={() => showModal(type)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='bi bi-plus'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
            </svg>
            Add New {getButtonLabel(type)}
          </button>
        )}
        <img
          style={{width: width ?? '25%', height}}
          alt='no-data'
          src={toAbsoluteUrl('/media/logos/noDataTable.png')}
        />
        {!type && <strong className='text-muted'>{title}</strong>}
      </div>
      {type == 'property' && (
        <AddPropertyTypeSelection
          modalOpen={addProperty}
          handleClose={() => {
            setAddProperty(false)
          }}
        />
      )}
      {type == 'checklist' && (
        <Modal
          show={addChecklist}
          onHide={() => {
            setAddChecklist(false)
          }}
          centered
        >
          <ModalHeader closeButton>
            <Modal.Title>Add Checklist</Modal.Title>
          </ModalHeader>
          <Modal.Body>
            <AddChecklist onSuccess={() => {}} />
          </Modal.Body>
        </Modal>
      )}
      {type == 'staff' && (
        <Modal
          show={addStaff}
          onHide={() => {
            setAddStaff(false)
          }}
          centered
        >
          <ModalHeader closeButton>
            <Modal.Title>Add Staff</Modal.Title>
          </ModalHeader>
          <Modal.Body>
            <StaffForm />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default EmptyTable
