import React, { Dispatch } from 'react';
import { ISection } from '../../../interfaces/Checklist';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import ViewButton from '../../../umut-components/Buttons/ViewButton';
import { useDispatch, useSelector } from 'react-redux';
import { ChecklistAction } from '../../../redux/actionTypes/masterChecklistTypes';
import * as checklistActionCreators from '../../../redux/actionCreators/masterChecklistActionCreators';
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
import { useState } from 'react';
import SectionQuestions from './MasterQuestions/MasterSectionQuestions';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { useEffect } from 'react';
import UpdateButton from '../../../umut-components/Buttons/UpdateButton';
import ChecklistButton from '../../../umut-components/Buttons/ChecklistButton';
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes';
import { useDialog } from '../../../contexts/DialogContext';
import ApiCalls from '../../../network/ApiCalls';
import { useParams } from 'react-router';
import { decryptText } from '../../../utils/util';
import { closeDeleteDialog, openDeleteDialog } from '../../../redux/actionCreators/deleteDialogActionCreators';
import DeleteButton from '../../../umut-components/Buttons/DeleteButton';

type Props = {
    index: number,
    section: ISection
}

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 24px 0`,
    // styles we need to apply on draggables
    ...draggableStyle
});

const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        There is no checklist item for this room.
    </Tooltip>
);

const ChecklistSection: React.FC<Props> = (props) => {
    let { checklistId }: any = useParams();
    checklistId = decryptText(checklistId);
    const { section, index } = props;
    const { questionCollapse } = useSelector((state: RedusxAppState) => state.masterChecklist);
    const [questionSectionActive, setQuestionSectionActive] = useState(false);
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();
    const deleteDispatch = useDispatch<Dispatch<DeleteActions>>();
    const { showSuccessDialog, showFailureDialog } = useDialog();

    const deleteSection = async () => {
        try {
            await ApiCalls.deleteChecklistSection(section.id);
            dispatch(checklistActionCreators.getChecklistItems(parseInt(checklistId)));
            deleteDispatch(closeDeleteDialog());
            showSuccessDialog('Room deleted');
        }
        catch (err: any) {
            showFailureDialog(err?.response?.data?.message);
        }
    }

    const showDeleteDialog = async () => {
        deleteDispatch(openDeleteDialog(deleteSection, `Do you want to remove section ${section.roomIndex}?`, 'Delete Section'))
    }

    return (
        <Draggable key={section.id} draggableId={section.id.toString()} index={index}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <>
                    <div
                        ref={provided.innerRef}
                        {...(questionCollapse.collapseIndex !== section.id ? provided.draggableProps : null)}
                        {...(questionCollapse.collapseIndex !== section.id ? provided.dragHandleProps : null)}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                        className={`bg-${snapshot.isDragging ? 'warning' : 'light'} shadow d-flex justify-content-between flex-wrap align-items-center`}
                    >
                        <div className='d-flex flex-row align-items-center'>
                            {section.questions.length === 0 ?
                                <OverlayTrigger
                                    placement='top'
                                    overlay={renderTooltip}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-exclamation-lg me-3" viewBox="0 0 16 16">
                                        <path d="M6.002 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm.195-12.01a1.81 1.81 0 1 1 3.602 0l-.701 7.015a1.105 1.105 0 0 1-2.2 0l-.7-7.015z" />
                                    </svg>
                                </OverlayTrigger> : null}
                            <div className='d-flex flex-column'>
                                <strong>{section.roomIndex}</strong>
                                <small className='text-muted'>{section.checklistSectionTypeName}</small>
                            </div>
                        </div>
                        <div>
                            <ChecklistButton viewFn={() => { questionCollapse.collapseIndex === section.id ? dispatch(checklistActionCreators.closeQuestionDialog()) : dispatch(checklistActionCreators.openQuestionDialog(section)) }} />
                            <DeleteButton iconWidth='20' iconHeight='20' classNames='btn btn-link btn-sm text-danger ms-6' deleteFn={showDeleteDialog} />
                        </div>
                        <Collapse in={questionCollapse.collapseIndex === section.id}>
                            <div className='w-100'>
                                <SectionQuestions />
                            </div>
                        </Collapse>
                    </div>
                </>
            )}
        </Draggable>
    )
}

export default ChecklistSection;

//                            <UpdateButton updateFn={() => dispatch(checklistActionCreators.openSectionEditDialog(section))} />