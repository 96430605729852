import { AxiosResponse } from "axios";
import { put, call, all, fork, takeLatest, delay, takeEvery } from "redux-saga/effects";
import ApiCalls from "../../network/ApiCalls";
import { GetUserDetailsResponse, LoginNetworkResponse } from "../../network/NetworkResponses/NetworkResponses";

import * as actionCreators from "../actionCreators/userActionCreators";
import * as actionTypes from "../actionTypes/userActionTypes";

function* onLogin({ loginRequest, logoutFunc }: actionTypes.LoginAction) {
    try {
        //yield call(ApiCalls.ressetToken);
        const loginNetworkResponse: LoginNetworkResponse = yield call(ApiCalls.login, loginRequest);
        yield put(actionCreators.setLoginSuccess(loginNetworkResponse.data.accessToken));
        yield put(actionCreators.getUserDetails(loginNetworkResponse.data.accessToken, logoutFunc));
    }
    catch (error:any) {
        yield put(actionCreators.setLoginFailure(error?.response?.data?.errors?.[0]?.message ?? "Email or Password Wrong"));
    }
}

function* watchOnLogin() {
    yield takeLatest(actionTypes.LOGIN, onLogin);
}

function* onGetUserDetails({ token, logoutFunc }: actionTypes.GetUserDetailAction) {
    try {
        if (token) {
            yield call(ApiCalls.setToken, token);
            yield call(ApiCalls.setLogout, logoutFunc);
        }
        const getUserNetworkResponse: GetUserDetailsResponse = yield call(ApiCalls.getUserDetails);
        yield put(actionCreators.getUserInfoSuccess(getUserNetworkResponse.data));
    }
    catch (error) {
        yield put(actionCreators.getUserInfoFailure("Login Expired"));
    }
}

function* watchOnGetUserDetails() {
    yield takeLatest(actionTypes.GET_USER_DETAILS, onGetUserDetails);
}


export default function* userSaga() {
    yield all([fork(watchOnLogin), fork(watchOnGetUserDetails)]);
}