import React, {useEffect, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useQuery} from 'react-query'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  setDxFilterPlaceholder,
} from '../../utils/util'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import ApiCalls from '../../network/ApiCalls'
import {PageTitle} from '../../../_metronic/layout/core'
import {Card} from 'react-bootstrap-v5'

const CleaningRates = () => {
  const [list, setList] = useState<any>([])
  const {data, isLoading, error, refetch} = useQuery(
    'Get Host Properties Job Rates',
    () => ApiCalls.getPropertiesJobRates(),
    {cacheTime: 10000, refetchOnWindowFocus: true}
  )

  useEffect(() => {
    if (!error && !isLoading) {
      if (data?.data?.data.length > 0) {
        const newList = []
        let index = 1
        for (const prop of data?.data?.data) {
          newList.push({id: index, ...prop})
          index++
        }
        setList(newList)
      }
    }
  }, [isLoading, data, error])

  const tableRef = useRef<DataGrid>(null)

  const calculateDateTime = (cellValue: any) => {
    return convertUTCDateToLocalDate(cellValue?.lastJob)
  }

  const getChecklistName = (cellValue: any) => {
    if (cellValue.checklistName) {
      return cellValue.checklistName?.en
    } else {
      return '--'
    }
  }

  const toPropertyDetail = (cellData: any) => {
    if (cellData?.data?.propertyId) {
      window.open('/properties/edit/' + encryptText(cellData?.data?.propertyId))
    }
  }

  if (list.length === 0) {
    return <EmptyTable title='No Properties' />
  }

  return (
    <>
      <PageTitle>Properties Service Rate Lists</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            <div className='position-relative'>
              <div style={{zIndex: 1}} className='position-absolute top-15'>
                <span
                  className='fs-8 text-primary cursor-pointer'
                  onClick={() => tableRef.current?.instance.clearFilter()}
                >
                  Clear Filter
                </span>
              </div>
              <DataGrid
                id='propertyTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                  e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={list}
                showBorders={false}
                ref={tableRef}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(70vh)'}
                allowColumnResizing={true}
                selection={{mode: 'single'}}
                onCellClick={toPropertyDetail}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, 'Properties')}
              >
                <Export enabled />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={50} />
                <Summary>
                  <TotalItem
                    cssClass='absolute-right'
                    displayFormat='Total Properties: {0}'
                    column='propertyId'
                    summaryType='count'
                  />
                </Summary>

                <Column
                  dataField='id'
                  alignment={'left'}
                  caption='#ID'
                  minWidth={50}
                  cssClass='cls'
                />
                <Column dataField='hostName' caption='Client' minWidth={300} cssClass='cls' />
                <Column dataField='propertyName' caption='Property' minWidth={450} cssClass='cls' />
                <Column dataField='suite' caption='Suite' minWidth={70} cssClass='cls' />
                <Column dataField='bedroom' caption='Bedroom' minWidth={150} cssClass='cls' />
                <Column
                  dataField='duration'
                  caption='Duration (min.)'
                  minWidth={180}
                  alignment={'center'}
                  cssClass='cls'
                />
                <Column
                  dataField='serviceType'
                  caption='Service Type'
                  minWidth={180}
                  alignment={'center'}
                  cssClass='cls'
                />
                <Column
                  dataField='checklistName'
                  calculateCellValue={getChecklistName}
                  caption='Checklist'
                  alignment={'center'}
                  minWidth={200}
                  cssClass='cls'
                />
                <Column
                  dataField='offerRate'
                  caption='Offer Rate ($)'
                  alignment={'center'}
                  minWidth={200}
                  cssClass='cls'
                />
                <Column
                  dataField='vendorName'
                  alignment={'center'}
                  caption='Vendor'
                  minWidth={200}
                  cssClass='cls'
                />
                <Column
                  dataField='vendorRate'
                  caption='Vendor Rate ($)'
                  alignment={'center'}
                  minWidth={200}
                  cssClass='cls'
                />
                <Column
                  dataField='lastJob'
                  caption='Last Job'
                  alignment={'center'}
                  calculateCellValue={calculateDateTime}
                  minWidth={200}
                  cssClass='cls'
                />
              </DataGrid>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default React.memo(CleaningRates)
