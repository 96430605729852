import {AxiosResponse} from 'axios'
import React, {Dispatch, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {IGetAllVendors} from '../../../interfaces/Vendors'
import ApiCalls from '../../../network/ApiCalls'
import {decryptText} from '../../../utils/util'
import StaffTab from '../../staff/StaffTab'
import VendorEditDialog from '../VendorEditDialog'
import ProfileTab from './Tabs/ProfileTab'
import VendorDocuments from './Tabs/vendorDocuments/Documents'
import VendorJobs from './Tabs/vendorJobs/VendorJobs'
import VendorOffered from './Tabs/vendorOffered/VendorOffered'
import PropertyTab from './Tabs/vendorProperties/PropertyTab'
import VendorReference from './Tabs/vendorReferences/VendorReference'
import VendorServiceCities from './Tabs/vendorServiceCities/VendorServiceCities'
import VendorViolations from './Tabs/vendorViolations/VendorViolations'
import VendorServiceRates from './Tabs/vendorServiceRates/VendorServiceRates'
import VendorSubscription from './Tabs/vendorSubscription/VendorSubscription'

type TabNames =
  | 'vendor-staffs'
  | 'vendor-documents'
  | 'vendor-service-cities'
  | 'vendor-violations'
  | 'vendor-jobs'
  | 'vendor-references'
  | 'vendor-offered'
  | 'vendor-service-rate'
  | 'vendor-properties'
  | 'vendor-subscription'

const VendorDetail: React.FC = () => {
  let {vendorId}: any = useParams()
  vendorId = decryptText(vendorId)
  const [selectedTab, setSelectedTab] = useState<TabNames>('vendor-staffs')

  const {data, isLoading, error, refetch} = useQuery<AxiosResponse<IGetAllVendors>>(
    ['Get Vendor Details by Id', vendorId],
    () => ApiCalls.getVendorById(vendorId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: vendorId ? true : false}
  )

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      <PageTitle>Vendor's Detail</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Header>{data && <ProfileTab data={data.data} isLoading={isLoading} />}</Card.Header>
          <Card.Body>
            <Tabs
              defaultActiveKey='vendor-staffs'
              onSelect={handleChangeTab}
              id='uncontrolled-tab-example'
              className='mb-3'
            >
              <Tab eventKey='vendor-service-cities' title='Service Locations'>
                {selectedTab === 'vendor-service-cities' ? <VendorServiceCities /> : null}
              </Tab>
              <Tab eventKey='vendor-offered' title='Vendor Services'>
                {selectedTab === 'vendor-offered' ? <VendorOffered /> : null}
              </Tab>
              <Tab eventKey='vendor-service-rate' title='Vendor Service Rates'>
                {selectedTab === 'vendor-service-rate' ? <VendorServiceRates /> : null}
              </Tab>
              <Tab eventKey='vendor-jobs' title='Vendor Jobs'>
                {selectedTab === 'vendor-jobs' ? <VendorJobs /> : null}
              </Tab>
              <Tab eventKey='vendor-staffs' title='Staff'>
                {selectedTab === 'vendor-staffs' ? <StaffTab /> : null}
              </Tab>

              <Tab eventKey='vendor-violations' title='Violations'>
                {selectedTab === 'vendor-violations' ? <VendorViolations /> : null}
              </Tab>
              <Tab eventKey='vendor-properties' title='Properties'>
                {selectedTab === 'vendor-properties'
                  ? data && <PropertyTab data={data.data} />
                  : null}
              </Tab>
              <Tab eventKey='vendor-documents' title='Documents'>
                {selectedTab === 'vendor-documents' ? <VendorDocuments /> : null}
              </Tab>
              <Tab eventKey='vendor-references' title='References'>
                {selectedTab === 'vendor-references' ? <VendorReference /> : null}
              </Tab>
              <Tab eventKey='vendor-subscription' title='Subscription'>
                {selectedTab === 'vendor-subscription' ? <VendorSubscription /> : null}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
        <VendorEditDialog refetch={refetch} />
      </div>
    </>
  )
}

export default VendorDetail
