/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, useState } from 'react'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'

import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { Form } from 'react-bootstrap-v5'
import { useDialog } from '../../contexts/DialogContext';
import ApiCalls from '../../network/ApiCalls';
import { HostRegisterRequest } from '../../network/PostRequestModels';
import { useDispatch, useSelector } from 'react-redux';
import { HostAction } from '../../redux/actionTypes/hostTypes';
import * as adminActionCreator from '../../redux/actionCreators/adminActionCreators';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { isPhoneValid } from '../../utils/util';
import PhoneTextInput from '../Inputs/PhoneInput';
import { AdminRegisterRequest } from '../../interfaces/Admins';
import { AdminAction } from '../../redux/actionTypes/AdminTypes';
import { LobRoleNameEnum } from '../../enums/UserRoles';
import SelectBox from '../Inputs/SelectBox';
import SelectInput from '../Inputs/SelectInput';

type InputType = {
    firstName: string,
    lastName: string,
    email: string,
    roleId: number,
    phoneNumber: string

}

const schema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
        .email('Please Enter Valid Email')
        .required('Email is required'),

})

const defaultValues: InputType = {
    firstName: '',
    lastName: '',
    email: '',
    roleId: 0,
    phoneNumber: ''
}

const rolesArray = [
    { label: "Admin", value: 7 },
    { label: "Manager", value: 8 }
]

export function AdminCreateForm() {
    const { adminAddDialog } = useSelector((state: RedusxAppState) => state.admin);
    const dispatch = useDispatch<Dispatch<AdminAction>>();
    const [roleId, setRoleId] = useState<number>(7)
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string | undefined>('');
    const { showFailureDialog, showSuccessDialog } = useDialog();

    const { control, formState, handleSubmit, reset, register } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { isValid, dirtyFields, errors } = formState;

    const onRegister = async (data: InputType) => {
        try {
            setErrorText(undefined);
            setLoading(true);
            if (phoneNumber && isPhoneValid(phoneNumber)) {
                const AdminRegister: AdminRegisterRequest = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    roleId: roleId,  // 7 refers to system admin
                    phoneNumber: phoneNumber
                }
                await ApiCalls.CreateAdmin(AdminRegister);
                showSuccessDialog('Verification mail sent to ' + data.email);
                adminAddDialog.refetch();
                dispatch(adminActionCreator.closeAddAdminDialog());
                setErrorText('');
                setLoading(false);
            }
            else {
                setLoading(false);
                setErrorText('Phone is not valid!');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorText(err?.response?.data?.message);
        }
    }
    return (
        <form
            className='form w-100'
            onSubmit={handleSubmit(onRegister)}
            noValidate
            id='kt_login_signin_form'
        >


            <div className='row'>
                {/* begin::Form group */}
                <div className='fv-row col-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        autoComplete='off'
                        {...register("firstName")}
                        required
                    />
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.firstName?.message}</p>
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row col-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        autoComplete='off'
                        {...register("lastName")}
                        required
                    />
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.lastName?.message}</p>
                </div>
                {/* end::Form group */}
            </div>

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    className='form-control form-control-lg form-control-solid'
                    type='email'
                    autoComplete='off'
                    {...register("email")}
                    required
                />
                <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.email?.message}</p>
            </div>


            <div className='row mb-10'>
                {/* begin::Form group */}
                <div className='fv-row col-12 '>
                    <label className='form-label fs-6 fw-bolder text-dark'>Phone</label>
                    <PhoneTextInput onChange={setPhoneNumber} />
                </div>
            </div>
            <div className='row mb-10'>
                {/* begin::Form group */}
                <div className='fv-row col-12 '>
                    <label className='form-label fs-6 fw-bolder text-dark'>Role</label>
                    <SelectInput options={rolesArray} onValueChanged={(value: any) => setRoleId(value)} />

                </div>
            </div>

            {/* begin:: ErrorText */}
            <strong style={{ color: 'red' }}>{errorText}</strong>
            {/* end:: ErrorText */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className={`btn btn-lg btn-${errorText && !loading ? 'danger' : 'primary'} w-100 mb-5 ${errorText && !loading ? 'mt-5' : ''}`}
                    disabled={!isValid || loading}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}
export default AdminCreateForm