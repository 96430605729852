import React, {Dispatch, useEffect} from 'react'
import StepWizard from 'react-step-wizard'
import {IHostPropertyAdminInput} from '../../../interfaces/Property'
import {useState} from 'react'
import {Card, Spinner, Tab, Tabs} from 'react-bootstrap-v5'
import ApiCalls from '../../../network/ApiCalls'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import {useDialog} from '../../../contexts/DialogContext'
import {useHistory} from 'react-router-dom'
import AddWizardNav from './property-add/AddWizardNav'
import dayjs from 'dayjs'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'
import JobDialog from '../../../pages/jobs/JobDialog'
import AddPropertyDetails from './property-add/AddPropertyDetails'
import {PageTitle} from '../../../../_metronic/layout/core/PageData'
import AddPropertyProviders from './property-add/AddPropertyProviders'
import AddPropertyChecklists from './property-add/AddPropertyChecklists'

type Props = {
  propertyId?: number
  type: 'pms' | 'manual'
  editModalClose?: () => void
}

type TabNames = 'property-detail' | 'service-provider' | 'staffs-and-roles' | 'checklists'

const PropertyAddManuallyWizard = () => {
  const history = useHistory()
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {showSuccessDialog, showFailureDialog, openLoadingModal} = useDialog()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState<TabNames>('property-detail')
  const [savedValues, setSavedValues] = useState<IHostPropertyAdminInput | null>(null)
  const [missingField, setMissingField] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(1)
  const [keyId, setKeyId] = useState(1)

  const handleChangeHostProperty = (changedValues: IHostPropertyAdminInput) => {
    setSavedValues({...savedValues, ...changedValues})
  }

  useEffect(() => {
    if (activeStep == 3) {
      handleCreateProperty()
    }
  }, [savedValues])

  function isPropertyValid() {
    if (
      !savedValues?.address ||
      !savedValues?.zipCode ||
      !savedValues?.latitude ||
      !savedValues?.longitude ||
      !savedValues?.country ||
      !savedValues?.state ||
      !savedValues?.city
    ) {
      showFailureDialog('Please fill location informations!')
      return false
    }

    if (savedValues.sameDayBooking) {
      const checkinDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[0])
            : 15
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkInTime
            ? parseInt(savedValues.hostPropertyAutomation.checkInTime.split(':')[1])
            : 0
        )
      const checkoutDate = dayjs()
        .set(
          'hours',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[0])
            : 11
        )
        .set(
          'minutes',
          savedValues?.hostPropertyAutomation?.checkOutTime
            ? parseInt(savedValues.hostPropertyAutomation.checkOutTime.split(':')[1])
            : 0
        )
      if (checkinDate.diff(checkoutDate, 'minutes') <= 0) {
        showFailureDialog(
          'Since same day booking is available, Check-in Time cannot be earlier then Check-out Time.'
        )
        return false
      }
    }

    if (
      savedValues?.cleaningProviderId === 2 &&
      (!savedValues?.hostPropertyAccess?.unitAccessTypeId ||
        !savedValues?.hostPropertyAccess?.unitAccessValue ||
        !savedValues?.hostPropertyAccess?.buildingAccessTypeId ||
        !savedValues?.hostPropertyAccess?.buildingAccessValue)
    ) {
      showFailureDialog('Please fill access informations!')
      return false
    }

    if (!savedValues?.hostPropertyChecklist) {
      showFailureDialog('Please select a checklist!')
      return false
    }

    if (savedValues.hostPropertyICals && savedValues.hostPropertyICals!.length > 0) {
      var icalList = savedValues.hostPropertyICals
      var emptyList = []
      icalList?.forEach((il: {}, k: any) => {
        if (Object.keys(il).length === 0) {
          emptyList.push(k)
        }
      })

      if (emptyList.length == icalList?.length) {
        delete savedValues.hostPropertyICals
      }
    }

    return true
  }

  const handleCreateProperty = async () => {
    openLoadingModal('Please wait..')
    setTimeout(() => {
      setLoading(true)
      if (isPropertyValid()) {
        setMissingField(false)
        let hostId = savedValues?.hostId
        let providerId = savedValues?.cleaningProviderId
        let name =
          (savedValues?.address ? savedValues?.address : '') +
          (savedValues?.suite ? '#' + savedValues?.suite : '')
        var requestData: IHostPropertyAdminInput = {
          ...savedValues,
          name,
          cleaningProviderId: providerId,
          hostId,
        }

        ApiCalls.createHostProperty(requestData)
          .then((res) => {
            if (res.data.success) {
              setLoading(false)
              showSuccessDialog('Property is successfully added.')
              history.push('/properties/active')
            } else {
              setLoading(false)
              showFailureDialog(res.data.message)
            }
          })
          .catch((err) => {
            setLoading(false)
            showFailureDialog(
              err?.response?.data?.errors?.[0]?.message || err?.response?.data?.message
            )
          })
      } else {
        setMissingField(true)
      }
    }, 1200)
  }

  const setCurrentStep = (d: any) => {
    setActiveStep(d.activeStep)
    setKeyId((keyId + 1) * d.activeStep)
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      <PageTitle>Add a Property</PageTitle>
      <Card id={'cardbox-' + keyId}>
        <Card.Body>
          <StepWizard
            onStepChange={setCurrentStep}
            nav={
              <AddWizardNav stepNames={['Property Details', 'Service Providers', 'Checklists']} />
            }
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
            }}
          >
            <AddPropertyDetails
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              currentStep={selectedTab}
            />
            <AddPropertyProviders
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              currentStep={selectedTab}
            />
            <AddPropertyChecklists
              missingField={missingField}
              handleChangeHostProperty={handleChangeHostProperty}
              goToStep={handleChangeTab}
              currentStep={selectedTab}
              handleComplete={handleCreateProperty}
              property={savedValues}
            />
          </StepWizard>
        </Card.Body>
      </Card>
    </>
  )
}

export default PropertyAddManuallyWizard
