import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useRef } from 'react';
import { Card } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { JobStatusType } from '../../enums/JobEnum';
import ApiCalls from '../../network/ApiCalls';
import EmptyTable from '../../umut-components/Icons/EmptyTable';
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading';
import { convertUTCDateToLocalDate, encryptText, getDateFilter, onExporting, setDxFilterPlaceholder, toConvertedDate } from '../../utils/util';
import config from '../../config/config.json';


const HasVirtualInspectionTable = () => {
    const history = useHistory();
    const tableRef = useRef<DataGrid>(null)
    const { data, isLoading, error } = useQuery(['Get Has Virtual Inspection Jobs'], () => ApiCalls.getHasVirtualInspectionsJobs(), { cacheTime: 50000 })


    const toVirtualInspection = (cellData: any) => {
        if (cellData?.data?.id) {
            //history.push('/virtual-inspection/' + encryptText(cellData?.data?.id))
            window.open('/virtual-inspection/' + encryptText(cellData?.data?.id))
        }
    }
    const isManuallyCreatedJob = (cellValue: any) => {
        return cellValue?.data.isManuallyCreated ? <p>{cellValue.data.hostPropertyAddress}<span style={{ color: "#ff5d5d" }}> ⓜ</span></p> : cellValue.data.hostPropertyAddress
    }

    const displayStatusType = (cellData: any) => {
        const jobStatusType = cellData?.data?.jobStatusTypeName;
        return (<strong className={`text-${jobStatusType === JobStatusType.COMPLETED ? "success" : jobStatusType === JobStatusType.PENDING ? "warning" : "danger"}`}>{jobStatusType === "Started" ? "In Progress" : jobStatusType}</strong>)
    }

    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone);
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone);
    }

    if (isLoading) {
        return (
            <SkeletonTableLoading />
        )
    }

    if (data?.data?.data.length === 0) {
        return <EmptyTable title='No Virtual Inspections to be made at this time' />
    }

    return (
        <>
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="jobTable"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data?.data?.data}
                showBorders={false}
                showColumnLines={false}
                ref={tableRef}
                showRowLines={true}
                
                hoverStateEnabled={true}
                height={'calc(60vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onEditorPreparing={setDxFilterPlaceholder}
                onCellClick={toVirtualInspection}
                onExporting={(e) => onExporting(e, "Jobs")}
            >
                <Export enabled />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Inspections: {0}" column='hostPropertyAddress' summaryType='count' />
                </Summary>

                <Column dataField="hostPropertyAddress" cellRender={isManuallyCreatedJob} minWidth={450} caption='Property' cssClass='cls' />
                <Column dataField="jobStatusTypeName" cellRender={displayStatusType} caption='Status' minWidth={150} cssClass='cls' alignment='center' />
                <Column dataField="hostName" caption='Client Name' minWidth={200} cssClass='cls' />
                <Column dataField="startedOn" caption='Started At' dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledStart} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('startedOn')} />
                </Column>
                <Column dataField="finishedOn" caption='Finished At' dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateScheduledFinish} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('finishedOn')} />
                </Column>
                <Column dataField="hostPropertyCityName" caption="City" minWidth={150} cssClass='cls' />
                <Column dataField="hostPropertyCountryName" caption="Country" minWidth={200} cssClass='cls' />
                <Column dataField="hostPropertyStateName" caption="State" minWidth={200} cssClass='cls' />
            </DataGrid>
        </>
    )
}

export default HasVirtualInspectionTable
