import React, { Dispatch } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import ApiCalls from '../../network/ApiCalls';
import { UserAction } from '../../redux/actionTypes/userActionTypes';
import TurnifyLoading from '../../umut-components/Loading/TurnifyLoading';
import * as ActionCreators from '../../redux/actionCreators/userActionCreators';
import { decryptText } from '../../utils/util';

type ParamObj = {
    token: string
}

const AccountConfirm: React.FC = () => {
    const dispatch = useDispatch<Dispatch<UserAction>>();
    const { token }: ParamObj = useParams();
    const history = useHistory();
    const { isLoading, error } = useQuery('Confirm Account', () => ApiCalls.verifyUser(token), { retry: false, refetchOnWindowFocus: false });

    if (isLoading) {
        return <TurnifyLoading />
    }

    if (error) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh', fontSize: 40, fontWeight: 'bold' }}>
                <img alt="error" style={{ width: 150, height: 150 }} src={toAbsoluteUrl('/media/icons/danger.png')} />
                Error
                <button className="btn btn-danger" onClick={() => {
                    dispatch(ActionCreators.logout());
                    history.push('/auth/login');
                }}>Back to Login Page</button>
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh', fontSize: 40, fontWeight: 'bold' }}>
            <img alt="error" style={{ width: 150, height: 150 }} src={toAbsoluteUrl('/media/icons/success.png')} />
            Confirm Success
            <button className="btn btn-success" onClick={() => {
                dispatch(ActionCreators.logout());
                history.push('/auth/login');
            }}>Back to Login Page</button>
        </div>
    )
}

export default AccountConfirm;