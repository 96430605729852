import * as dayjs from 'dayjs'
import React, {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router'
import {useDialog} from '../../contexts/DialogContext'
import {IBillingDetail} from '../../interfaces/Billing'
import ApiCalls from '../../network/ApiCalls'

type BillProps = {
  isVisible: boolean
  handleClose: (type: string) => void
  bill?: IBillingDetail
  billId?: number
}

const BillingRefundModal: React.FC<BillProps> = ({bill, billId, handleClose, isVisible}) => {
  const history = useHistory()
  const {showFailureDialog, showSuccessDialog} = useDialog()
  const [amount, setAmount] = useState<number | undefined>(0)
  const [error, setError] = useState<string>('')

  const refundAmount = () => {
    if (billId != undefined && bill != undefined) {
      setError('')
      if (amount == 0 || amount == undefined) {
        setError('Please enter the amount you want to refund.')
      } else if (amount > bill.totalNetAmount) {
        setError('You cannot refund more than the invoice amount!')
      } else {
        ApiCalls.refundBilling(billId, {refundAmount: amount})
          .then((d) => {
            showSuccessDialog(
              'A refund of $' + amount + ' has been made for invoice number ' + bill.invoiceNumber
            )
            setTimeout(() => {
              handleClose('success')
            }, 500)
          })
          .catch(() => {
            showFailureDialog('An error occur during refunding!')
          })
      }
    } else {
      showFailureDialog('Unable to access billing information. Please try again later.')
    }
  }

  const changeAmount = (e: any) => {
    if (!isNaN(+e.target.value)) {
      setAmount(+e.target.value)
    }
  }

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        handleClose('close')
      }}
      size='lg'
      centered
    >
      <Modal.Header>
        <h6>Refund / {bill?.invoiceNumber}</h6>
      </Modal.Header>
      <Modal.Body>
        <div className='row mb-5'>
          <div className='col-12'>
            <strong className='text-dark' style={{fontSize: 14}}>
              You can make refund for property job located at {bill?.hostPropertyName}
            </strong>
          </div>
        </div>
        <div className='row align-items-end'>
          <div className='col-10'>
            <label className='form-label'>Refund Amount ($)</label>
            <input
              type='text'
              className='form-control'
              value={amount}
              onChange={(e) => {
                changeAmount(e)
              }}
            />
          </div>
          <div className='col-2'>
            <button
              className='btn btn-dark'
              onClick={() => {
                setAmount(bill?.totalNetAmount)
              }}
            >
              Bill Total
            </button>
          </div>
        </div>
        {error != '' && (
          <div className='row'>
            <div className='col-12'>
              <small className='text-danger'>
                <strong>{error}</strong>
              </small>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        className='d-flex justify-content-end py-3 '
        style={{backgroundColor: '#000033'}}
      >
        <button
          type='button'
          className='btn btn-link text-muted me-6'
          onClick={() => {
            handleClose('close')
          }}
        >
          Close
        </button>
        <button type='button' className='btn btn-danger' onClick={refundAmount}>
          Refund
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default BillingRefundModal
