import React from 'react'
import {useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {IVendorScoreReview} from '../../interfaces/Vendors'
import StarRatingView from '../Rating/StarRatingView'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  title?: string
  data?: IVendorScoreReview[]
}

const ReviewListModal: React.FC<Props> = ({data, handleClose, modalOpen, title}) => {
  return (
    <Modal show={modalOpen} onHide={handleClose} centered size='lg'>
      <ModalHeader closeButton>
        <Modal.Title>
          {title} Reviews ({data?.length})
        </Modal.Title>
      </ModalHeader>
      <Modal.Body style={{height: '50vh', overflowY: 'scroll'}}>
        {data &&
          data.length > 0 &&
          data.map((d: IVendorScoreReview, index) => {
            return (
              <>
                <div
                  className={
                    'row ' + (index + 1 < data.length ? 'border-bottom border-bottom-2' : '')
                  }
                >
                  <div className='col-12'>
                    <ul className='review-detail-row'>
                      <li className='score'>
                        <strong>Score : </strong> <StarRatingView rate={d.score} /> ({d.score})
                      </li>
                      {d.note && <li>{d.note}</li>}
                    </ul>
                  </div>
                </div>
              </>
            )
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReviewListModal
