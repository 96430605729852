import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch, useState} from 'react'
import {FC} from 'react'
import DatePicker from '../../../../../umut-components/Inputs/DatePicker'
import dayjs from 'dayjs'
import {useDialog} from '../../../../../contexts/DialogContext'
import ApiCalls from '../../../../../network/ApiCalls'

type Props = {
  visible: boolean
  handleClose: () => void
  document: any
  refetch: () => void
}
const ApproveModal: FC<Props> = ({document, visible, handleClose, refetch}) => {
  const [expDate, setExpDate] = useState<any>()
  const [errMsg, setErrMsg] = useState('')
  const {showSuccessDialog, showFailureDialog} = useDialog()

  const changeDate = (date: any) => {
    setExpDate(dayjs(date).format('YYYY-MM-DD'))
  }

  const handleApprove = async () => {
    setErrMsg('')
    if (expDate == undefined || expDate < dayjs().utc().add(1, 'days').format('YYYY-MM-DD')) {
      setErrMsg('Please select a valid expiration date')
    } else {
      try {
        const data = {
          documentId: document.id,
          expirationDate: expDate,
          vendorId: document.vendorId,
        }
        await ApiCalls.approveVendorDocument(data)
        showSuccessDialog('Vendor insurance successfully approved')
        refetch()
        handleClose()
      } catch (error) {
        showFailureDialog('An error occur during approving insurance')
      }
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered size='lg'>
      <ModalHeader closeButton>
        <Modal.Title>Approve Insurance Document</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <label className='form-label fs-6 fw-bolder text-dark mt-6'>Expiration Date</label>
            <DatePicker
              onChange={changeDate}
              min={dayjs().utc().add(1, 'days').format('YYYY-MM-DD')}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        {errMsg != '' && <span className='text-danger me-3'>{errMsg}</span>}
        <button className='btn btn-link text-warning me-3' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleApprove}>
          Approve
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ApproveModal
