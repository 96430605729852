import { Column, DataGrid, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { IForceFinish } from '../../../interfaces/DashboardContent';
import { ForceFinishActions } from '../../../redux/actionTypes/finishRequestTypes';
import * as finishActionCreators from '../../../redux/actionCreators/finishRequestActionCreators';
import { onExporting } from '../../../utils/util';
import ApproveButton from '../../../umut-components/Buttons/ApproveButton';
import { useDialog } from '../../../contexts/DialogContext';
import ApiCalls from '../../../network/ApiCalls';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';
import { useHistory } from 'react-router';
import DeclineCheckDialog from './DeclineCheckDialog';
import JobForceModal from './JobForceModal';


type Props = {
    data: IForceFinish[],
    refetch: () => void
}
const JobForceTable: FC<Props> = ({ data, refetch }) => {
    const { showSuccessDialog } = useDialog()
    const dispatch = useDispatch<Dispatch<ForceFinishActions>>()
    const [showDecline, setShowDecline] = useState(false)

    const [loading, setloading] = useState({ columnId: null, boolean: false })
    const tableRef = useRef<DataGrid>(null)
    const history = useHistory();
    const [declineData, setDeclineData] = useState<any>({})


    const openRequestDetail = (cellValue: any) => {
        if (cellValue?.column?.name !== "accept" && cellValue?.column?.name !== "reject" && cellValue?.data?.id) {
            history.push(cellValue && dispatch(finishActionCreators.openForceFinishView(cellValue?.data))
            )
        }
    }
    const closeModal = () => {
        setShowDecline(false)
        refetch()
    }

    const renderApproveButton = (cellValue: any) => {

        return (
            <ApproveButton disabled={loading.boolean || loading.columnId == cellValue?.data?.id || cellValue?.data?.jobForceFinishStatusName !== "Pending"} spinner={loading.boolean && loading.columnId == cellValue?.data?.id} iconWidth="15" iconHeight="15" approveFn={async () => {
                try {
                    setloading({ columnId: cellValue?.data?.id, boolean: true })
                    await ApiCalls.postApproveForce(cellValue?.data?.id)
                    refetch()
                    showSuccessDialog('Request successfully accepted.')
                    setloading({ columnId: cellValue?.data?.id, boolean: false })
                } catch (error: any) {
                    setloading({ columnId: cellValue?.data?.id, boolean: false })
                    console.log(error?.response?.data?.message)
                }
            }} />
        )
    }
    const renderDeclineButton = (cellData: any) => {
        return (
            <button className="btn btn-sm btn-danger" onClick={() => { setDeclineData({ data: cellData?.data, statusId: 5 }); setShowDecline(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
            </button>
        )
    }
    if (data.length === 0) {
        return <EmptyTable title='No Job Finish Requests' />
    }
    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="forceFinish"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                ref={tableRef}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(60vh - 60px)'}
                className='mt-6'
                onCellClick={openRequestDetail}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Force Jobs")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Requests: {0}" column='hostPropertyAddress' summaryType='count' />
                </Summary>

                <Column dataField="hostPropertyAddress" minWidth={450} caption='Property' dataType='string' cssClass='cls' />
                <Column dataField="vendorName" caption="Vendor" minWidth={200} cssClass='cls' />
                <Column dataField="hostName" caption='Client Name' dataType='string' minWidth={150} cssClass='cls' />
                <Column dataField="vendorName" caption='Vendor' dataType='string' alignment="center" width={150} cssClass='cls' />
                <Column dataField="hasTechnicalDifficulties" caption='Technical Difficulties' dataType='boolean' alignment="center" width={250} cssClass='cls' />

                {/* <Column dataField="medias.length" caption='media' dataType='number' minWidth={280} cssClass='cls' /> */}
                <Column dataField="jobForceFinishStatusName" caption='Status' dataType='string' minWidth={130} cssClass='cls' />
                <Column name="accept" cellRender={renderApproveButton} width={130} caption="accept" cssClass='cls' />
                <Column name="reject" cellRender={renderDeclineButton} width={130} caption="reject" cssClass='cls' />
            </DataGrid>
            <JobForceModal />
            <DeclineCheckDialog show={showDecline} handleClose={closeModal} data={declineData} refetch={refetch} />

        </div >
    )
}

export default JobForceTable
