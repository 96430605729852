import React, { Dispatch } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { IUserDetail } from '../../interfaces/User';
import { UserAction } from '../../redux/actionTypes/userActionTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import UserEditForm from '../../umut-components/Forms/UserEditForm';

type Props = {
    modalOpen: boolean,
    handleClose: () => void
}

const ProfileEditDialog: React.FC<Props> = ({ modalOpen, handleClose }) => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const { id, firstName, lastName, phoneNumber, profilePhotoPath } = user.user;

    const handleSuccess = () => {
        handleClose();
        location.reload();
    }

    return (
        <Modal show={modalOpen} onHide={handleClose} size='xl' centered>
            <ModalHeader closeButton>
                <Modal.Title>Staff Edit</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <UserEditForm user={{ id, firstName, lastName, phoneNumber, profilePhotoPath } as IUserDetail} userId={user.user.id} successFunc={handleSuccess} />
            </Modal.Body>
        </Modal>
    )
}

export default ProfileEditDialog;