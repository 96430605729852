import {combineReducers} from 'redux'
import userReducer from './userReducer'
import hostPropertyReducer from './hostPropertyReducer'
import localforage from 'localforage'
import {persistReducer} from 'redux-persist'
import turnoverReducer from './turnoverReducer'
import checklistReducer from './checklistReducer'
import staffReducer from './staffReducer'
import addressReducer from './addressReducer'
import deleteDialogReducer from './deleteDialogReducer'
import jobReducer from './jobReducer'
import hostReducer from './hostReducer'
import vendorReducer from './vendorReducer'
import violationReducer from './violationReducer'
import forceFinishReducer from './finishRequestReducer'
import serviceDetailReducer from './serviceDetailReducer'
import serviceGroupDetailReducer from './serviceGroupDetailReducer'
import masterChecklistReducer from './masterChecklistReducer'
import adminReducer from './adminReducer'
import assignReducer from './assignReducer'

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: [],
  blacklist: [
    'user',
    'turnover',
    'checklist',
    'masterChecklist',
    'staff',
    'address',
    'deleteDialog',
    'job',
    'host',
    'hostProperty',
  ],
}

const userPersistConfig = {
  key: 'user',
  storage: localforage,
  whitelist: ['token', 'isAuthenticated', 'user'],
  blacklist: ['loading', 'errorText', 'loginError'],
}

const rootReducer = combineReducers({
  user: persistReducer<any>(userPersistConfig, userReducer),
  hostProperty: hostPropertyReducer,
  turnover: turnoverReducer,
  checklist: checklistReducer,
  masterChecklist: masterChecklistReducer,
  staff: staffReducer,
  serviceDetail: serviceDetailReducer,
  serviceGroupDetail: serviceGroupDetailReducer,
  address: addressReducer,
  forceFinish: forceFinishReducer,
  job: jobReducer,
  vendor: vendorReducer,
  violation: violationReducer,
  deleteDialog: deleteDialogReducer,
  host: hostReducer,
  admin: adminReducer,
  assign: assignReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RedusxAppState = ReturnType<typeof persistedReducer>

export default persistedReducer
