import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useDialog } from '../../contexts/DialogContext'
import ApiCalls from '../../network/ApiCalls'
import { VendorRequestForm } from '../../network/PostRequestModels/VendorRequestForm'
import { isPhoneValid, setErrorObject } from '../../utils/util'
import PhoneTextInput from '../Inputs/PhoneInput'
import SelectInput from '../Inputs/SelectInput'

type InputType = {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordAgain: string
  companyName: string
  name: string
  location: string
  website?: string
  handleUnit: number
  vendorTypeId: number
  dispatchStrategyTypeId: number
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Please Enter Valid Email').required('Email is required'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      'Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter and one number'
    )
    .required('Password is required'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password'), null], 'Password must be matched'),
  companyName: Yup.string().nullable(),
  name: Yup.string().required('Vendor Name is required'),
  website: Yup.string().nullable().notRequired().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    { message: 'Enter correct website!', excludeEmptyString: true }
  ),
  handleUnit: Yup.number().required(),
  vendorTypeId: Yup.number().required(),
  dispatchStrategyTypeId: Yup.number().required(),
})

const defaultValues: InputType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordAgain: '',
  companyName: '',
  name: '',
  location: '',
  website: undefined,
  handleUnit: 0,
  vendorTypeId: 0,
  dispatchStrategyTypeId: 0,
}

const vendorTypes = [
  {
    label: "Cleaning Company",
    value: 1
  },
  {
    label: "Individual",
    value: 2
  }
]

export const VendorCreateForm = () => {
  const history = useHistory()
  const [selectedVendorType, setSelectedVendorType] = useState<number>(1);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState<string | null>(null);
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | undefined>('')
  const { showFailureDialog, showSuccessDialog } = useDialog()
  const [location, setLocation] = useState<any>(null);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const { data: countries } = useQuery('Get Countries', () => ApiCalls.getCountries(), {
    retry: false,
    refetchOnWindowFocus: false,
  })
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('')
  const ref: any = useRef<any>()


  const { control, formState, handleSubmit, reset, register } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  })

  const { isValid, dirtyFields, errors } = formState

  const onRegister = async (data: InputType) => {

    try {
      if (!location?.label) return setErrorText('Location is required.');

      setErrorText(undefined)
      setLoading(true)

      const locationPlaces = location.label.split(/, /)
      locationPlaces.reverse()
      if (locationPlaces[0] == 'USA') {
        locationPlaces[0] = 'United States'
      }
      if (locationPlaces[1] == 'DC') {
        locationPlaces[1] = 'Washington'
      }
      locationPlaces[2] ?? setErrorText('City state country information is required.')

      if (phoneNumber && companyPhoneNumber && locationPlaces[2]) {
        const registerRequest: VendorRequestForm = {
          vendorUserInfo: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            phoneNumber: phoneNumber,
          },
          vendorInfo: {
            name: data.name,
            countryName: locationPlaces[0],
            stateName: locationPlaces[1],
            cityName: locationPlaces[2],
            website: data.website ? data.website : undefined,
            phoneNumber: companyPhoneNumber,
            handleUnit: 1,
            vendorTypeId: selectedVendorType,
            dispatchStrategyTypeId: 2,
          },
        }
        await ApiCalls.createVendor(registerRequest)
        showSuccessDialog('Register confirmation is sent to ' + data.email)
        setErrorText('')
        setLoading(false)
        history.push('/auth/login')
      }
      else {
        setLoading(false)
      }
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
      setLoading(false)
      setErrorText(err?.response?.data?.message)
      setErrorObject(err, setValidationErrors);
    }
  }

  const onCountryCodeChange = (e: any) => {
    setSelectedCountryCode(e.target.value)
  }


  useEffect(() => {
    if (countries) {
      setSelectedCountryCode('(+1)')
    }
  }, [countries])

  return (
    <form
      className='form w-100 '
      onSubmit={handleSubmit(onRegister)}
      noValidate
      id='kt_login_signup_form'
    >
      <div className='d-flex flex-colunm gap-5'>
        <div className='col-6'>
          <div className='d-flex align-items-center mb-10'>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            <span className='fw-bold text-gray-400 fs-7 mx-2'>Contact</span>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          </div>

          <div className='row'>
            {/* begin::Form group */}
            <div className='fv-row col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                autoComplete='off'
                {...register('firstName')}
                required
              />
              <p style={{ color: 'red', fontWeight: 'bold' }}>{errors.firstName?.message}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row col-6'>
              <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                autoComplete='off'
                {...register('lastName')}
                required
              />
              <p style={{ color: 'red' }}>{errors.lastName?.message}</p>
            </div>
            {/* end::Form group */}
          </div>

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              className='form-control form-control-lg form-control-solid'
              type='email'
              autoComplete='off'
              {...register('email')}
              required
            />
            <p style={{ color: 'red' }}>{errors.email?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                {/* end::Label */}
              </div>
            </div>
            <input
              {...register('password')}
              type='password'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
              required
            />
            <p style={{ color: 'red' }}>{errors.password?.message}</p>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password Again</label>
                {/* end::Label */}
              </div>
            </div>
            <input
              {...register('passwordAgain')}
              type='password'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
              required
            />
            <p style={{ color: 'red' }}>{errors.passwordAgain?.message}</p>
          </div>
          {/* end::Form group */}

          <div className='row mb-10'>
            {/* begin::Form group */}
            <div className='fv-row col-12 '>
              <label className='form-label fs-6 fw-bolder text-dark'>Phone</label>
              <PhoneTextInput onChange={setPhoneNumber} />
            </div>
            {/* end::Form group */}
          </div>
        </div>
        <div className='col-6'>
          <div className='d-flex align-items-center mb-10'>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            <span className='fw-bold text-gray-400 fs-7 mx-2'>Vendor</span>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          </div>
          <div>

            {/* begin::Form group */}
            <div className='fv-row'>
              <label className='form-label fs-6 fw-bolder text-dark'>Vendor Type</label>
              <SelectInput options={vendorTypes} defaultValue={1} onValueChanged={setSelectedVendorType} />
              <p style={{ color: 'red' }}>{errors.name?.message}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row'>
              <label className='form-label fs-6 fw-bolder text-dark mt-3'>Vendor Name</label>
              <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                autoComplete='off'
                {...register('name')}
                required
              />
              <p style={{ color: 'red' }}>{errors.name?.message}</p>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mt-5 mb-0'>Location</label>
                  {/* end::Label */}
                </div>
              </div>
              <GooglePlacesAutocomplete
                {...register('location')}
                ref={ref}
                selectProps={{
                  location,
                  onChange: setLocation,
                  placeholder: "Please Enter Location"
                }}
                apiOptions={{ language: 'en' }}
                apiKey='AIzaSyBA9K-sDSC-_yqnJRfCGiH4b3GFTWOUt84'
              />
              {validationErrors["cityName"] || validationErrors["stateName"] && <p className='text-danger'>Location is not valid</p>}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0 mt-3'>Web Site</label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                {...register('website')}
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
                required
              />
              {validationErrors["website"] && <p style={{ color: 'red' }}>{validationErrors["website"]}</p>}
            </div>
            {/* end::Form group */}

            <div className='row mb-10'>
              {/* begin::Form group */}
              <div className='fv-row col-12'>
                <label className='form-label fs-6 fw-bolder text-dark'>Company Phone</label>
                <PhoneTextInput onChange={setCompanyPhoneNumber} />
              </div>
              {/* end::Form group */}
            </div>
          </div>
        </div>
      </div>

      {/* begin:: ErrorText */}
      <strong style={{ color: 'red' }}>{errorText}</strong>
      {/* end:: ErrorText */}

      {/* begin::Action */}
      <div className='d-flex justify-content-end'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className={`btn btn-success btn-lg mb-5`}
          disabled={!isValid || loading}
          onClick={() => {
            onRegister
          }}
        >
          {!loading && <span className='indicator-label'>Add Vendor</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* end::Action */}
    </form>
  )
}