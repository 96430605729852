import { IAdmins } from "../../interfaces/Admins";

export const OPEN_ADD_ADMIN_DIALOG = "OPEN_ADD_ADMIN_DIALOG";
export interface OpenAddAdminDialog {
    type: typeof OPEN_ADD_ADMIN_DIALOG,
    refetch: () => void
}
export const CLOSE_ADD_ADMIN_DIALOG = "CLOSE_ADD_ADMIN_DIALOG";
export interface CloseAddAdminDialog {
    type: typeof CLOSE_ADD_ADMIN_DIALOG,
}
export const OPEN_ADMIN_EDIT_DIALOG = "OPEN_ADMIN_EDIT_DIALOG"
export interface OpenEditAdminDialog {
    type: typeof OPEN_ADMIN_EDIT_DIALOG,
    admin: IAdmins,
    refetch: () => void
}
export const CLOSE_ADMIN_EDIT_DIALOG = "CLOSE_ADMIN_EDIT_DIALOG"
export interface CloseEditAdminDialog {
    type: typeof CLOSE_ADMIN_EDIT_DIALOG
}

export type AdminAction =
    | OpenAddAdminDialog
    | CloseAddAdminDialog
    | OpenEditAdminDialog
    | CloseEditAdminDialog