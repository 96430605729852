import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'
import './globals.css';
import 'devextreme/dist/css/dx.light.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { WindowProvider } from './contexts/WindowContext'
import { ConfirmProvider } from './contexts/DialogContext'
import AuthRemind from './pages/auth/AuthRemind'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './redux/store/configureStore'
import { QueryClient, QueryClientProvider, } from 'react-query'

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <WindowProvider>
        <ConfirmProvider>
          <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter>
              <I18nProvider>
                <LayoutProvider>
                  <Provider store={configureStore.store}>
                    <PersistGate persistor={configureStore.persistor} >
                      <AuthRemind>
                        <Routes />
                      </AuthRemind>
                    </PersistGate>
                  </Provider>
                </LayoutProvider>
              </I18nProvider>
            </BrowserRouter>
          </Suspense>
        </ConfirmProvider>
      </WindowProvider>
    </QueryClientProvider>
  )
}

export { App }
