import React, { useState } from 'react'
import { Marker, GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { Spinner } from 'react-bootstrap-v5'



function MapZip({ setZipCodes, zipCodes, setMarks, marks, deleteLocation, pastLocations, center }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBA9K-sDSC-_yqnJRfCGiH4b3GFTWOUt84",
        language: "EN"
    })
    const [loading, setLoading] = useState(false)
    const [autocomplete, setautocomplete] = useState(null)
    const [lat, setLat] = React.useState(center?.lat ? center?.lat : 39.952583);
    const [lng, setLng] = React.useState(center?.lng ? center?.lng : -75.165222);

    const findLongName = (res, typeName) => {
        const resultList = res.results[0].address_components;
        var longName = "";
        resultList.forEach(data => {
            if (data.types.includes(typeName)) {
                longName = data.long_name
            }
        })
        return longName;
    }

    const onLoad = (autocomplete) => {
        setautocomplete(autocomplete)
    }
    const onPlaceChanged = () => {
        try {
            if (autocomplete !== null) {
                setLat(autocomplete.getPlace().geometry.location.lat())
                setLng(autocomplete.getPlace().geometry.location.lng())
            } else {
                console.log('Autocomplete is not loaded yet!')
            }
        } catch (error) {

        }

    }

    const handleMark = async (event) => {
        try {
            setLoading(true)
            let lat = event.latLng.lat()
            let lng = event.latLng.lng()
            setLat(lat);
            setLng(lng);
            const geocoder = new window.google.maps.Geocoder();
            const res = await geocoder.geocode({ location: { lat, lng } });
            if (res) {
                const zip = {
                    zipCode: findLongName(res, 'postal_code'),
                    cityName: findLongName(res, 'administrative_area_level_1'),
                    stateName: findLongName(res, 'administrative_area_level_2')
                }
                if (zip.zipCode) {
                    setMarks([...marks, [lat, lng]])
                    if (!zipCodes.find(item => item.zipCode === zip.zipCode)) {
                        setZipCodes(prevState => [...prevState, zip])
                    }

                }

            }
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    return (

        isLoaded ? (

            <GoogleMap
                onClick={(e) => handleMark(e)}
                mapContainerStyle={containerStyle}
                center={{ lat, lng }}
                zoom={6}
            > <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
            >
                    <input
                        type="text"
                        placeholder="Please Enter Location"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            textAlign: "center",
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-120px"
                        }}
                    />
                </Autocomplete>
                {loading && (<div className="d-flex justify-content-center align-items-center" style={{
                    background: "#f5f8fab4 ",
                    opacity: "0.3",
                    width: '100%',
                    height: '500px'
                }}><Spinner animation='border' /></div>)}
                {marks.map((item, idx) => (<Marker label={{ text: zipCodes?.[idx]?.zipCode, color: "white", fontSize: "8px" }} key={idx} onClick={() => { deleteLocation(idx) }} position={{ lat: marks[idx][0], lng: marks[idx][1] }} />))}
                {pastLocations.map((item, idx) => (<Marker animation={window.google.maps.Animation.DROP}
                    key={idx} onClick={() => { }} position={{ lat: item.latitude, lng: item.longitude }} />))}
            </GoogleMap>
        ) : <></>


    )
}
const containerStyle = {
    borderRadius: "7px",
    width: '100%',
    height: '520px'
};

const initialcenter = {
    lat: 40.729587,
    lng: -74.165703
};
export default React.memo(MapZip)
