import * as vendorActionCreators from '../../redux/actionCreators/vendorActionCreators';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import VendorEditForm from '../../umut-components/Forms/VendorEditForm';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'react';
import { HostAction } from '../../redux/actionTypes/hostTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { VendorAction } from '../../redux/actionTypes/vendorTypes';
import { FC } from 'react';

type Props = {
    refetch: () => void
}
const VendorEditDialog: FC<Props> = ({refetch}) => {
    const { modalOpen } = useSelector((state: RedusxAppState) => state.vendor);
    const dispatch = useDispatch<Dispatch<VendorAction>>();

    const handleClose = () => {
        dispatch(vendorActionCreators.CloseVendorEditDialog())
    }
    return (
        <Modal show={modalOpen} onHide={handleClose} centered size="lg">
            <ModalHeader closeButton>
                <Modal.Title>Vendor Edit</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <VendorEditForm refetch={refetch} />
            </Modal.Body>
        </Modal>
    )
}

export default VendorEditDialog
