import React, {Dispatch, useState, useRef} from 'react'
import {Card} from 'react-bootstrap-v5'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {useDispatch} from 'react-redux'
import {HostAction} from '../../../redux/actionTypes/hostTypes'
import config from '../../../config/config.json'
import {useHistory, useParams} from 'react-router-dom'
import {
  decryptText,
  setDxFilterPlaceholder,
  onExporting,
  encryptText,
  toConvertedDate,
} from '../../../utils/util'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'

const MyTeamSubscriberDetail: React.FC = () => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const dispatch = useDispatch<Dispatch<HostAction>>()
  let {propertyId}: any = useParams()
  let hostPropertyId = decryptText(propertyId)
  const {
    data: propertyData,
    error: propertyError,
    isLoading: propertyLoading,
    isFetching: propertyFetching,
  } = useQuery(
    ['Get Host Property Invoices', hostPropertyId],
    () => ApiCalls.getHostPropertyInvoices(hostPropertyId),
    {cacheTime: 500000, refetchOnWindowFocus: true, enabled: hostPropertyId ? true : false}
  )

  const calculateNetAmount = (cellValue: any) => {
    return '$' + cellValue?.totalNetAmount
  }
  const calculateBillingDate = (cellValue: any) => {
    return toConvertedDate(cellValue?.billingDate)
  }

  const showInvoiceDetail = (cellValue: any) => {
    if (cellValue.rowType == 'data') {
      history.push('/my-team-subscriber-detail/invoice/' + encryptText(cellValue?.data?.id))
    }
  }

  const calculateStatus = (cellValue: any) => {
    if (cellValue.data.hostInvoiceStatusTypeName == 'Unpaid') {
      return <div style={{color: '#ff5d5d'}}>Unpaid</div>
    }
    if (cellValue.data.hostInvoiceStatusTypeName == 'Paid') {
      return <div style={{color: '#34db1a'}}>Paid</div>
    }
    if (cellValue.data.hostInvoiceStatusTypeName == 'Due') {
      return <div style={{color: '#efb30e'}}>Due</div>
    }
  }

  if (propertyData?.data?.data?.length === 0) {
    return <EmptyTable title='No Billings' />
  }

  return (
    <>
      <PageTitle>
        {propertyData?.data?.data.length > 0
          ? propertyData?.data?.data[0].hostPropertyName + ' - Invoices'
          : 'My Team Subscriber Invoices'}
      </PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            {propertyLoading ? (
              <SkeletonTableLoading />
            ) : (
              <DataGrid
                id='billingTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                  e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={propertyData?.data?.data}
                ref={tableRef}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(60vh)'}
                allowColumnResizing={true}
                selection={{mode: 'single'}}
                onCellClick={showInvoiceDetail}
                onEditorPreparing={setDxFilterPlaceholder}
                onExporting={(e) => onExporting(e, 'Billings')}
              >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={10} />
                <Summary>
                  <TotalItem
                    cssClass='absolute-right'
                    displayFormat='Total Invoices: {0}'
                    column='invoiceNumber'
                    summaryType='count'
                  />
                </Summary>

                <Column
                  dataField='invoiceNumber'
                  dataType='string'
                  caption='Invoice ID'
                  minWidth={150}
                  cssClass='cls'
                />
                <Column
                  dataField='hostPropertyName'
                  caption='Property'
                  minWidth={280}
                  dataType='string'
                  cssClass='cls'
                  alignment='center'
                />
                <Column
                  dataField='nameSurname'
                  caption='Host'
                  minWidth={280}
                  dataType='string'
                  cssClass='cls'
                  alignment='center'
                />
                <Column
                  dataField='billingDate'
                  dataType='time'
                  caption='INVOICE DATE'
                  format={config.date_time_format_dev_extreme}
                  calculateCellValue={calculateBillingDate}
                  minWidth={200}
                  alignment='center'
                  cssClass='cls'
                ></Column>
                <Column
                  dataField='hostInvoiceStatusTypeName'
                  dataType='string'
                  caption='Status'
                  cellRender={calculateStatus}
                  width={250}
                  cssClass='cls'
                  alignment='center'
                />
                <Column
                  dataField='totalNetAmount'
                  caption='Amount(Net)'
                  calculateCellValue={calculateNetAmount}
                  dataType='string'
                  width={180}
                  cssClass='cls'
                />
              </DataGrid>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default MyTeamSubscriberDetail
