import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  MasterDetail,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid'
import {useHistory, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../../network/ApiCalls'
import {
  decryptText,
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  toConvertedDate,
  getDateFilter,
  setDxFilterPlaceholder,
} from '../../../../../utils/util'
import {useRef, useState} from 'react'
import ViewButton from '../../../../../umut-components/Buttons/ViewButton'
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton'
import {JobStatusType} from '../../../../../enums/JobEnum'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable'
import config from '../../../../../config/config.json'
import {Spinner} from 'react-bootstrap-v5'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import dayjs from 'dayjs'
import DatePicker from '../../../../../umut-components/Inputs/DatePicker'
import {useDialog} from '../../../../../contexts/DialogContext'

const VendorJobs = () => {
  let {vendorId}: any = useParams()
  vendorId = decryptText(vendorId)
  const history = useHistory()
  const [startColumn, setStartColumn] = useState<string>('')
  const [endColumn, setEndColumn] = useState<string>('')
  const {showFailureDialog} = useDialog()
  const [cleaningPeriodStart, setCleaningPeriodStart] = useState(
    dayjs().add(-90, 'day').format('YYYY-MM-DD')
  )
  const [cleaningPeriodEnd, setCleaningPeriodEnd] = useState(
    dayjs().add(30, 'day').format('YYYY-MM-DD')
  )

  const {
    data,
    isLoading: jobLoading,
    error,
    refetch: refetchVendorJobs,
  } = useQuery(
    ['Get Vendors Jobs', vendorId],
    () => ApiCalls.getJobsByVendorId(vendorId, cleaningPeriodStart, cleaningPeriodEnd),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const tableRef = useRef<DataGrid>(null)
  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const toJobDetail = (cellData: any) => {
    if (cellData?.data?.id && cellData?.column?.name !== 'delete-button') {
      history.push({
        pathname: `/job-details/` + encryptText(cellData?.data?.id),
      })
    }
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.startedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodStart = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const calculateCleaningPeriodEnd = (cellValue: any) => {
    return toConvertedDate(cellValue.cleaningPeriodEnd, cellValue.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDate(cellValue.finishedOn, cellValue.hostPropertyCityTimeZone)
  }

  const calculateFullName = (cellValue: any) => {
    if (cellValue?.cancelUser?.firstName) {
      return cellValue?.cancelUser?.firstName + ' ' + cellValue?.cancelUser?.lastName
    }
  }

  const changeStartDate = (date: any) => {
    setCleaningPeriodStart(dayjs(date).format('YYYY-MM-DD'))
  }

  const changeEndDate = (date: any) => {
    setCleaningPeriodEnd(dayjs(date).format('YYYY-MM-DD'))
  }

  const searchFilteredJobs = () => {
    if (dayjs(cleaningPeriodStart).isAfter(cleaningPeriodEnd)) {
      showFailureDialog('Start date should lower than end date!')
    } else {
      refetchVendorJobs()
    }
  }

  if (jobLoading) {
    return <SkeletonTableLoading />
  }

  if (!jobLoading && data?.data?.data?.length === 0) {
    return <EmptyTable title='No Jobs' />
  }

  return (
    <div className='posiiton-relative'>
      <div className='mt-4'>
        <div className='row'>
          <div className='col-lg-2 col-12 mb-2'>
            <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
          </div>
          <div className='col-lg-2 col-12 mb-2'>
            <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
          </div>
          <div className='col-lg-2 col-12 mb-2'>
            <button
              className='btn btn-sm btn-primary'
              onClick={() => {
                searchFilteredJobs()
              }}
              disabled={jobLoading}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id={'jobTable'}
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data?.data}
        showBorders={false}
        showColumnLines={false}
        ref={tableRef}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        className='mt-6'
        onCellClick={toJobDetail}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Vendor Jobs')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />

        <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
        <Column dataField='jobStaff' caption='Staff' minWidth={200} cssClass='cls' />
        <Column
          dataField='scheduledStart'
          caption='Scheduled Start'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
        </Column>
        <Column
          dataField='jobStatusTypeName'
          alignment='center'
          caption='Status'
          cellRender={displayStatusType}
          minWidth={250}
          cssClass='cls'
        />

        <Column
          dataField='startedOn'
          caption='Started at'
          dataType='date'
          format={config.date_time_format_dev_extreme}
          selectedFilterOperation='between'
          calculateCellValue={calculateStartedOn}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('startedOn')} />
        </Column>

        <Column
          dataField='scheduledFinish'
          caption='Scheduled Finish'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
        </Column>

        <Column
          dataField='finishedOn'
          caption='Finished at'
          selectedFilterOperation='between'
          dataType='date'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateFinishedOn}
          minWidth={190}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>
        <Column
          dataField='hostPropertyCityName'
          caption='City'
          alignment='center'
          minWidth={150}
          cssClass='cls'
        />
        <Column
          dataField='hostPropertyCountryName'
          caption='Country'
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />
        <Column dataField='hostPropertyStateName' caption='State' minWidth={200} cssClass='cls' />
      </DataGrid>
    </div>
  )
}

export default VendorJobs
