import { Badge, ButtonToolbar, Card, Dropdown, Spinner } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ApiCalls from '../../../../../network/ApiCalls'
import VendorServiceTable from './VendorServiceTable'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'
import { useEffect, useRef, useState } from 'react'
import ServiceCitiesModal from './VendorServiceCitiesModal'
import MapZip from '../../../../../umut-components/Map/GoogleMapZip'
import { useDialog } from '../../../../../contexts/DialogContext'
import { decryptText, getLocationByAddressForStaff } from '../../../../../utils/util'
import AutocompleteMap from '../../../../../umut-components/Inputs/AutocompleteMap'
import { IStaffAddress } from '../../../../../interfaces/Address'
import { ILocation } from '../../../../../interfaces/Property'
var debounce = require('debounce')


const VendorServiceCities = () => {
  let { vendorId }: any = useParams()
  vendorId = decryptText(vendorId);
  const { showSuccessDialog } = useDialog()
  const { showFailureDialog } = useDialog()
  const { isLoading, refetch, data } = useQuery(
    ['Vendor Cities', vendorId],
    () => ApiCalls.getVendorCityService(Number(vendorId)),
    { refetchOnWindowFocus: false }
  )
  const formRef = useRef<any>(null)
  const { data: vendorInformation, isLoading: isLoadingInformation, error } = useQuery(['Get Vendor Details by Id', vendorId], () => ApiCalls.getVendorById(vendorId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: vendorId ? true : false });
  const [defaultInput, setDefaultInput] = useState<any>()
  const [pastLocations, setPastLocations] = useState<any>()
  const [zipCodes, setZipCodes] = useState<any>([])
  const [marks, setMarks] = useState([])
  const [show, setShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [text, setText] = useState<string>('');
  const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({ address: ' ' });
  const [loading, setLoading] = useState(false)
  const [inputLoading, setInputLoading] = useState(false)

  useEffect(() => {
    if (data ?? null) {
      setPastLocations(data?.data?.data)
    }
  }, [data])

  const deleteLocation = (index: number) => {
    const newZipCodes = [...zipCodes]
    const newMarkArray = [...marks]
    newMarkArray.splice(index, 1)
    newZipCodes.splice(index, 1)
    setZipCodes(newZipCodes)
    setMarks(newMarkArray)
  }

  const handleSave = () => {
    try {
      if (zipCodes.length !== 0) {
        setLoading(true)
        {
          zipCodes.length !== 0 &&
            (ApiCalls.createVendorCityService({
              vendorId: Number(vendorId),
              cityZipCodes: zipCodes.map((item: any) => item.zipCode),
            }).then((response: any) => {
              response?.data?.notAddedZipCodes.length != 0 && setErrorMessage(`Zipcodes Not found: ${response?.data?.notAddedZipCodes}`)
              refetch()
              showSuccessDialog(`Service ${zipCodes.length >= 1 ? 'Cities' : 'City'} Added.`)
              setLoading(false); setZipCodes([]); setMarks([]); handleClose()
            }))
        }

      }
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error?.response?.data?.message)
    }
  }

  
  const handleInputAdded = () => {
    try {
      setInputLoading(true)
      ApiCalls.createVendorCityService({
        vendorId: Number(vendorId),
        cityZipCodes: [defaultInput]
      }).then((response: any) => {
        response?.data?.notAddedZipCodes.length == 0 ?
          showSuccessDialog('Repeat the process to enter more service areas you service. This will broaden your business and increase the number of jobs you can receive.') :
          showFailureDialog(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
        setErrorMessage(`Zipcode Not found: ${response?.data?.notAddedZipCodes}`)
        refetch()

        if (formRef) {
          formRef.current.value = null;
          setDefaultInput(null)
        }
        setInputLoading(false)
      })
    } catch (error: any) {
      setInputLoading(false)
      setErrorMessage(error?.response?.data?.message)
    }
  }
  const handleAutocompleteAdded = () => {
    if(fullLocation && vendorId){
      try {
        setInputLoading(true)
        ApiCalls.createVendorCityService({
          vendorId: Number(vendorId),
          cityZipCodes: [fullLocation?.zipCode]
        }).then((response: any) => {
          response?.data?.notAddedZipCodes.length == 0 ?
            showSuccessDialog('Repeat the process to enter more service areas you service. This will broaden your business and increase the number of jobs you can receive.') :
            showFailureDialog(`Zipcode is Not found: ${response?.data?.notAddedZipCodes}`)
          setErrorMessage(`Zipcode is Not found: ${response?.data?.notAddedZipCodes}`)
          refetch()
          setText('')
          setInputLoading(false)
        })
      } catch (error: any) {
        setInputLoading(false)
        setErrorMessage(error?.response?.data?.message)
      }
    }
  }
  const handleInputChange = (event: any) => {
    setDefaultInput(event.target.value)
  }
  const handleSelectAddress = (e: string) => {
    setSelectedLocation({ ...selectedLocation, address: e });
    setText(e)
  }
  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '');
  }


  useEffect(() => {
    getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
  }, [selectedLocation])

  if (isLoading) {
    return (
      <div>
        <Spinner animation={'border'} />
      </div>
    )
  }
  
  const handleClose = () => setShow(false)
  return (
    <>
      <div>
        <Card>
          <Card.Body className='row h-lg-550px'>
            <div className='col-lg-5 col-sm-12'>
              <MapZip
                setZipCodes={setZipCodes}
                deleteLocation={deleteLocation}
                zipCodes={zipCodes}
                setMarks={setMarks}
                center={{ lat: vendorInformation?.data?.cityLat, lng: vendorInformation?.data?.cityLong }}
                pastLocations={pastLocations}
                marks={marks}
              />
            </div>
            <div className="col-lg-7 col-sm-12 h-100 position-relative">
              <div style={{ zIndex: 1 }} className="left-0 position-absolute">
                <div className="mt-8">
                  <Dropdown >
                    <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
                     Add Manually 
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-275px">
                      <Dropdown.ItemText className="d-flex">
                        <input type="number" placeholder="zip code ..." ref={formRef} className="form-control ml-3" style={{ height: 40 }} onChange={debounce(handleInputChange, 200)} />
                        <button type="button" disabled={pastLocations?.some((item: any) => item.zipCode === defaultInput)} className="btn btn-sm btn-success rounded ms-3" onClick={() => { handleInputAdded() }}>{inputLoading ? <Spinner animation="border" size="sm" /> : '+'}</button>
                      </Dropdown.ItemText>
                      <Dropdown.ItemText className="d-flex">
                        <AutocompleteMap text={text} setText={setText} handleSelectAddress={handleSelectAddress} handleOnError={handleAutocompleteError} />
                        <button type="button" className="btn btn-sm btn-success rounded ms-3"  onClick={handleAutocompleteAdded}>{inputLoading ? <Spinner animation="border" size="sm" /> : '+'}</button>
                      </Dropdown.ItemText>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>

              </div>
              <div className='mt-8 h-100' style={{ overflowY: "hidden" }}>
                <VendorServiceTable
                  data={pastLocations}
                  refetch={refetch}
                  vendorId={Number(vendorId)}
                />
              </div>
            </div>

          </Card.Body>

          <Card.Footer className='d-flex justify-content-end'>
            <button
              className='btn btn-success'
              style={{ height: '100%' }}
              disabled={!(zipCodes.length !== 0)}
              onClick={() => {
                setShow(true)
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add Service Locations
              <Badge className="bg-secondary text-dark ms-3">{marks.length}</Badge>
            </button>
          </Card.Footer>
        </Card>
      </div>
      <ServiceCitiesModal
        show={show}
        loading={loading}
        save={handleSave}
        close={handleClose}
        added={zipCodes}
        cancelAdd={deleteLocation}
      />
      <DeleteDialog />
    </>
  )
}

export default VendorServiceCities
