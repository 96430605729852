import { IAdmins } from "../../interfaces/Admins";
import * as actions from "../actionTypes/AdminTypes";

export interface AdminState {
    adminAddDialog: {
        modalOpen: boolean;
        refetch: () => void
    }
    adminEditDialog: {
        modalOpen: boolean;
        admin: IAdmins | null
        refetch: () => void
    }
}

const initialState: AdminState = {
    adminAddDialog: {
        modalOpen: false,
        refetch: () => { }
    },
    adminEditDialog: {
        modalOpen: false,
        admin: null,
        refetch: () => { }
    }
}

export default function adminReducer(
    state: AdminState = initialState,
    action: actions.AdminAction
): AdminState {
    switch (action.type) {
        case actions.OPEN_ADD_ADMIN_DIALOG:
            return { ...state, adminAddDialog: { modalOpen: true, refetch: action.refetch } }
        case actions.CLOSE_ADD_ADMIN_DIALOG:
            return { ...state, adminAddDialog: { modalOpen: false, refetch: () => { } } }
        case actions.OPEN_ADMIN_EDIT_DIALOG:
            return { ...state, adminEditDialog: { modalOpen: true, refetch: action.refetch, admin: action.admin } }
        case actions.CLOSE_ADMIN_EDIT_DIALOG:
            return { ...state, adminEditDialog: { modalOpen: false, refetch: () => { }, admin: null } }
        default:
            return state;
    }
}