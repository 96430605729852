import React, { Dispatch } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import ApiCalls from '../../network/ApiCalls';
import { TextInput } from '../Inputs/TextInput';
import { useDialog } from '../../contexts/DialogContext';
import * as checklistActionCreator from '../../redux/actionCreators/checklistActionCreators';
import SwitchBox from '../Inputs/SwitchBox';
import { Button } from '@material-ui/core';
import { IUserDetail } from '../../interfaces/User';
import { UserEditRequest } from '../../network/PostRequestModels/UserRequest';
import { isPhoneValid, toAmazonUrl } from '../../utils/util';
import PhoneTextInput from '../Inputs/PhoneInput';

type Props = {
    user: IUserDetail,
    userId: any,
    successFunc?: () => void
}

const UserEditForm: React.FC<Props> = ({ user, userId, successFunc }) => {
    const { showSuccessDialog } = useDialog();
    const [editRequest, setEditRequest] = useState<UserEditRequest>({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
    } as UserEditRequest);
    const [imagePreview, setImagePreview] = useState<any>(user?.profilePhotoPath ? toAmazonUrl(user?.profilePhotoPath) : null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleUserEdit = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            var formData = new FormData();
            formData.append("firstName", editRequest.firstName);
            formData.append("lastName", editRequest.lastName);
            formData.append("phoneNumber", editRequest.phoneNumber);
            formData.append("profilePhoto", editRequest.profilePhoto);
            const { firstName, lastName, phoneNumber } = editRequest;
            if (firstName && lastName && phoneNumber) {
                if (isPhoneValid(phoneNumber)) {
                    await ApiCalls.editUser(formData, userId);
                    setLoading(false);
                    showSuccessDialog('User Updated.');
                    successFunc && successFunc()
                }
                else {
                    setLoading(false)
                    setErrorMessage('Phone is not valid!');
                }
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const changeImage = (e: any) => {
        try {
            setErrorMessage(null);
            var file = e.target.files[0];
            if (file.size <= 2097152) {
                setEditRequest({ ...editRequest, profilePhoto: file })
                if (file) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setImagePreview(reader.result);
                    };
                }
                else {
                    setImagePreview(null);
                }
            }
            else {
                setErrorMessage('Max file size is 2MB.');
            }
        }
        catch (err: any) {
            setErrorMessage('File cannot be uploaded.')
        }
    }

    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Profile Photo</label>
                    <div className='row d-flex justify-content-center'>
                        {imagePreview ? <div className='d-flex justify-content-center'><img className='rounded' style={{ height: 200 }} alt='selected-sample' src={imagePreview} /></div> : <strong className='text-muted'>No Photo Available</strong>}
                        <Button
                            className='col-lg-9 mt-6'
                            variant="contained"
                            component="label"
                        >
                            Upload Photo
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={changeImage}
                                hidden
                            />
                        </Button>
                        (Max Photo Size 2MB)
                    </div>
                </div>
                <div className='col-1 mt-6' />
                <div className='col-lg-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, firstName: text })} defaultValue={editRequest?.firstName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Last Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, lastName: text })} defaultValue={editRequest?.lastName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Phone</label>
                    <PhoneTextInput defaultValue={editRequest?.phoneNumber} onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} />

                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleUserEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(UserEditForm);