import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {INotification} from '../../interfaces/Notification'
import ApiCalls from '../../network/ApiCalls'
import {IListResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {encryptText, getTimeFromNow} from '../../utils/util'
import EmptyTable from '../Icons/EmptyTable'

type ItemProps = {
  index: number
  notification: INotification
}

const NotificationItem: React.FC<ItemProps> = ({index, notification}) => {
  let notificationType = notification.data.type
  const history = useHistory()
  const [messageRead, setMessageRead] = useState<boolean>(notification.isRead)
  const readMessage = () => {
    try {
      ApiCalls.readNotification([notification.id])
        .then((res) => res)
        .catch((err) => err)
      setMessageRead(true)
      notificationType === 'Job' &&
        notification.data.jobId &&
        history.push('/job-details/' + encryptText(notification.data.jobId))
      notificationType === 'Vendor Document' &&
        notification.data.vendorId &&
        history.push('/vendor-details/' + encryptText(notification.data.vendorId))
    } catch (err: any) {
      //Handle Error
    }
  }

  return (
    <a
      className='d-flex flex-row justify-content-between align-items-center p-8 border-bottom bg-hover-light-primary position-relative'
      onClick={readMessage}
    >
      <div className='d-flex flex-column'>
        <span className='text-gray-800 text-hover-primary fw-bold'>{notification.data.type}</span>
        <span className='text-muted text-hover-primary fw-bold'>{notification.message}</span>
      </div>
      <span className='badge badge-light fs-8'>{getTimeFromNow(notification.createdOn)}</span>
      {!messageRead && (
        <span className='menu-bullet position-absolute' style={{top: '30%', right: 10}}>
          <span className='bullet bullet-dot bg-primary animation-blink'></span>
        </span>
      )}
    </a>
  )
}

type NotificationType = {
  notifications: INotification[]
}

const NotificationMenu: React.FC<NotificationType> = ({notifications}) => {
  return (
    <Dropdown.Menu align='right' className='shadow-sm border border-xl bgi-no-repeat py-0'>
      <div className='d-flex flex-column w-100 w-sm-400px rounded'>
        <div
          className='w-100 h-100px rounded-top'
          style={{backgroundImage: 'url("/media/misc/pattern-1.jpg")'}}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>
        </div>
        <div className='d-flex flex-column bg-white scroll-y mh-325px'>
          {notifications.map((notification, index) => (
            <NotificationItem index={index} notification={notification} />
          ))}
          {notifications.length === 0 && (
            <div className='pb-10'>
              <EmptyTable width={200} title='No Notifications' />
            </div>
          )}
        </div>
      </div>
    </Dropdown.Menu>
  )
}

type CustomToggleProps = {
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}
}

const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        props.onClick && props.onClick(e)
      }}
    >
      {props.children}
      <KTSVG path='/media/icons/duotone/General/Notifications1.svg' className='svg-icon-2x mt-1' />
    </a>
  )
)

const Notification: React.FC = () => {
  const history = useHistory()
  const [notificationBlink, setNotificationBlink] = useState<boolean>(false)
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {data, refetch} = useQuery<AxiosResponse<IListResponse<INotification>>>(
    ['Get notifications', user?.user?.id],
    () => ApiCalls.getNotifications(),
    {
      cacheTime: 10000,
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
      enabled: user?.user?.id ? true : false,
    }
  )

  useEffect(() => {
    setNotificationBlink(
      !!data &&
        data.data &&
        data.data.data.filter((notification) => !notification.isRead).length > 0
    )
  }, [data])

  useEffect(() => {
    history.listen(() => {
      refetch()
    })
  }, [history])

  useEffect(() => {
    if (user?.user?.id) {
      refetch()
    }
  }, [user])

  return (
    <Dropdown className='d-flex align-items-center position-relative'>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-notification' />
      <NotificationMenu notifications={data?.data.data ?? []} />
      {notificationBlink && (
        <span className='menu-bullet position-absolute' style={{top: 0, right: 0}}>
          <span className='bullet bullet-dot bg-danger animation-blink' />
        </span>
      )}
    </Dropdown>
  )
}

export default Notification
