import React, {Dispatch, useEffect, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {useDispatch, useSelector} from 'react-redux'
import {HostAction} from '../../../redux/actionTypes/hostTypes'
import {GetHostPropertyResponse} from '../../../network/NetworkResponses/NetworkResponses'
import PropertyTable from '../../properties/PropertyTable'
import {HostPropertyStatusId} from '../../../enums/PropertyEnum'
import {useParams} from 'react-router'
import PropertyView from './PropertyView'
import {decryptText} from '../../../utils/util'
import OfferPendingView from './OfferPendingView'
import OfferPendingTable from './property-offer-pending/OfferPendingTable'
import {IHostProperty} from '../../../interfaces/Property'

type TabNames = 'turnify-pro' | 'turnify-plus' | 'marketplace'

const OfferPendings = () => {
  let {propertyId}: any = useParams()
  propertyId = propertyId ? decryptText(propertyId) : undefined

  const [selectedTab, setSelectedTab] = useState<TabNames | null>('turnify-pro')

  const [pendingModalOpen, setPendingModalOpen] = useState<boolean>(false)
  const [statusId, setStatusId] = useState<any>()
  const [proProperties, setProProperties] = useState<IHostProperty[] | null>(null)
  const [plusProperties, setPlusProperties] = useState<IHostProperty[] | null>(null)
  const [mpProperties, setMpProperties] = useState<IHostProperty[] | null>(null)

  const dispatch = useDispatch<Dispatch<HostAction>>()
  var query = useQuery<GetHostPropertyResponse>(
    ['Get Pending Properties'],
    () => ApiCalls.getPendingProperties(),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: true}
  )
  const {data, isLoading, error, refetch} = query

  useEffect(() => {
    setStatusId(HostPropertyStatusId['offer-pending'])
  }, [])

  useEffect(() => {
    setPendingModalOpen(propertyId ? true : false)
  }, [propertyId])

  useEffect(() => {
    if (data != undefined) {
      const proList = []
      const plusList = []
      const mpList = []
      for (const item of data?.data?.data) {
        if (item.hasProQuoteRequest) {
          if (item.isMarketplace) {
            mpList.push(item)
          } else {
            proList.push(item)
          }
        }
        if (item.hasPlusQuoteRequest) {
          plusList.push(item)
        }
      }

      setProProperties(proList)
      setPlusProperties(plusList)
      setMpProperties(mpList)
    }
  }, [data])

  if (error) {
    return <div>Error</div>
  }

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }
  return (
    <>
      <PageTitle>Pending Properties</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <Tabs
                defaultActiveKey='turnify-pro'
                id='offer-pending-tabs'
                className='mb-3 p-3'
                onSelect={handleChangeTab}
              >
                <Tab eventKey='turnify-pro' title='Turnify PRO'>
                  <OfferPendingTable
                    data={proProperties}
                    proFilterActive
                    pendingButtonActive={true}
                    refetch={refetch}
                  />
                </Tab>
                <Tab eventKey='turnify-plus' title='Turnify+'>
                  <OfferPendingTable
                    data={plusProperties}
                    proFilterActive={false}
                    pendingButtonActive={true}
                    refetch={refetch}
                  />
                </Tab>
                <Tab eventKey='marketplace' title='Marketplace'>
                  <OfferPendingTable
                    data={mpProperties}
                    proFilterActive={false}
                    pendingButtonActive={true}
                    refetch={refetch}
                  />
                </Tab>
              </Tabs>
            )}
          </Card.Body>
        </Card>
      </div>
      {pendingModalOpen && <OfferPendingView modalOpen={pendingModalOpen} refetch={refetch} />}
    </>
  )
}

export default OfferPendings
