import React, {useState} from 'react'
import {IMarketplaceOfferedRate} from '../../../../interfaces/Marketplace'
import StarRatingView from '../../../Rating/StarRatingView'
import dayjs from 'dayjs'
import ApiCalls from '../../../../network/ApiCalls'
import {useDialog} from '../../../../contexts/DialogContext'

type Props = {
  offers: IMarketplaceOfferedRate[]
  refetch: () => void
}

const MarketplaceServiceOffers: React.FC<Props> = ({offers, refetch}) => {
  const [dropMenuIndx, setDropMenuIndx] = useState('')
  const [selectedOffers, setSelectedOffers] = useState<number[]>([])
  const [deletingOffers, setDeletingOffers] = useState<boolean>(false)
  const {showSuccessDialog, showFailureDialog} = useDialog()
  const getOfferStatusColor = (statusId: number) => {
    if (statusId === 3) {
      return 'badge-light-success'
    } else if (statusId === 4) {
      return 'badge-light-danger'
    } else if (statusId === 2) {
      return 'badge-light-warning'
    } else {
      return 'badge-light-primary'
    }
  }

  const showDropDown = (i: number) => {
    if (dropMenuIndx == 'ktmenu-' + i) {
      setDropMenuIndx('')
    } else {
      setDropMenuIndx('ktmenu-' + i)
    }
  }

  const selectOffer = (i: number) => {
    if (selectedOffers.includes(i)) {
      setSelectedOffers(selectedOffers.filter((offer) => offer !== i))
    } else {
      setSelectedOffers([...selectedOffers, i])
    }
  }

  const deleteOffer = async (i: number) => {
    if (selectedOffers.length == 0) {
    } else {
      try {
        await ApiCalls.deleteMarketplaceOffer([i])
        showSuccessDialog('Offer Deleted Successfully')
        refetch()
      } catch (error) {
        showFailureDialog('An error occur during deleting offer')
      }
    }
  }

  const deleteSelectedOffers = async () => {
    if (selectedOffers.length == 0) {
      showFailureDialog('Please select an offer to delete')
    } else {
      setDeletingOffers(true)
      try {
        await ApiCalls.deleteMarketplaceOffer(selectedOffers)
        setDeletingOffers(false)
        showSuccessDialog('Offers Deleted Successfully')
        refetch()
      } catch (error) {
        setDeletingOffers(false)
        showFailureDialog('An error occur during deleting offers')
      }
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='w-10px pe-2 sorting_disabled'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input className='form-check-input' type='checkbox' value='1'></input>
                    </div>
                  </th>
                  <th className='min-w-200px sorting'>Service Provider</th>
                  <th className='text-end min-w-100px sorting'>Rating</th>
                  <th className='text-end min-w-100px sorting'>Date</th>
                  <th className='text-end min-w-100px sorting'>Rate</th>
                  <th className='text-end min-w-100px sorting'>Is Management</th>
                  <th className='text-end min-w-100px sorting'>Status</th>
                  <th className='text-end min-w-70px sorting_disabled'>Actions</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {offers.map((offer, index) => {
                  return (
                    <tr className={index % 2 == 0 ? 'odd' : 'even'} key={'offer-row-' + index}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={offer.id}
                            onChange={(v) => {
                              selectOffer(+v.target.value)
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px'>
                            <span className='symbol-label'></span>
                          </div>

                          <div className='ms-5'>
                            <div
                              className='text-gray-800 text-hover-primary fs-5 fw-bold'
                              data-kt-ecommerce-product-filter='product_name'
                            >
                              {offer.vendorName}
                            </div>
                            <span className='text-gray-600 fs-8'>
                              {offer.checklistId > 0 ? offer.checklistName : 'Any Checklist'}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end pe-0' data-order='rating-3'>
                        <div className='rating justify-content-end'>
                          <StarRatingView rate={offer.vendorRate} />
                        </div>
                      </td>
                      <td className='text-end pe-0'>
                        <span className='fw-bold'>{dayjs(offer.date).format('MM/DD/YYYY')}</span>
                      </td>
                      <td className='text-end pe-0'>${offer.offerPrice}</td>
                      <td className='text-end pe-0'>
                        {offer.isManagementService ? (
                          <span className='text-success'>YES</span>
                        ) : (
                          <span className='text-danger'>NO</span>
                        )}
                      </td>
                      <td className='text-end pe-0'>
                        <div className={'badge ' + getOfferStatusColor(offer.offerStatusId)}>
                          {offer.offerStatusName}
                        </div>
                      </td>
                      <td className='text-end'>
                        <div
                          className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-target={'#ktmenu-' + offer.id}
                          onClick={() => {
                            showDropDown(offer.id)
                          }}
                        >
                          Actions
                        </div>
                        <div
                          className={
                            dropMenuIndx == 'ktmenu-' + offer.id
                              ? 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-danger fw-semibold fs-7 w-125px py-4 dropbox show'
                              : 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-danger fw-semibold fs-7 w-125px py-4 dropbox'
                          }
                          data-kt-menu='true'
                          id={'ktmenu-' + offer.id}
                        >
                          <div
                            className='menu-item px-3'
                            onClick={() => {
                              deleteOffer(offer.id)
                            }}
                          >
                            <div className='menu-link px-3'>Delete</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedOffers.length > 0 && (
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button className='btn btn-danger fw-bold' onClick={deleteSelectedOffers}>
            {!deletingOffers && 'Delete'}
            {deletingOffers && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export default MarketplaceServiceOffers
