import React, {Dispatch, FC, useEffect, useState} from 'react'
import {decryptText, encryptText} from '../../../utils/util'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading'
import {AxiosResponse} from 'axios'
import {IHostPropertyServiceRateListResponse} from '../../../network/NetworkResponses/NetworkResponses'
import {HostPropertyAction} from '../../../redux/actionTypes/hostPropertyTypes'
import * as hostPropertyActionCreator from '../../../redux/actionCreators/hostPropertyActionCreators'
import {IHostPropertyOffer} from '../../../interfaces/Property'
import EmptyTable from '../../Icons/EmptyTable'
import DeleteDialog from '../../Modals/DeleteDialog'
import PropertyAddServiceRateDialog from './PropertyAddServiceRateDialog'
import PropertyServiceRateList from './ServiceRate/PropertyServiceRateList'

type ItemProps = {
  propertyService: IHostPropertyOffer
  propertyServiceRefetch: () => void
}

const PropertyOfferedStep: React.FC = () => {
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  let {propertyId}: any = useParams()
  propertyId = propertyId ? decryptText(propertyId) : undefined
  const [showAddModal, setShowAddModal] = useState(false)
  const {
    data: propertyServiceData,
    isFetching: propertyServicesLoading,
    error: propertyServiceError,
    refetch: propertyServiceRefetch,
  } = useQuery(
    ['Get Property Service Rates', propertyId],
    () => ApiCalls.getHostPropertyServiceRateList(propertyId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (propertyServiceData) {
      dispatch(
        hostPropertyActionCreator.setPropertyApproveEnabled(
          propertyServiceData?.data?.data.length > 0
        )
      )
    }
  }, [propertyServiceData])

  if (propertyServicesLoading) {
    return (
      <div>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (propertyServiceError) {
    return <div>Error</div>
  }

  return (
    <div className='row'>
      {propertyServiceData?.data.data.length === 0 ? (
        <EmptyTable title='No offered services' />
      ) : (
        <PropertyServiceRateList
          data={propertyServiceData?.data.data}
          refetch={propertyServiceRefetch}
        />
      )}
      <div className='d-flex w-100 justify-content-end mt-3'>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            setShowAddModal(true)
          }}
        >
          Add New Service Rate
        </button>
      </div>
      <PropertyAddServiceRateDialog
        propertyId={propertyId}
        visible={showAddModal}
        handleClose={() => {
          setShowAddModal(false)
        }}
      />
      {/* <PropertyOfferedDialog /> */}
      <DeleteDialog />
    </div>
  )
}

export default PropertyOfferedStep
