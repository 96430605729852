import { AxiosResponse } from 'axios';
import DataGrid, { Column, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem, Selection } from 'devextreme-react/data-grid';
import { FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDialog } from '../../contexts/DialogContext';
import { IGetAllVendors } from '../../interfaces/Vendors';
import ApiCalls from '../../network/ApiCalls';
import { IPropertyResponse } from '../../network/NetworkResponses/NetworkResponses';
import { BanRequest, ExcludeVendor, PermissionRequest } from '../../network/PostRequestModels/Permission';
import { VendorAction } from '../../redux/actionTypes/vendorTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { decryptText, onExporting } from '../../utils/util';
import EmptyTable from '../Icons/EmptyTable';
import ProfilePhotoTemp from '../Icons/ProfilePhotoTemp';
import { TextInput } from '../Inputs/TextInput';
import SkeletonTableLoading from '../Loading/SkeletonTableLoading';
import * as vendorPropertyCreator from '../../../app/redux/actionCreators/vendorActionCreators'

type Props = { data: IGetAllVendors }
const VendorPropertyForm: FC<Props> = ({ data }) => {
    let { vendorId }: any = useParams()
    vendorId = decryptText(vendorId)

    const { showFailureDialog } = useDialog();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<Dispatch<VendorAction>>();
    const { vendorPropertyDialog } = useSelector((state: RedusxAppState) => state.vendor)
    const { data: permissionProperties, isLoading: permissionPropertiesLoading, error: permissionPropertiesError } = useQuery<AxiosResponse<IPropertyResponse>>(['Get allowed/banned properties', vendorId, vendorPropertyDialog.accessType], () => vendorPropertyDialog.accessType === "allowed" ? ApiCalls.getAllowedProperties(vendorId) : ApiCalls.getBannedProperties(vendorId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: vendorPropertyDialog.modalOpen });
    const { data: properties, isLoading: propertiesLoading, error: propertiesError } = useQuery<AxiosResponse<IPropertyResponse>>(['Get Vendor Properties', vendorId], () => ApiCalls.getAvailablePropertiesByVendorId(vendorId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: vendorPropertyDialog.modalOpen });
    const [errorMessage, setErrorMessage] = useState<string | null>('');
    const [availableProperties, setAvailableProperties] = useState<any[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<any>(null);
    const [banDescription, setBanDescription] = useState<string | null>(null);

    const savePermissions = async () => {
        try {
            if (data && selectedProperty) {
                setLoading(true);
                setErrorMessage(null);
                let permissionRequest: PermissionRequest[] = selectedProperty.map((property: any) => ({
                    hostPropertyId: property.id,
                    userId: vendorId,
                }))
                if (vendorPropertyDialog.accessType === "allowed") {
                    await ApiCalls.addPropertyPermission(permissionRequest);
                    vendorPropertyDialog.refetch();
                    setLoading(false);
                    dispatch(vendorPropertyCreator.CloseVendorPropertyDialog());
                }
                else if (vendorPropertyDialog.accessType === "banned" && selectedProperty?.[0].hostId) {
                    let banRequest: ExcludeVendor = {
                        hostPropertyId: selectedProperty?.[0].id,
                        vendorId: Number(vendorId),
                        hostId: selectedProperty?.[0].hostId,
                        description: banDescription ? banDescription : undefined
                    }
                    await ApiCalls.postExcludeVendor(banRequest);
                    vendorPropertyDialog.refetch();
                    setLoading(false);
                    dispatch(vendorPropertyCreator.CloseVendorPropertyDialog());
                }
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const selectedPropertyChanged = (data: any) => {
        
        setSelectedProperty(data.selectedRowsData)
    }

    useEffect(() => {
        if (permissionProperties && properties) {
            vendorPropertyDialog.accessType === "allowed" && setAvailableProperties(properties.data.data.filter(property => !permissionProperties.data.data.some(permissionProperty => permissionProperty.hostPropertyId === property.id)));
            vendorPropertyDialog.accessType === "banned" && setAvailableProperties(properties.data.data.filter(property => !permissionProperties.data.data.some(permissionProperty => permissionProperty.hostPropertyId === property.id)));
        }
    }, [permissionProperties, properties])

    if (permissionPropertiesLoading || propertiesLoading) {
        return <SkeletonTableLoading />
    }

    if (permissionPropertiesError || propertiesError) {
        return <div>Error</div>
    }

    return (
        <div className='row '>
            <div className='col-lg-6'>
                {
                    availableProperties.length === 0 ?
                        <EmptyTable classNames='mt-6' title="No Properties" /> :
                        <DataGrid
                            id="propertyTable"
                            keyExpr="id"
                            onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                            dataSource={availableProperties}
                            showBorders={false}
                            showColumnLines={false}
                            showRowLines={true}
                            hoverStateEnabled={true}
                            height={'calc(40vh - 60px)'}
                            allowColumnResizing={true}
                            onExporting={(e) => onExporting(e, "Permissions")}
                            onSelectionChanged={selectedPropertyChanged}
                        >
                            <Export enabled={false} />
                            <Selection
                                mode={vendorPropertyDialog.accessType === "banned" ? "single" : "multiple"}
                                showCheckBoxesMode='always'
                            />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                            <Paging defaultPageSize={10} />
                            <Summary>
                                <TotalItem cssClass='absolute-right' displayFormat="Total Properties: {0}" column='name' summaryType='count' />
                            </Summary>

                            <Column dataField="name" caption='Address' minWidth={250} dataType='string' cssClass='cls' />
                        </DataGrid>

                }

            </div>
            <div className='col-lg-6 d-flex flex-column bg-light-primary p-6'>
                <strong className='w-100'>Properties</strong>
                <div style={{ maxHeight: 200, overflowY: 'auto' }}>{selectedProperty?.map((property: any) => <p className='text-muted'>-{property.name}</p>)}</div>
                <strong className='w-100 mt-6'>Staff</strong>
                <div className='d-flex flex-wrap mt-2'>
                    <div className='d-flex me-6'>
                        <ProfilePhotoTemp width={35} height={35} fontSize={12} borderRadius='50%' profilePhotoPath={data?.profilePhotoPath} firstName={data?.name ?? ''} lastName={''} />
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 h-auto d-flex align-items-center'>
                            <strong className='text-muted'>{data?.name}</strong>
                        </div>
                    </div>
                </div>
                {
                    vendorPropertyDialog.accessType === "banned" &&
                    <div className='row mt-6'>
                        <div className='d-flex flex-column'>
                            <label className='form-label'>Restriction Description</label>
                            <TextInput onValueChange={setBanDescription} />
                        </div>
                    </div>
                }
                <div className='d-flex flex-column w-100 align-items-end mt-6'>
                    <button className={`btn btn-${vendorPropertyDialog.accessType === "allowed" ? "success" : "danger"} btn-sm`} onClick={savePermissions} disabled={loading}>{vendorPropertyDialog.accessType === "allowed" ? "Assign" : "Restrict"} Vendor {loading && <Spinner animation='border' size='sm' />}</button>
                    <strong className='w-100 d-flex justify-content-end align-items-end text-danger'>{errorMessage}</strong>
                </div>
            </div>
        </div>
    )
}

export default VendorPropertyForm
