import React from 'react'
import { useHistory, useParams } from 'react-router'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { PropertyFormProvider } from '../../../../contexts/PropertyFormContext'
import PropertyAddWizard from '../../../../umut-components/PropertyAddWizards/PropertyAddWizard'
import { decryptText } from '../../../../utils/util';
import PropertyDeleteDialog from '../../../properties/PropertyDeleteDialog';

export default function PropertyEdit() {
    let { propertyId }: any = useParams();
    propertyId = decryptText(propertyId);
    const history = useHistory();

    const exitEditPage = () => {
        history.push('/properties');
    }

    return (
        <>
            <PageTitle>Property Detail</PageTitle>
            <PropertyFormProvider>
                <PropertyAddWizard type="pms" propertyId={propertyId} editModalClose={exitEditPage} propertyType='active'/>
            </PropertyFormProvider>
            <PropertyDeleteDialog />
        </>
    )
}
