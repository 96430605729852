import {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import Dashboard from '../pages/dashboard/Dashboard'
import VendorDetail from '../pages/vendors/VendorDetail/VendorDetail'
import Vendors from '../pages/vendors/Vendors'

import HostDetails from '../pages/hosts/host-details/HostDetails'
import Hosts from '../pages/hosts/hosts/Hosts'
import Properties from '../pages/hosts/properties/Properties'
import StaffDetail from '../pages/staff/staff-detail/StaffDetail'
import PropertyEdit from '../pages/hosts/properties/property-edit/PropertyEdit'
import VendorStatus from '../pages/vendors/VendorStatus/PendingVendor'
import Billings from '../pages/billings/Billings'
import Live from '../pages/live/Live'
import JobDetails from '../pages/job-details/JobDetails'
import PropertyChecklists from '../pages/checklist/PropertyChecklists/PropertyChecklists'
import ChecklistSections from '../pages/checklist/ChecklistSections/ChecklistSections'
import Jobs from '../pages/jobs/Jobs'
import VirtualInspectionJobs from '../pages/virtual-inspection-jobs/VirtualInspectionJobs'
import Profile from '../pages/profile/Profile'
import StaffStatus from '../pages/vendors/StaffStatus/PendingStaffs'
import Services from '../pages/services/Services'
import MasterChecklists from '../pages/master-checklist/MasterChecklists/MasterChecklists'
import MasterChecklistSections from '../pages/master-checklist/MasterChecklistSections/MasterChecklistSections'
import HasVirtualInspection from '../pages/virtual-inspection-jobs/HasVirtualInspection'
import ForgotPassword from '../pages/auth/components/ForgotPassword'
import ForgotPasswordConfirm from '../pages/auth/components/ForgotPasswordConfirm'
import Admins from '../pages/Admins/Admins'
import SchedulerDialog from '../pages/job-details/Tabs/VendorTab/SchedulerDialog'
import HardDeleteUser from '../pages/hard-delete-user/HardDeleteUser'
import AddPropertyWizard from '../pages/hosts/properties/AddPropertyWizard'
import MyTeamSubscribers from '../pages/hosts/my-team-subscribers/MyTeamSubscribers'
import MyTeamSubscriberDetail from '../pages/hosts/my-team-subscribers/MyTeamSubscriberDetail'
import Chat from '../pages/chat/ChatPage'
import PaymentFeeSettings from '../pages/settings/PaymentFeeSettings/PaymentFeeSettings'
import MarketplaceRequests from '../pages/marketplace-requests/MarketplaceRequests'
import MarketplaceRequestDetail from '../pages/marketplace-requests/MarketplaceRequestDetail'
import PropertyList from '../pages/hosts/properties/PropertyList'
import MyTeamSubscriberDetailInvoiceDetail from '../pages/hosts/my-team-subscribers/MyTeamSubscriberDetailInvoiceDetail'
import CleaningRates from '../pages/properties/CleaningRates'
import OfferPendings from '../pages/hosts/properties/OfferPendings'
import OfferPendingView from '../pages/hosts/properties/OfferPendingView'
import VendorSubscriptionPacks from '../pages/vendor-subscription-packs/VendorSubscriptionPacks'
import VendorsRateList from '../pages/vendors/VendorsRateList'
import ServiceGroups from '../pages/service-groups/ServiceGroups'
import MarketplaceManagedProperties from '../pages/marketplace-managed-properties/MarketplaceManagedProperties'
import JobsPage from '../pages/jobs/JobsPage'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/live/client-managed'>
          <Live type='client-managed' />
        </Route>
        <Route path='/live/turnify-managed'>
          <Live type='turnify-managed' />
        </Route>
        <Route path='/jobs/client-managed'>
          <JobsPage type='client-managed' />
        </Route>
        <Route path='/jobs/turnify-managed'>
          <JobsPage type='turnify-managed' />
        </Route>
        {/* <Route path='/jobs/:providerName' component={Jobs} /> */}
        <Route path='/job-details/:jobId' component={JobDetails} />
        <Route path='/vendors-rate-list' component={VendorsRateList} />
        <Route path='/vendors' component={Vendors} />
        <Route path='/vendor-details/:vendorId' component={VendorDetail} />
        <Route path='/pending-vendors' component={VendorStatus} />
        <Route exact path='/pending-staff' component={StaffStatus} />
        <Route exact path='/pending-staff/:staffId/:vendorId' component={StaffStatus} />
        <Route path='/service-groups' component={ServiceGroups} />
        <Route path='/services' component={Services} />
        <Route exact path='/virtual-inspections' component={HasVirtualInspection} />
        <Route exact path='/virtual-inspection/:jobId' component={VirtualInspectionJobs} />
        <Route path='/hosts' component={Hosts} />
        <Route path='/host-details/:hostId' component={HostDetails} />
        <Route path='/my-team-subscribers' component={MyTeamSubscribers} />
        <Route
          exact
          path='/my-team-subscriber-detail/invoice/:billingId'
          component={MyTeamSubscriberDetailInvoiceDetail}
        />
        <Route path='/my-team-subscriber-detail/:propertyId' component={MyTeamSubscriberDetail} />
        <Route path='/marketplace-requests' component={MarketplaceRequests} />
        <Route
          path='/marketplace-request-detail/:marketplaceId'
          component={MarketplaceRequestDetail}
        />
        <Route exact path='/properties/edit/:propertyId' component={PropertyEdit} />
        {/* <Route exact path='/properties/active'>
          <Properties propertyStatus='active' key='active' />
        </Route> */}
        <Route exact path='/properties/offer-pending' component={OfferPendings} />
        <Route exact path='/properties/offer-pending/:propertyId' component={OfferPendingView} />
        {/* <Route exact path='/properties/offer-pending'>
          <Properties propertyStatus='offer-pending' key='offer-pending' />
        </Route>
        <Route exact path='/properties/offer-pending/:propertyId'>
          <Properties propertyStatus='offer-pending' key='offer-pending-property' />
        </Route> */}
        <Route exact path='/properties/add-a-property' component={AddPropertyWizard} />
        <Route path='/properties/turnify'>
          <PropertyList provider='turnify' />
        </Route>
        <Route path='/properties/turnify-plus'>
          <PropertyList provider='turnify-plus' />
        </Route>
        <Route path='/properties/marketplace'>
          <PropertyList provider='marketplace' />
        </Route>
        <Route path='/properties/my-team'>
          <PropertyList provider='my-team' />
        </Route>
        <Route path='/checklists/:hostPropertyId' component={PropertyChecklists} />
        <Route path='/checklist-sections/:checklistId' component={ChecklistSections} />
        <Route path='/host-staff-details/:hostUserId' component={StaffDetail} />
        <Route path='/vendor-staff-details/:vendorUserId' component={StaffDetail} />
        <Route exact path='/billings/detail/:billingId' component={Billings} />
        <Route path='/billings' component={Billings} />
        <Route path='/admins' component={Admins} />
        <Route path='/vendor-subscription-packs' component={VendorSubscriptionPacks} />
        <Route path='/turnify-messenger' component={Chat} />
        {/* <Route path='/asd' component={SchedulerDialog} /> */}
        <Route path='/master-checklists' component={MasterChecklists} />
        <Route path='/master-checklist-sections/:checklistId' component={MasterChecklistSections} />
        <Route exact path='/check-properties-cleaning-rates' component={CleaningRates} />
        <Route path='/profile' component={Profile} />
        <Route path='/hard-delete-user' component={HardDeleteUser} />
        <Route exact path='/settings/payment-fee-settings' component={PaymentFeeSettings} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
