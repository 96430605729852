import { Dispatch, FC } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { useDispatch, useSelector } from 'react-redux'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import AdminCreateForm from '../../umut-components/Forms/AdminCreateForms'
import { VendorCreateForm } from '../../umut-components/Forms/VendorCreateForm'
import * as adminActionCreators from '../../redux/actionCreators/adminActionCreators';
import { AdminAction } from '../../redux/actionTypes/AdminTypes'


type Props = {
    modalOpen: Boolean,
    handleClose: () => void
}

const AdminCreateDialog: FC<Props> = ({ modalOpen }) => {
    const dispatch = useDispatch<Dispatch<AdminAction>>();
    
    const handleClose = () => {
        dispatch(adminActionCreators.closeAddAdminDialog())
    }

    return (
        <div>
            <Modal show={modalOpen} onHide={handleClose} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Admin Create</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <AdminCreateForm />

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default AdminCreateDialog