import React, { Dispatch } from 'react';
import { Modal, Spinner } from 'react-bootstrap-v5';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ChecklistAction } from '../../../../redux/actionTypes/masterChecklistTypes';
import * as checklistActionCreators from '../../../../redux/actionCreators/masterChecklistActionCreators';
import { RedusxAppState } from '../../../../redux/reducers/rootReducer';
import { useState } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Button } from '@material-ui/core';
import * as checklistActionCreator from '../../../../redux/actionCreators/masterChecklistActionCreators';
import ApiCalls from '../../../../network/ApiCalls';
import { useDialog } from '../../../../contexts/DialogContext';
import { decryptText, toAmazonUrl } from '../../../../utils/util';
import { DeleteActions } from '../../../../redux/actionTypes/deleteDialogTypes';
import * as deleteDialogActionCreators from '../../../../redux/actionCreators/deleteDialogActionCreators';

type Props = {
}

const QuestionPhotoDialog: React.FC<Props> = () => {
    let { checklistId }: any = useParams();
    checklistId = decryptText(checklistId);
    const { showSuccessDialog } = useDialog();
    const { questionPhotoDialog } = useSelector((state: RedusxAppState) => state.masterChecklist);
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [selectedImageRequest, setSelectedImageRequest] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const deleteDialogDispatch = useDispatch<Dispatch<DeleteActions>>();
    const { showFailureDialog } = useDialog();

    const closeModal = () => {
        dispatch(checklistActionCreators.closeQuestionPhotoDialog());
        setImagePreview(null);
        setSelectedImageRequest(null);
    }

    const deleteSamplePhoto = async () => {
        deleteDialogDispatch(deleteDialogActionCreators.openDeleteDialog(
            async () => {
                try {
                    await ApiCalls.deleteSamplePhoto(questionPhotoDialog?.selectedQuestion?.id)
                    dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)))
                    dispatch(checklistActionCreator.closeQuestionPhotoDialog());
                    deleteDialogDispatch(deleteDialogActionCreators.closeDeleteDialog());
                }
                catch (err: any) {
                    showFailureDialog(err?.description?.data?.message);
                }
            },
            `Are you sure you want to delete sample photo?`,
            'Delete Photo?'
        ))
    }

    const changeImage = (e: any) => {
        try {
            setErrorMessage(null);
            var file = e.target.files[0];
            if (file.size <= 2097152) {
                setSelectedImageRequest(file);
                if (file) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setImagePreview(reader.result);
                    };
                }
                else {
                    setImagePreview(null);
                }
            }
            else {
                setErrorMessage('Max file size is 2MB.');
            }
        }
        catch (err: any) {
            setErrorMessage('File cannot be uploaded.')
        }
    }

    const handleQuestionPhotoChange = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            var formData = new FormData();
            formData.append("samplePhoto", selectedImageRequest);
            if (selectedImageRequest) {
                if (questionPhotoDialog.selectedQuestion?.photos[0]?.id) {
                    await ApiCalls.editQuestionPhoto(formData, questionPhotoDialog.selectedQuestion?.photos[0]?.id);
                }
                else {
                    formData.append("hostPropertyChecklistSectionQuestionId", questionPhotoDialog.selectedQuestion ? questionPhotoDialog.selectedQuestion?.id.toString() : "");
                    formData.append("hostPropertyChecklistId", checklistId)
                    await ApiCalls.newQuestionPhoto(formData);
                }
                dispatch(checklistActionCreator.closeQuestionPhotoDialog());
                dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)));
                setLoading(false);
                setImagePreview(null);
                setSelectedImageRequest(null);
                showSuccessDialog('Checklist Item Photo Updated.');
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    return (
        <Modal show={questionPhotoDialog.dialogOpen} onHide={closeModal} centered>
            <Modal.Body className="d-flex justify-content-center align-items-center flex-column" >
                <img className='rounded' style={{ width: '100%', objectFit: 'contain' }} alt='selected' src={imagePreview ? imagePreview : questionPhotoDialog.selectedQuestion?.photos[0]?.samplePhotoURL ? toAmazonUrl(questionPhotoDialog.selectedQuestion?.photos[0]?.samplePhotoURL) : toAbsoluteUrl('/media/icons/noImage.png')} />
                <Button
                    className='w-100 mt-6'
                    variant="contained"
                    component="label"
                >
                    Upload Photo
                    <input
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={changeImage}
                        hidden
                    />
                </Button>
                {questionPhotoDialog.selectedQuestion?.photos[0] && <button className="w-100 mt-6 btn btn-danger btn-sm" onClick={deleteSamplePhoto} >Delete Photo </button>}
                <small className="w-100 mt-6 text-muted"> Upload a photo to show your cleaners how you want your guests to see the room after this checklist item is completed.They will be encouraged to take a similar photo for you. </small>
                (Max Photo Size 2MB)
            </Modal.Body>
            {selectedImageRequest ?
                <Modal.Footer>

                    <div className='row'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleQuestionPhotoChange}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </Modal.Footer> : null}

        </Modal>
    )
}

export default QuestionPhotoDialog;