import {IServiceGroup} from '../../interfaces/ServiceType'
import * as actions from '../actionTypes/serviceGroupDetailActionType'

export function openServiceGroupView(
  serviceGroup: IServiceGroup | null
): actions.OpenServiceGroupViewAction {
  return {
    type: actions.SERVICE_GROUP_OPEN_VIEW,
    serviceGroup,
  }
}
export function closeServiceGroupView(): actions.CloseServiceGroupViewAction {
  return {
    type: actions.SERVICE_GROUP_CLOSE_VIEW,
  }
}
