import React, {Dispatch, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  ColumnHeaderFilter,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import UpdateButton from '../../umut-components/Buttons/UpdateButton'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  toConvertedDate,
} from '../../utils/util'
import {IGetAllVendors, IGetAllVendorsRate} from '../../interfaces/Vendors'
import * as vendorActionCreator from '../../redux/actionCreators/vendorActionCreators'
import {VendorAction} from '../../redux/actionTypes/vendorTypes'
import {useDispatch} from 'react-redux'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import config from '../../config/config.json'
import VendorRateDetailModal from './VendorRateDetail/VendorRateDetailModal'

type Props = {
  data: IGetAllVendorsRate[]
  refetch: () => void
}

const VendorsRateListTable: React.FC<Props> = ({data, refetch}) => {
  const dispatch = useDispatch<Dispatch<VendorAction>>()
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const [showRateDetail, setShowRateDetail] = useState(false)
  const [selectedRate, setSelectedRate] = useState<IGetAllVendorsRate>()

  const handleClose = () => {
    setShowRateDetail(false)
    setSelectedRate(undefined)
    refetch()
  }

  const calculateVendorRate = (cellValue: any) => {
    return '$' + cellValue.vendorRate
  }

  const calculateHostRate = (cellValue: any) => {
    return '$' + cellValue.hostRate
  }

  const editVendorRate = (e: any) => {
    setSelectedRate(e.data)
    setShowRateDetail(true)
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Vendors Rate' />
  }
  return (
    <>
      <div className='position-relative'>
        <div style={{zIndex: 1}} className='position-absolute top-15'>
          <span
            className='fs-8 text-primary cursor-pointer'
            onClick={() => tableRef.current?.instance.clearFilter()}
          >
            Clear Filter
          </span>
        </div>
        <DataGrid
          id='vendorsRateListTable'
          keyExpr='id'
          onRowPrepared={(e) => {
            e.rowElement.classList.add('data-grid-row')
          }}
          dataSource={data}
          showBorders={false}
          ref={tableRef}
          showColumnLines={false}
          showRowLines={true}
          hoverStateEnabled={true}
          height={'calc(64vh - 60px)'}
          allowColumnResizing={true}
          selection={{mode: 'single'}}
          onRowClick={(e) => editVendorRate(e)}
          onExporting={(e) => onExporting(e, 'Vendors Rate List')}
        >
          <Export enabled />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
          <Paging defaultPageSize={50} />
          <Summary>
            <TotalItem
              cssClass='absolute-right'
              displayFormat='Total Rate: {0}'
              column='id'
              summaryType='count'
            />
          </Summary>

          <Column
            dataField='vendorName'
            caption='Vendor'
            dataType='string'
            minWidth={195}
            cssClass='cls'
          />
          <Column
            dataField='hostPropertyName'
            caption='Property'
            dataType='string'
            minWidth={300}
            cssClass='cls'
          />
          <Column
            dataField='hostPropertySuite'
            caption='Suite'
            dataType='string'
            minWidth={120}
            cssClass='cls'
            alignment='center'
          />
          <Column
            dataField='hostName'
            caption='Client'
            dataType='string'
            minWidth={195}
            cssClass='cls'
          />
          <Column
            dataField='serviceTypeName'
            caption='Service Type'
            dataType='string'
            minWidth={195}
            cssClass='cls'
          />
          <Column
            dataField='checklistName'
            caption='Checklist'
            dataType='string'
            minWidth={250}
            cssClass='cls'
          />
          <Column
            dataField='vendorRate'
            caption='Vendor Rate'
            dataType='string'
            calculateCellValue={calculateVendorRate}
            minWidth={195}
            cssClass='cls'
          />
          <Column
            dataField='hostRate'
            caption='Client Rate'
            dataType='string'
            calculateCellValue={calculateHostRate}
            minWidth={195}
            cssClass='cls'
          />
        </DataGrid>
      </div>
      {showRateDetail && (
        <VendorRateDetailModal
          visible={showRateDetail}
          handleClose={handleClose}
          data={selectedRate!}
        />
      )}
    </>
  )
}

export default React.memo(VendorsRateListTable)
