import React, {Dispatch, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {useDialog} from '../../../contexts/DialogContext'
import {PropertyFormProvider, usePropertyForm} from '../../../contexts/PropertyFormContext'
import {CleaningProviders, HostPropertyStatusId} from '../../../enums/PropertyEnum'
import {IHostPropertyInput, IHostPropertyOffer} from '../../../interfaces/Property'
import ApiCalls from '../../../network/ApiCalls'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../redux/actionCreators/deleteDialogActionCreators'
import {DeleteActions} from '../../../redux/actionTypes/deleteDialogTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import PropertyDetails from '../../../umut-components/PropeprtyDetails/PropertyDetails'
import PropertyAddWizard from '../../../umut-components/PropertyAddWizards/PropertyAddWizard'
import {decryptText} from '../../../utils/util'
import OfferPendingTabs from './property-offer-pending/OfferPendingTabs'

type Props = {
  modalOpen: boolean
  refetch: () => void
}

const OfferPendingView: React.FC<Props> = ({modalOpen, refetch}) => {
  const {approveEnabled} = useSelector((state: RedusxAppState) => state.hostProperty)
  const {showFailureDialog, showSuccessDialog} = useDialog()
  let {propertyId}: any = useParams()
  propertyId = decryptText(propertyId)
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()

  const closeModal = () => {
    history.push({
      pathname: '/properties/offer-pending',
    })
  }

  const handleDecline = async () => {
    try {
      setLoading(true)
      await ApiCalls.answerOfferPending(propertyId, HostPropertyStatusId.declined)
      setLoading(false)
      showSuccessDialog('Property declined!')
      deleteDispatch(closeDeleteDialog())
      closeModal()
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
    }
  }

  const openDeclineModal = async () => {
    deleteDispatch(
      openDeleteDialog(
        handleDecline,
        'If you decline property, cleaning provider will be My Team.',
        'Decline property.'
      )
    )
  }

  const handleApprove = async () => {
    try {
      setLoading(true)
      await ApiCalls.answerOfferPending(propertyId, HostPropertyStatusId.active)
      setLoading(false)
      showSuccessDialog('Property approved!')
      closeModal()
    } catch (err: any) {
      showFailureDialog(err?.response?.data?.message)
    }
  }

  return (
    <Modal show={true} onHide={closeModal} size='xl' centered>
      <ModalHeader closeButton>
        <Modal.Title>Property Info</Modal.Title>
      </ModalHeader>
      <Modal.Body className='p-0'>
        <PropertyFormProvider>
          <OfferPendingTabs type='pms' propertyId={propertyId} propertyType='pending' />
        </PropertyFormProvider>
      </Modal.Body>
      <Modal.Footer>
        <strong className='text-info me-4'>
          To be approved,property must have at least one service assigned to it!
        </strong>
        <div className='d-flex justify-content-end'>
          <div>
            <button type='button' className='btn btn-danger me-3' onClick={openDeclineModal}>
              Decline
            </button>
            <button type='button' className='btn btn-success' onClick={handleApprove}>
              Approve
            </button>
          </div>
        </div>
        <DeleteDialog />
      </Modal.Footer>
    </Modal>
  )
}

export default OfferPendingView
