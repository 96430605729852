import { Dispatch, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import HardDeleteUserForm from './HardDeleteUserForm'

const emptyUser = {
    id: 0,
    createdOn: '',
    updatedOn: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    profilePhotoPath: ''
  }

const HardDeleteUser = () => {


    return (
        <>
            <PageTitle>Hard Delete User</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card className='shadow'>
                    <Card.Body>
                        <HardDeleteUserForm isUserLoading={false} user={emptyUser} />
                    </Card.Body>
                </Card>
            </div>
        </>

    )
}

export default HardDeleteUser