import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useQuery} from 'react-query'
import config from '../../../config/config.json'
import {JobStatusType} from '../../../enums/JobEnum'
import ApiCalls from '../../../network/ApiCalls'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {encryptText, getDateFilter, onExporting, toConvertedDate} from '../../../utils/util'

const MissedJobsTable = () => {
  const {data, isLoading, error} = useQuery(
    ['Missed Jobs Get'],
    () => ApiCalls.getMissingJobs(true),
    {cacheTime: 10000}
  )

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue?.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue?.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }
  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDate(cellValue?.startedOn, cellValue.hostPropertyCityTimeZone)
  }
  const calculateIsFinished = (cellValue: any) => {
    return toConvertedDate(cellValue?.finishedOn, cellValue.hostPropertyCityTimeZone)
  }
  const isManuallyCreatedJob = (cellValue: any) => {
    return cellValue?.data.isManuallyCreated ? (
      <p>
        {cellValue.data.hostPropertyAddress}
        <span style={{color: '#ff5d5d'}}> ⓜ</span>
      </p>
    ) : (
      cellValue.data.hostPropertyAddress
    )
  }
  const toJobDetail = (cellValue: any) => {
    if (cellValue?.data?.id) {
      window.open(`/job-details/` + `${encryptText(cellValue?.data?.id)}`)
    }
  }

  if (isLoading) {
    return <SkeletonTableLoading />
  }
  if (error) {
    return <div className='mt-6'>Error</div>
  }

  if (data?.data?.data?.length === 0) {
    return <EmptyTable title='No Pending Jobs' />
  }
  return (
    <div>
      <DataGrid
        id='forceFinish'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data?.data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(45vh - 60px)'}
        className='mt-6'
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onExporting={(e) => onExporting(e, 'Missed Jobs')}
      >
        <Export enabled />
        <SearchPanel visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Pending Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='hostPropertyAddress'
          cellRender={isManuallyCreatedJob}
          minWidth={450}
          caption='Property'
          cssClass='cls'
        />
        <Column
          dataField='scheduledStart'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
        </Column>
        <Column
          dataField='jobStatusTypeName'
          alignment='center'
          caption='Status'
          cellRender={displayStatusType}
          minWidth={150}
          cssClass='cls'
        />
        <Column dataField='vendorName' caption='Cleaner' minWidth={200} cssClass='cls' />

        <Column
          dataField='startedOn'
          caption='started at '
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateStartedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('startedOn')} />
        </Column>
        <Column
          dataField='scheduledFinish'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
        </Column>
        <Column
          dataField='finishedOn'
          caption='finished at'
          dataType='date'
          selectedFilterOperation='between'
          format={config.date_time_format_dev_extreme}
          calculateCellValue={calculateIsFinished}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>
        <Column dataField='hostName' caption='Client Name' minWidth={200} cssClass='cls' />
        <Column dataField='hostPropertyCityName' caption='City' minWidth={150} cssClass='cls' />
        <Column dataField='hostPropertyStateName' caption='State' minWidth={200} cssClass='cls' />
        <Column
          dataField='hostPropertyCountryName'
          caption='Country'
          minWidth={200}
          cssClass='cls'
        />
      </DataGrid>
    </div>
  )
}

export default MissedJobsTable
