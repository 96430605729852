import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Button, Card, Spinner} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {IHostPropertyCheckList} from '../../../../../interfaces/Checklist'
import ApiCalls from '../../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import ChecklistTable from '../../../../checklist/PropertyChecklists/ChecklistTable'
const debounce = require('debounce')

type Props = {
  goToStep?: (step: number) => void
  currentStep?: number
  propertyId: number
}

const ChecklistStep: React.FC<Props> = ({goToStep, currentStep, propertyId}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])
  const [checklistAddModalOpen, setChecklistAddModalOpen] = useState(false)
  const [checklistCloneModalOpen, setChecklistCloneModalOpen] = useState(false)
  const [serviceTypes, setServiceTypes] = useState<any[]>([])

  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery(
    ['Get Checklist By Property Id', propertyId],
    () => ApiCalls.getHostPropertyChecklists(propertyId),
    {refetchOnWindowFocus: false, cacheTime: 50000}
  )
  const {data: propertyDetailData} = useQuery(
    'Get Property by Id',
    () => ApiCalls.getHostPropertyById(propertyId),
    {cacheTime: 50000, refetchOnWindowFocus: false}
  )
  const {data: masterChecklistData} = useQuery(
    ['Get Admin Master Checklist', propertyId],
    () => ApiCalls.getMasterChecklists(),
    {refetchOnWindowFocus: false, enabled: true, cacheTime: 100000}
  )
  const {data: serviceTypeData} = useQuery('Get Property by Id', () => ApiCalls.getServiceTypes(), {
    cacheTime: 50000,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    const list = []
    if (serviceTypeData) {
      for (const service of serviceTypeData?.data?.data) {
        if (service.isActive) {
          var find = list.find((l) => l.id == service.id)
          if (!find) {
            list.push({id: service.id, name: service.name + ' (' + service.serviceGroup.name + ')'})
          }
        }
      }
      setServiceTypes(list)
    }
  }, [serviceTypeData])

  const handleCloseChecklistAddModal = () => {
    setChecklistAddModalOpen(false)
  }
  const handleCloseChecklistCloneModal = () => {
    setChecklistCloneModalOpen(false)
    refetchChecklists()
  }

  const handleAddPropertySuccess = () => {
    setChecklistAddModalOpen(false)
    refetchChecklists()
  }

  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (checklistData) {
      setChecklists(checklistData?.data)
    }
  }, [checklistData])

  return (
    <>
      <PageTitle>Checklist</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Header>
            <Card.Title className='text-danger'>
              You can assign specific checklist items to different rooms and areas of the property.
              Once you've created your checklist, it will be available for your staff to access and
              complete during their jobs.
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {checklistLoading ? (
              <div className='d-flex justify-content-center'>
                <Spinner animation='border' />
              </div>
            ) : checklists.length > 0 ? (
              <ChecklistTable
                data={checklists}
                serviceTypes={serviceTypes}
                refetchChecklists={refetchChecklists}
                refreshPage={refreshPage}
              />
            ) : (
              <div className='d-flex h-100 justify-content-center align-items-center fw-bold'>
                No checklist found for this property
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default ChecklistStep
