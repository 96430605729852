import React, { Dispatch } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AddressAction } from '../../../redux/actionTypes/addressTypes';
import * as addressActionCreators from '../../../redux/actionCreators/addressActionCreators';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import AddressEditForm from '../../../umut-components/Forms/AddressEditForm';

type Props = {
}

const AddressEditDialog: React.FC<Props> = () => {
    const { addressEdit } = useSelector((state: RedusxAppState) => state.address);
    const dispatch = useDispatch<Dispatch<AddressAction>>();

    const handleClose = () => {
        dispatch(addressActionCreators.closeAddressDialog());
    }

    return (
        <Modal show={addressEdit.dialogOpen} onHide={handleClose} size='xl' centered>
            <ModalHeader closeButton>
                <Modal.Title>Address Edit</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <AddressEditForm />
            </Modal.Body>
        </Modal>
    )
}

export default AddressEditDialog;