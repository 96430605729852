import { IForceFinish } from '../../interfaces/DashboardContent'
import * as actions from '../actionTypes/finishRequestTypes'

export interface forceFinishState {
    modalOpen: boolean,
    forceFinishDetail: IForceFinish | null,
    rejectDialog:{
    modalOpen:boolean,
    deleteFn?: () => Promise<void>,
    bodyText: string | null,
    title: string | null
    }
    
}

const initialState: forceFinishState = {
    modalOpen: false,
    forceFinishDetail: null,
    rejectDialog:{
        modalOpen:false,
        deleteFn: undefined,
        bodyText: null,
        title: null
    }
    
}

export default function forceFinishReducer(
    state: forceFinishState = initialState,
    action: actions.ForceFinishActions
) {
    switch (action.type) {
        case actions.FORCEFINISH_OPEN_VIEW:
            return { ...state, modalOpen: true, forceFinishDetail: action.forceFinishDetail }
        case actions.FORCEFINISH_CLOSE_VIEW:
            return { ...state, modalOpen: false, forceFinishDetail: null }
            case actions.OPEN_REJECT_DIALOG:
                return { ...state, rejectDialog:{modalOpen:true, deleteFn: action.deleteFn, bodyText: action.bodyText, title: action.title} }
            case actions.CLOSE_REJECT_DIALOG:
                return { ...state, rejectDialog:{modalOpen: false, deleteFn: undefined, bodyText: null, title: null}  }
        default:
            return state
    }
}