import React, { Dispatch, useRef } from 'react';
import { Column, DataGrid, HeaderFilter, Paging, Scrolling, Selection, FilterRow, Export, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import * as deleteActionCreator from '../../../../../redux/actionCreators/deleteDialogActionCreators';
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
import ApiCalls from '../../../../../network/ApiCalls';
import { useDispatch } from 'react-redux';
import { useDialog } from '../../../../../contexts/DialogContext';
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton';
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable';
import { DeleteActions } from '../../../../../redux/actionTypes/deleteDialogTypes';

type Props = {
    data: any;
    refetch: () => void;
    propertyType: "allowed" | "banned";
}

const renderTooltip = (props: any, description: string) => (
    <Tooltip id="button-tooltip" {...props}>
        {description}
    </Tooltip>
);

const PropertyTable: React.FC<Props> = ({ data, refetch, propertyType }) => {

    const dispatch = useDispatch<Dispatch<DeleteActions>>();
    const { showFailureDialog } = useDialog();
    const tableRef = useRef<DataGrid>(null)

    const renderDeleteButton = (cellValue: any) => {
        const deleteFn = () => {
            dispatch(deleteActionCreator.openDeleteDialog(
                async () => {
                    try {
                        propertyType === "allowed" && await ApiCalls.deletePropertyPermission(cellValue?.data?.id);
                        propertyType === "banned" && await ApiCalls.deleteExcludeVendor(cellValue?.data?.id);
                        refetch();
                        dispatch(deleteActionCreator.closeDeleteDialog());
                    }
                    catch (err: any) {
                        showFailureDialog(err?.response?.data?.message);
                    }
                },
                `Do you want to remove ${propertyType} property ${cellValue?.data?.name} from vendor ?`,
                "Do you want to remove property?"
            ))
        }
        return <DeleteButton iconWidth='16' iconHeight='16' deleteFn={deleteFn} />
    }


    const renderProperty = (cellValue: any) => {
        return (
            propertyType === "allowed" ?
                cellValue?.data?.name
                :
                <OverlayTrigger
                    placement='top'
                    overlay={props => renderTooltip(props, cellValue?.data?.description)}>
                    <p>{cellValue?.data?.name}</p>
                </OverlayTrigger>
        )
    }

    if (data?.length === 0) {
        return <EmptyTable title={propertyType === "allowed" ? "Allowed for all properties" : "No restricted properties"} />
    }

    return (
        <div className="position-relative">
    
         <DataGrid
            id={"propertyTable " + propertyType}
            keyExpr="hostPropertyId"
            onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
            dataSource={data}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            hoverStateEnabled={true}
            height={'calc(45vh - 60px)'}
            allowColumnResizing={true}
            selection={{ mode: 'single' }}
        >
            <Export enabled />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} />
            <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
            <Paging defaultPageSize={50} />
            <Summary>
                <TotalItem cssClass='absolute-right' displayFormat="Total Properties: {0}" column='name' summaryType='count' />
            </Summary>

            <Column dataField="Property" cellRender={renderProperty} minWidth={150} cssClass='cls' />
            <Column dataField="cityName" caption="city" minWidth={150} cssClass='cls' />
            <Column dataField="stateName" caption="state" minWidth={150} cssClass='cls' />
            <Column dataField="countryName" caption="country" minWidth={150} cssClass='cls' />


            <Column cellRender={renderDeleteButton} width={75} />
        </DataGrid>
         
        </div>
       
    )
}

export default PropertyTable;