import React, { useRef } from 'react';
import { Column, DataGrid, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { onExporting, toAmazonUrl } from '../../../../utils/util';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';
import EmptyTable from '../../../../umut-components/Icons/EmptyTable';

type Props = {
    data: any
}

const StaffTable: React.FC<Props> = ({ data }) => {
    const renderProfilePhoto = (cellValue: any) => {
        return (
            <ProfilePhotoTemp profilePhotoPath={cellValue?.data?.profilePhotoPath} firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }
    const tableRef = useRef<DataGrid>(null)
    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + " " + cellValue?.lastName;
    }

    if (data?.length === 0) {
        return <EmptyTable title='No Staff' />
    }

    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id="jobTasks"
                keyExpr="userId"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(55vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Staff")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Staff: {0}" column='firstName' summaryType='count' />
                </Summary>

                <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />

                <Column dataField="firstName" calculateCellValue={calculateFullName} caption='Fullname' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="phoneNumber" caption='Phone' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="email" caption='Email' minWidth={180} dataType='string' cssClass='cls' />
            </DataGrid>

        </div>
    )
}

export default StaffTable;