import {useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import VendorsCreateDialog from './VendorsCreateDialog'
import VendorsTable from './VendorsTable'

const vendors = () => {
  const {isLoading, data, error, refetch} = useQuery(
    ['Get Vendors'],
    () => ApiCalls.getAllVendors(),
    {cacheTime: 50000}
  )

  const [show, setShow] = useState(false)
  const handleCloseModal = () => setShow(false)

  return (
    <>
      <PageTitle>Turnify Vendors</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <VendorsTable refetch={refetch} data={data?.data?.data} />
            )}
          </Card.Body>

          <div className='d-flex justify-content-end p-10'>
            <button
              className='btn btn-success'
              style={{height: '100%'}}
              onClick={() => setShow(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Vendor
            </button>
          </div>
        </Card>
      </div>
      <VendorsCreateDialog show={show} handleClose={handleCloseModal} />
    </>
  )
}

export default vendors
