import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap-v5"
import ModalHeader from "react-bootstrap-v5/lib/esm/ModalHeader"
import { useHistory, useParams } from "react-router";
import { decryptText } from "../../../utils/util";
import StaffDocuments from "./StaffDocuments";

const PendingStaffDocuments = () => {
    let { staffId, vendorId }: any = useParams();
    staffId = decryptText(staffId)
    vendorId = decryptText(vendorId)

    const [documentModalOpen, setDocumentModalOpen] = useState(false)
    const history = useHistory()

    const closeModal = () => {
        setDocumentModalOpen(false);
        history.push({
            pathname: '/pending-staff'
        })
    }
    useEffect(() => {
        setDocumentModalOpen(staffId ? true : false);
    }, [staffId])

    return (
        <>
            <Modal show={documentModalOpen} onHide={closeModal} centered size="lg">
                <ModalHeader closeButton>
                    <Modal.Title>Staff Documents</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <StaffDocuments staffId={staffId} vendorId={vendorId}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PendingStaffDocuments
