import React from 'react';
import { Button, Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useHistory, useParams } from 'react-router-dom';
import ChecklistForm from '../../../umut-components/Forms/MasterChecklistForms/ChecklistForm';
import { decryptText } from '../../../utils/util';

type Props = {
    modalOpen: boolean,
    handleClose: () => void,
    handleAddPropertySuccess: () => void
}

const ChecklistAddDialog: React.FC<Props> = ({ modalOpen, handleClose, handleAddPropertySuccess }) => {

    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Add Checklist</Modal.Title>
            </ModalHeader>
            <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
                <ChecklistForm handleAddPropertySuccess={handleAddPropertySuccess} />
            </Modal.Body>
        </Modal>
    )
}

export default ChecklistAddDialog;