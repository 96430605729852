import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import SelectInput from '../../../Inputs/SelectInput'
import {useQuery} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import {NumberInput} from '../../../Inputs/NumberInput'

type Props = {
  handleClose: () => void
  refetch: () => void
  serviceName: string | undefined
  serviceTypeId: number | undefined
  propertyId: number
}
const AddMarketplaceOfferDialog: React.FC<Props> = ({
  handleClose,
  refetch,
  serviceName,
  serviceTypeId,
  propertyId,
}) => {
  const [checklistList, setChecklistList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [selectedChecklist, setSelectedChecklist] = useState<any>()
  const [selectedVendor, setSelectedVendor] = useState<any>()
  const [vendorRate, setVendorRate] = useState(0)
  const [duration, setDuration] = useState(0)
  const [errMsg, setErrMsg] = useState('')

  const {
    data: checklistData,
    isFetching: checklistLoading,
    error: checklistError,
    refetch: checklistRefetch,
  } = useQuery(
    ['Get Property checklist by service', serviceTypeId, propertyId],
    () => ApiCalls.getHostPropertyChecklists(propertyId, null, serviceTypeId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: serviceTypeId && propertyId ? true : false,
    }
  )

  const {
    data: vendorsData,
    isFetching: vendorsLoading,
    error: vendorsError,
    refetch: vendorsRefetch,
  } = useQuery(
    ['Get available vendors by property', propertyId],
    () => ApiCalls.getAvailableVendors(propertyId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: propertyId ? true : false,
    }
  )

  useEffect(() => {
    if (!checklistLoading && checklistData) {
      if (checklistData?.data && checklistData?.data.length > 0) {
        const list: any = []
        for (const cl of checklistData.data) {
          list.push({label: cl.name.en + ' (' + cl.roleName + ')', value: cl.id})
        }
        setChecklistList(list)
      }
    }
  }, [checklistLoading, checklistData])

  useEffect(() => {
    if (!vendorsLoading && vendorsData) {
      if (vendorsData?.data && vendorsData?.data?.data && vendorsData?.data?.data.length > 0) {
        const list: any = []
        for (const v of vendorsData.data.data) {
          var findVendor = list.find((lf: any) => lf.value == v.id)
          if (findVendor == undefined) {
            list.push({label: v.vendorName, value: v.id})
          }
        }
        setVendorList(list)
      }
    }
  }, [vendorsLoading, vendorsData])

  const onChecklistChange = (v: any) => {
    setSelectedChecklist(v)
  }

  const onVendorChange = (v: any) => {
    setSelectedVendor(v)
  }

  const onVendorRateChange = (v: any) => {
    setVendorRate(v)
  }

  const onDurationChange = (v: any) => {
    setDuration(v)
  }

  const handleAddOffer = async () => {
    setErrMsg('')
    if (vendorRate <= 0) {
      setErrMsg('Please enter vendor rate')
    } else if (duration <= 0) {
      setErrMsg('Please enter duration')
    } else {
      try {
        const data = {
          hostPropertyId: +propertyId,
          serviceTypeId,
          checklistId: selectedChecklist,
          vendorId: selectedVendor,
          offerDuration: duration,
          offerPrice: vendorRate,
        }
        await ApiCalls.addPropertyOffer(data)
        refetch()
        handleClose()
      } catch (error) {
        setErrMsg('An error occur during saving offer')
      }
    }
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add New Offer for {serviceName}</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <form className='form w-100 mt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-lg-12 mb-3'>
              <label className='form-label fs-6 fw-bolder text-dark'>Checklist</label>
              <SelectInput onValueChanged={onChecklistChange} options={checklistList} />
            </div>
            {selectedChecklist && (
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Vendor</label>
                <SelectInput onValueChanged={onVendorChange} options={vendorList} />
              </div>
            )}
            {selectedChecklist && selectedVendor && (
              <>
                <div className='col-12 d-flex flex-column mb-3'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Vendor Rate ($)</label>
                  <NumberInput
                    min={1}
                    max={5000}
                    defaultValue={vendorRate}
                    onValueChange={(e: number) => {
                      onVendorRateChange(e)
                    }}
                  />
                </div>
                <div className='col-12 d-flex flex-column mb-3'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Duration (min.)</label>
                  <NumberInput
                    min={1}
                    max={1000}
                    defaultValue={duration}
                    onValueChange={(e: number) => {
                      onDurationChange(e)
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end'>
        <button className='btn btn-link text-dark me-3' onClick={handleClose}>
          Cancel
        </button>
        <button className='btn btn-primary' onClick={handleAddOffer}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddMarketplaceOfferDialog
