import {
  IJob,
  IJobChecklistPhoto,
  IJobDetail,
  IJobIssue,
  IJobService,
  IJobTask,
  JobTabNames,
} from '../../interfaces/Job'
import {IJobAddForm} from '../../interfaces/Staff'
import {IVirtualInspection} from '../../interfaces/VirtualInspection'
import {ManualJobRequest} from '../../network/PostRequestModels/JobRequests'
import * as actions from '../actionTypes/jobTypes'

export function openJobDialog(): actions.OpenJobDialog {
  return {
    type: actions.OPEN_JOB_DIALOG,
  }
}

export function closeJobDialog(): actions.CloseJobDialog {
  return {
    type: actions.CLOSE_JOB_DIALOG,
  }
}

export function setJobForm(jobForm: IJobAddForm | null): actions.SetJobForm {
  return {
    type: actions.SET_JOB_FORM,
    jobForm: jobForm,
  }
}
export function setResponseJobId(jobId: number): actions.SetResponseJobId {
  return {
    type: actions.RESPONSE_JOB_ID,
    jobId: jobId,
  }
}

export function openServiceAddDialog(
  refetch: () => void,
  services: any
): actions.OpenServiceAddDialog {
  return {
    type: actions.OPEN_SERVICE_ADD_DIALOG,
    services,
    refetch,
  }
}

export function closeServiceAddDialog(): actions.CloseServiceAddDialog {
  return {
    type: actions.CLOSE_SERVICE_ADD_DIALOG,
  }
}

export function openTaskDialog(refetch: () => void, taskData?: IJobTask): actions.OpenTaskDialog {
  return {
    type: actions.OPEN_TASK_DIALOG,
    refetch,
    taskData,
  }
}

export function closeTaskDialog(): actions.CloseTaskDialog {
  return {
    type: actions.CLOSE_TASK_DIALOG,
  }
}

export function openIssueDialog(issue: IJobIssue): actions.OpenIssueMediaDialog {
  return {
    type: actions.OPEN_ISSUE_MEDIA_DIALOG,
    issue,
  }
}

export function closeIssueDialog(): actions.CloseIssueMediaDialog {
  return {
    type: actions.CLOSE_ISSUE_MEDIA_DIALOG,
  }
}

export function openChecklistPhotoDialog(
  checklistPhoto: IJobChecklistPhoto
): actions.OpenChecklistPhotoDialog {
  return {
    type: actions.OPEN_CHECKLIST_PHOTO_DIALOG,
    checklistPhoto,
  }
}

export function closeChecklistPhotoDialog(): actions.CloseChecklistPhotoDialog {
  return {
    type: actions.CLOSE_CHECKLIST_PHOTO_DIALOG,
  }
}

export function openReviewDialog(
  refetch: () => void,
  serviceTypeId: number
): actions.OpenReviewDialog {
  return {
    type: actions.OPEN_REVIEW_DIALOG,
    refetch,
    serviceTypeId,
  }
}

export function closeReviewDialog(): actions.CloseReviewDialog {
  return {
    type: actions.CLOSE_REVIEW_DIALOG,
  }
}

export function openCancelJobDialog(refetch: () => void, job: any): actions.OpenCancelJobDialog {
  return {
    type: actions.OPEN_CANCEL_JOB_DIALOG,
    refetch,
    job,
  }
}

export function closeCancelJobDialog(): actions.CloseCancelJobDialog {
  return {
    type: actions.CLOSE_CANCEL_JOB_DIALOG,
  }
}

export function openVirtualInspectionDialog(): actions.OpenVirtualInspectionDialog {
  return {
    type: actions.OPEN_VIRTUAL_INSPECTION_DIALOG,
  }
}

export function closeVirtualInspectionDialog(): actions.CloseVirtualInspectionDialog {
  return {
    type: actions.CLOSE_VIRTUAL_INSPECTION_DIALOG,
  }
}

export function openInspectionDetail(
  refetch: () => void,
  virtualInspectionId: number
): actions.OpenInspectionDetailDialog {
  return {
    type: actions.OPEN_INSPECTION_DETAIL_DIALOG,
    refetch,
    virtualInspectionId,
  }
}

export function closeInspectionDetail(): actions.CloseInspectionDetailDialog {
  return {
    type: actions.CLOSE_INSPECTION_DETAIL_DIALOG,
  }
}

export function setJobDetail(jobDetail: IJobDetail | null): actions.SetJobDetail {
  return {
    type: actions.SET_JOB_DETAIL,
    jobDetail,
  }
}

export function setSelectedJobTab(selectedJobTab: JobTabNames): actions.SetSelectedJobTab {
  return {
    type: actions.SET_SELECTED_JOB_TAB,
    selectedJobTab,
  }
}

export function setSelectedMyJobTab(selectedMyJobTab: JobTabNames): actions.SetSelectedMyJobTab {
  return {
    type: actions.SET_SELECTED_MY_JOB_TAB,
    selectedMyJobTab,
  }
}

export function setSelectedTurnifyPlusJobTab(
  selectedTurnifyPlusJobTab: JobTabNames
): actions.SetSelectedTurnifyPlusJobTab {
  return {
    type: actions.SET_SELECTED_TURNIFY_PLUS_JOB_TAB,
    selectedTurnifyPlusJobTab,
  }
}

export function setSelectedMarketplaceJobTab(
  selectedMarketplaceJobTab: JobTabNames
): actions.SetSelectedMarketplaceJobTab {
  return {
    type: actions.SET_SELECTED_MARKETPLACE_JOB_TAB,
    selectedMarketplaceJobTab,
  }
}

export function setSelectedMarketplacePlusJobTab(
  selectedMarketplacePlusJobTab: JobTabNames
): actions.SetSelectedMarketplacePlusJobTab {
  return {
    type: actions.SET_SELECTED_MARKETPLACE_PLUS_JOB_TAB,
    selectedMarketplacePlusJobTab,
  }
}

export function setSelectedJobProvider(
  selectedJobProvider: number
): actions.SetSelectedJobProvider {
  return {
    type: actions.SET_SELECTED_JOB_PROVIDER,
    selectedJobProvider,
  }
}
