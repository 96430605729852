import React, { Dispatch } from 'react';
import { Card } from 'react-bootstrap-v5';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../_metronic/layout/core';
import { JobAction } from '../../redux/actionTypes/jobTypes';
import InspectionDetailDialog from '../virtual-inspection/InspectionDetailDialog';
import VirtualInspectionTable from './VirtualInspectionTable';

const VirtualInspectionJobs: React.FC = () => {
    const dispatch = useDispatch<Dispatch<JobAction>>();
    return (
        <>
            <PageTitle>Virtual Inspection Job</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card className="shadow">
                    <Card.Body className="d-flex flex-column">
                        <VirtualInspectionTable />
                    </Card.Body>

                </Card>
            </div>
            <InspectionDetailDialog />
        </>
    )
}

export default VirtualInspectionJobs;