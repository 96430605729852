import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import ApiCalls from '../../../../../network/ApiCalls'
import { useParams } from 'react-router';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import DocumentsTable from './DocumentsTable';
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog';
import { decryptText } from '../../../../../utils/util';

const VendorDocument = () => {
    let { vendorId }: any = useParams()
    vendorId = decryptText(vendorId);
    const { data, isLoading, error, refetch } = useQuery(
        ['Get Documents', vendorId],
        () => ApiCalls.getVendorDocuments(vendorId),
        { refetchOnWindowFocus: false }
    )


    return (
        <>
            <div className='row g-5 g-xl-8'>
                <Card>
                    <Card.Title></Card.Title>
                    <Card.Body>
                        {isLoading ? (
                            <SkeletonTableLoading />
                        ) : (
                            <DocumentsTable refetch={refetch} data={data?.data?.data} />
                        )}
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-end'>
                    </Card.Footer>
                </Card>
            </div>
            <DeleteDialog />
        </>
    )
}

export default VendorDocument
