import {AxiosResponse} from 'axios'
import {Dispatch} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {IService} from '../../interfaces/ServiceType'
import ApiCalls from '../../network/ApiCalls'
import {ServiceDetailAction} from '../../redux/actionTypes/serviceDetailActionType'
import * as serviceDetailActionCreator from '../../redux/actionCreators/serviceDetailActionCreators'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import ServiceReferenceTable from './ServiceReferenceTable'
import ServiceReferenceDialog from './ServiceReferenceDialog'

const Services = () => {
  const {
    data,
    isLoading,
    error,
    refetch: refetchReferences,
  } = useQuery<AxiosResponse<{data: Array<IService>}>>(
    ['Get Service'],
    () => ApiCalls.getServiceTypes(),
    {
      refetchOnWindowFocus: false,
    }
  )
  const showViewDispatch = useDispatch<Dispatch<ServiceDetailAction>>()
  return (
    <>
      <PageTitle>Services</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <ServiceReferenceTable refetchReferences={refetchReferences} data={data?.data.data} />
            )}
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <button
              className='btn btn-success'
              onClick={() => showViewDispatch(serviceDetailActionCreator.openReferenceView(null))}
              style={{height: '100%'}}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Service
            </button>
          </Card.Footer>
        </Card>
      </div>
      <ServiceReferenceDialog refetchReferences={refetchReferences} />
    </>
  )
}
export default Services
