import { Dispatch, useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { PageTitle } from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import AdminEditDialog from './AdminEditDialog'
import AdminCreateDialog from './AdminsCreateDialog'
import AdminsTable from './AdminsTable'

import * as adminActionCreators from '../../redux/actionCreators/adminActionCreators';
import { useDispatch, useSelector } from 'react-redux'
import { AdminAction } from '../../redux/actionTypes/AdminTypes'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import DeleteDialog from '../../umut-components/Modals/DeleteDialog'
import { AccountStatusTypes } from '../../enums/AccountEnum'

const Admins = () => {
    const { isLoading, data, error, refetch } = useQuery(['Get Admins'], () => ApiCalls.getAllAdmins(AccountStatusTypes.ACTIVE), { cacheTime: 50000 })
    const dispatch = useDispatch<Dispatch<AdminAction>>();
    const { adminAddDialog: { modalOpen } } = useSelector((state: RedusxAppState) => state.admin);

    const [show, setShow] = useState(false)
    const handleCloseModal = () => setShow(false)

    const handleOpen = () => {
        dispatch(adminActionCreators.openAddAdminDialog(refetch))
    }

    return (
        <>
            <PageTitle>Admins</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Card className='shadow'>
                    <Card.Body>
                        {isLoading ? <SkeletonTableLoading /> : <AdminsTable refetch={refetch} data={data?.data?.data} />}
                    </Card.Body>

                    <div className='d-flex justify-content-end p-10'>
                        <button
                            className='btn btn-success'
                            style={{ height: '100%' }}
                            onClick={handleOpen}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus'
                                viewBox='0 0 16 16'
                            >
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            Add New Admin
                        </button>
                    </div>
                </Card>
            </div>
            <AdminEditDialog />
            <AdminCreateDialog handleClose={handleCloseModal} modalOpen={modalOpen} />
            <DeleteDialog />
        </>

    )
}

export default Admins