import {AxiosResponse} from 'axios'
import {Dispatch, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import ApiCalls from '../../../../../network/ApiCalls'
import {IVendorServiceRateResponse} from '../../../../../network/NetworkResponses/NetworkResponses'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'
import {decryptText} from '../../../../../utils/util'
import VendorServiceRateTable from './VendorServiceRateTable'

const VendorServiceRates = () => {
  let {vendorId}: any = useParams()
  vendorId = decryptText(vendorId)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const {
    data: serviceRateData,
    isLoading: serviceRateLoading,
    error: serviceRateError,
    refetch,
  } = useQuery<AxiosResponse<IVendorServiceRateResponse>>(
    ['Vendor Offered Services Rates', vendorId],
    () => ApiCalls.getVendorServiceRates(vendorId),
    {
      enabled: vendorId ? true : false,
      cacheTime: 500000,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body className='p-0'>
            <div className='d-flex flex-row flex-wrap'>
              <VendorServiceRateTable data={serviceRateData?.data?.data} refetch={refetch} />
            </div>
          </Card.Body>
        </Card>
      </div>

      <DeleteDialog />
    </>
  )
}

export default VendorServiceRates
