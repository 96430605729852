import {AxiosResponse} from 'axios'
import React, {FC, useEffect, useState} from 'react'
import {Card, Tabs, Tab} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {IBilling} from '../../interfaces/Billing'
import ApiCalls from '../../network/ApiCalls'
import {IBillingResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import EmptyData from '../../umut-components/Icons/EmptyData'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import BillingDetail from './BillingDetail'
import BillingsTable from './BillingsTable'

type TabNames = 'paid' | 'unpaid'

const Billings: FC = () => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {
    data: billingData,
    isLoading: billingLoading,
    error: billingError,
  } = useQuery<AxiosResponse<IBillingResponse>>(['Get Billings'], () => ApiCalls.getAllBillings(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })
  const [selectedTab, setSelectedTab] = useState<TabNames>('unpaid')
  const [billings, setBillings] = useState<IBilling[]>([])

  const getFilteredBillings = (data: IBilling[], type: string) => {
    var newType = type.charAt(0).toUpperCase() + type.slice(1)
    var filtered = data.filter((d) => d.hostInvoiceStatusTypeName == newType)
    setBillings(filtered)
  }

  useEffect(() => {
    if (!billingLoading) {
      if (billingData && billingData?.data?.data?.length > 0) {
        getFilteredBillings(billingData?.data?.data, 'unpaid')
      }
    }
  }, [billingLoading])

  const handleChangeTab = (key: any) => {
    if (billingData && billingData?.data?.data?.length > 0) {
      getFilteredBillings(billingData?.data?.data, key)
    }
    setSelectedTab(key)
  }

  return (
    <>
      <PageTitle>Billings</PageTitle>
      <Card>
        <Card.Body>
          {billingLoading ? (
            <SkeletonTableLoading />
          ) : billings.length > 0 ? (
            <Tabs
              defaultActiveKey='unpaid'
              id='unpaid-tab'
              className='mb-3'
              onSelect={handleChangeTab}
            >
              <Tab eventKey='unpaid' title='Unpaid'>
                {selectedTab == 'unpaid' && <BillingsTable data={billings} type={'unpaid'} />}
              </Tab>
              <Tab eventKey='paid' title='Paid'>
                {selectedTab == 'paid' && <BillingsTable data={billings} type={'paid'} />}
              </Tab>
            </Tabs>
          ) : (
            <EmptyData emptyText='No Billing Found' />
          )}
        </Card.Body>
      </Card>
      <BillingDetail />
    </>
  )
}

export default Billings
