import {IService} from '../../interfaces/ServiceType'
import * as actions from '../actionTypes/serviceDetailActionType'

export function openReferenceView(
    service:IService | null
): actions.OpenReferenceViewAction{
    return {
        type:actions.REFERENCE_OPEN_VIEW,
        service
    }
}
export function closeReferenceView(): actions.CloseReferenceViewAction {
    return {
      type: actions.REFERENCE_CLOSE_VIEW,
    }
  }
  export function openDocumentView(documentTypes: any): actions.OpenDocumentViewAction {
    return {
      type: actions.DOCUMENT_OPEN_VIEW,
      documentTypes,
    }
  }
  export function closeDocumentView(): actions.CloseDocumentViewAction {
    return {
      type: actions.DOCUMENT_CLOSE_VIEW,
    }
  }
  