import { FC } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap-v5'

type Props = {
  show: boolean
  save: () => void
  cancelAdd: (index:number) => void,
  added: Array<any>,
  loading: boolean,
  close: () => void
}
const ServiceCitiesModal: FC<Props> = ({ show, close, save, cancelAdd, added, loading }) => {

  if(added.length == 0){
    close()
  }

  return (

    <>
      <Modal show={show} onHide={close} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Service Cities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead className="bg-secondary rounded">
              <tr className="text-left text-uppercase">
                <th style={{ minWidth: "30px", fontWeight: "bold" }}></th>
                <th style={{ minWidth: "150px", fontWeight: "bold" }} className="pl-7">
                  <span className="text-dark-100 ms-3">City Name</span>
                </th>
                <th style={{ minWidth: "100px", fontWeight: "bold" }}>State Name</th>
                <th style={{ minWidth: "100px", fontWeight: "bold" }}>Zipcode</th>

              </tr>
            </thead>
            <tbody className="">
              {added.map((item: any, idx: number) => (
                <tr key={idx}>
                  <td className="d-flex justify-content-center mS-5">
                    <button onClick={()=> cancelAdd(idx)} style={{ background: "transparent", border: "0px" }}><i className="bi bi-dash-circle-fill text-danger"></i></button>
                  </td>
                  <td >
                    <span className="text-muted font-weight-bold d-block ms-3">{item.cityName}</span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.stateName}</span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg ms-3">{item.zipCode}</span>
                  </td>

                </tr>
              ))}
            </tbody>

          </table>

        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={close}>
            Close
          </Button>
          <Button variant='primary' disabled={loading} onClick={save}>Save changes {loading ? <Spinner animation='border' size='sm' /> : null}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ServiceCitiesModal
