import React from 'react'
import {useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  data: any
  modalSuccess: 'success' | 'danger' | null
  extraHtml?: any
}

const DisplayModal: React.FC<Props> = ({data, handleClose, modalOpen, modalSuccess, extraHtml}) => {
  return (
    <Modal show={modalOpen} onHide={handleClose} centered>
      <ModalHeader closeButton>
        <Modal.Title>{data?.title}</Modal.Title>
      </ModalHeader>
      <Modal.Body className='d-flex justify-content-center align-items-center flex-column'>
        {data?.image && !modalSuccess ? (
          <img style={{width: '100%'}} alt='modal' src={data?.image} />
        ) : modalSuccess != null ? (
          <img
            style={{width: '10%', marginBottom: 20}}
            alt='modal'
            src={toAbsoluteUrl(`/media/icons/${modalSuccess}.png`)}
          />
        ) : (
          ''
        )}
        <strong className={modalSuccess ? 'text-' + modalSuccess : ''} style={{fontSize: 20}}>
          {data?.text}
        </strong>
        {extraHtml && <span className='mt-5'>{extraHtml}</span>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DisplayModal
