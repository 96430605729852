import {AxiosResponse} from 'axios'
import {Dispatch} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import {IService, IServiceGroup} from '../../interfaces/ServiceType'
import ApiCalls from '../../network/ApiCalls'
import {ServiceGroupDetailAction} from '../../redux/actionTypes/serviceGroupDetailActionType'
import * as serviceGroupDetailActionCreator from '../../redux/actionCreators/serviceGroupDetailActionCreators'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import ServiceGroupsTable from './ServiceGroupsTable'
import ServiceGroupDialog from './ServiceGroupDialog'

const ServiceGroups = () => {
  const {
    data,
    isLoading,
    error,
    refetch: refetchGroups,
  } = useQuery<AxiosResponse<{data: Array<IServiceGroup>}>>(
    ['Get Service Groups'],
    () => ApiCalls.getServiceGroups(),
    {
      refetchOnWindowFocus: false,
    }
  )
  const showViewDispatch = useDispatch<Dispatch<ServiceGroupDetailAction>>()
  return (
    <>
      <PageTitle>Service Groups</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <ServiceGroupsTable refetchGroups={refetchGroups} data={data?.data.data} />
            )}
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <button
              className='btn btn-success'
              onClick={() =>
                showViewDispatch(serviceGroupDetailActionCreator.openServiceGroupView(null))
              }
              style={{height: '100%'}}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
              </svg>
              Add New Service Group
            </button>
          </Card.Footer>
        </Card>
      </div>
      <ServiceGroupDialog refetchGroups={refetchGroups} />
    </>
  )
}
export default ServiceGroups
