import {
    Column,
    DataGrid,
    Export,
    HeaderFilter,
    Paging,
    Scrolling,
    SearchPanel,
    Summary,
    TotalItem,
} from 'devextreme-react/data-grid'
import { Dispatch, useRef } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { JobStatusObj, JobStatusType } from '../../enums/JobEnum'
import { VirtualInspectionType , VirtualInspectionObj} from '../../enums/VirtualInspectionEnum'
import ApiCalls from '../../network/ApiCalls'
import { openInspectionDetail } from '../../redux/actionCreators/jobActionCreators'
import { JobAction } from '../../redux/actionTypes/jobTypes'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import {
    encryptText,
    convertUTCDateToLocalDate,
    onExporting,
    decryptText,
    toConvertedDate,
} from '../../utils/util'

const VirtualInspectionTable = () => {
    const dispatch = useDispatch<Dispatch<JobAction>>()
    const history = useHistory()
    let { jobId }: any = useParams()
    jobId = decryptText(jobId)

    const tableRef = useRef<DataGrid>(null)
    const { data, isLoading, error, refetch } = useQuery(
        ['Get Virtual Inspections', jobId],
        () => ApiCalls.getVirtualInspections(jobId),
        { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false }
    )

    const displayStatusType = (cellData: any) => {
        const jobStatusType = cellData?.data?.jobVirtualInspectionStatusTypeName
        return (
            <strong
                className={`text-${jobStatusType === 'Approved'
                        ? 'success'
                        : jobStatusType === JobStatusType.PENDING
                            ? 'warning'
                            : 'danger'
                    }`}
            >
                {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
            </strong>
        )
    }

    const toJobDetail = (cellData: any) => {
        if (cellData?.column?.name !== 'inspect-button' && cellData?.data?.jobId)
            // history.push({
            //     pathname: `/job-details/` + encryptText(cellData?.data?.jobId)
            // })
            window.open(`/job-details/` + encryptText(cellData?.data?.jobId))
    }

    const displayVirtualInspectionButton = (cellData: any) => {
        return (
            <button
                className='btn btn-sm bg-info text-white'
                onClick={() => {
                    dispatch(openInspectionDetail(refetch, cellData?.data?.id))
                }}
            >
                Inspect
            </button>
        )
    }
    const isManuallyCreatedJob = (cellValue: any) => {

        return cellValue?.data.isManuallyCreated ? (
            
                <p>
                {cellValue.data.hostPropertyAddress}
                <span style={{ color: '#ff5d5d' }}> ⓜ</span>
            </p>
        ) : (
            cellValue.data.hostPropertyAddress
        )
    }

    const calculateScheduledStart = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
    }

    const calculateScheduledFinish = (cellValue: any) => {
        return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
    }
    const calculateFullName = (cellValue: any) => {
        return cellValue?.cancelUser?.firstName + ' ' + cellValue?.cancelUser?.lastName
    }
    const calculateDescription = (cellValue: any) => {
        return (
            cellValue?.questionDescription?.en ??
            cellValue?.questionDescription[Object.keys(cellValue?.questionDescription)[0]]
        )
    }

    if (isLoading) {
        return (
            <div className='mt-6'>
                <SkeletonTableLoading />
            </div>
        )
    }

    if (error) {
        return <div className='mt-6'>Error</div>
    }

    if (data?.data?.data?.length === 0) {
        return <EmptyTable title='No Pending Inspection Photos' />
    }
     
    if(data?.data?.data?.length){
        data!.data.data = data?.data?.data.filter((x:any) => x.jobVirtualInspectionStatusTypeId != 1);
    }

    return (
        < div className='position-relatibe'>
            <div style={{ zIndex: 1 }} className='position-absolute top-15'>
                <span className='badge badge-primary badge me-3'>{jobId}</span>
                <span className='badge badge-info badge me-3'>
                    {data?.data?.data?.[0]?.hostPropertyName}
                </span>
                {data?.data?.data?.[0]?.scheduledStart ? (
                    <span className='badge badge-success me-3'>{data?.data?.data?.[0]?.scheduledStart}</span>
                ) : (
                    <span className='badge badge-success me-3'>
                        {data?.data?.data?.[0]?.cleaningPeriodStart}
                    </span>
                )}

                {data?.data?.scheduledFinish ? (
                    <span className='badge badge-danger'>{data?.data?.data?.[0]?.scheduledFinish}</span>
                ) : (
                    <span className='badge badge-danger'>{data?.data?.data?.[0]?.cleaningPeriodEnd}</span>
                )}
            </div>
            
            <DataGrid
                id={'NotCompletedJobTable'}
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data?.data?.data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                hoverStateEnabled={true}
                height={'calc(65vh - 60px)'}
                onCellClick={toJobDetail}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                className='mt-6'
                onExporting={(e) => onExporting(e, 'Cancelled Jobs')}
            >
                <Export enabled />
                <SearchPanel visible={true} />
                <HeaderFilter visible={true} />
                <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem
                        cssClass='absolute-right'
                        displayFormat='Total Checklist Items: {0}'
                        column='hostPropertyAddress'
                        summaryType='count'
                    />
                </Summary>
                
                <Column
                    dataField='hostPropertyAddress'
                    cellRender={isManuallyCreatedJob}
                    minWidth={450}
                    caption='Property'
                    cssClass='cls'
                />
                <Column
                    dataField='questionDescription.en'
                    calculateCellValue={calculateDescription}
                    caption='Description'
                    minWidth={200}
                    cssClass='cls'
                />
                <Column
                    dataField='jobVirtualInspectionStatusTypeName'
                    alignment='center'
                    caption='Inspection Status'
                    cellRender={displayStatusType}
                    minWidth={150}
                    cssClass='cls'
                />

                <Column
                    width={110}
                    name='inspect-button'
                    alignment='center'
                    cellRender={displayVirtualInspectionButton}
                    cssClass='cls'
                />
            </DataGrid>
        </div>
    )
}

export default VirtualInspectionTable
