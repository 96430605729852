import { FC, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap-v5"
import ApiCalls from "../../../network/ApiCalls"
import { IEditStatus } from './../../../network/PostRequestModels/EditStatus';

type Props = {
    show: Boolean,
    handleClose: () => void,
    refetch: () => void,
    data: any
}

const PendingCheckDialog: FC<Props> = ({ show, handleClose, data, refetch }) => {
    const [loading, setLoading] = useState(false)

    const handleConfirmation = async () => {
        try {
            if (data) {
                setLoading(true)
                await ApiCalls.putVendorEditStatus({ id: data.data.id, vendorStatusTypeId: data.statusId },data.data.id )
                setLoading(false)
                refetch()
                handleClose()
            }
        } catch (error) {
            setLoading(false)
            handleClose();
            console.error(error)
        }
    }
    const handleCloseModal = () => {
        handleClose()
    }
    return (
        <Modal show={show} onHide={handleClose} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Vendor confirmation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                Are you sure you want to approve the vendor?
            </Modal.Body>

            <Modal.Footer>
                <button type='button' className='btn btn-light' onClick={handleCloseModal} >
                    Cancel
                </button>
                <Button className="btn btn-success" disabled={loading} onClick={handleConfirmation}>Approve {loading ? <Spinner animation='border' size='sm' /> : null}</Button>

            </Modal.Footer>
        </Modal>
    )
}

export default PendingCheckDialog
