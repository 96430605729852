import React, { Dispatch, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { JobStatusObj } from '../../../../enums/JobEnum';
import { IJobDetail } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading';
import { decryptText } from '../../../../utils/util';
import AssingmentModal from './AssingmentModal';
import StaffTable from './StaffTable';

type Props = {
    jobEventsActive: boolean
    jobDetail: IJobDetail
}

const StaffTab: React.FC<Props> = ({ jobEventsActive, jobDetail }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const { data, isLoading, error, refetch } = useQuery(['Get Job Staffs By Job Id', jobId], () => ApiCalls.getJobStaffs(jobId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false });
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const handleCloseModal = () => {
        setModalOpen(false)
    }
    if (isLoading) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <StaffTable data={data?.data?.data} />
            <div className="d-flex justify-content-end w-100">
                {jobDetail?.vendorId && jobDetail.jobStatusTypeId !== JobStatusObj['Completed'] && jobDetail.jobStatusTypeId !== JobStatusObj['Started'] && jobDetail.jobStatusTypeId !== JobStatusObj['Cancelled'] && <button type="button" onClick={() => setModalOpen(true)} className="btn btn-success">Assignment</button>}
            </div>

            
            <AssingmentModal modalOpen={modalOpen} refetch={refetch} closeModal={handleCloseModal} jobDetail={jobDetail && jobDetail} />
        </>
    )
}

export default StaffTab;