import React, { useState, FC } from 'react'
import { Dispatch, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {isNotEmpty, toAbsoluteUrl} from '../../../_metronic/helpers';
import * as Yup from 'yup';
import { IUserDetail } from '../../interfaces/User';
import {useFormik} from 'formik'
import clsx from 'clsx'
import ApiCalls from '../../network/ApiCalls';
import { Alert } from 'react-bootstrap-v5';
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp';
import { toAmazonUrl } from '../../utils/util';
import { Button, Card, Spinner, Modal } from 'react-bootstrap-v5'


const editUserSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required')
})

type Props = {
    isUserLoading: boolean
    user: IUserDetail
}

const defaultUserInfo = {
    hasError: false,
    canDelete: false,
    type: '',
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    profilePhotoPath: ''
}

const HardDeleteUserForm: FC<Props> = ({user, isUserLoading}) => {
    const [userForEdit] = useState<IUserDetail>({
        ...user,
        email: user.email || "",
    });
    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState("An error occurred during the operation. Please try again later.");
    const [foundedUser, setFoundedUser] = useState(defaultUserInfo);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const findUser = (user: any) => {
        setFoundedUser(defaultUserInfo);
        setHasError(false);
        setHasSuccess(false);
        setDeleting(false);
        setShowPromptModal(false);
        setErrMsg("An error occurred during the operation. Please try again later.");
        ApiCalls.findUserByEmail(user.email).then((d) => {
            if(d.status == 200) {
                if(d.data.hasError) {
                    setErrMsg("User does not exist or has been deleted!");
                    setHasError(true);    
                } else {
                    setFoundedUser(d.data);
                }
            } else {
                setHasError(true);
            }
        }).catch(() => {
            setHasError(true);
        })
    }

    const deleteUser = () => {
        setDeleting(true);
        setHasError(false);
        setHasSuccess(false);
        setErrMsg("An error occurred during the operation. Please try again later.");
        ApiCalls.hardDeleteUser(foundedUser?.userId).then((d) => {
            setDeleting(false);
            setShowPromptModal(false);
            if(d.status == 200) {
                if(d.data.success) {
                    setHasSuccess(true);
                    setFoundedUser(defaultUserInfo);
                } else {
                    setHasError(true);     
                }
            } else {
                setHasError(true); 
            }
        }).catch(() => {
            setShowPromptModal(false);
            setDeleting(false);
            setHasError(true);
        })
    }

    const hidePromptModal = () => {
        setShowPromptModal(false);
    }
    
    const formik = useFormik({
        initialValues: userForEdit,
        validationSchema: editUserSchema,
        onSubmit: async (values, {setSubmitting}) => {
          setSubmitting(true)
          try {
            findUser(values);
          } catch (ex) {
            console.error(ex)
          }
        },
    });

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
                >
                <div className="fv-row mb-7">
                    <h3>
                    In this area you can delete user accounts <span style={{ color: 'red' }}>irreversibly</span>. In order to delete the account, <span style={{ color: 'red' }}>the user must not have a registered home or a completed job record</span>.
                    </h3>
                </div>
                {hasError && 
                <Alert key={"danger"} variant={"danger"}>
                    {errMsg}
                </Alert>
                }
                {hasSuccess && 
                <Alert key={"success"} variant={"success"}>
                    The user has been successfully deleted!
                </Alert>
                }
                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email</label>
                    <input
                    placeholder='Type user email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                        'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                    )}
                </div>

                <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                    <span className='indicator-label'>Find User</span>
                    {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )}
                </button>
                </div>
            </form>
            {foundedUser.email != '' && !hasError && 
            <>
            <div style={{ padding: 5, marginTop: 60,
                width: '100%' }}>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex justify-content-start align-items-center gap-5">
                        {foundedUser?.profilePhotoPath ? <img alt='pp' style={{ width: 55, height: 55, borderRadius: '50%' }} src={foundedUser?.profilePhotoPath ? toAmazonUrl(foundedUser?.profilePhotoPath) : toAbsoluteUrl('/media/icons/noImage.png')} />
                            : <ProfilePhotoTemp firstName={foundedUser?.firstName} lastName={foundedUser?.lastName} width={45} height={45} borderRadius='50%' />}
                        <div className="w-175px">
                            <div className="text-dark">
                                <strong>{foundedUser?.firstName}</strong>&nbsp;
                                <strong>{foundedUser?.lastName}</strong>
                            </div>
                            <span className="text-muted">{foundedUser?.type == 'system' ? 'System User' : foundedUser?.type == 'vendor' ? 'Vendor User' : foundedUser?.type == 'host' ? 'Host User' : 'No Role'}</span>
                        </div>

                        <div className="d-flex align-items-center w-250px d-none d-lg-block">
                            <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                </g>
                            </svg>
                            </span>
                            <a href={`mailto:${foundedUser?.email}`} className="fs-6 text-muted ms-2">{foundedUser?.email}</a>
                        </div>
                        <div className="d-flex w-175px align-items-center d-none d-xl-block">
                            <span className="svg-icon svg-icon-4 me-2 text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>
                            </span>
                            <a href={`tel:${foundedUser?.phoneNumber}`} className="fs-6 text-muted">{foundedUser?.phoneNumber}</a>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button onClick={() => {setShowPromptModal(true)}} variant={"danger"} className="btn-sm h-30px">Hard Delete User</Button>
                    </div>
                </div>
            </div>
            <Modal show={showPromptModal} onHide={hidePromptModal} centered>
                <Modal.Body>
                    {foundedUser?.canDelete && 
                    <div>
                        <h5>You are about to delete the user named "{foundedUser?.firstName+' '+foundedUser.lastName}". You cannot undo this action. Do you want to continue processing?</h5>
                    </div>
                    }
                    {!foundedUser?.canDelete && 
                    <div>
                        <h5>You cannot delete the user named "{foundedUser?.firstName+' '+foundedUser.lastName}"! The user is a "{foundedUser?.type == 'system' ? 'System User' : foundedUser?.type == 'vendor' ? 'Vendor User' : foundedUser?.type == 'host' ? 'Host User' : 'No Role'}" user and has completed records.</h5>
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary me-3" onClick={hidePromptModal} >
                        Cancel
                    </button>
                    {foundedUser?.canDelete && 
                    <button disabled={deleting} className="btn btn-danger" onClick={deleteUser} >
                        Delete User
                    </button>}
                </Modal.Footer>
            </Modal>
            </>
            }
        </>
    )
}
export default React.memo(HardDeleteUserForm)
