import {
    Column,
    DataGrid, Export, HeaderFilter,
    Paging,
    Scrolling, SearchPanel,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid'
import { FC, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IGetAllVendors } from '../../../interfaces/Vendors'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import { encryptText, onExporting } from '../../../utils/util'
import DeclineCheckDialog from './DeclineCheckDialog'
import PendingCheckDialog from './PendingCheckDialog'

type Props = {
    data: IGetAllVendors[],
    refetch: () => void
}
const VendorStatusTable: FC<Props> = ({ data, refetch }) => {
    const history = useHistory()
    const [show, setShow] = useState(false)
    const [showDecline, setShowDecline] = useState(false)

    const tableRef = useRef<DataGrid>(null)
    const [approvaledData, setApprovaledData] = useState<any>({})
    const [declineData, setDeclineData] = useState<any>({})

    const toStaffProfile = (cellData: any) => {
        history.push({
            pathname: '/vendor-details/' + encryptText(cellData?.data?.id)
        })
    }

    const closeModal = () => {
        setShow(false)
        setShowDecline(false)
        refetch()
    }

    const renderCheckButton = (cellData: any) => {
        return (
            <button className="btn btn-success" onClick={() => { setApprovaledData({ data: cellData?.data, statusId: 2 }); setShow(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                </svg>
            </button>
        )
    }
    // const renderWebsiteLink = (cellData: any) => {
    //     return <a href={cellData?.data?.website?.startsWith("http") ? `${cellData?.data?.website}` : `https://${cellData?.data?.website}`} target="_blank">{cellData?.data?.website}</a>
    // }
    const renderDeclineButton = (cellData: any) => {
        return (
            <button className="btn btn-danger" onClick={() => { setDeclineData({ data: cellData?.data, statusId: 5 }); setShowDecline(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
            </button>
        )
    }

    if (data?.length === 0) {
        return <EmptyTable title='No Pending Vendors' />
    }

    return (
        <div className="posiiton-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id='pendingVendors'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                ref={tableRef}
                
                hoverStateEnabled={true}
                height={'calc(54vh - 60px)'}
                allowColumnResizing={true}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Pending Vendors")}
                onCellDblClick={toStaffProfile}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Vendors: {0}" column='name' summaryType='count' />
                </Summary>

                <Column dataField='name' caption="Company Name" dataType='string' minWidth={200} cssClass='cls' />
                {/* <Column dataField='vendorScore' dataType='string' alignment='center' width={300} cssClass='cls' /> */}
                <Column dataField='phoneNumber' dataType='string' width={180} cssClass='cls' />
                <Column dataField='vendorInformationStatusTypeName' caption="Documents Status" dataType='string' width={170} cssClass='cls' />
                <Column dataField='vendorTypeName' caption="PRO Type" dataType='string' width={180} cssClass='cls' />
                <Column dataField='dispatchStrategyTypeName' dataType='string' width={180} cssClass='cls' />
                <Column dataField='stateName' dataType='string' width={180} cssClass='cls' />
                <Column dataField='cityName' dataType='string' width={180} cssClass='cls' />
                <Column dataField='countryName' dataType='string' width={180} cssClass='cls' />
                <Column width={100} cellRender={renderCheckButton} caption="Approve" cssClass='cls' />
                <Column width={100} cellRender={renderDeclineButton} caption="Decline" cssClass='cls' />
            </DataGrid>

            <PendingCheckDialog show={show} handleClose={closeModal} data={approvaledData} refetch={refetch} />
            <DeclineCheckDialog show={showDecline} handleClose={closeModal} data={declineData} refetch={refetch} />

        </div>
    )
}


export default VendorStatusTable