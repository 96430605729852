import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
} from 'devextreme-react/data-grid'
import { FC, useRef } from 'react'
import { IVendorReference } from '../../../../../interfaces/Reference'
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable'
import { onExporting } from '../../../../../utils/util'


type Props = {
  data: IVendorReference[]
  refetchReferences: () => void
}
const ReferenceTable: FC<Props> = ({ data }) => {
  
const tableRef = useRef<DataGrid>(null)
 

  if (data?.length === 0) {
    return <EmptyTable title="No Vendor Reference" />
  }
  return (
    <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
         Clear Filter
        </span>
      </div>

      <DataGrid
        id='referenceTable'
        keyExpr='name'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(60vh - 60px)'}
        allowColumnResizing={true}
        selection={{ mode: 'single' }}
        onExporting={(e) => onExporting(e, "Vendor References")}
      >
        <Export enabled={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' />
        <Paging enabled={false} />

        <Column dataField='name' minWidth={350} cssClass='cls' />
        <Column
          dataField='email'
          alignment='center'
          caption='Email'
          minWidth={150}
          cssClass='cls'
        />
        <Column dataField='phone' alignment='center' caption='Phone' minWidth={80} cssClass='cls' />

      </DataGrid>
      
      
    </div>
  )
}

export default ReferenceTable
