import {AxiosResponse} from 'axios'
import {Dispatch, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {IVendorService} from '../../../../../interfaces/Vendors'
import ApiCalls from '../../../../../network/ApiCalls'
import {
  GetOffered,
  IVendorServiceListResponse,
  IVendorServiceResponse,
} from '../../../../../network/NetworkResponses/NetworkResponses'
import {OpenVendorOfferDialog} from '../../../../../redux/actionCreators/vendorActionCreators'
import {VendorAction} from '../../../../../redux/actionTypes/vendorTypes'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'
import {decryptText} from '../../../../../utils/util'
import VendorEditOfferedDialog from './VendorEditOfferedDialog'
import VendorOfferedDialog from './VendorOfferedDialog'
import VendorOfferedTable from './VendorOfferedTable'
import VendorServiceItem from './VendorServiceItem'

const VendorOffered = () => {
  let {vendorId}: any = useParams()
  vendorId = decryptText(vendorId)
  const {
    data: offeredServiceData,
    isLoading: servicesLoading,
    error: servicesError,
    refetch,
  } = useQuery<AxiosResponse<IVendorServiceListResponse>>(
    ['Vendor Offered Services List', vendorId],
    () => ApiCalls.getVendorOfferedServices(vendorId),
    {
      enabled: vendorId ? true : false,
      cacheTime: 500000,
      refetchOnWindowFocus: false,
    }
  )
  const {vendorOfferDialog, vendorEditOfferDialog} = useSelector(
    (state: RedusxAppState) => state.vendor
  )
  const dispatch = useDispatch<Dispatch<VendorAction>>()

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body className='p-0'>
            <div className='d-flex flex-row flex-wrap'>
              {offeredServiceData?.data?.data.map((service) => (
                <VendorServiceItem vendorService={service} refetch={refetch} />
              ))}
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-success mt-6'
                onClick={() => {
                  dispatch(OpenVendorOfferDialog(refetch))
                }}
              >
                Add Service
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
      {vendorOfferDialog.modalOpen && <VendorOfferedDialog />}
      {vendorEditOfferDialog.modalOpen && <VendorEditOfferedDialog />}
      <DeleteDialog />
    </>
  )
}

export default VendorOffered
