import React, {useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  FilterRow,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  toConvertedDate,
  setDxFilterPlaceholder,
  toConvertedDateTz,
} from '../../utils/util'
import {JobStatusType} from '../../enums/JobEnum'
import {IJob} from '../../interfaces/Job'
import ViewButton from '../../umut-components/Buttons/ViewButton'
import {useHistory} from 'react-router'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import {Link, useParams} from 'react-router-dom'
import config from '../../config/config.json'
import {height} from '@material-ui/system'
import dayjs from 'dayjs'

type Props = {
  data: IJob[]
  type: string
}

const LiveTable: React.FC<Props> = ({data, type}) => {
  let {providerName}: any = useParams()
  const tableRef = useRef<DataGrid>(null)

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    const isForceFinish = cellData?.data?.isForceFinish

    return (
      <span
        style={{
          color:
            jobStatusType === JobStatusType.COMPLETED
              ? '#a7b084'
              : jobStatusType === JobStatusType.PENDING
              ? '#d24f43'
              : jobStatusType === JobStatusType.SCHEDULED
              ? '#f8cc75'
              : jobStatusType === JobStatusType.STARTED
              ? '#8fb021'
              : 'danger',
        }}
      >
        {jobStatusType === 'Started'
          ? 'In Progress'
          : jobStatusType == 'Completed' && isForceFinish
          ? 'Completed (FF)'
          : jobStatusType}
      </span>
    )
  }

  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDateTz(cellValue?.startedOn, cellValue?.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDateTz(cellValue?.finishedOn, cellValue?.hostPropertyCityTimeZone)
  }

  const calculateNextCheckIn = (cellValue: any) => {
    return cellValue?.nextCheckIn
      ? toConvertedDate(cellValue?.nextCheckIn, cellValue?.hostPropertyCityTimeZone)
      : '--'
  }
  const isManuallyCreatedJob = (cellValue: any) => {
    return cellValue?.data.isManuallyCreated ? (
      <p>
        {cellValue.data.hostPropertyAddress}
        <span style={{color: '#ff5d5d'}}> ⓜ</span>
      </p>
    ) : (
      cellValue.data.hostPropertyAddress
    )
  }

  const renderCheckIn = (cellValue: any) => {
    if (!cellValue?.data?.nextCheckIn) return '--'

    const checkInDate = dayjs(
      toConvertedDate(cellValue?.data?.nextCheckIn, cellValue?.data?.hostPropertyCityTimeZone)
    )
    const isToday = dayjs().get('date') === checkInDate.get('date')

    if (isToday) {
      return <strong className='text-danger'>Today</strong>
    } else {
      return <strong className='text-success'>Future</strong>
    }

    return (
      <span>
        {dayjs(
          toConvertedDate(cellValue?.data?.nextCheckIn, cellValue?.data?.hostPropertyCityTimeZone)
        ).format(config.time_format)}
      </span>
    )
  }

  const toPropertyDetail = (cellData: any) => {
    if (cellData?.column?.name !== 'delete-button' && cellData?.data?.id)
      // history.push({
      //     //pathname: `/job-details/` + encryptText(cellData?.data?.id)
      // })
      window.open(`/job-details/` + encryptText(cellData?.data?.id))
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Jobs' />
  }

  const renderProvider = (cellValue: any) => {
    if (cellValue?.cleaningProviderId == 1) {
      return 'My Team'
    }
    if (cellValue?.cleaningProviderId == 3) {
      return 'Turnify+'
    }
    if (cellValue?.cleaningProviderId == 2) {
      if (cellValue?.isMarketplace) {
        if (cellValue?.hasMpManagement) {
          return 'MP+'
        } else {
          return 'MP'
        }
      } else {
        return 'PRO'
      }
    }
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>

      <DataGrid
        id='jobTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(75vh)'}
        allowColumnResizing={true}
        onCellClick={toPropertyDetail}
        selection={{mode: 'single'}}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Live')}
      >
        <Export enabled />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Jobs: {0}'
            column='hostPropertyAddress'
            summaryType='count'
          />
        </Summary>
        <Column
          dataField='hostPropertyAddress'
          cellRender={isManuallyCreatedJob}
          minWidth={450}
          caption='Property'
          cssClass='cls'
        />
        <Column dataField='services' minWidth={250} caption='Service' cssClass='cls' />

        <Column
          dataField='scheduledStart'
          dataType='time'
          selectedFilterOperation='between'
          caption='Sch.Start'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateScheduledStart}
          minWidth={150}
          cssClass='cls'
        ></Column>

        <Column
          dataField='jobStatusTypeName'
          cellRender={displayStatusType}
          caption='Status'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          visible={type === 'turnify-managed'}
          dataField='vendorName'
          caption='PRO'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='jobStaff'
          caption='Staff'
          minWidth={250}
          cssClass='cls'
          alignment='center'
        />
        <Column
          dataField='startOn'
          caption='Started at'
          dataType='time'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateStartedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('startOn')} />
        </Column>
        <Column
          dataField='scheduledFinish'
          dataType='date'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateScheduledFinish}
          minWidth={150}
          cssClass='cls'
        ></Column>
        {/* <Column dataField="startOn" dataType='date' selectedFilterOperation='between' format={config.time_format_dev_extreme} calculateCellValue={calculateStartedOn} minWidth={150} cssClass='cls'  >
                    <HeaderFilter dataSource={getDateFilter('startOn')} />
                </Column> */}
        <Column
          dataField='finishedOn'
          dataType='date'
          selectedFilterOperation='between'
          caption='Finished At'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateFinishedOn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('finishedOn')} />
        </Column>
        <Column
          dataField='hostName'
          caption='Client Name'
          minWidth={180}
          cssClass='cls'
          alignment='center'
        />
        <Column dataField='hostPropertyCityName' caption='City' minWidth={200} cssClass='cls' />
        <Column dataField='hostPropertyStateName' caption='State' minWidth={150} cssClass='cls' />
        {/* <Column dataField="hostPropertyCountryName" caption="Country" minWidth={200} cssClass='cls' /> */}
        <Column
          dataField='nextCheckIn'
          caption='Next Check-In'
          dataType='datetime'
          selectedFilterOperation='between'
          format={config.time_format_dev_extreme}
          calculateCellValue={calculateNextCheckIn}
          cellRender={renderCheckIn}
          minWidth={150}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('nextCheckIn')} />
        </Column>
        <Column
          dataField='cleaningProviderId'
          calculateCellValue={renderProvider}
          caption='Provider'
          minWidth={150}
          cssClass='cls'
          alignment='center'
        />
      </DataGrid>
    </div>
  )
}

export default LiveTable
