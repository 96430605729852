import React, { Dispatch } from 'react'
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import * as hostActionCreators from '../../../redux/actionCreators/hostActionCreators';
import { HostAction } from '../../../redux/actionTypes/hostTypes';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import HostEditForm from '../../../umut-components/Forms/HostEditForm';

const HostEditDialog = () => {
    const { hostEditDialog } = useSelector((state: RedusxAppState) => state.host);
    const dispatch = useDispatch<Dispatch<HostAction>>();

    const handleClose = () => {
        dispatch(hostActionCreators.closeEditHostDialog());
    }
    return (
        <Modal show={hostEditDialog.modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Host Add</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <HostEditForm />
            </Modal.Body>
        </Modal>
    )
}

export default HostEditDialog
