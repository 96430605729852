import React, { Dispatch } from 'react';
import { useQuery } from 'react-query';
import * as vendorActionCreators from '../../../../../redux/actionCreators/vendorActionCreators'
import { useDispatch, useSelector } from 'react-redux';
import ApiCalls from '../../../../../network/ApiCalls';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import { useParams } from 'react-router-dom';
import { decryptText } from '../../../../../utils/util';
import { VendorAction } from '../../../../../redux/actionTypes/vendorTypes';
import PropertyTable from './PropertyTable';

type Props = {
}

const BannedProperties: React.FC<Props> = () => {
    let { vendorId }: any = useParams()
    vendorId = decryptText(vendorId)
    const dispatch = useDispatch<Dispatch<VendorAction>>();
    const { vendorPropertyDialog } = useSelector((state: RedusxAppState) => state.vendor);
    const { data: propertyData, isLoading: propertyLoading, error: propertyError, refetch } = useQuery(['Banned Properties', vendorId], () => ApiCalls.getExcludeVendor(vendorId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: vendorId ? true : false });

    const clickAdd = () => {
        dispatch(vendorActionCreators.OpenVendorPropertyDialog(refetch, "banned"));
    }

    return (
        <div className='p-6'>
            <h4>Restricted Properties</h4>
            {propertyLoading ? <SkeletonTableLoading /> : <PropertyTable data={propertyData?.data?.data} refetch={refetch} propertyType="banned" />}
            <div className='w-100 d-flex justify-content-end'>
                <button className='btn btn-danger btn-sm mt-3' onClick={clickAdd}>Restrict Properties</button>
            </div>
        </div>
    )
}

export default React.memo(BannedProperties);