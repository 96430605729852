import React, {Dispatch, useEffect, useState} from 'react'
import {Card, Tab, Tabs} from 'react-bootstrap-v5'
import {PageTitle} from '../../../_metronic/layout/core'
import {JobStatusObj} from '../../enums/JobEnum'
import JobTable from './JobTable'
import JobCancelTable from './JobCancelTable'
import * as jobActionCreator from '../../redux/actionCreators/jobActionCreators'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import {useDispatch, useSelector} from 'react-redux'
import JobDialog from './JobDialog'
import JobCancelDialog from './JobCancelDialog'
import JobNotCompletedTable from './JobNotCompletedTable'
import JobLateStart from './JobLateStart'
import JobLateFinish from './JobLateFinish'
import {JobTabNames} from '../../interfaces/Job'
import {useParams} from 'react-router'
import JobsTable from './JobsTable'

type Props = {
  type: 'turnify-managed' | 'client-managed'
}

type TabNames =
  | 'Pending'
  | 'Scheduled / Cancelled'
  | 'Started'
  | 'Not Completed'
  | 'Completed'
  | 'Cancelled'
  | 'Late Start'
  | 'Late Finish'

const JobsPage: React.FC<Props> = ({type}) => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState<TabNames>('Pending')
  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  return (
    <>
      <PageTitle>Jobs</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body className='d-flex flex-column'>
            <div className='d-flex justify-content-end'>
              {type == 'turnify-managed' && (
                <button
                  type='button'
                  className='btn btn-success'
                  onClick={() => dispatch(jobActionCreator.openJobDialog())}
                >
                  Add Job
                </button>
              )}
            </div>
            <Tabs
              defaultActiveKey={selectedTab}
              onSelect={handleChangeTab}
              id={`job-tab-${selectedTab}`}
              key={`job-tab-${selectedTab}`}
            >
              <Tab eventKey={'Pending'} title={'Pending'}>
                {selectedTab == 'Pending' ? (
                  <JobsTable jobStatusId={JobStatusObj['Pending']} type={type} />
                ) : null}
              </Tab>
              <Tab eventKey={'Scheduled / Cancelled'} title={'Scheduled / Cancelled'}>
                {selectedTab == 'Scheduled / Cancelled' ? (
                  <JobsTable
                    jobStatusId={JobStatusObj['Scheduled'] + ',' + JobStatusObj['Cancelled']}
                    type={type}
                  />
                ) : null}
              </Tab>
              <Tab eventKey={'Completed'} title={'Completed'}>
                {selectedTab == 'Completed' ? (
                  <JobsTable jobStatusId={JobStatusObj['Completed']} type={type} />
                ) : null}
              </Tab>
              <Tab eventKey={'Not Completed'} title={'Not Completed'}>
                {selectedTab == 'Not Completed' ? (
                  <JobsTable jobStatusId={JobStatusObj['Not Completed']} type={type} />
                ) : null}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
      <JobDialog />
      <JobCancelDialog />
    </>
  )
}

export default JobsPage
