import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../../_metronic/layout/core'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import VendorStatusTable from './PendingVendorsTable'
import {VendorStatusObj} from './../../../enums/VendorStatusEnum'

const VendorStatus = () => {
  const {
    data,
    isLoading,
    error: servicesError,
    refetch,
  } = useQuery(['Get Pending Vendors'], () => ApiCalls.getPendingVendors(1), {
    cacheTime: 10000,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <PageTitle>Pending Vendors</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <VendorStatusTable refetch={refetch} data={data?.data?.data} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default VendorStatus
