import {
    Column,
    DataGrid,
    HeaderFilter,
    Paging,
    Scrolling,
    FilterRow,
    Export,
    SearchPanel,
} from 'devextreme-react/data-grid'
import * as violationActionCreators from '../../../../../redux/actionCreators/violationActionCreators'
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router';
import ApiCalls from '../../../../../network/ApiCalls';
import ViewButton from '../../../../../umut-components/Buttons/ViewButton';
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading';
import ViolationDetail from './ViolationDetail';
import { useDispatch } from 'react-redux';
import { Dispatch, useRef } from 'react';
import { ViolationDetailActions } from '../../../../../redux/actionTypes/violationTypes';
import { decryptText, getDateFilter, setDxFilterPlaceholder } from '../../../../../utils/util';
import { PageTitle } from '../../../../../../_metronic/layout/core';
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable';
import config from '../../../../../config/config.json';

const VendorViolations = () => {
    let { vendorId }: any = useParams()
    vendorId = decryptText(vendorId);
    const history = useHistory()
    const tableRef = useRef<DataGrid>(null)
    const showViewDispatch = useDispatch<Dispatch<ViolationDetailActions>>()
    const { data, isLoading, error } = useQuery(['Get Violation'], () => ApiCalls.getVendorViolation(vendorId), { cacheTime: 50000 })

    if (isLoading) {
        return <SkeletonTableLoading />
    }
    const renderViewButton = (cellData: any) => {
        return (
            <ViewButton
                classNames='btn-sm'
                iconWidth='15'
                iconHeight='15'
                viewFn={() => {
                    showViewDispatch(violationActionCreators.openViolationView(cellData?.data))

                }}
            />
        )
    }
    const calculateFullName = (cellValue: any) => {
        return cellValue?.creatorFirstName + ' ' + cellValue?.creatorLastName
    }
    if (data?.data?.data?.length === 0) {
        return <EmptyTable title='No Violations' />
    }
    return (
        <div className="position-relative">
            <div style={{ zIndex: 1 }} className="position-absolute top-15">
                <span className="fs-8 text-primary cursor-pointer"
                    onClick={() => tableRef.current?.instance.clearFilter()}
                >
                    Clear Filter
                </span>
            </div>
            <DataGrid
                id='violationTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row-violation')
                }}
                dataSource={data?.data?.data}
                showBorders={false}
                showColumnLines={false}
                ref={tableRef}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(60vh - 60px)'}
                allowColumnResizing={true}
                onEditorPreparing={setDxFilterPlaceholder}
                selection={{ mode: 'single' }}
            >
                <Export enabled={true} />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={10} />


                <Column dataField='description' minWidth={300} cssClass='cls'  />
                <Column
                    dataField='creatorFirstName'
                    calculateCellValue={calculateFullName}
                    caption='Creator'
                    minWidth={180}
                    dataType='string'
                    cssClass='cls'
                    
                />
                <Column
                    dataField='violationTypeName'
                    alignment='center'
                    caption='Type Name'
                    minWidth={150}
                    cssClass='cls'
                     
                />
                <Column
                    dataField='violationStatusTypeName'
                    alignment='center'
                    caption='Status'
                    minWidth={80}
                    cssClass='cls'
                     
                />
                <Column
                    dataField='jobDate'
                    alignment='center'
                    dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme}
                    caption='Job Date'
                    minWidth={80}
                    cssClass='cls'
                >
                    <HeaderFilter dataSource={getDateFilter('jobDate')} />
                </Column>

                <Column width={100} cellRender={renderViewButton} cssClass='cls' />
            </DataGrid>

            <ViolationDetail />
        </div>
    )
}

export default VendorViolations
