import React, {useEffect, useRef, useState} from 'react'
import {decryptText} from '../../../utils/util'
import {DataGrid} from 'devextreme-react/data-grid'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import {useHistory, useParams} from 'react-router'
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading'
import {AxiosResponse} from 'axios'
import {IJobResponse} from '../../../network/NetworkResponses/NetworkResponses'
import * as jobActionCreator from '../../../redux/actionCreators/jobActionCreators'
import EmptyTable from '../../Icons/EmptyTable'
import dayjs from 'dayjs'
import DatePicker from '../../../umut-components/Inputs/DatePicker'
import {useDialog} from '../../../contexts/DialogContext'
import {Tabs, Tab} from 'react-bootstrap-v5'
import JobHistorySectionTab from './JobTabs/JobHistorySectionTab'
import JobDialog from '../../../pages/jobs/JobDialog'
import {useDispatch} from 'react-redux'

type TabNames = 'upcoming' | 'past'

type Props = {
  isMarketplace?: boolean
}

const JobHistoryStep: React.FC<Props> = ({isMarketplace}) => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState<TabNames | null>('upcoming')
  const {showFailureDialog} = useDialog()
  const [cleaningPeriodStart, setCleaningPeriodStart] = useState(
    dayjs().add(-30, 'day').format('YYYY-MM-DD')
  )
  const [cleaningPeriodEnd, setCleaningPeriodEnd] = useState(
    dayjs().add(90, 'day').format('YYYY-MM-DD')
  )

  let {propertyId}: any = useParams()
  const tableRef = useRef<DataGrid>(null)
  propertyId = propertyId ? decryptText(propertyId) : undefined

  const {
    data: jobData,
    isLoading: jobLoading,
    error: jobError,
    refetch: refetchJobs,
  } = useQuery<AxiosResponse<IJobResponse>>(
    ['Get Jobs by Property Id', propertyId],
    () =>
      ApiCalls.getJobsByPropertyId(parseInt(propertyId), cleaningPeriodStart, cleaningPeriodEnd),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  const changeStartDate = (date: any) => {
    setCleaningPeriodStart(dayjs(date).format('YYYY-MM-DD'))
  }

  const changeEndDate = (date: any) => {
    setCleaningPeriodEnd(dayjs(date).format('YYYY-MM-DD'))
  }

  const searchFilteredJobs = () => {
    if (dayjs(cleaningPeriodStart).isAfter(cleaningPeriodEnd)) {
      showFailureDialog('Start date should lower than end date!')
    } else {
      refetchJobs()
    }
  }

  if (jobLoading) {
    return (
      <div>
        <SkeletonTableLoading />
      </div>
    )
  }
  return (
    <div className='row position-relative'>
      <div className='mb-5 mt-4'>
        <span className='text-danger'>
          This tab displays a list of all jobs created for the selected property. You can view the
          status of each job, as well as the scheduled and completed time for each job.
        </span>
      </div>
      <div className='mt-4'>
        <div className='row'>
          <div className='col-lg-2 col-12 mb-2'>
            <DatePicker defaultValue={cleaningPeriodStart} onChange={changeStartDate} />
          </div>
          <div className='col-lg-2 col-12 mb-2'>
            <DatePicker defaultValue={cleaningPeriodEnd} onChange={changeEndDate} />
          </div>
          <div className='col-lg-2 col-12 mb-2'>
            <button
              className='btn btn-sm btn-primary'
              onClick={() => {
                searchFilteredJobs()
              }}
              disabled={jobLoading}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div style={{zIndex: 1}} className='position-relative top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <Tabs
        defaultActiveKey='upcoming'
        id='uncontrolled-tab-example'
        className='mb-3 p-3'
        onSelect={handleChangeTab}
      >
        <Tab eventKey='upcoming' title='Upcoming Jobs'>
          <JobHistorySectionTab
            dataType='upcoming'
            data={jobData?.data?.data}
            propertyId={propertyId}
          />
        </Tab>

        <Tab eventKey='past' title='Past Jobs'>
          <JobHistorySectionTab
            dataType='past'
            data={jobData?.data?.data}
            propertyId={propertyId}
          />
        </Tab>
      </Tabs>
      <div className='d-flex justify-content-end mt-5'>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => dispatch(jobActionCreator.openJobDialog())}
        >
          + Add Job
        </button>
      </div>
      <JobDialog propertyId={propertyId} isMarketplace={isMarketplace} />
    </div>
  )
}

export default JobHistoryStep
