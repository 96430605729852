import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import ApiCalls from '../../../network/ApiCalls';
import SelectBox from '../../Inputs/SelectBox';
import { TextInput } from '../../Inputs/TextInput';
import SwitchBox from '../../Inputs/SwitchBox';
import { ChecklistAddRequest } from '../../../network/PostRequestModels/ChecklistRequests';
import { useSelector } from 'react-redux';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { useDialog } from '../../../contexts/DialogContext';
import { useHistory } from 'react-router-dom';
import SelectInput from '../../Inputs/SelectInput';
import { encryptText } from '../../../utils/util';
import { AxiosResponse } from 'axios';
import { LanguageResponse } from '../../../network/NetworkResponses/NetworkResponses';
import DeleteButton from '../../Buttons/DeleteButton';

type Props = {
    hostPropertyId: number,
    handleAddPropertySuccess?: () => void
}

type LanguageOptions = {
    label: string;
    value: string;
}

type NameInput = {
    code: string;
    text: string;
}

const ChecklistForm: React.FC<Props> = ({ hostPropertyId, handleAddPropertySuccess }) => {
    const { user } = useSelector((state: RedusxAppState) => state.user);
    const history = useHistory();
    const { showSuccessDialog, showFailureDialog } = useDialog();
    const [checklistRequest, setChecklistRequest] = useState({ hostPropertyId, hostId: user.userSection.hostId } as ChecklistAddRequest);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [roles, setRoles] = useState([]);
    const [languages, setLanguages] = useState<LanguageOptions[]>([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [checklistNames, setChecklistNames] = useState<NameInput[]>([{ code: 'en', text: '' }]);
    const { data: rolesData, isLoading: rolesLoading, error: rolesError } = useQuery('Get Roles', () => ApiCalls.getStaffRoles(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: serviceData, isLoading: serviceLoading, error: serviceError } = useQuery('Get Service Types', () => ApiCalls.getServiceTypes(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: hostPropertyDetailData, isLoading: propertyDetailLoading, error: propertyDetailError } = useQuery('Get Property Detail', () => ApiCalls.getHostPropertyById(hostPropertyId), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: languageData, isLoading: languageLoading, error: languageError } = useQuery<AxiosResponse<LanguageResponse>>('Get Langauges', () => ApiCalls.getLanguages(), { cacheTime: 500000, refetchOnWindowFocus: false });

    const handleChecklistAdd = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            const { roleId, serviceTypeId } = checklistRequest;
            const isNamesValid = !checklistNames.some(name => name.text === '' || !name.text) && checklistNames.length > 0;
            if (isNamesValid && roleId && serviceTypeId) {
                const names = checklistNames.reduce((total, currentName) => ({ ...total, [currentName.code]: currentName.text }), {});
                const res = await ApiCalls.postHostPropertyChecklist({ ...checklistRequest, name: names });
                setLoading(false);
                showSuccessDialog('Checklist Added.');
                handleAddPropertySuccess && handleAddPropertySuccess();
                history.push('/checklist-sections/' + encryptText(res.data.id));
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const handleChangeLanguage = (languageCode: string, index: number) => {
        let newChecklistNames = [...checklistNames];
        newChecklistNames[index].code = languageCode;
        setChecklistNames(newChecklistNames);
    }

    const handleChangeText = (newText: string, index: number) => {
        let newChecklistNames = [...checklistNames];
        newChecklistNames[index].text = newText;
        setChecklistNames(newChecklistNames);
    }

    const handleAddName = () => {
        let newName: NameInput = { code: 'en', text: '' };
        setChecklistNames([...checklistNames, newName]);
    }

    const removeName = (removeIndex: number) => {
        if (checklistNames.length > 1) {
            setChecklistNames(checklistNames.filter((name, index) => index !== removeIndex));
        }
        else {
            setChecklistNames([{ code: 'en', text: '' }]);
        }
    }

    useEffect(() => {
        if (rolesData && hostPropertyDetailData) {
            setRoles(rolesData?.data?.filter((role: any) => (hostPropertyDetailData?.data?.hostProperty?.cleaningProviderId === 1 || hostPropertyDetailData?.data?.hostProperty?.cleaningProviderId === 3) ? role.lobRoleName === "Host" && role.roleName !== "Manager" && role.roleName !== "Admin" : role.lobRoleName === "Vendor" && role.roleName !== "Admin" && role.roleName !== "Manager").map((role: any) => ({ label: role.roleName, value: role.roleId })));
        }
    }, [rolesData, hostPropertyDetailData])

    useEffect(() => {
        if (serviceData) {
            setServiceTypes(serviceData?.data?.data?.map((service: any) => ({ label: service.name, value: service.id })));
        }
    }, [serviceData])

    useEffect(() => {
        if (languageData) {
            setLanguages(languageData.data.data.map(language => ({ label: language.name, value: language.code })));
        }
    }, [languageData])

    if (rolesLoading || serviceLoading || languageLoading) {
        return <div className='d-flex justify-content-center'><Spinner animation='border' /></div>
    }

    return (
        <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Who is going to be using this checklist?</label>
                    <SelectInput options={roles} onValueChanged={(value) => setChecklistRequest({ ...checklistRequest, roleId: value })} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>What service will this checklist be used for?</label>
                    <SelectInput options={serviceTypes} onValueChanged={(value) => setChecklistRequest({ ...checklistRequest, serviceTypeId: value })} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Name your checklist. (For your eyes only.)</label>
                    {
                        checklistNames.map((name, index) => <div className='row mt-6'>
                            <div className='col-4'>
                                <SelectInput options={languages} defaultValue={name.code} onValueChanged={value => handleChangeLanguage(value, index)} />
                            </div>
                            <div className='col-7'>
                                <input
                                    value={name.text}
                                    onChange={e => handleChangeText(e.target.value, index)}
                                    className='form-control'
                                    type='text'
                                    autoComplete='off'
                                    required
                                />
                            </div>
                            <div className='col-1'>
                                <DeleteButton iconWidth='20' iconHeight='20' classNames='btn btn-link btn-sm text-danger' deleteFn={() => removeName(index)} />
                            </div>
                        </div>
                        )
                    }
                    <button type='button' className='btn btn-info btn-sm w-100 mt-3' onClick={handleAddName}>Add checklist name in different language</button>

                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleChecklistAdd}>Add {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(ChecklistForm);