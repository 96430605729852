import { Dispatch, FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators';
import { Modal } from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import { useDispatch, useSelector } from 'react-redux';
import * as vendorActionCreators from '../../../../../redux/actionCreators/vendorActionCreators'
import { RedusxAppState } from '../../../../../redux/reducers/rootReducer';
import { VendorAction } from '../../../../../redux/actionTypes/vendorTypes';
import VendorPropertyForm from '../../../../../umut-components/Forms/VendorPropertyForm';
import { IGetAllVendors } from '../../../../../interfaces/Vendors';


type Props = {
    data:IGetAllVendors
}
const VendorPropertyDialog: FC<Props> = ({data}) => {
    const { vendorPropertyDialog } = useSelector((state: RedusxAppState) => state.vendor);
    const dispatch = useDispatch<Dispatch<VendorAction>>();

    const closeModal = () => {
        dispatch(vendorActionCreators.CloseVendorPropertyDialog());
    }

    return (
        <Modal show={vendorPropertyDialog.modalOpen} onHide={closeModal} size='xl' centered>
            <ModalHeader closeButton>
                {/* <Modal.Title>{staffPropertyDialog.formType === "allowed" ? "Assign properties" : "Add restrictions "}  for {staffDetail.staff?.firstName + ' ' + staffDetail.staff?.lastName}</Modal.Title> */}
            </ModalHeader>
            <Modal.Body>
                {vendorPropertyDialog.modalOpen ? <VendorPropertyForm data={data}/> : null}
            </Modal.Body>
        </Modal>
    )
}

export default VendorPropertyDialog
