import * as actions from '../actionTypes/serviceGroupDetailActionType'
import {IServiceGroup} from '../../interfaces/ServiceType'

export interface serviceGroupDetailState {
  serviceGroupState: {
    ModalOpen: boolean
    ModalData: IServiceGroup | null
  }
}
const initialState: serviceGroupDetailState = {
  serviceGroupState: {
    ModalOpen: false,
    ModalData: null,
  },
}
export default function vendorDetailReducer(
  state: serviceGroupDetailState = initialState,
  action: actions.ServiceGroupDetailAction
) {
  switch (action.type) {
    case actions.SERVICE_GROUP_OPEN_VIEW:
      return {...state, serviceGroupState: {ModalOpen: true, ModalData: action.serviceGroup}}
    case actions.SERVICE_GROUP_CLOSE_VIEW:
      return {...state, serviceGroupState: {ModalOpen: false, ModalData: null}}
    default:
      return state
  }
}
