import React from 'react'
import {letterToHex, stringToColour, toAmazonUrl} from '../../utils/util'

type Props = {
  profilePhotoPath?: string
  firstName: string
  lastName: string
  width?: number | string
  height?: number | string
  borderRadius?: string
  fontSize?: string | number
  classNames?: string
  margin?: string | number
  padding?: string | number
  vendorName?: string
}

const getAndCapitalizeFirstLetter = (letter: string) => {
  return letter.substr(0, 1).toUpperCase()
}

const ProfilePhotoTemp: React.FC<Props> = ({
  profilePhotoPath,
  firstName,
  lastName,
  width,
  height,
  borderRadius,
  fontSize,
  classNames,
  margin,
  padding,
  vendorName,
}) => {
  if (profilePhotoPath) {
    return (
      <img
        className={classNames}
        alt={firstName + lastName}
        style={{width, height, borderRadius}}
        src={toAmazonUrl(profilePhotoPath)}
      />
    )
  }

  if (vendorName) {
    return (
      <div
        className={'d-flex flex-row justify-content-center align-items-center ' + classNames}
        style={{
          width,
          height,
          borderRadius,
          color: 'white',
          backgroundColor: '#FE5D5D',
          margin,
          padding,
        }}
      >
        <strong style={{fontSize}}>{vendorName}</strong>
      </div>
    )
  }

  let backgroundColor = stringToColour(firstName + ' ' + lastName)

  return (
    <div
      className={'d-flex flex-row justify-content-center align-items-center ' + classNames}
      style={{width, height, borderRadius, color: 'white', backgroundColor, margin, padding}}
    >
      <strong style={{fontSize}}>
        {firstName ? getAndCapitalizeFirstLetter(firstName) : null}
      </strong>
      <strong style={{fontSize}}>{lastName ? getAndCapitalizeFirstLetter(lastName) : null}</strong>
    </div>
  )
}

export default ProfilePhotoTemp
