import React, { Dispatch, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ServiceDetailAction } from '../../redux/actionTypes/serviceDetailActionType'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import * as serviceDetailActionCreators from '../../redux/actionCreators/serviceDetailActionCreators'
import { Modal } from 'react-bootstrap-v5'
import ServiceReferenceForm from './ServiceReferenceForm'



type Props = {
  refetchReferences: () => void
}

const ServiceReferenceDialog: FC<Props> = ({ refetchReferences }) => {
  const {
    referenceState: { ModalOpen,ModalData }
  } = useSelector((state: RedusxAppState) => state.serviceDetail)
  const showViewDispatch = useDispatch<Dispatch<ServiceDetailAction>>()
  const handleCloseModal = () => {
    showViewDispatch(serviceDetailActionCreators.closeReferenceView())
  }
  return (
    <div>
      <Modal show={ModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{ModalData ? `Edit ${ModalData.name}` : "Add New Service"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ServiceReferenceForm refetchReferences={refetchReferences} selectedService={ModalData} unitSizeRequired={true} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ServiceReferenceDialog
