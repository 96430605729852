import React, {Dispatch} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useDialog} from '../../../../contexts/DialogContext'
import {JobStatusObj} from '../../../../enums/JobEnum'
import {IJob, IJobNote} from '../../../../interfaces/Job'
import {DeleteActions} from '../../../../redux/actionTypes/deleteDialogTypes'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import DeleteButton from '../../../../umut-components/Buttons/DeleteButton'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import {convertUTCDateToLocalDate} from '../../../../utils/util'
import * as deleteDialogActionCreators from '../../../../redux/actionCreators/deleteDialogActionCreators'
import ApiCalls from '../../../../network/ApiCalls'
import {JobStatusType} from '../../../../enums/JobEnum'

type Props = {
  note: IJobNote
  noteType: 'job' | 'service' | 'task'
  openModal: (noteType: 'job-note' | 'task-note' | 'service-note', note: IJobNote | null) => void
  refetch: () => void
}

const NoteItem: React.FC<Props> = ({note, noteType, openModal, refetch}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)
  const deletDialogDispatch = useDispatch<Dispatch<DeleteActions>>()
  const {showFailureDialog} = useDialog()

  return (
    <div style={{borderRadius: '8px', width: '83%'}} className='row p-3 pb-6 mt-3 border '>
      <div className='col-12 d-flex flex-row justify-content-between p-2'>
        <div className='d-flex flex-grow-1 '>
          <ProfilePhotoTemp
            classNames='me-2'
            width={45}
            height={45}
            borderRadius='50%'
            profilePhotoPath={user?.profilePhotoPath}
            firstName={note?.creatorFirstName}
            lastName={note?.creatorLastName}
          />
          <div className='d-flex flex-column'>
            <strong>{note?.creatorFirstName + ' ' + note?.creatorLastName}</strong>
            <span className='text-gray-400 w-100px fs-9 text-wrap'>
              {convertUTCDateToLocalDate(note?.createdOn, jobDetail?.hostPropertyCityTimeZone)}
            </span>
            <p className='text-success text-break'>
              {note.serviceTypeName ? note.serviceTypeName : '--'}
            </p>
          </div>
        </div>
        <div className='d-flex justif-content-center align-items-baseline ml-5 flex-row'>
          <button
            type='button'
            className='btn btn-primary btn-sm mt-1'
            style={{height: 'fit-content'}}
            onClick={() => {
              openModal(
                noteType === 'job'
                  ? 'job-note'
                  : noteType === 'service'
                  ? 'service-note'
                  : 'task-note',
                note
              )
            }}
          >
            Edit
          </button>
          {jobDetail?.jobStatusType!.name !== JobStatusType.COMPLETED &&
            jobDetail?.jobStatusType!.name !== JobStatusType.CANCELLED && (
              <div>
                <DeleteButton
                  classNames='btn btn-sm btn-light-danger ms-3'
                  iconWidth='20'
                  iconHeight='20'
                  deleteFn={() => {
                    deletDialogDispatch(
                      deleteDialogActionCreators.openDeleteDialog(
                        async () => {
                          try {
                            await ApiCalls.deleteJobNote(note?.id)
                            refetch()
                            deletDialogDispatch(deleteDialogActionCreators.closeDeleteDialog())
                          } catch (err: any) {
                            showFailureDialog(err?.description?.data?.message)
                          }
                        },
                        `Are you sure you want to delete Note?`,
                        'Delete Note?'
                      )
                    )
                  }}
                />
              </div>
            )}
        </div>
      </div>
      <div className='col-12 mt-3'>
        <p className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'>
          {note.note}
        </p>
      </div>
    </div>
  )
}

export default NoteItem
