import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import React, {FC} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../_metronic/layout/core'
import ApiCalls from '../../network/ApiCalls'
import {LiveJobResponse} from '../../network/NetworkResponses/NetworkResponses'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import LiveTable from './LiveTable'

type Props = {
  type: 'turnify-managed' | 'client-managed'
}

const Live: FC<Props> = ({type}) => {
  const cleaningPeriodStart = dayjs().format('YYYY-MM-DD')
  const cleaningPeriodEnd = dayjs(cleaningPeriodStart).add(1, 'day').format('YYYY-MM-DD')

  const {
    data: liveData,
    isFetching: liveLoading,
    error: liveError,
  } = useQuery<AxiosResponse<LiveJobResponse>>(
    ['Get Live Jobs', type],
    () =>
      type == 'client-managed'
        ? ApiCalls.getClientManagedLiveJobs(
            '?cleaningPeriodStart=' +
              cleaningPeriodStart +
              '&cleaningPeriodEnd=' +
              cleaningPeriodEnd
          )
        : ApiCalls.getTurnifyManagedLiveJobs(
            '?cleaningPeriodStart=' +
              cleaningPeriodStart +
              '&cleaningPeriodEnd=' +
              cleaningPeriodEnd
          ),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const pageTitle = `Live (
        ${type === 'client-managed' ? 'Client Managed' : ''}
        ${type === 'turnify-managed' ? 'Turnify Managed' : ''}
    )`

  if (liveError) {
    return <div>Error</div>
  }

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <Card>
        <Card.Body>
          {liveLoading ? (
            <SkeletonTableLoading />
          ) : (
            liveData && <LiveTable data={liveData?.data.data} type={type} />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default Live
