import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap-v5';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import * as vendorActionCreator from '../../../../redux/actionCreators/vendorActionCreators'
import { VendorAction } from '../../../../redux/actionTypes/vendorTypes';
import UpdateButton from '../../../../umut-components/Buttons/UpdateButton';
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp';
import SkeletonCircleLoading from '../../../../umut-components/Loading/SkeletonCircleLoading';
import { decryptText } from '../../../../utils/util';
import { IGetAllVendors } from './../../../../interfaces/Vendors';

type Props = {
    data: IGetAllVendors
    isLoading: boolean
}
const ProfileTab: React.FC<Props> = ({ data, isLoading }) => {
    const dispatch = useDispatch<Dispatch<VendorAction>>();
    let { vendorId }: any = useParams();
    vendorId = decryptText(vendorId);
    const [vendorDetail, setVendorDetail] = useState<IGetAllVendors | null>(null);

    const renderEditButton = () => {
        dispatch(vendorActionCreator.OpenVendorEditDialog(data))
    }
    useEffect(() => {
        if (data) {
            setVendorDetail(data);
        }
    }, [data])

    if (isLoading) {
        return <SkeletonCircleLoading />
    }



    return (
        <div className='d-flex p-6 ps-0 flex-row-wrap justify-content-between'>

            <div className='column d-flex align-items-center'>
                <div className='d-flex me-6 '>
                    {vendorDetail && <ProfilePhotoTemp width={75} height={75} borderRadius={'50%'} fontSize={25} firstName={vendorDetail?.name.split(' ')[0] ?? ''} lastName={vendorDetail?.name.split(' ')[1] ?? ''} />}
                </div>
                <div className="row">
                    <div className='col-lg-2 h-auto'>
                        <strong>Pro: &nbsp;</strong>
                    </div>
                    <div className='col-lg-10 h-auto'>
                        <small className='text-muted'>{vendorDetail?.name}</small>
                    </div>
                    <div className='col-lg-2 h-auto'>
                        <strong>Website: &nbsp;</strong>
                    </div>
                    <div className='col-lg-10 h-auto'>
                        <a href={vendorDetail?.website?.startsWith("https://") ? vendorDetail?.website : ('https://' + vendorDetail?.website)} target="_blank">
                            <small className='text-muted'>{vendorDetail?.website}</small>
                        </a>

                    </div>
                    <div className='col-lg-2 h-auto'>
                        <strong>Phone: &nbsp;</strong>
                    </div>
                    <div className='col-lg-10 h-auto'>
                        <a href={`tel:${vendorDetail?.phoneNumber}`}>
                            <small className='text-muted'>{vendorDetail?.phoneNumber}</small>
                        </a>
                    </div>
                    <div className='col-lg-2 h-auto'>
                        <strong>E-mail: &nbsp;</strong>
                    </div>
                    <div className='col-lg-10 h-auto'>
                        <a href={`mailto:${vendorDetail?.email}`} target="_blank">
                            <small className='text-muted'>{vendorDetail?.email}</small>
                        </a>
                    </div>
                </div>
                <div className="row">


                   
                    <div className='col-lg-3 h-auto'>
                        <strong>City: &nbsp;</strong>
                    </div>
                    <div className='col-lg-9 h-auto'>
                        <small className='text-muted'>{vendorDetail?.cityName}</small>
                    </div>
                    <div className='col-lg-3 h-auto'>
                        <strong>State: &nbsp;</strong>
                    </div>
                    <div className='col-lg-9 h-auto'>
                        <small className='text-muted'>{vendorDetail?.stateName}</small>
                    </div>
                    <div className='col-lg-3 h-auto'>
                        <strong>Country: &nbsp;</strong>
                    </div>
                    <div className='col-lg-9 h-auto'>

                        <small className='text-muted'>{vendorDetail?.countryName}</small>
                    </div>
                    <div className='col-lg-3 h-auto'>
                        <strong>Type: &nbsp;</strong>
                    </div>
                    <div className='col-lg-9 h-auto'>
                        <small className='text-muted'>{vendorDetail?.vendorTypeName}</small>
                    </div>

                </div>

            </div>
            <div className="">
                <Button className="btn-sm d-flex align-items-center text-nowrap" onClick={renderEditButton}>Edit Pro</Button>
            </div>
        </div>
    )
}

export default ProfileTab;