import * as violationActionCreators from '../../../../../redux/actionCreators/violationActionCreators'
import { Dispatch, FC } from "react"
import {  Modal } from "react-bootstrap-v5"
import { useDispatch, useSelector } from "react-redux"
import { RedusxAppState } from "../../../../../redux/reducers/rootReducer"
import { ViolationDetailActions } from '../../../../../redux/actionTypes/violationTypes'
import ViolationReview from './ViolationReview'



const ViolationDetail = () => {
    const { ModalOpen, ModalData } = useSelector((state: RedusxAppState) => state.violation)
    const showViewDispatch = useDispatch<Dispatch<ViolationDetailActions>>()


    const handleCloseModal = () => {
        showViewDispatch(violationActionCreators.closeViolationView())
    }
    return (
        <div>
            <Modal
                size="lg"
                show={ModalOpen}
                onHide={handleCloseModal}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <div className="card-header ribbon ribbon-top p-5">
                    <div className="ribbon-label bg-primary">{ModalData?.violationStatusTypeName}</div>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {ModalData?.violationTypeName}
                    </Modal.Title>
                </div>
                <Modal.Body>

                    <ViolationReview modalData={ModalData}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ViolationDetail
