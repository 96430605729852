import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useQuery} from 'react-query'
import config from '../../../config/config.json'
import {JobStatusType} from '../../../enums/JobEnum'
import ApiCalls from '../../../network/ApiCalls'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {encryptText, getDateFilter, onExporting, toConvertedDate} from '../../../utils/util'

const HostLogs = () => {
  const {data, isLoading, error} = useQuery(['Get Host Logs'], () => ApiCalls.getHostLogs(), {
    cacheTime: 1000,
  })

  const getActionName = (cellData: any) => {
    if (cellData?.type == 'icalDelete') {
      return 'iCal Delete'
    }
    if (cellData?.type == 'propertyDelete') {
      return 'Property Delete'
    }
    if (cellData?.type == 'jobCancel') {
      return 'Job Canceled'
    }

    if (cellData?.type == 'jobCreated') {
      return 'Job Created'
    }
  }

  if (isLoading) {
    return <SkeletonTableLoading />
  }
  if (error) {
    return <div className='mt-6'>Error</div>
  }

  if (data?.data?.data?.length === 0) {
    return <EmptyTable title='No pro client actions found' />
  }
  return (
    <div>
      <DataGrid
        id='forceFinish'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data?.data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(45vh - 60px)'}
        className='mt-6'
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onExporting={(e) => onExporting(e, 'Host Logs')}
      >
        <Export enabled />
        <SearchPanel visible={true} />
        <FilterRow showOperationChooser={false} visible={true} />
        <HeaderFilter visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Logs: {0}'
            column='id'
            summaryType='count'
          />
        </Summary>
        <Column dataField='propertyName' caption='Property' minWidth={150} cssClass='cls' />
        <Column dataField='hostName' caption='Client' minWidth={200} cssClass='cls' />
        <Column dataField='userName' caption='Done By' minWidth={150} cssClass='cls' />
        <Column
          dataField='type'
          calculateCellValue={getActionName}
          caption='Action'
          minWidth={150}
          cssClass='cls'
        />
        <Column dataField='date' caption='Date' minWidth={150} cssClass='cls' />
        <Column dataField='message' caption='Message' minWidth={150} cssClass='cls' />
      </DataGrid>
    </div>
  )
}

export default HostLogs
