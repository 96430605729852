export const Endpoints = {
  Users: {
    Login: 'users/login/system-user',
    ConfirmAccount: 'host-users/verify-host-user',
    GetUserDetails: 'users/me',
    EditUser: 'users/',
    GetUserDetailsById: 'users/',
    Forgotpassword: 'users/forgot-password',
    ResetPassword: 'users/reset-password',
    FindByEmail: 'users/find-by-email/',
    HardDeleteUser: 'users/hard-delete-user/',
  },
  Host: {
    GetHosts: 'hosts',
    GetHostById: 'hosts/',
    GetHostUsers: 'host-users/',
    AddHost: 'host-users/host-registration-by-admin',
    GetHostStatusTypes: 'host-status-type',
    UpdateHost: 'hosts/',
    GetLogs: 'host-log',
  },
  Admins: {
    GetAllAdmins: 'system-users',
    CreateAdmin: 'system-users',
    UpdateAdmins: 'system-users/',
    AcceptAdmin: 'system-users/accept-invite',
  },
  Property: {
    GetAllProperties: 'host-properties',
    GetSearchedProperties: 'host-properties/search/',
    GetProperties: 'host-properties?hostId=',
    GetPropertiesByStatusId: 'host-properties?hostPropertyStatusTypeId=',
    GetPropertiesByAddress: 'host-properties/get-by-address/',
    GetPropertyById: 'host-properties/',
    GetMarketplaceManagedProperties: 'host-properties/has-marketplace-management',
    GetPendingProperties: 'host-properties/pendings/',
    GetPropertiesFromGuesty: 'host-properties/get-properties-from-guesty',
    GetPropertiesFromHostify: 'host-properties/get-properties-from-hostaway',
    GetPropertiesFromMyVr: 'host-properties/get-properties-from-myvr',
    GetMyTeamSubscribers: 'host-properties/get-my-team-subscribers',
    GetPropertyIsAutoEnabled: 'host-properties/is-auto-enabled/',
    PostHostProperties: 'host-properties/create-many',
    PostHostProperty: 'host-properties',
    CreateHostProperty: 'host-properties/create-by-admin',
    UpdateHostProperty: 'host-properties/',
    HostPropertyOtherQuestion: 'host-property-other-question',
    GetHostPropertyInvoices: 'host-properties/invoices/',
    GetHostProperty: 'host-properties/',
    AllowedProperties: 'user-host-property-permission/accepted-properties/',
    BannedProperties: 'excluded-staff/',
    AcceptedProperties: 'user-host-property-permission/accepted-staffs/',
    AddPropertyPermission: 'user-host-property-permission/create-many',
    PutPriorityProperty: 'user-host-property-permission',
    GetExcludedStaff: 'excluded-staff/',
    DeleteExcludedStaff: 'excluded-staff/',
    BanUserFromProperty: 'excluded-staff',
    GetExcludeVendor: 'excluded-vendors/',
    PostExcludeVendor: 'excluded-vendors',
    DeleteExcludeVendor: 'excluded-vendors/',
    DeletePropertyPermission: 'user-host-property-permission/',
    DeleteBanUserFromProperty: 'excluded-staff/',
    EditPropertyStatus: 'host-properties/',
    GetVendorProperties: 'host-properties/get-available/',
    GetHostPropertyServiceOffers: 'host-property-service-offer',
    GetHostPropertyServiceOffersForVendor: 'host-property-service-offer/for-vendor',
    GetHostPropertyServiceRates: 'host-property-service-rate/property/',
    GetHostPropertyServiceRateList: 'host-property-service-rate/property-rate-list/',
    GetHostPropertyServiceRatesOffersWithVariables: 'host-property-service-rate/offers',
    DeleteHostPropertyServiceOffers: 'host-property-service-offer/',
    DeleteHostPropertyServiceRate: 'host-property-service-rate/',
    AddHostPropertyServiceOffer: 'host-property-service-offer',
    AddHostPropertyServiceRate: 'host-property-service-rate',
    AddHostPropertyVendorServiceRate: 'host-property-service-rate/add-property-vendor-service-rate',
    GetHostPropertyServiceRate: 'host-property-service-rate/property/',
    GetHostServiceRateAbleVendors: 'host-property-service-rate/vendor-rate-offers/',
    GetHostServiceOffersAbleVendors: 'host-property-service-offer/vendors/',
    GetHostServiceOffersAbleMarketplaceVendors: 'host-property-service-offer/marketplace-vendors/',
    HostPropertyAvailableServices: 'host-property-service-offer/available?hostPropertyId=',
    PostRefreshJobSyncPro: 'jobs/turnify-pro-auto',
    PostRefreshJobSyncMyteam: 'jobs/my-team-auto',
    PostRefreshJobSyncPlus: 'jobs/turnify-plus-auto',
    PostRefreshCalendar: 'host-properties/sync/',
    PostResetJobs: 'host-properties/reset-jobs/',
    PostResetHostJobs: 'host-properties/reset-host-jobs/',
    GetJobRates: 'host-properties/get-job-rates',
    GetAllHostPropertyServiceRates: 'host-property-service-rate/',
    UpdateHostPropertyServiceRate: 'host-property-service-rate/update/',
    UpdateHostPropertyServiceRateOffer: 'host-property-service-rate/update-offer/',
    UpdateHostPropertyServiceRateOfferedRate: 'host-property-service-rate/update-offer-rate/',
    DeleteHostPropertyServiceRateOffer: 'host-property-service-rate/offer/',
  },
  Turnovers: {
    GetTurnovers: 'host-properties/turnovers/',
    GetFutureTurnovers: 'hosts/get-future-turnovers/',
    GetPastTurnovers: 'hosts/get-past-turnovers/',
  },
  Checklist: {
    GetMasterChecklists: 'host-property-checklists',
    GetHostPropertyChecklists: 'host-property-checklists?hostPropertyId=',
    PostHostPropertyChecklist: 'host-property-checklists',
    EditChecklist: 'host-property-checklists/',
    UpdateMainChecklist: 'host-property-checklists/main-update/',
    GetChecklistItems: 'host-property-checklists/',
    AddChecklistSection: 'host-property-checklists/section',
    GetChecklistSectionTypes: 'checklist-section-types',
    SetSectionOrder: 'host-property-checklists/',
    SetQuestionOrder: 'host-property-checklists/',
    EditSection: 'host-property-checklists/section/',
    EditQuestion: 'host-property-checklists/question/',
    AddQuestion: 'host-property-checklists/section/question',
    EditQuestionPhoto: 'host-property-checklists/samplePhoto/',
    NewQuestionPhoto: 'host-property-checklists/samplePhoto',
    DeleteChecklist: 'host-property-checklists/',
    DeleteSamplePhoto: 'host-property-checklists/delete-sample-photo/',
    DeleteSection: 'host-property-checklists/section/',
    DeleteQuestion: 'host-property-checklists/question/',
    PostCloneChecklist: 'host-property-checklists/clone',
    UpdateFutureJobsChecklistsByProperty: 'host-property-checklists/update-jobs-checklists',
  },
  PMS: {
    GetPMSTypes: 'pms-types',
  },
  Countries: {
    GetCountries: 'countries',
  },
  CleaningProviders: {
    GetCleaningProviders: 'cleaning-providers',
  },
  AccessTypes: {
    GetAccessTypes: 'access-types',
  },
  Roles: {
    GetStaffRoles: 'roles/staff-roles',
  },
  Service: {
    GetServiceTypes: 'service-types',
    PostServiceType: 'service-types',
    PutServiceType: 'service-types/',
    DeleteServiceType: 'service-types/',
  },
  ServiceGroup: {
    GetServiceGroups: 'service-groups',
    GetServiceGroupsWithServiceTypes: 'service-groups/with-service-types',
    PostServiceGroup: 'service-groups',
    PutServiceGroup: 'service-groups/',
    DeleteServiceGroup: 'service-groups/',
  },
  ChecklistAnswerTypes: {
    GetChecklistAnswerTypes: 'checklist-answer-types',
  },
  Languages: {
    GetLanguages: 'languages',
  },
  Address: {
    GetAddressByUserId: 'users/addresses?userId=',
    CreateAddress: 'users/',
    UpdateAddress: 'users/',
    DeleteAddress: 'users/address/',
    GetAddressTypes: 'user-address-type',
  },
  OfferedServices: {
    GetOfferedServices: 'staff-offered-service?userId=',
    GetOfferedServicesByHostId: 'staff-offered-service/available/',
    GetOfferedStaffs: 'staff-offered-service?serviceTypeId=',
    GetAvailableVendorServices: 'vendor-offered-service/available',
    GetAvailableMarketplaceVendorServices: 'vendor-offered-service/available-for-marketplace',
    GetOfferedServiceType: 'vendor-offered-service/service-types/',
    GetMarketplaceOfferedServiceType: 'vendor-offered-service/marketplace-service-types/',
  },
  OffDays: {
    GetStaffOffDays: 'staff-off-days?userId=',
    PostStaffOffDays: 'staff-off-days',
    PostStaffOffTime: 'staff-off-time/',
    DeleteStaffOffTime: 'staff-off-time/',
    GetStaffOffTime: 'staff-off-time?userId=',
    DeleteStaffOffDays: 'staff-off-days',
  },
  JobRatingTypes: {
    GetJobRatingTypes: 'job-rating-types',
  },
  VendorOfferedServices: {
    GetVendorOfferedService: 'vendor-offered-service',
    GetVendorOffersWithProperty: 'vendor-offered-service/services-by-property',
    DeleteVendorOffered: 'vendor-offered-service/delete/',
    DeleteVendorOfferedService: 'vendor-offered-service/',
    CreateVendorOfferedService: 'vendor-offered-service/create',
  },
  Vendors: {
    GetVendorDetails: 'vendors/',
    CreateVendor: 'vendor-users',
    GetAllVendors: 'vendors/',
    GetVendorById: 'vendors/',
    GetPendingVendors: 'vendors?vendorStatusTypeId=',
    PutVendorsStatus: 'vendors/',
    PutVendorEditStatus: 'vendors/editStatus/',
    GetSelectedVendors: 'vendors/selected/',
    GetAvailableVendors: 'vendors/available/',
    GetAvailableVendorsByJobId: 'vendors/suitable/',
    GetSuitableVendorsByJobId: 'vendor-users/suitable-vendor/',
    GetSuitableMarketplaceVendorsByJobId: 'vendor-users/suitable-marketplace-vendor/',
    GetSuitableStaffByJobId: 'vendor-users/suitable-staff',
    GetVendorServices: 'vendors/services/',
    GetVendorServiceRates: 'host-property-service-rate/by-vendor/',
    UpdateVendorServiceRate: 'host-property-service-rate/update-vendor-service-rate',
    DeleteVendorServiceRate: 'host-property-service-rate/offer/',
    DeleteHostServiceRate: 'host-property-service-rate/',
    GetVendorStatus: 'vendor-status-type',
    GetSuitableHostStaffByJobId: 'host-users/suitable-host-staff?jobId=',
    GetAvailableVendorsForProperty: 'vendors/available-for-property-by-job/',
    SaveVendorServiceToProperty: 'host-property-service-rate/save-vendor-service',
  },
  Reference: {
    GetReference: 'vendor-reference?vendorId=',
  },
  VendorDocuments: {
    GetVendorDocuments: 'vendor-documents?vendorId=',
    DeleteVendorDocuments: 'vendor-documents/',
    ApproveVendorDocument: 'vendor-documents/approve',
  },
  VendorServiceCities: {
    GetVendorCities: 'vendor-city-service/',
    CreateVendorCityService: 'vendor-city-service',
    DeleteVendorCityService: 'vendor-city-service/',
  },
  Billing: {
    GetAllBillings: 'host-invoices',
    GetBillingsByHostId: 'host-invoices?hostId=',
    GetBillingsByJobId: 'host-invoices?jobId=',
    GetBillingDetail: 'host-invoices/',
    GetBillingDetailForMyTeam: 'host-invoices/for-myteam/',
    RefundBilling: 'host-invoices/',
    ChargeBilling: 'host-invoices/',
  },
  JobForceFinishRequest: {
    GetForce: 'job-force-finish-requests',
    PostApproveForce: 'job-force-finish-requests/accept/',
    PostDeclineForce: 'job-force-finish-requests/reject/',
  },
  DashboardContent: {
    GetDashboardContent: 'admin-dashboard/admin-dashboard-content',
  },
  JobCancelTypes: {
    GetJobCancelTypes: 'job-cancel-types',
  },
  VendorViolation: {
    GetViolation: 'vendor-violation?vendorId=',
  },
  Job: {
    GetJobs: 'jobs/web',
    GetClientManagedAllJobs: 'jobs/client-managed-web',
    GetTurnifyManagedAll: 'jobs/turnify-managed-web',
    GetAllJobs: 'jobs/web',
    GetLiveJobs: 'jobs/live',
    GetClientManagedLiveJobs: 'jobs/client-managed-live',
    GetTurnifyManagedLiveJobs: 'jobs/turnify-managed-live',
    GetJobsByStatus: 'jobs/web?',
    GetJobsByStaffId: 'jobs/web?hostId=',
    GetJobsByUserId: 'jobs/web?userId=',
    GetJobsByPropertyId: 'jobs/web?hostPropertyId=',
    GetJobsByVendorId: 'jobs/web?vendorId=',
    AddJob: 'jobs',
    ChangeAssignJob: 'job-assignment/reassign',
    GetLateStartJobs: 'jobs/web',
    GetLateFinishJobs: 'jobs/web',
    GetMissingJobs: 'jobs/web',
    GetGpsDiscrepanciesJobs: 'jobs/web',
    GetJobServices: 'job-services?jobId=',
    GetJobDetails: 'jobs/',
    AddJobService: 'job-services',
    DeleteJobService: 'job-services/',
    GetJobNotes: 'jobs/notes/',
    AddJobNote: 'job-notes',
    DeleteJobNote: 'job-notes/',
    GetJobTasks: 'job-tasks?jobId=',
    GetJobStaffs: 'job-staff?jobId=',
    AddJobTask: 'job-tasks',
    UpdateJobTask: 'job-tasks/',
    DeleteJobTask: 'job-tasks/',
    GetAllIssue: 'job-issue',
    GetAllIssueByPropertyId: 'job-issue?hostPropertyId=',
    GetLateIssues: 'job-issue?jobIssueTypeId=',
    GetJobIssues: 'job-issue?jobId=',
    ResolveIssue: 'job-issue/resolve/',
    DeclineIssue: 'job-issue/decline/',
    AddJobIssue: 'job-issue',
    UpdateJobIssue: 'job-issue/',
    DeleteJobIssue: 'job-issue/',
    GetChecklistPhotos: 'jobs/get-checklist-photos?jobId=',
    GetJobReview: 'job-review?jobId=',
    AddJobReview: 'job-review',
    DeleteReview: 'job-review/',
    CancelJob: 'jobs/',
    GetJobsDailyByStaffId: 'jobs/daily/',
    ScheduleMarketplaceJob: 'jobs/schedule-marketplace',
    ReScheduleMarketplaceJob: 'jobs/reschedule-marketplace',
    ScheduleJob: 'jobs/schedule',
    ReSchedule: 'jobs/reschedule',
    ScheduleMyTeamJob: 'jobs/schedule-myteam',
    ReScheduleMyTeam: 'jobs/reschedule-myteam',
    ScheduleTurnifyPlusJob: 'jobs/schedule-turnifyplus',
    ReScheduleTurnifyPlus: 'jobs/reschedule-turnifyplus',
    UpdateJobNote: 'job-notes/',
    UpdateJobServiceNote: 'job-services/updateNote/',
    UpdateJobTaskNote: 'job-tasks/updateNote/',
    GetVirtualInspection: 'jobs/virtual-inspection',
    jobForceFinish: 'jobs/force-finish/',
    ScheduleAssignStaffToJob: 'jobs/edit',
    GetJobDuration: 'jobs/duration',
  },
  Staff: {
    GetStaffsByHostId: 'host-users?hostId=',
    GetStaffsByVendorId: 'vendor-users?vendorId=',
    CreateStaffByHostId: 'host-users/host-staff-create',
    GetUserDocumentTypes: 'vendor-user-document-types',
    DeleteUserDocument: 'vendor-user-documents/',
    GetUserDocuments: 'vendor-user-documents?',
    CreateUserDocument: 'vendor-user-documents',
    CreateStaffByVendorId: 'vendor-users/vendor-staff-create',
    GetPermissionsStaffs: 'vendor-users/by-property/',
    GetHostStaffDetails: 'host-users/',
    GetVendorStaffDetails: 'vendor-users/',
    AddStaffOfferedService: 'staff-offered-service',
    UpdateStaffOfferedService: 'staff-offered-service/',
    DeleteStaffOfferedservice: 'staff-offered-service/',
    GetPendingStaffs: 'vendor-users?',
    GetStaffsForAddJob: 'vendor-users/available?vendorId=',
    UpdateVendorStaffStatus: 'vendor-users/editStatus',
    UpdateStaffStatus: 'host-users/editStatus',
    StaffStatusTypes: 'account-status-types',
    StaffChangePassword: 'users/change-password/',
    StaffServiceCities: 'staff-city-service',
    GetOutOfServiceCities: 'staff-city-service/',
  },
  Account: {
    GetAccountStatusType: 'account-status-types',
    StaffStatusTypes: 'account-status-types',
  },
  VirtualInspection: {
    GetVirtualInspections: 'job-virtual-inspections?jobId=',
    GetVirtualInspectionsByJobId: 'job-virtual-inspections/',
    PostVirtualInspections: 'job-virtual-inspections',
    PutApproveVirtualInspections: 'job-virtual-inspections/',
    PutDeclineVirtualInspections: 'job-virtual-inspections/',
  },
  Search: {
    GetSearch: 'search/search?keyword=',
    GetHostSearch: 'search/host?keyword=',
  },
  ICalValidation: {
    ValidateICall: 'host-properties/validate-ical',
  },
  Notification: {
    GetNotifications: 'notifications',
    ReadNotification: 'notifications',
  },
  Settings: {
    GetPaymentFees: 'payment-fee',
    UpdatePaymentFee: 'payment-fee/update',
    GetVendorSubscriptionPacks: 'vendor-subscription-pack',
    UpdateVendorSubscriptionPack: 'vendor-subscription-pack/update',
  },
  Marketplace: {
    GetRequests: 'marketplace/get-all-requests',
    GetRequestDetail: 'marketplace/get-request-detail/',
    GetPropertyOffers: 'marketplace/get-property-offers/',
    GetPropertyOffersByService: 'marketplace/get-property-offers-by-service/',
    GetPropertyMarketplaceServices: 'marketplace/get-property-services/',
    GetPropertyAcceptedMarketplaceServices: 'marketplace/get-property-accepted-services/',
    GetVendorsByJobId: 'marketplace/get-vendors-by-job/',
    AddPropertyOffer: 'marketplace/add-property-offer',
    DeleteMarketplaceOffers: 'marketplace/delete-offers',
  },
  RateType: {
    GetAll: 'rate-type',
  },
  Subscription: {
    GetPacks: 'vendor-subscription-pack',
    SubscribeByAdmin: 'vendors/subscribe-by-admin/',
    GetVendorSubscriptions: 'vendor-subscription/',
    GetVendorSubscriptionInvoiceDetail: 'vendor-subscription/invoice-by-admin/',
    ChargeSubscriptionInvoice: 'vendor-subscription/invoice/charge/',
    CancelSubscription: 'vendor-subscription/cancel-by-admin/',
    GetVendorSubscriptionStaffFee: 'vendor-subscription/staff-fee/',
  },
}
