import {AxiosResponse} from 'axios'
import {Card} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {PageTitle} from '../../../../_metronic/layout/core'
import {IPaymentFee} from '../../../interfaces/PaymentFee'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import PaymentFeeTable from './PaymentFeeTable'

const PaymentFeeSettings = () => {
  const {
    data: fees,
    isLoading,
    error,
    refetch: refetchFeeData,
  } = useQuery<AxiosResponse<{data: Array<IPaymentFee>}>>(
    ['Get Service'],
    () => ApiCalls.getPaymentFees(),
    {
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <PageTitle>Payment Fee Settings</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <PaymentFeeTable data={fees?.data.data} refetchData={refetchFeeData} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
export default PaymentFeeSettings
