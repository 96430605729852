import React, { Dispatch, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiCalls from '../../network/ApiCalls';
import { TextInput } from '../../umut-components/Inputs/TextInput';

type Props = {
    modalOpen: boolean
    close: () => void,
    userId: number
}
const ProfileChangePassword: React.FC<Props> = ({ modalOpen, close, userId }) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [password, setPassword] = useState({ oldPassword: "", newPassword: "", passwordConfirm: "" })
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [matchError, setMatchError] = useState<string | null>(null)

    const handleClose = () => {
        close()
        setErrorMessage(null);
        setPassword({...password,oldPassword:""})
    }


    const handleChangePass = async () => {
        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
        if (password.newPassword !== password.passwordConfirm) {
            setMatchError("* passwords do not match")
        } else if (!password.newPassword.match(reg)) {
            setMatchError("Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter and one number")

        } else
            try {
                setMatchError("")
                setLoading(true);
                await ApiCalls.putStaffPassword({ oldPassword: password?.oldPassword, newPassword: password?.newPassword }, userId)
                setErrorMessage(null);
                setLoading(false);
                handleClose()

            }
            catch (err: any) {
                setLoading(false);
                setErrorMessage(err?.response?.data?.errors?.[0]?.message ?? err?.response?.data?.message);
            }
    }

    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>
                   Change Password
                </Modal.Title>
            </ModalHeader>
            <Modal.Body>
                <form className='form w-100 my-10'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-12'>
                            <p className="mb-10">You can change your password below</p>
                            <label className='form-label fs-6 fw-bolder text-dark'>Old Password {<span style={{ color: "red" }}>*</span>}</label><TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, oldPassword: text })} />

                            <label className='form-label fs-6 fw-bolder text-dark mt-3'>New Password {<span style={{ color: "red" }}>*</span>}</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, newPassword: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                            <label className='form-label fs-6 fw-bolder text-dark mt-6'>New Password (confirm){<span style={{ color: "red" }}>*</span>}</label>
                            <TextInput type="password" onValueChange={(text: string) => setPassword({ ...password, passwordConfirm: text })} />
                            <strong className='d-flex fw-bold break-word text-danger'>{matchError}</strong>

                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="d-flex mx-5" variant="link" onClick={() => { handleClose(); setMatchError("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleChangePass} disabled={loading}>
                    Change {loading ? <Spinner animation='border' size='sm' /> : null}
                </Button>
                <strong className='text-danger'>{errorMessage}</strong>
            </Modal.Footer>
        </Modal>
    )
}

export default ProfileChangePassword;