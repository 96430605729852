import { useSelector } from "react-redux"
import { RedusxAppState } from "../../../../redux/reducers/rootReducer"
import { useQuery } from 'react-query';
import ApiCalls from "../../../../network/ApiCalls";
import SkeletonCircleLoading from "../../../../umut-components/Loading/SkeletonCircleLoading";
import ProfilePhotoTemp from "../../../../umut-components/Icons/ProfilePhotoTemp";
import { useState } from "react";
import AssingmentVendorModal from "./AssingmentVendorModal";
import { ProgressBar } from "react-bootstrap-v5";
import { encryptText } from "../../../../utils/util";
import { Link } from "react-router-dom";


const VendorTab = () => {
    const { jobDetail } = useSelector((state: RedusxAppState) => state.job)
    const [modalOpen, setModalOpen] = useState(false)

    const {
        data: vendorDetailData,
        isLoading: vendorDetailLoading,
        error: isVendorDetailError,
        refetch
    } = useQuery(['Get Vendor Detail', jobDetail?.vendorId], () => ApiCalls.getVendorDetails(jobDetail?.vendorId), {
        refetchOnWindowFocus: false,
        enabled: jobDetail?.vendorId ? true : false
    })

    if (isVendorDetailError) {
        return <div className="d-flex justify-content-center">Error...</div>
    }
    const setHttps = (link: any) => {
        if (link?.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + `${vendorDetailData?.data.website}`;
        }
        return link;

    }
    const handleModalClose = () => { setModalOpen(false) }
    return (

        <>
            <div className="card mb-5 mb-xl-12">
                {!vendorDetailLoading ? <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3 border rounded p-5 shadow-sm">
                        <div className="me-7 mb-5">

                            <div className="symbol symbol-100px symbol-lg-200px symbol-fixed position-relative">
                                {vendorDetailData && <ProfilePhotoTemp width={175} height={175} borderRadius={'50%'} fontSize={40} firstName={vendorDetailData?.data?.name.split(' ')[0] ?? ''} lastName={vendorDetailData?.data?.name.split(' ')[1] ?? ''} />}
                            </div>
                        </div>


                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start mb-2">
                                <div className="d-flex flex-column col-6 flex-wrap w-100">
                                    <div className="d-flex align-items-center mb-2 justify-content-between">
                                     <Link to={(jobDetail?.vendorId ? '/vendor-details/' : ' /host-staff-details/') + encryptText(vendorDetailData?.data?.id)} className="d-flex px-10 "><span className="text-gray-800 text-hover-primary fs-1 fw-bolder me-1 mb-5">{vendorDetailData?.data?.name}</span></Link>
                                         {/* <a href="/" className="text-gray-800 text-hover-primary fs-1 fw-bolder me-1 mb-5">{vendorDetailData?.data.name}</a>  */}
                                        <div className="">
                                            <div className="d-flex justify-content-between align-items-center">
                                           {/* <a href=""></a> <span style={{marginRight:"5px",fontWeight:"bold",fontSize:"20px"}}>{vendorDetailData?.data?.name}</span> */}
{/* 
                                                <span className="text-muted">Vendor Score</span>
                                                 */}
                                            </div>
                                            <ProgressBar className="w-100px w-lg-200px h-5px" animated variant="success" now={vendorDetailData?.data?.vendorScore} />

                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap gap-6 mt-5">

                                        <div className="d-flex flex-column border-1 border-dashed rounded p-3">
                                            <p className="fs-5 fw-bold">Country</p>
                                            <span className="d-flex align-items-center text-nowrap text-center text-gray-500 text-hover-primary me-5 mb2">
                                                {vendorDetailData?.data.countryName}
                                            </span>
                                        </div>

                                        <div className="d-flex flex-column font-weight-bold border-1 border-dashed rounded p-3">
                                            <p className="fs-5 fw-bold">State</p>
                                            <p className="d-flex align-items-center text-nowrap text-gray-500 text-hover-primary me-5 mb-2">
                                                {vendorDetailData?.data.stateName}
                                            </p>
                                        </div>
                                        <div className="d-flex flex-column font-weight-bold border-1 border-dashed rounded p-3">
                                            <p className="fs-5 text-nowrap fw-bold">City</p>
                                            <p className="d-flex align-items-center text-nowrap text-gray-500 text-hover-primary me-5">
                                                {vendorDetailData?.data.cityName}
                                            </p>
                                        </div>
                                        <div className="d-flex flex-column font-weight-bold border-1 border-dashed rounded p-3">
                                            <p className="fs-5 fw-bold text-nowrap">Staff</p>
                                            <div className="d-flex gap-1">
                                                <span>
                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                                        <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path>
                                                        <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path></svg>
                                                </span>
                                                <p className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 justify-content-center">
                                                    {vendorDetailData?.data.staffCount}
                                                </p>
                                            </div>


                                        </div>

                                        <div className="d-flex flex-column font-weight-bold border-1 border-dashed rounded p-3">
                                            <p className="fs-5 fw-bold text-center" >Website</p>
                                            <a href={setHttps(vendorDetailData?.data.website)} target="_blank" className="d-flex align-items-center text-gray-500 text-hover-primary mb-2"><span className="svg-icon svg-icon-4 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                                                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                                                </svg>
                                            </span>{vendorDetailData?.data.website}</a>
                                        </div>
                                        <div className="d-flex flex-column font-weight-bold border-1 border-dashed rounded p-3">
                                            <p className="fs-5 fw-bold" >Phone Number</p>
                                            <a href={`tel:${vendorDetailData?.data.phoneNumber}`} className="d-flex align-items-center text-nowrap text-gray-500 text-hover-primary me-5 mb-2">
                                                <span className="svg-icon svg-icon-4 me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                    </svg>
                                                </span>{vendorDetailData?.data.phoneNumber}
                                            </a>

                                        </div>
                                        <div className="d-flex flex-column font-weight-bold border-1 border-dashed rounded p-3">
                                            <p className="fs-5 fw-bold" >Email</p>
                                            <div className="d-flex gap-2">
                                                <span className="symbol">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M13.8,4 C13.1562,4 12.4033,4.72985286 12,5.2 C11.5967,4.72985286 10.8438,4 10.2,4 C9.0604,4 8.4,4.88887193 8.4,6.02016349 C8.4,7.27338783 9.6,8.6 12,10 C14.4,8.6 15.6,7.3 15.6,6.1 C15.6,4.96870845 14.9396,4 13.8,4 Z" fill="#000000" opacity="0.3"></path>
                                                            <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z" fill="#000000"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <a href={`mailto:${vendorDetailData?.data?.email}`} className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 text-center">{vendorDetailData?.data?.email}</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> : <div className="mt-5"><SkeletonCircleLoading /></div>}

            </div>
            {modalOpen && <AssingmentVendorModal show={modalOpen} closeModal={handleModalClose} />}
        </>
    )
}

export default VendorTab
