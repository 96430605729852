import {TextField} from '@material-ui/core'
import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import ApiCalls from '../../../network/ApiCalls'
import {JobAction} from '../../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import Select, {components} from 'react-select'
import * as jobActionCreator from '../../../redux/actionCreators/jobActionCreators'
import ArrowButton from '../../Buttons/ArrowButton'
import {selectStyles} from '../../../config/selectStyle'
import DateTimePicker from '../../Inputs/DateTimePicker'
import {CleaningProviders, HostPropertyStatus} from '../../../enums/PropertyEnum'
import {convertUTCDateToLocalDate, toConvertedTimeString} from '../../../utils/util'
import {ISelectedVendorResponse} from '../../../network/NetworkResponses/NetworkResponses'
import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import VirtualSelect from '../../Inputs/VirtualSelect'
import {useParams} from 'react-router'
import {valueContainerCSS} from 'react-select/src/components/containers'

type Props = {
  nextStep?: () => void
  previousStep?: () => void
  propertyId?: number
}

type DateTime = {
  date?: string | null
  time?: string | null
}

type VendorSelectType = {
  label: string
  value: number
}

const PropertyStep: React.FC<Props> = ({nextStep, previousStep, propertyId}) => {
  const roleRef = useRef<any>(null)
  const vendorRef = useRef<any>(null)
  const {jobDialog} = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [properties, setProperties] = useState<any>([])
  const [roles, setRoles] = useState([])
  const [startDate, setStartDate] = useState<string | null>(null)
  const [vendors, setVendors] = useState<VendorSelectType[]>([])
  const {selectedJobProvider} = useSelector((state: RedusxAppState) => state.job)
  const [checkout, setCheckout] = useState('10:00')

  const {
    data: propertyData,
    isLoading: propertyLoading,
    error: propertyError,
  } = useQuery(
    'Get Properties for Job Add',
    () =>
      propertyId != undefined
        ? ApiCalls.getPropertyById(propertyId)
        : ApiCalls.getPropertiesByStatusId(HostPropertyStatus['Active'], selectedJobProvider),
    {cacheTime: 500000, refetchOnWindowFocus: true}
  )
  const {
    data: roleData,
    isLoading: roleLoading,
    error: roleError,
  } = useQuery('Get Roles for Job Add', () => ApiCalls.getStaffRoles(), {
    cacheTime: 500000,
    refetchOnWindowFocus: false,
  })
  const {
    data: vendorData,
    isFetching: vendorLoading,
    error: vendorError,
  } = useQuery<AxiosResponse<ISelectedVendorResponse>>(
    ['Get selected vendors', jobDialog.jobForm?.hostPropertyId],
    () => ApiCalls.getAvailableVendors(jobDialog.jobForm?.hostPropertyId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: jobDialog.jobForm?.hostPropertyId ? true : false,
    }
  )
  let {providerName}: any = useParams()

  const handleChangeProperty = (value: any) => {
    setCheckout(value?.value?.checkOut)

    setStartDate(dayjs().format('YYYY-MM-DDT' + value?.value?.checkOut))

    if (propertyId != undefined) {
      setRoles(
        roleData?.data
          ?.filter((role: any) => {
            if (value?.value?.cleaningProviderId === 2) {
              return (
                role.lobRoleName === 'Vendor' &&
                role.roleName !== 'Manager' &&
                role.roleName !== 'Admin'
              )
            }
            return (
              role.lobRoleName === 'Host' &&
              role.roleName !== 'Manager' &&
              role.roleName !== 'Admin'
            )
          })
          .map((role: any) => {
            return {
              value: role.roleId,
              label: role.roleName,
            }
          })
      )
    } else {
      setRoles(
        roleData?.data
          ?.filter((role: any) => {
            if (value?.value?.cleaningProviderName === 'Turnify PRO') {
              return (
                role.lobRoleName === 'Vendor' &&
                role.roleName !== 'Manager' &&
                role.roleName !== 'Admin'
              )
            }
            return (
              role.lobRoleName === 'Host' &&
              role.roleName !== 'Manager' &&
              role.roleName !== 'Admin'
            )
          })
          .map((role: any) => {
            return {
              value: role.roleId,
              label: role.roleName,
            }
          })
      )
    }

    vendorRef.current?.select?.clearValue()
    dispatch(
      jobActionCreator.setJobForm({
        ...jobDialog.jobForm,
        jobServices: undefined,
        hostPropertyId: parseInt(value?.value?.id),
        roleId: 11,
        vendorId: undefined,
        jobTimezone: value?.value?.hostProperty
          ? value?.value?.hostProperty.timeZone
          : value?.value?.timeZone,
        cleaningPeriodStart: dayjs().tz(value?.value?.timeZone).format('YYYY-MM-DDTHH:mm:ss.sss'),
      })
    )
  }

  const handleTypePropertyAddress = (value: any) => {
    if (value.length > 2) {
      //console.log(value);
    }
  }

  useEffect(() => {
    if (propertyId != undefined) {
      if (propertyData) {
        const props = []
        props.push({
          label: propertyData?.data?.hostProperty?.name,
          value: propertyData?.data,
        })

        setProperties(props)
      }
    } else {
      if (propertyData) {
        let providerId = providerName === 'my-team' ? 1 : providerName === 'turnify-plus' ? 3 : 2
        setProperties(
          propertyData?.data?.data
            ?.filter(
              (property: any) =>
                (providerId === 2 && property?.cleaningProviderName === 'Turnify PRO') ||
                (providerId === 3 && property?.cleaningProviderName === 'Turnify+')
            )
            ?.map((property: any) => {
              return {
                value: property,
                label: property.name + ` (${property.cleaningProviderName})`,
              }
            })
        )
      }
    }
  }, [propertyData])

  useEffect(() => {
    if (roleData) {
      setRoles(
        roleData?.data?.map((role: any) => {
          return {
            value: role.roleId,
            label: role.roleName,
          }
        })
      )
    }
  }, [roleData])

  useEffect(() => {
    if (startDate && startDate) {
      dispatch(jobActionCreator.setJobForm({...jobDialog.jobForm, cleaningPeriodStart: startDate}))
    }
  }, [startDate])

  useEffect(() => {
    if (vendorData) {
      setVendors(
        vendorData.data.data.map((vendor) => ({label: vendor.vendorName, value: vendor.id}))
      )
      dispatch(jobActionCreator.setJobForm({...jobDialog.jobForm, vendorId: undefined}))
    }
  }, [vendorData])
  const NoOptionsMessageProperty = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Please select property.</span>
      </components.NoOptionsMessage>
    )
  }
  const NoOptionsMessageDo = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No role was found.Please select property first.</span>
      </components.NoOptionsMessage>
    )
  }
  const NoOptionsMessageVendor = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No suitable vendor was found.</span>
      </components.NoOptionsMessage>
    )
  }

  return (
    <div className='row'>
      {/* begin::Form group */}
      <div className='fv-row col-lg-12'>
        <label className='form-label fs-6 fw-bolder text-dark'>Property</label>
        <VirtualSelect
          isLoading={propertyLoading}
          onChange={handleChangeProperty}
          options={properties}
          errorMessage={'no found Property.Please add property first.'}
          onInputChange={handleTypePropertyAddress}
        />
      </div>
      <div className='fv-row col-lg-12 mt-6'>
        <label className='form-label fs-6 fw-bolder text-dark'>Who will do this?</label>
        <Select
          components={{NoOptionsMessage: NoOptionsMessageDo}}
          styles={selectStyles}
          isLoading={roleLoading}
          ref={roleRef}
          onChange={(value: any) => {
            dispatch(
              jobActionCreator.setJobForm({
                ...jobDialog.jobForm,
                jobServices: undefined,
                roleId: value?.value,
              })
            )
          }}
          options={jobDialog?.jobForm?.hostPropertyId ? roles : []}
          defaultValue={{value: 11, label: 'Cleaner'}}
        />
      </div>
      {jobDialog.jobForm?.jobTimezone && (
        <div className='fv-row col-lg-12 mt-6'>
          <label className='form-label fs-6 fw-bolder text-dark'>Cleaning Date</label>
          <DateTimePicker
            defaultValue={toConvertedTimeString(
              dayjs()
                .subtract(30, 'minutes')
                .format('YYYY-MM-DDT' + checkout)
                .toString(),
              jobDialog.jobForm?.jobTimezone
            )}
            onChange={setStartDate}
            dateOutOfRangeMessage={'Jobs cannot be created in past!'}
          />
          <small className='text-muted'>
            Property time:{' '}
            {convertUTCDateToLocalDate(dayjs().toString(), jobDialog.jobForm?.jobTimezone)}
          </small>
        </div>
      )}
      <div className='d-flex justify-content-end'>
        <ArrowButton
          type='forward'
          func={nextStep}
          disabled={
            !jobDialog.jobForm?.hostPropertyId ||
            !jobDialog.jobForm?.roleId ||
            !jobDialog.jobForm.cleaningPeriodStart
          }
          classNames='mt-6'
        />
        {/* // disabled cond - || !jobDialog.jobForm?.vendorId */}
      </div>
    </div>
  )
}

export default PropertyStep
