import {
    Column,
    DataGrid,
    Export,
    HeaderFilter,
    Paging,
    Scrolling,
    SearchPanel,
} from 'devextreme-react/data-grid'
import { Dispatch, FC, useRef } from 'react'
import DeleteButton from '../../../../../umut-components/Buttons/DeleteButton'
import * as deleteDialogActionCreators from '../../../../../redux/actionCreators/deleteDialogActionCreators';
import ApiCalls from '../../../../../network/ApiCalls';
import { useDispatch } from 'react-redux';
import { DeleteActions } from '../../../../../redux/actionTypes/deleteDialogTypes';
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable';
import { onExporting } from '../../../../../utils/util';

type Props = {
    data: any[]
    refetch: () => void,
    vendorId: number
}
const ServiceCitiesTable: FC<Props> = ({ data, vendorId, refetch }) => {

    const dispatch = useDispatch<Dispatch<DeleteActions>>();
    const tableRef = useRef<DataGrid>(null)

    const displayDeleteButton = (cellData: any) => {
        return (
            <DeleteButton iconWidth="20" iconHeight="20" deleteFn={() => {
                dispatch(deleteDialogActionCreators.openDeleteDialog(
                    async () => {
                        try {
                            await ApiCalls.deleteVendorCityService(cellData?.data?.id)
                            dispatch(deleteDialogActionCreators.closeDeleteDialog());
                            refetch();
                        }
                        catch (err: any) {
                            console.log(err)
                        }
                    },
                    `Are you sure you want to delete?`,
                    'Delete Service City?'
                ))
            }} />
        )
    }
    if (data?.length === 0) {
        return <EmptyTable title="No Service Cities" />
    }
    return (
        <div>
            <DataGrid
                id='ServiceCitiesTable'
                keyExpr='id'
                onRowPrepared={(e) => {
                    e.rowElement.classList.add('data-grid-row')
                }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                ref={tableRef}
                height={'calc(50vh - 60px)'}
                allowColumnResizing={true}
                onExporting={(e) => onExporting(e, "Service Cities")}
                selection={{ mode: 'single' }}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <Scrolling showScrollbar='always' />
                <Paging enabled={false} />
                <SearchPanel visible={true} />
                <Column dataField='cityName' minWidth={150} cssClass='cls' />
                <Column
                    dataField='stateName'
                    alignment='center'
                    caption='STATE'
                    minWidth={150}
                    cssClass='cls'
                />
                <Column dataField='zipCode' alignment='center' caption='ZIPCODE' minWidth={80} cssClass='cls' />

                <Column width={110} alignment='center' cellRender={displayDeleteButton} cssClass='cls' />
            </DataGrid>
          
        </div>
    )
}

export default ServiceCitiesTable
