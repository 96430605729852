import { boolean } from "yup/lib/locale";
import { IOfferedService, IStaff, IStaffDetail } from "../../interfaces/Staff";
import * as actions from "../actionTypes/staffActionTypes";

export interface StaffState {
    staffList: IStaff[],
    listLoading: boolean,
    listErrorText: string,
    staffAddDialog: {
        dialogOpen: boolean,
        refetch: () => void
    },
    staffEditDialog: {
        dialogOpen: boolean,
        refetch: () => void,
        staff: IStaff | null
    },
    staffDetail: {
        staff: IStaffDetail | null,
        loading: boolean,
        errorText: string
    },
    staffOfferedDialog: {
        modalOpen: boolean,
        refetch: () => void,
        staffOfferedService?: IOfferedService | null
    },
    staffPropertyDialog: {
        modalOpen: boolean,
        refetch: () => void,
        formType: "allowed" | "banned" | null
    }
}

const initialState: StaffState = {
    staffList: [],
    listLoading: false,
    listErrorText: '',
    staffAddDialog: {
        dialogOpen: false,
        refetch: () => { }
    },
    staffEditDialog: {
        dialogOpen: false,
        refetch: () => { },
        staff: null
    },
    staffDetail: {
        staff: null,
        loading: false,
        errorText: ''
    },
    staffOfferedDialog: {
        modalOpen: false,
        refetch: () => { },
        staffOfferedService: null
    },
    staffPropertyDialog: {
        modalOpen: false,
        refetch: () => { },
        formType: "allowed"
    }
}

export default function staffReducer(
    state: StaffState = initialState,
    action: actions.StaffAction
): StaffState {
    switch (action.type) {
        case actions.GET_STAFF_LIST:
            return { ...state, listErrorText: '', listLoading: true }
        case actions.GET_STAFF_LIST_SUCCESS:
            return { ...state, listErrorText: '', listLoading: false, staffList: action.staffList }
        case actions.GET_STAFF_LIST_FAILURE:
            return { ...state, listErrorText: action.errorText, listLoading: false, staffList: [] }
        case actions.GET_HOST_STAFF_DETAIL:
            return { ...state, staffDetail: { loading: true, errorText: '', staff: null } }
        case actions.GET_VENDOR_STAFF_DETAIL:
            return { ...state, staffDetail: { loading: true, errorText: '', staff: null } }
        case actions.GET_STAFF_DETAIL_SUCCESS:
            return { ...state, staffDetail: { loading: false, errorText: '', staff: action.staff } }
        case actions.GET_STAFF_DETAIL_FAILURE:
            return { ...state, staffDetail: { loading: false, errorText: action.errorText, staff: null } }
        case actions.OPEN_STAFF_ADD_DIALOG:
            return { ...state, staffAddDialog: { dialogOpen: true, refetch: action.refetch } }
        case actions.CLOSE_STAFF_ADD_DIALOG:
            return { ...state, staffAddDialog: { dialogOpen: false, refetch: () => { } } }
        case actions.OPEN_STAFF_EDIT_DIALOG:
            return { ...state, staffEditDialog: { dialogOpen: true, refetch: action.refetch, staff: action.staff } }
        case actions.CLOSE_STAFF_EDIT_DIALOG:
            return { ...state, staffEditDialog: { dialogOpen: false, refetch: () => { }, staff: null } }
        case actions.OPEN_STAFF_OFFERED_ADD_DIALOG:
            return { ...state, staffOfferedDialog: { modalOpen: true, refetch: action.refetch, staffOfferedService: action.staffOfferedService } }
        case actions.CLOSE_STAFF_OFFERED_ADD_DIALOG:
            return { ...state, staffOfferedDialog: { modalOpen: false, refetch: () => { }, staffOfferedService: null } }
        case actions.OPEN_STAFF_PROPERTY_DIALOG:
            return { ...state, staffPropertyDialog: { modalOpen: true, refetch: action.refetch, formType: action.formType } }
        case actions.CLOSE_STAFF_PROPERTY_DIALOG:
            return { ...state, staffPropertyDialog: { modalOpen: false, refetch: () => { }, formType: null } }
        default:
            return state;
    }
}