import React, { Dispatch } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useQuery } from 'react-query';
import ApiCalls from '../../../network/ApiCalls';
import SelectBox from '../../Inputs/SelectBox';
import { TextInput } from '../../Inputs/TextInput';
import { ChecklistQuestionEditRequest } from '../../../network/PostRequestModels/ChecklistRequests';
import { useDispatch, useSelector } from 'react-redux';
import { useDialog } from '../../../contexts/DialogContext';
import { IQuestion } from '../../../interfaces/Checklist';
import { ChecklistAction } from '../../../redux/actionTypes/checklistTypes';
import * as checklistActionCreator from '../../../redux/actionCreators/checklistActionCreators';
import SwitchBox from '../../Inputs/SwitchBox';
import SelectInput from '../../Inputs/SelectInput';
import DeleteButton from '../../Buttons/DeleteButton';
import { AxiosResponse } from 'axios';
import { LanguageResponse } from '../../../network/NetworkResponses/NetworkResponses';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { LobRoleNameEnum } from '../../../enums/UserRoles';

type Props = {
    question: IQuestion | null,
    checklistId: string,
    cleaningProviderName: string
}

type LanguageOptions = {
    label: string;
    value: string;
}

type NameInput = {
    code: string;
    description: string;
    title: string;
}

const QuestionEditForm: React.FC<Props> = ({ question, checklistId, cleaningProviderName }) => {
    const { showSuccessDialog } = useDialog();
    const { checklistItems } = useSelector((state: RedusxAppState) => state.checklist);
    const dispatch = useDispatch<Dispatch<ChecklistAction>>();
    const [questionRequest, setQuestionRequest] = useState<ChecklistQuestionEditRequest>({
        checklistAnswerTypeId: question?.checklistAnswerTypeId,
        isPhotoMandatory: question?.isPhotoMandatory,
        hasVirtualInspection: question?.hasVirtualInspection
    } as ChecklistQuestionEditRequest);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [checklistAnswerTypes, setChecklistAnswerTypes] = useState([]);
    const [questionNames, setQuestionNames] = useState<NameInput[]>([]);
    const [languages, setLanguages] = useState<LanguageOptions[]>([]);
    const { data: answerTypesData, isLoading: answerTypesLoading, error: answerTypesError } = useQuery('Get Answer Types', () => ApiCalls.getChecklistAnswerTypes(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const { data: languageData, isLoading: languageLoading, error: languageError } = useQuery<AxiosResponse<LanguageResponse>>('Get Languages', () => ApiCalls.getLanguages(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const isTurnifyPro = checklistItems?.lobRoleId === LobRoleNameEnum.VENDOR;

    const handleQuestionEdit = async () => {
        try {
            const isNamesValid = !questionNames.some(name => name.title === '' || name.description === '' || !name.title || !name.description) && questionNames.length > 0;
            if (isNamesValid) {
                setLoading(true);
                setErrorMessage(null);
                const { checklistAnswerTypeId, isPhotoMandatory } = questionRequest;
                if (checklistAnswerTypeId !== undefined && isPhotoMandatory !== undefined) {
                    const titles = questionNames.reduce((total, currentName) => ({ ...total, [currentName.code]: currentName.title }), {});
                    const descriptions = questionNames.reduce((total, currentName) => ({ ...total, [currentName.code]: currentName.description }), {});
                    await ApiCalls.editQuestion({ ...questionRequest, title: titles, description: descriptions, hasVirtualInspection: isTurnifyPro && questionRequest.isPhotoMandatory && questionRequest.hasVirtualInspection }, question?.id);
                    dispatch(checklistActionCreator.closeQuestionEditDialog());
                    dispatch(checklistActionCreator.getChecklistItems(parseInt(checklistId)));
                    setLoading(false);
                    showSuccessDialog('Question Updated.');
                }
                else {
                    setLoading(false)
                    setErrorMessage('All fields are required.');
                }
            }
            else {
                setErrorMessage('Please fill all titles and descriptions.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message);
        }
    }

    const handleChangeLanguage = (languageCode: string, index: number) => {
        let newQuestionNames = [...questionNames];
        newQuestionNames[index].code = languageCode;
        setQuestionNames(newQuestionNames);
    }

    const handleChangeDescription = (newText: string, index: number) => {
        let newQuestionNames = [...questionNames];
        newQuestionNames[index].description = newText;
        setQuestionNames(newQuestionNames);
    }

    const handleChangeTitle = (newText: string, index: number) => {
        let newQuestionNames = [...questionNames];
        newQuestionNames[index].title = newText;
        setQuestionNames(newQuestionNames);
    }

    const handleAddName = () => {
        let newName: NameInput = { code: 'en', description: '', title: '' };
        setQuestionNames([...questionNames, newName]);
    }

    const removeName = (removeIndex: number) => {
        if (questionNames.length > 1) {
            setQuestionNames(questionNames.filter((name, index) => index !== removeIndex));
        }
        else {
            setQuestionNames([{ code: 'en', description: '', title: '' }]);
        }
    }

    useEffect(() => {
        if (answerTypesData) {
            setChecklistAnswerTypes(answerTypesData?.data?.data?.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [answerTypesData])

    useEffect(() => {
        if (languageData) {
            setLanguages(languageData.data.data.map(language => ({ label: language.name, value: language.code })));
        }
    }, [languageData])

    useEffect(() => {
        if (question) {
            let oldTitles = Object.entries(question.title).map(([code, title]) => ({ code, title }));
            let oldQuestions = oldTitles.map((name: any) => ({ ...name, description: question.description[name.code] }));
            setQuestionNames(oldQuestions);
        }
    }, [question])

    if (answerTypesLoading || languageLoading) {
        return <div className='d-flex justify-content-center'><Spinner animation='border' /></div>
    }

    return (
        <form className='form w-100'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>
                    {
                        questionNames.map((name, index) => <div className='row mt-6'>
                            <div className='col-5'>
                                <label className='w-100 form-label fs-6 fw-bolder text-dark'>Language #{index + 1}</label>
                                <SelectInput options={languages} defaultValue={name.code} onValueChanged={value => handleChangeLanguage(value, index)} />
                            </div>
                            <div className='col-6'>
                                <label className='w-100 form-label fs-6 fw-bolder text-dark'>Title #{index + 1}</label>
                                <input
                                    value={name.title}
                                    onChange={e => handleChangeTitle(e.target.value, index)}
                                    className='form-control'
                                    type='text'
                                    autoComplete='off'
                                    required
                                />
                            </div>
                            <div className='col-1 d-flex align-items-end'>
                                <DeleteButton iconWidth='20' iconHeight='20' classNames='btn btn-link btn-sm text-danger' deleteFn={() => removeName(index)} />
                            </div>
                            <div className='col-12 border-bottom pb-6 mt-3'>
                                <label className='w-100 form-label fs-6 fw-bolder text-dark'>Description #{index + 1}</label>
                                <textarea
                                    style={{ minHeight: 90 }}
                                    value={name.description}
                                    onChange={e => handleChangeDescription(e.target.value, index)}
                                    className='form-control'
                                    autoComplete='off'
                                    required
                                />
                            </div>
                        </div>
                        )
                    }
                    <button type='button' className='btn btn-info btn-sm w-100 mt-3' onClick={handleAddName}>Add checklist item name in different language</button>

                    <label className='w-100 form-label fs-6 fw-bolder text-dark mt-6'>Select Answer Type</label>
                    <small className='text-muted'>What type of response are you expecting from your cleaner for this task? For example, select "number" to require a numeric response, such as "How may spoons are there?" answer "4".</small>
                    <SelectInput classNames='mt-2' defaultValue={questionRequest.checklistAnswerTypeId} options={checklistAnswerTypes} onValueChanged={(value: any) => setQuestionRequest({ ...questionRequest, checklistAnswerTypeId: value })} />

                    <div className='row mt-6'>
                        <div className='col-12 mt-6 text-start'>
                            <SwitchBox text='Photo Required?' defaultChecked={questionRequest.isPhotoMandatory} onCheckboxChange={(check: boolean) => setQuestionRequest({ ...questionRequest, isPhotoMandatory: check })} />
                        </div>
                        {isTurnifyPro && questionRequest.isPhotoMandatory && (
                            <div className='col-lg-12 mt-6'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Do you want this checklist item to be inspected virtually? <span className='text-danger'>*</span><small className='text-muted'>additional fees may apply.</small></label>
                                <SwitchBox
                                    text='Want an extra set of eyes? Turn on Virtual Inspection for this room and Turnify virtual inspector will ensure that the room is prepared to your spec. *Additional charges may apply'
                                    mutedText={true}
                                    defaultChecked={questionRequest.hasVirtualInspection}
                                    onCheckboxChange={(check: boolean) =>
                                        setQuestionRequest({ ...questionRequest, hasVirtualInspection: check })
                                    }
                                />
                            </div>
                        )}
                        <div className='col-12 mt-6 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleQuestionEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(QuestionEditForm);