import { IOfferedService, IStaff, IStaffDetail } from '../../interfaces/Staff';
import * as actions from '../actionTypes/staffActionTypes';

export function getStaffList(hostId: number): actions.GetStaffList {
    return {
        type: actions.GET_STAFF_LIST,
        hostId
    }
}

export function getStaffListSuccess(staffList: IStaff[]): actions.GetStaffListSuccess {
    return {
        type: actions.GET_STAFF_LIST_SUCCESS,
        staffList
    }
}

export function getStaffListFailure(errorText: string): actions.GetStaffListFailure {
    return {
        type: actions.GET_STAFF_LIST_FAILURE,
        errorText
    }
}

export function getStaffDetailByHostUserId(hostUserId: number): actions.GetHostStaffDetail {
    return {
        type: actions.GET_HOST_STAFF_DETAIL,
        hostUserId
    }
}

export function getStaffDetailByVendorUserId(vendorUserId: number): actions.GetVendorStaffDetail {
    return {
        type: actions.GET_VENDOR_STAFF_DETAIL,
        vendorUserId
    }
}

export function getStaffDetailSuccess(staff: IStaffDetail): actions.GetStaffDetailSuccess {
    return {
        type: actions.GET_STAFF_DETAIL_SUCCESS,
        staff
    }
}

export function getStaffDetailFailure(errorText: string): actions.GetStaffDetailFailure {
    return {
        type: actions.GET_STAFF_DETAIL_FAILURE,
        errorText
    }
}

export function openStaffAddDialog(refetch: () => void): actions.OpenStaffAddDialog {
    return {
        type: actions.OPEN_STAFF_ADD_DIALOG,
        refetch
    }
}

export function closeStaffAddDialog(): actions.CloseStaffAddDialog {
    return {
        type: actions.CLOSE_STAFF_ADD_DIALOG,
    }
}

export function openStaffEditDialog(refetch: () => void, staff: IStaff): actions.OpenStaffEditDialog {
    return {
        type: actions.OPEN_STAFF_EDIT_DIALOG,
        refetch,
        staff
    }
}

export function closeStaffEditDialog(): actions.CloseStaffEditDialog {
    return {
        type: actions.CLOSE_STAFF_EDIT_DIALOG,
    }
}

export function openStaffOfferedAddDialog(refetch: () => void, staffOfferedService?: IOfferedService): actions.OpenStaffOfferedAddDialog {
    return {
        type: actions.OPEN_STAFF_OFFERED_ADD_DIALOG,
        refetch,
        staffOfferedService
    }
}

export function closeStaffOfferedAddDialog(): actions.CloseStaffOfferedAddDialog {
    return {
        type: actions.CLOSE_STAFF_OFFERED_ADD_DIALOG,
    }
}

export function openStaffPropertyDialog(refetch: () => void, formType: "allowed" | "banned"): actions.OpenStaffPropertyDialog {
    return {
        type: actions.OPEN_STAFF_PROPERTY_DIALOG,
        refetch,
        formType
    }
}

export function closeStaffPropertyDialog(): actions.CloseStaffPropertyDialog {
    return {
        type: actions.CLOSE_STAFF_PROPERTY_DIALOG,
    }
}
export function openStaffDocumentView(staff: any): actions.OpenStaffDocumentDialog {
    return {
        type: actions.OPEN_STAFF_DOCUMENT_DIALOG,
        staff
    }
}

export function closeStaffDocumentView(): actions.CloseStaffDocumentDialog {
    return {
        type: actions.CLOSE_STAFF_DOCUMENT_DIALOG,
    }
}