import {AxiosResponse} from 'axios'
import {Dispatch, FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {JobStatusType} from '../../../enums/JobEnum'
import {IJobDetail} from '../../../interfaces/Job'
import ApiCalls from '../../../network/ApiCalls'
import SchedulerDialog from '../../../pages/job-details/Tabs/VendorTab/SchedulerDialog'
import SuitableVendorsTable from '../../../pages/job-details/Tabs/VendorTab/SuitableVendorsTable'
import {setJobDetail} from '../../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../Loading/SkeletonTableLoading'
import {HostPropertyStatus} from '../../../enums/PropertyEnum'

const AssignStep = () => {
  const {jobId} = useSelector((state: RedusxAppState) => state.job)
  const {selectedJobProvider} = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()
  const [checkout, setCheckout] = useState('10:00')

  const {data: vendors, isLoading: availableVendor} = useQuery(
    ['Get Available Vendors', jobId],
    () => ApiCalls.getSuitableVendorByJobId(jobId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false}
  )

  const {data, isLoading, error, refetch} = useQuery<AxiosResponse<IJobDetail>>(
    ['Get Job Detail', jobId],
    () => ApiCalls.getJobDetails(jobId),
    {cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobId ? true : false}
  )

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        ApiCalls.getHostPropertyById(data?.data?.hostPropertyId).then((res) => {
          if (res?.status == 200) {
            setCheckout(res?.data?.hostPropertyAutomation?.checkOutTime)
          }
        })
      }
    }
  }, [isLoading, data])

  useEffect(() => {
    if (data) {
      dispatch(setJobDetail(data.data))
    }
    return () => {
      dispatch(setJobDetail(null))
    }
  }, [data])

  if (availableVendor) {
    return <SkeletonTableLoading />
  }
  if (!jobId) {
    return <div></div>
  }

  return (
    <>
      <div>
        <SuitableVendorsTable data={vendors?.data} checkOut={checkout} />
      </div>

      <SchedulerDialog />
    </>
  )
}

export default AssignStep
