import {Dispatch, FC, useEffect, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  ColumnHeaderFilter,
  MasterDetail,
} from 'devextreme-react/data-grid'
import {
  convertUTCDateToLocalDate,
  decryptText,
  onExporting,
  toRequestTimeFormat,
} from '../../../../utils/util'
import StaffAccordionTab from './StaffAccordionTab'
import DateTimePicker from '../../../../umut-components/Inputs/DateTimePicker'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import dayjs from 'dayjs'
import {Spinner} from 'react-bootstrap-v5'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import {useParams} from 'react-router-dom'
import React from 'react'
import ConfirmationDialog from './ConfirmationDialog'
import {useDialog} from '../../../../contexts/DialogContext'
import notify from 'devextreme/ui/notify'
import {JobAction} from '../../../../redux/actionTypes/jobTypes'
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators'
import HostStaffAccordionTab from './HostStaffAccordionTab'
import JobDetails from '../../JobDetails'
import SkeletonTableLoading from '../../../../umut-components/Loading/SkeletonTableLoading'
import {Z_DATA_ERROR} from 'zlib'
import {IHost} from '../../../../interfaces/Host'
import ProfilePhotoTemp from '../../../../umut-components/Icons/ProfilePhotoTemp'
import {IReStaffAssign} from '../../../../network/PostRequestModels/ReAssignTurnifyPlusRequests'

type Props = {
  data: any
}
const SuitableStaffsTable: FC<Props> = ({data}) => {
  let {jobId, hostId}: any = useParams()

  hostId = hostId ? decryptText(hostId) : undefined

  const {jobDetail}: any = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()

  jobId = jobId ?? jobDetail?.id
  const {scheduleStartTime}: any = useSelector((state: RedusxAppState) => state.assign)

  const [hostDetail, setHostDetail] = useState<IHost | null>(null)

  useEffect(() => {
    if (data) {
      setHostDetail(data)
    }
  }, [data])

  const ref = useRef<{getAssingObject: () => IReStaffAssign}>()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const {showSuccessDialog} = useDialog()

  const [loading, setLoading] = useState(false)
  const [scheduleStart, setScheduleStart] = useState<string>(
    toRequestTimeFormat(jobDetail?.hostProperty!.city.timezone, jobDetail?.cleaningPeriodStart)
  )

  const [errorMessage, setErrorMessage] = useState()
  const queryClient = useQueryClient()

  const {mutateAsync: scheduledMyTeamJob} = useMutation(ApiCalls.scheduleMyTeamJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })
  const {mutateAsync: reScheduledMyTeamJob} = useMutation(ApiCalls.putReScheduleMyTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })

  const {mutateAsync: scheduledJob} = useMutation(ApiCalls.scheduleTurnifyPlusJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })
  const {mutateAsync: reScheduledJob} = useMutation(ApiCalls.putReScheduleTurnifyPlus, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })

  const checkScheduledEndTime = () => {
    let day1 = dayjs(scheduleStart)
    let day2 = dayjs(jobDetail?.cleaningPeriodEnd)
    if (day2.diff(day1) <= 0) {
      setConfirmationModal(true)
    } else {
      sendRequest()
    }
  }

  const sendRequest = async () => {
    try {
      if (ref.current) {
        const reAssignObject: IReStaffAssign = {
          ...ref.current.getAssingObject(),
          scheduledStart: scheduleStart,
        }
        setLoading(true)
        {
          jobDetail.jobStatusType.name == 'Pending' &&
            +jobDetail.cleaningProviderId == 1 &&
            (await scheduledMyTeamJob(reAssignObject))
        }
        {
          jobDetail.jobStatusType.name == 'Scheduled' &&
            +jobDetail.cleaningProviderId == 1 &&
            (await reScheduledMyTeamJob(reAssignObject))
        }

        {
          jobDetail.jobStatusType.name == 'Pending' &&
            +jobDetail.cleaningProviderId == 3 &&
            (await scheduledJob(reAssignObject))
        }
        {
          jobDetail.jobStatusType.name == 'Scheduled' &&
            +jobDetail.cleaningProviderId == 3 &&
            (await reScheduledJob(reAssignObject))
        }
        dispatch(jobActionCreator.closeJobDialog())
        showSuccessDialog('Assignment Successful.')
      }
    } catch (error: any) {
      notify(
        {
          message: error?.response?.data?.message,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        },
        'warning',
        5000
      )
      setLoading(false)
    }
  }

  return (
    <>
      <StaffTable data={data} ref={ref} />

      <div className='d-flex justify-content-between align-items-center'>
        <div className='me-3 w-100 d-flex justify-content-end'>
          <div>
            <strong className='text-muted'>Schedule start time</strong>
            <DateTimePicker
              className='h-40px'
              dateOutOfRangeMessage='Time cannot be earlier than job start time.'
              onChange={(date: any) => {
                setScheduleStart(date)
              }}
              min={dayjs(
                convertUTCDateToLocalDate(
                  jobDetail?.cleaningPeriodStart,
                  jobDetail?.hostProperty!.city.timezone
                )
              ).toString()}
              defaultValue={
                scheduleStartTime
                  ? scheduleStartTime
                  : dayjs(
                      convertUTCDateToLocalDate(
                        jobDetail?.cleaningPeriodStart,
                        jobDetail?.hostProperty!.city.timezone
                      )
                    ).toString()
              }
            />
            <small className='text-muted'>
              Job starts at{' '}
              {convertUTCDateToLocalDate(
                jobDetail?.cleaningPeriodStart,
                jobDetail?.hostProperty!.city.timezone
              )}
            </small>
          </div>
          <div className='my-auto mx-3'>
            <button
              disabled={loading}
              type='button'
              onClick={() => {
                checkScheduledEndTime()
              }}
              className='btn btn-success'
            >
              ✔ Save Assignment {loading && <Spinner animation='border' size='sm' />}
            </button>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        modalOpen={confirmationModal}
        modalClose={() => setConfirmationModal(false)}
        sendRequest={sendRequest}
      />
    </>
  )
}

export default SuitableStaffsTable

type StaffTableProps = {
  data: any
}
const StaffTable = React.memo(
  React.forwardRef(({data}: StaffTableProps, ref) => {
    return (
      <>
        <div className='d-flex w-100 p-6 ps-0 flex-wrap mt-5' style={{position: 'relative'}}>
          <div className='d-flex flex-column me-6'>
            <ProfilePhotoTemp
              width={50}
              height={50}
              borderRadius={'50%'}
              fontSize={15}
              firstName={data?.name.split(' ')[0] ?? ''}
              lastName={data?.name.split(' ')[1] ?? ''}
            />
          </div>

          <div className='row d-flex align-items-center'>
            <div className='d-flex flex-column me-6'>
              <div className='col-lg-12 h-auto'>
                <strong>{data?.name}</strong>
                <span
                  className={`badge badge-${
                    data?.hostStatusTypeName === 'Active' ? 'success' : 'warning'
                  } badge-sm ms-2`}
                >
                  {data?.hostStatusTypeName}
                </span>
              </div>
              <div className='col-lg-12 h-auto'>
                <strong className='text-muted fs-7'>Phone: {data?.contactUser.phoneNumber}</strong>
              </div>
              <div className='col-lg-12 h-auto'>
                <strong className='text-muted'>E-mail: {data?.contactUser.email}</strong>
              </div>
            </div>
            <div className='col-lg-12 h-auto'>
              <strong className='text-muted fs-7'>
                Last Login Date: {dayjs(data?.contactUser?.lastLoginOn).toString()}
              </strong>
            </div>
            <div className='col-lg-12 h-auto'>
              <strong className='text-muted fs-7'>
                Registration Date: {dayjs(data?.createdOn).toString()}
              </strong>
            </div>
            <div className='col-lg-12 h-auto'>
              <strong className='text-muted fs-7'>Scheduled Jobs: {data?.scheduledJobCount}</strong>
            </div>
          </div>
        </div>
        <div className='row g-5 g-xl-8'>
          <HostStaffAccordionTab ref={ref} data={data} />
        </div>
      </>
    )
  })
)
