import Select from 'react-select';
import { FC, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap-v5"
import ApiCalls from "../../../network/ApiCalls"
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { DeclineNotesEnum } from '../../../enums/DeclineNoteEnums'
import { TextInput } from '../../../umut-components/Inputs/TextInput';
import { StaffPendingRequest } from '../../../network/PostRequestModels';
import { Margin } from 'devextreme-react/bar-gauge';
import { Width } from 'devextreme-react/chart';

type Props = {
  show: Boolean
  handleClose: () => void
  refetch: () => void
  data: any
}


const DeclineCheckDialog: FC<Props> = ({ show, handleClose, data, refetch }) => {
  const [loading, setLoading] = useState(false)
  const [selectedNote, setSelectedNote] = useState<string | undefined>(undefined);


  const handleConfirmation = async () => {
    try {
      if (data) {
        setLoading(true)
        let request: StaffPendingRequest = {
          userId: data.data.userId,
          accountStatusId: data.statusId,
          declineNote: selectedNote
        }
        await ApiCalls.declineVendorStaffStatus(request)
        setLoading(false)
        refetch()
        handleClose()
      }
    } catch (error) {
      setLoading(false)
    }
  }
  const handleCloseModal = () => {
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Staff Verification</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to decline the staff?
      </Modal.Body>

      <div style={{ width: "95%", marginLeft: "10px", marginBottom: "5px" }} className="d-flex flex-direction-row rounded w-80">
        <TextInput placeholder="Decline note" type="text" onValueChange={setSelectedNote} />
      </div>


      <Modal.Footer>
      <button type='button' className='btn btn-light' onClick={handleCloseModal} >
          Cancel
        </button>
        <Button variant="primary" className="btn btn-danger" disabled={loading} onClick={handleConfirmation}>Decline {loading ? <Spinner animation='border' size='sm' /> : null}</Button>
        
      </Modal.Footer>
    </Modal>
  )
}

export default DeclineCheckDialog
