import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {useSelector} from 'react-redux'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {NumberInput} from '../../umut-components/Inputs/NumberInput'
import {TextInput} from '../../umut-components/Inputs/TextInput'
import ApiCalls from '../../network/ApiCalls'
import SelectInput from '../../umut-components/Inputs/SelectInput'
import VirtualSelect from '../../umut-components/Inputs/VirtualSelect'
import {useDialog} from '../../contexts/DialogContext'

type Props = {
  visible: boolean
  handleClose: () => void
}

const VendorRateListAddNewRate: React.FC<Props> = ({visible, handleClose}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [propertySearchKey, setPropertySearchKey] = useState('')
  const [propertiesLoading, setPropertiesLoading] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState<any>()
  const [selectedServiceType, setSelectedServiceType] = useState<any>()
  const [selectedChecklist, setSelectedChecklist] = useState<any>()
  const [selectedVendor, setSelectedVendor] = useState<any>()
  const [hasClientRate, setHasClientRate] = useState(false)
  const [clientRate, setClientRate] = useState(0)
  const [hasVendorRate, setHasVendorRate] = useState(false)
  const [vendorRate, setVendorRate] = useState(0)
  const [duration, setDuration] = useState(0)
  const [propertyList, setPropertyList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [checklistList, setChecklistList] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [vendorOfferList, setVendorOfferList] = useState([])
  const {showSuccessDialog} = useDialog()

  const {
    data: serviceTypes,
    isLoading: serviceTypesLoading,
    error: serviceTypesError,
  } = useQuery(['Get All Services'], () => ApiCalls.getServiceTypes(), {cacheTime: 50000})

  useEffect(() => {
    if (propertySearchKey.length < 3) {
      setPropertyList([])
    } else {
      const data = {key: propertySearchKey}
      ApiCalls.getSearchedProperties(data).then((d) => {
        if (d.data.data.length > 0) {
          const list: any = []
          for (const prop of d.data.data) {
            list.push({label: prop.name, value: prop.id})
          }
          setPropertyList(list)
        }
      })
    }
  }, [propertySearchKey])

  useEffect(() => {
    if (!serviceTypesLoading && !serviceTypesError) {
      if (serviceTypes?.data?.data.length > 0) {
        const list: any = []
        for (const st of serviceTypes?.data?.data) {
          list.push({label: st.name + ' (' + st.serviceGroup.name + ')', value: st.id})
        }
        setServiceList(list)
      }
    }
  }, [serviceTypesLoading, serviceTypesError, serviceTypes])

  useEffect(() => {
    if (selectedProperty) {
      ApiCalls.getAvailableVendors(selectedProperty.value).then((d) => {
        if (d.data && d.data.data.length > 0) {
          const list: any = []
          for (const v of d.data.data) {
            list.push({label: v.vendorName, value: v.id})
          }
          setVendorList(list)
        }
      })
    }
  }, [selectedProperty])

  useEffect(() => {
    if (selectedProperty && selectedServiceType) {
      ApiCalls.getHostPropertyChecklists(selectedProperty.value, null, selectedServiceType).then(
        (d) => {
          if (d.data && d.data.length > 0) {
            const list: any = []
            for (const cl of d.data) {
              list.push({label: cl.name.en + ' (' + cl.roleName + ')', value: cl.id})
            }
            setChecklistList(list)
          }
        }
      )
    }
  }, [selectedProperty, selectedServiceType])

  useEffect(() => {
    if (selectedProperty && selectedServiceType && selectedChecklist) {
      const data = {
        hostPropertyId: selectedProperty.value,
        serviceTypeId: selectedServiceType,
        hostPropertyChecklistId: selectedChecklist,
      }
      ApiCalls.getHostPropertyServiceRateOffersWithVariables(data)
        .then((d) => {
          if (d.data) {
            setClientRate(d.data.offeredRate)
            setHasClientRate(true)
            if (d.data.rateOffers.length > 0) {
              const list: any = []
              for (const offer of d.data.rateOffers) {
                list.push({
                  vendorId: offer.vendorId,
                  vendorName: offer.vendor.name,
                  vendorRate: offer.offeredRate,
                  duration: 80,
                })
              }
              setVendorOfferList(list)
            }
          }
        })
        .catch(() => {
          setHasClientRate(false)
          setClientRate(0)
          setVendorOfferList([])
        })
    }
  }, [selectedProperty, selectedServiceType, selectedChecklist])

  const onValChange = (v: string) => {
    //console.log('onValChange', v)
  }

  const handleTypePropertySearchKey = (v: string) => {
    setPropertySearchKey(v)
  }

  const onPropertyChange = (v: any) => {
    setSelectedProperty(v)
  }

  const onServiceTypeChange = (v: any) => {
    setSelectedServiceType(v)
  }

  const onChecklistChange = (v: any) => {
    setSelectedChecklist(v)
  }

  const onClientRateChange = (v: any) => {
    setClientRate(v)
  }

  const onVendorRateChange = (v: any) => {
    setVendorRate(v)
  }

  const onDurationChange = (v: any) => {
    setDuration(v)
  }

  const onVendorChange = (v: any) => {
    setSelectedVendor(v)
    const find: any = vendorOfferList.find((x: any) => x.vendorId === v)
    if (find) {
      setHasVendorRate(true)
      setVendorRate(find.vendorRate)
    } else {
      setHasVendorRate(false)
    }
  }

  const handleApply = async () => {
    try {
      if (!selectedVendor) {
        setErrorMessage('Please select a vendor!')
      } else if (clientRate < 0) {
        setErrorMessage('Client rate must be greater than 0')
      } else if (duration <= 0) {
        setErrorMessage('Duration must be greater than 0')
      } else {
        setErrorMessage('')
        const data = {
          hostPropertyId: selectedProperty.value,
          serviceTypeId: selectedServiceType,
          hostPropertyChecklistId: selectedChecklist,
          vendorId: selectedVendor,
          offeredRate: clientRate,
          offeredVendorRate: vendorRate,
          duration: duration,
        }
        await ApiCalls.addHostPropertyVendorServiceRate(data)
        showSuccessDialog('Property service rate successfully saved')
        handleClose()
      }
    } catch (error: any) {
      setErrorMessage(
        error?.response?.data?.message || 'An error occur during updating service rate'
      )
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered size='xl'>
      <ModalHeader closeButton>
        <Modal.Title>Add New Vendor Rate</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-12'>
            <div className='row mt-6'>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Property</label>
                <VirtualSelect
                  placeholder='Please type at least 3 characters'
                  isLoading={propertiesLoading}
                  onChange={onPropertyChange}
                  options={propertyList}
                  errorMessage={'No found Property. Please add property first.'}
                  onInputChange={handleTypePropertySearchKey}
                />
              </div>
              <div className='col-12 d-flex flex-column mb-3'>
                <label className='form-label fs-6 fw-bolder text-dark'>Service Type</label>
                <SelectInput onValueChanged={onServiceTypeChange} options={serviceList} />
              </div>
              {selectedProperty && selectedServiceType && (
                <div className='col-12 d-flex flex-column mb-3'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Checklist</label>
                  <SelectInput onValueChanged={onChecklistChange} options={checklistList} />
                </div>
              )}
              {selectedProperty && selectedServiceType && selectedChecklist && (
                <>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Vendor</label>
                    <SelectInput onValueChanged={onVendorChange} options={vendorList} />
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Client Rate ($)</label>
                    <NumberInput
                      min={1}
                      max={5000}
                      defaultValue={clientRate}
                      disabled={hasClientRate}
                      onValueChange={(e: number) => {
                        onClientRateChange(e)
                      }}
                    />
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Vendor Rate ($)</label>
                    <NumberInput
                      min={1}
                      max={5000}
                      defaultValue={vendorRate}
                      disabled={hasVendorRate}
                      onValueChange={(e: number) => {
                        onVendorRateChange(e)
                      }}
                    />
                  </div>
                  <div className='col-12 d-flex flex-column mb-3'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Duration (min.)</label>
                    <NumberInput
                      min={1}
                      max={1000}
                      defaultValue={duration}
                      disabled={hasVendorRate}
                      onValueChange={(e: number) => {
                        onDurationChange(e)
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            <strong className='w-100 text-danger'></strong>

            <div className='col mt-8 '>
              <div className='d-flex col gap-3 justify-content-end'>
                <button type='button' className='btn btn-light' onClick={handleClose}>
                  Cancel
                </button>
                {selectedProperty && selectedServiceType && selectedChecklist && !hasVendorRate && (
                  <button
                    type='button'
                    className='btn btn-success '
                    disabled={loading}
                    onClick={handleApply}
                  >
                    Apply {loading ? <Spinner animation='border' size='sm' /> : null}
                  </button>
                )}
              </div>
              <div className='col text-end'>
                <strong style={{color: 'red'}}>{errorMessage}</strong>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VendorRateListAddNewRate
