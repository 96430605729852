import React, {Dispatch, useRef} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  ColumnHeaderFilter,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import UpdateButton from '../../umut-components/Buttons/UpdateButton'
import {
  encryptText,
  convertUTCDateToLocalDate,
  onExporting,
  getDateFilter,
  toConvertedDate,
} from '../../utils/util'
import {IGetAllVendors} from '../../interfaces/Vendors'
import * as vendorActionCreator from '../../redux/actionCreators/vendorActionCreators'
import {VendorAction} from '../../redux/actionTypes/vendorTypes'
import {useDispatch} from 'react-redux'
import EmptyTable from '../../umut-components/Icons/EmptyTable'
import config from '../../config/config.json'

type Props = {
  data: IGetAllVendors[]
  refetch: () => void
}

const VendorTable: React.FC<Props> = ({data, refetch}) => {
  const dispatch = useDispatch<Dispatch<VendorAction>>()
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)

  const renderEditButton = (cellValue: any) => {
    return (
      <UpdateButton classNames='invisible' iconWidth='20' iconHeight='20' updateFn={() => {}} />
    )
  }
  const toVendorDetail = (cellData: any) => {
    if (cellData?.column?.name !== 'edit-button' && cellData?.data?.id) {
      window.open(`/vendor-details/` + `${encryptText(cellData?.data?.id)}`)
      // history.push({
      //   pathname: `/vendor-details/` + encryptText(cellData?.data?.id),
      // })
    }
  }

  const calculateLastLoginOn = (cellValue: any) => {
    return toConvertedDate(cellValue?.lastLoginOn)
  }

  const calculateLastLoginOnValue = (cellValue: any) => {
    if (!cellValue?.lastLoginOn) return undefined
    return toConvertedDate(cellValue?.lastLoginOn)
  }

  const calculateSubscribeDisplay = (cellValue: any) => {
    if (cellValue?.data?.subscribed) {
      return <strong className='text-success'>Yes</strong>
    } else {
      return <strong className='text-danger'>No</strong>
    }
  }

  const calculatePackDisplay = (cellValue: any) => {
    if (cellValue?.data?.subscriptionPack) {
      if (cellValue?.data?.subscriptionPack == 'TurnifyPRO') {
        return <strong className='text-success'>PRO</strong>
      }
      if (cellValue?.data?.subscriptionPack == 'Free Trial') {
        return <strong className='text-info'>Free Trial</strong>
      }
    } else {
      return <strong className='text-danger'>No Pack</strong>
    }
  }

  const calculateSuspendedDisplay = (cellValue: any) => {
    if (cellValue?.data?.suspended) {
      return <strong className='text-danger'>Yes</strong>
    } else {
      return <strong className='text-success'>No</strong>
    }
  }

  const calculateCreatedOn = (cellValue: any) => {
    return toConvertedDate(cellValue?.createdOn)
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Vendors' />
  }
  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='vendorsTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        ref={tableRef}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(64vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onCellClick={toVendorDetail}
        onExporting={(e) => onExporting(e, 'Vendors')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Vendors: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='name'
          caption='Company Name'
          dataType='string'
          minWidth={195}
          cssClass='cls'
        />
        <Column
          dataField='email'
          caption='E-mail'
          dataType='string'
          minWidth={195}
          cssClass='cls'
        />
        {/* <Column dataField='vendorScore' dataType='string' alignment='center' minWidth={180} cssClass='cls' /> */}
        <Column dataField='cityName' caption='City' dataType='string' width={180} cssClass='cls' />
        <Column
          dataField='stateName'
          caption='State'
          dataType='string'
          minWidth={180}
          cssClass='cls'
        />

        {/* <Column dataField='countryName' caption="Country" dataType='string' width={180} cssClass='cls' /> */}
        <Column
          dataField='dispatchStrategyTypeName'
          caption='Strategy Type'
          alignment='center'
          dataType='string'
          minWidth={180}
          cssClass='cls'
        />
        <Column
          dataField='vendorTypeName'
          caption='PRO Type'
          dataType='string'
          width={180}
          cssClass='cls'
        />
        <Column
          dataField='completedJobCount'
          caption='#of jobs (Completed)'
          dataType='string'
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />
        <Column
          dataField='scheduledJobCount'
          caption='#of jobs (Scheduled)'
          dataType='number'
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />
        <Column
          dataField='staffCount'
          caption='#of staff'
          dataType='number'
          alignment='center'
          minWidth={200}
          cssClass='cls'
        />

        <Column
          dataField='lastLoginOn'
          dataType='date'
          format={config.date_time_format_dev_extreme}
          calculateDisplayValue={calculateLastLoginOnValue}
          calculateCellValue={calculateLastLoginOnValue}
          caption='Last Login'
          minWidth={180}
          cssClass='cls'
        >
          <HeaderFilter dataSource={getDateFilter('lastLoginOn')} />
        </Column>

        <Column
          dataField='vendorStatusTypeName'
          caption='Status'
          dataType='string'
          alignment='center'
          minWidth={170}
          cssClass='cls'
          defaultFilterValues={['Active']}
        />
        <Column
          dataField='subscribed'
          caption='Subscribed'
          dataType='string'
          alignment='center'
          cellRender={calculateSubscribeDisplay}
          minWidth={170}
          cssClass='cls'
        />
        <Column
          dataField='subscriptionPack'
          caption='Subscription Pack'
          cellRender={calculatePackDisplay}
          dataType='string'
          alignment='center'
          minWidth={170}
          cssClass='cls'
        />
        <Column
          dataField='suspended'
          caption='Suspended'
          dataType='string'
          cellRender={calculateSuspendedDisplay}
          alignment='center'
          minWidth={170}
          cssClass='cls'
        />
        <Column name='edit-button' width={100} cellRender={renderEditButton} cssClass='cls' />
      </DataGrid>
    </div>
  )
}

export default React.memo(VendorTable)
