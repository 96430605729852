import { IForceFinish } from "../../interfaces/DashboardContent"

export const FORCEFINISH_OPEN_VIEW = 'FORCEFINISH_OPEN_VIEW'

export interface OpenForceFinishAction {
  type: typeof FORCEFINISH_OPEN_VIEW,
  forceFinishDetail: IForceFinish
}

export const FORCEFINISH_CLOSE_VIEW = 'FORCEFINISH_CLOSE_VIEW'
export interface CloseForceFinishAction {
  type: typeof FORCEFINISH_CLOSE_VIEW
}
export const OPEN_REJECT_DIALOG = "OPEN_Reject_DIALOG";
export interface OpenRejectDialog {
  type: typeof OPEN_REJECT_DIALOG,
  deleteFn: () => Promise<void>,
  bodyText: string,
  title: string
}

export const CLOSE_REJECT_DIALOG = "CLOSE_REJECT_DIALOG";
export interface CloseRejectDialog {
  type: typeof CLOSE_REJECT_DIALOG,
}
export type ForceFinishActions =
  | OpenForceFinishAction
  | CloseForceFinishAction
  | OpenRejectDialog
  | CloseRejectDialog