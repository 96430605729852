import { put, call, all, fork, takeLatest, delay, takeEvery, select } from "redux-saga/effects";
import ApiCalls from "../../network/ApiCalls";
import { GetStaffDetailResponse } from "../../network/NetworkResponses/NetworkResponses";

import * as staffActionCreators from "../actionCreators/staffActionCreators";
import * as staffActionTypes from "../actionTypes/staffActionTypes";

function* onGetHostStaffDetail({ hostUserId }: staffActionTypes.GetHostStaffDetail) {
    try {
        const result: GetStaffDetailResponse = yield call(ApiCalls.getHostStaffDetails, hostUserId);
        yield put(staffActionCreators.getStaffDetailSuccess(result.data));
    }
    catch (error: any) {
        yield put(staffActionCreators.getStaffDetailFailure(error?.response?.data?.message));
    }
}

function* watchOnGetHostStaffDetail() {
    yield takeLatest(staffActionTypes.GET_HOST_STAFF_DETAIL, onGetHostStaffDetail);
}

function* onGetVendorStaffDetail({ vendorUserId }: staffActionTypes.GetVendorStaffDetail) {
    try {
        const result: GetStaffDetailResponse = yield call(ApiCalls.getVendorStaffDetails, vendorUserId);
        yield put(staffActionCreators.getStaffDetailSuccess(result.data));
    }
    catch (error: any) {
        yield put(staffActionCreators.getStaffDetailFailure(error?.response?.data?.message));
    }
}

function* watchOnGetVendorStaffDetail() {
    yield takeLatest(staffActionTypes.GET_VENDOR_STAFF_DETAIL, onGetVendorStaffDetail);
}

export default function* staffSaga() {
    yield all([fork(watchOnGetHostStaffDetail), fork(watchOnGetVendorStaffDetail)]);
}