import React, { Dispatch, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap-v5';
import { PageTitle } from '../../../../_metronic/layout/core';
import { StaffAction } from '../../../redux/actionTypes/staffActionTypes';
import * as staffActionCreators from '../../../redux/actionCreators/staffActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProfileTab from './Tabs/ProfileTab';
import OfferedServices from './Tabs/OfferedServices';
import Jobs from './Tabs/Jobs';
import OffDayTab from './Tabs/OffDayTab';
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog';
import PropertyTab from './Tabs/PropertyTab';
import { RedusxAppState } from '../../../redux/reducers/rootReducer';
import { decryptText, encryptText } from '../../../utils/util';
import StaffDocuments from './Tabs/StaffDocuments';
import SkeletonCircleLoading from '../../../umut-components/Loading/SkeletonCircleLoading';
import StaffService from './Tabs/StaffService';

type TabNames =
    | 'property-list'
    | 'staff-offered-service'
    | 'jobs'
    | 'off-days'
    | 'documents'
    | 'staff-cities'

const StaffDetail: React.FC = () => {
    const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
    let { hostUserId, vendorUserId }: any = useParams();
    hostUserId = hostUserId ? decryptText(hostUserId) : undefined;
    vendorUserId = vendorUserId ? decryptText(vendorUserId) : undefined;
    const [selectedTab, setSelectedTab] = useState<TabNames>(hostUserId ? 'property-list' : 'jobs');
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    const handleChangeTab = (key: any) => {
        setSelectedTab(key);
    }

    useEffect(() => {
        if (hostUserId || vendorUserId) {
            if (hostUserId) {
                dispatch(staffActionCreators.getStaffDetailByHostUserId(parseInt(hostUserId)));
            }
            else {
                dispatch(staffActionCreators.getStaffDetailByVendorUserId(parseInt(vendorUserId)));
            }
        }
    }, [hostUserId, vendorUserId])

    if (staffDetail.staff == null) {
        return <div className="mt-5"><SkeletonCircleLoading /></div>
    }

    return (
        <>
            <PageTitle>Staff Detail</PageTitle>
            <div className='row g-5 g-xl-8'>
                <Link to={'/' + (hostUserId ? ('host-details/' + encryptText(staffDetail.staff?.hostId)) : ('vendor-details/' + encryptText(staffDetail.staff?.vendorId)))}>Back to {hostUserId ? 'Host' : 'Vendor'}</Link>
                <Card className="shadow">
                    <Card.Body className="p-5">
                        <ProfileTab />
                        <Tabs defaultActiveKey={hostUserId ? 'property-list' : 'jobs'} id="uncontrolled-tab-example" className="mb-3 mt-3" onSelect={handleChangeTab}>
                            {
                                hostUserId &&
                                <Tab eventKey="property-list" title="Properties">
                                    {selectedTab === 'property-list' && <PropertyTab />}
                                </Tab>
                            }
                            {
                                hostUserId &&
                                <Tab eventKey="staff-offered-service" title="Offered Services">
                                    {selectedTab === 'staff-offered-service' && <OfferedServices />}
                                </Tab>
                            }
                            <Tab eventKey="jobs" title="Jobs">
                                {selectedTab === 'jobs' && <Jobs />}
                            </Tab>
                            <Tab eventKey="off-days" title="Off Days">
                                {selectedTab === 'off-days' && <OffDayTab />}
                            </Tab>
                            {vendorUserId && <Tab eventKey="documents" title="Documents">
                                {selectedTab === 'documents' && <StaffDocuments />}
                            </Tab>}
                            {vendorUserId && <Tab eventKey="staff-cities" title="Service Cities">
                                {selectedTab === 'staff-cities' && staffDetail.staff.vendorId && <StaffService vendorId={staffDetail.staff?.vendorId} />}
                            </Tab>}
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
            <DeleteDialog />
        </>
    )
}

export default StaffDetail;