import { IGetAllVendors, IVendorService } from "../../interfaces/Vendors";

export const OPEN_VENDOR_EDIT_DIALOG = "OPEN_VENDOR_EDIT_DIALOG";
export interface OpenVendorEditDialog {
    type: typeof OPEN_VENDOR_EDIT_DIALOG,
    vendor: IGetAllVendors

}

export const CLOSE_VENDOR_EDIT_DIALOG = "CLOSE_VENDOR_EDIT_DIALOG";
export interface CloseVendorEditDialog {
    type: typeof CLOSE_VENDOR_EDIT_DIALOG,
}

export const OPEN_VENDOR_OFFER_DIALOG = "OPEN_VENDOR_OFFER_DIALOG";
export interface OpenVendorOfferDialog {
    type: typeof OPEN_VENDOR_OFFER_DIALOG,
    refetch: () => void,
}

export const CLOSE_VENDOR_OFFER_DIALOG = "CLOSE_VENDOR_OFFER_DIALOG";
export interface CloseVendorOfferDialog {
    type: typeof CLOSE_VENDOR_OFFER_DIALOG,
}

export const OPEN_VENDOR_EDIT_OFFER_DIALOG = "OPEN_VENDOR_EDIT_OFFER_DIALOG";
export interface OpenVendorEditOfferDialog {
    type: typeof OPEN_VENDOR_EDIT_OFFER_DIALOG,
    vendorService: IVendorService
}

export const CLOSE_VENDOR_EDIT_OFFER_DIALOG = "CLOSE_VENDOR_EDIT_OFFER_DIALOG";
export interface CloseVendorEditOfferDialog {
    type: typeof CLOSE_VENDOR_EDIT_OFFER_DIALOG,
}
export const OPEN_VENDOR_PROPERTY_DIALOG = "OPEN_VENDOR_PROPERTY_DIALOG";
export interface OpenVendorPropertyDialog {
    type: typeof OPEN_VENDOR_PROPERTY_DIALOG,
    refetch: () => void,
    accessType:string
}
export const CLOSE_VENDOR_PROPERTY_DIALOG = "CLOSE_VENDOR_PROPERTY_DIALOG";
export interface CloseVendorPropertyDialog {
    type: typeof CLOSE_VENDOR_PROPERTY_DIALOG
}

export type VendorAction =
    | OpenVendorEditDialog
    | CloseVendorEditDialog
    | OpenVendorOfferDialog
    | CloseVendorOfferDialog
    | OpenVendorEditOfferDialog
    | CloseVendorEditOfferDialog
    | OpenVendorPropertyDialog
    | CloseVendorPropertyDialog
