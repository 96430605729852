import { Column, DataGrid, Export, FilterRow, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useQuery } from 'react-query'
import config from '../../../config/config.json';
import { JobStatusType } from '../../../enums/JobEnum';
import { headingDistanceTo } from 'geolocation-utils';
import ApiCalls from '../../../network/ApiCalls';
import EmptyTable from '../../../umut-components/Icons/EmptyTable';
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading';
import { encryptText, getDateFilter, onExporting, toConvertedDate } from '../../../utils/util';

const GpsDiscrepanciesTable = () => { 

    const { data, isLoading, error } = useQuery(['Get GPS Discrepancies'], () => ApiCalls.getGpsDiscrepancies(true), { cacheTime: 10000 });

    const isManuallyCreatedJob = (cellValue: any) => {
        return cellValue?.data.isManuallyCreated ? <p>{cellValue.data.hostPropertyAddress}<span style={{ color: "#ff5d5d" }}> ⓜ</span></p> : cellValue.data.hostPropertyAddress
    }

    const calculateStartedOn = (cellValue: any) => {
        return toConvertedDate(cellValue?.startedOn, cellValue.hostPropertyCityTimeZone);
    }

    const calculateDistance = (cellValue: any) => {
        var data = cellValue.data;
        const hostPropertyLocation = {
            lat: data.propertyLatitude,
            lon: data.propertyLongitude
        };
        const staffLocation = {
            lat: data.startAwayLatitude,
            lon: data.startAwayLongitude
        };
        const calc = headingDistanceTo(hostPropertyLocation, staffLocation);
        const distanceMile = calc.distance * 0.000621371192;

        if(distanceMile > 0.5) {
            return '0.5 mile+';
        } else {
            return distanceMile+' mile'
        }
    }

    if (isLoading) {
        return (
            <SkeletonTableLoading />
        )
    }

    if (error) {
        return <div className='mt-6'>Error</div>
    }

    if (data?.data?.data?.length === 0) {
        return <EmptyTable title="No GPS Discrepancies" />
    }

    return(
        <div>
            <DataGrid
                id="gpsDiscrepancies"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data?.data?.data}
                showBorders={false}
                showColumnLines={false}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(45vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                selection={{ mode: 'multiple' }}
                onExporting={(e) => onExporting(e, "GPS Discrepancies")}
            >
                <Export enabled />
                <SearchPanel visible={true} />
                <FilterRow showOperationChooser={false} visible={true} />
                <HeaderFilter visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />

                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total GPS Discrepancies: {0}" column='hostPropertyAddress' summaryType='count' />
                </Summary>

                <Column dataField="hostPropertyAddress" cellRender={isManuallyCreatedJob} minWidth={450} caption="Property" cssClass='cls' />
                <Column dataField="startedOn" caption="started at " dataType='date' selectedFilterOperation='between' format={config.date_time_format_dev_extreme} calculateCellValue={calculateStartedOn} minWidth={150} cssClass='cls' >
                    <HeaderFilter dataSource={getDateFilter('startedOn')} />
                </Column>
                <Column dataField="startAwayFromProperty" caption='Distance' cellRender={calculateDistance} minWidth={200} cssClass='cls' />
                <Column dataField="startAwayLatitude" caption='Latitude' minWidth={200} cssClass='cls' />
                <Column dataField="startAwayLongitude" caption='Longitude' minWidth={200} cssClass='cls' />
            </DataGrid>
        </div>
    )
}

export default GpsDiscrepanciesTable;