import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ApiCalls from '../../network/ApiCalls'
import JobForceTable from './JobForce/JobForceTable'
import {AxiosResponse} from 'axios'
import {IForceFinishResponse, IListResponse} from '../../network/NetworkResponses/NetworkResponses'
import JobForceModal from './JobForce/JobForceModal'
import UnresolvedReport from './UnresolvedReport/UnresolvedReport'
import {IJobIssue} from '../../interfaces/Job'
import IssueMediaDialog from '../job-details/Tabs/IssuesTab/IssueMediaDialog'
import {Spinner} from 'react-bootstrap-v5'
import JobLateFinish from '../jobs/JobLateFinish'
import JobLateStart from '../jobs/JobLateStart'
import MissedJobsTable from './MissedJobs/MissedJobsTable'
import GpsDiscrepanciesTable from './GpsDiscrepancies/GpsDiscrepanciesTable'
import HostLogs from './HostLogs/HostLogs'

const Dashboard: React.FC = () => {
  const {
    data: forceFinishRequest,
    isLoading: forceFinishIsLoading,
    error: forceFinishError,
    refetch,
  } = useQuery<AxiosResponse<IForceFinishResponse>>(
    ['Get Force Finish Request'],
    () => ApiCalls.getJobForceFinish(),
    {cacheTime: 50000}
  )
  const {
    data: unResolvedIssue,
    isLoading: unresolverLoading,
    error: resolverError,
    refetch: resolvedFetch,
  } = useQuery<AxiosResponse<IListResponse<IJobIssue>>>(
    ['Get Unresolved Report'],
    () => ApiCalls.getAllJobIssue(),
    {cacheTime: 500000}
  )

  if (forceFinishIsLoading && unresolverLoading) {
    return (
      <div>
        <Spinner animation='border' />
      </div>
    )
  }
  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='p-8 border rounded bg-white'>
          <h1>Pro Client Logs</h1>
          <HostLogs />
        </div>
        <div className='p-8 border rounded bg-white'>
          <h1>Unresolved Issues.</h1>
          <UnresolvedReport
            data={
              unResolvedIssue?.data?.data.filter(
                (report) => report.jobIssueStatusTypeName === 'Reported'
              ) ?? []
            }
          />
        </div>
        <div className='p-8 mt-10 border rounded bg-white'>
          <h1>Force Finish Requests</h1>
          <JobForceTable
            data={
              forceFinishRequest?.data?.data.filter(
                (request) => request.jobForceFinishStatusName === 'Pending'
              ) ?? []
            }
            refetch={refetch}
          />
        </div>
        <div className='p-8 border rounded bg-white'>
          <h1>Late Start Jobs..</h1>
          <JobLateStart />
        </div>
        <div className='p-8 border rounded bg-white'>
          <h1>Late Finish Jobs...</h1>
          <JobLateFinish />
        </div>
        <div className='p-8 border rounded bg-white'>
          <h1>Missed Jobs</h1>
          <MissedJobsTable />
        </div>
        <div className='p-8 border rounded bg-white'>
          <h1>GPS Discrepancies</h1>
          <GpsDiscrepanciesTable />
        </div>
      </div>

      <IssueMediaDialog jobEventsActive={true} refetch={resolvedFetch} />
    </>
  )
}

export default Dashboard

/*

<div className="row gy-5 g-xl-3 gap-5 ms-2 p-0">
                    <div className="col-xl-7 col-md-12 bg-white rounded card card-custom gutter-b border">
                        <div className="card-header">
                            <div className="card-title">Jobs Status Informations</div>
                        </div>
                        <div className="card-body "><JobsInformations jobData={jobsInformation?.data?.data} /></div>
                    </div>
                    <div className="h-50 d-flex flex-column col-xl-4 gap-3">

                        <div className="card card-custom card-stretch card-stretch-half gutter-b">
                            <div className="card-body">
                                <JobPie data={dashboardContent?.data} />
                            </div>
                        </div>
                        <div className="card card-custom card-stretch card-stretch-half gutter-b">
                            <div className="card-body">
                                <IssuePie data={dashboardContent?.data} />
                            </div>
                        </div>
                    </div>
                </div>
*/

/*
                <div className="card bg-white rounded card-custom gutter-b border col-7">
                    <div className="card-header">
                        <div className="card-title">Jobs Status Informations</div>
                    </div>
                    <div className="card-body "><JobsInformations jobData={jobsInformation?.data?.data} /></div>
                </div>
                <div className="d-flex flex-column justify-content-between col-5">
                    <div className="card card-custom card-stretch card-stretch-half gutter-b border">
                        <div className="card-body">
                            <JobPie data={dashboardContent?.data} />
                        </div>
                    </div>
                    <div className="card card-custom card-stretch card-stretch-half gutter-b border">
                        <div className="card-body">
                            <IssuePie data={dashboardContent?.data} />
                        </div>
                    </div>
                </div>
*/
