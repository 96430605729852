import {AxiosResponse} from 'axios'
import dayjs from 'dayjs'
import {FC, useState} from 'react'
import {Accordion, Button, Card, ListGroup, Modal, Tab, Tabs} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {useDialog} from '../../contexts/DialogContext'
import {IMarketplace, IMarketplaceOffer} from '../../interfaces/Marketplace'
import ApiCalls from '../../network/ApiCalls'
import {MarketplaceListResponse} from '../../network/NetworkResponses/NetworkResponses'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp'
import ReviewListModal from '../../umut-components/Modals/ReviewListModal'
import StarRatingView from '../../umut-components/Rating/StarRatingView'
import {encryptText} from '../../utils/util'
import {Tooltip} from '@material-ui/core'

type Props = {
  offers: IMarketplaceOffer[] | []
  fetchData: () => void
}

const MarketplaceRequestDetailOffers: React.FC<Props> = ({offers, fetchData}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const history = useHistory()
  const {showSuccessDialog, showFailureDialog, openLoadingModal, closeModal} = useDialog()
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false)
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false)
  const [showReviews, setShowReviews] = useState<boolean>(false)
  const [selectedOffer, setSelectedOffer] = useState<IMarketplaceOffer | undefined>()

  const openReviewModal = (offer: IMarketplaceOffer) => {
    if (offer.vendor.vendorScoreReviews && offer.vendor.vendorScoreReviews.length > 0) {
      setSelectedOffer(offer)
      setShowReviews(true)
    }
  }

  return (
    <>
      <div className='row'>
        {offers.length > 0 && (
          <>
            {offers.map((offer, index) => {
              if (offer.vendor && offer.serviceTypeInfo) {
                var vendorNameRaw = offer.vendor.name.split(' ')
                var vendorShort = ''
                vendorNameRaw.map((vnr) => {
                  vendorShort += vnr[0]
                })
                var hasApprovedInsurance = false
                if (offer.vendor.documents != undefined && offer.vendor.documents?.length > 0) {
                  var findDoc = offer.vendor.documents.find((doc) => doc.documentTypeId == 2)
                  if (findDoc) {
                    if (
                      findDoc.approved &&
                      dayjs(findDoc.expirationDate).format('YYYY-MM-DD') >
                        dayjs().format('YYYY-MM-DD')
                    ) {
                      hasApprovedInsurance = true
                    }
                  }
                }
                return (
                  <div className='col-lg-4 col-12 mb-4' key={'offer-line-' + index}>
                    <div className='card card-custom card-stretch gutter-b h-100 border border-1 border-gray-300 ribbon ribbon-top'>
                      {+offer.vendor.vendorScore >= 4 && (
                        <Tooltip title='High scoring vendor' arrow>
                          <div className='ribbon-label bg-danger'>
                            <span className='svg-icon svg-icon-2 trustbadge'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                version='1.1'
                              >
                                <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                  <rect x='0' y='0' width='24' height='24' />
                                  <path
                                    d='M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z'
                                    fill='#290'
                                    opacity='0.3'
                                  />
                                  <path
                                    d='M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z'
                                    fill='#290'
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </Tooltip>
                      )}
                      {hasApprovedInsurance && (
                        <Tooltip title='Insurance certificate approved vendor' arrow>
                          <div
                            className={
                              +offer.vendor.vendorScore >= 4
                                ? 'ribbon-label bg-success me-15'
                                : 'ribbon-label bg-success'
                            }
                          >
                            <span className='svg-icon svg-icon-2 trustbadge'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                version='1.1'
                              >
                                <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                  <polygon points='0 0 24 0 24 24 0 24' />
                                  <path
                                    d='M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z M10.875,15.75 C11.1145833,15.75 11.3541667,15.6541667 11.5458333,15.4625 L15.3791667,11.6291667 C15.7625,11.2458333 15.7625,10.6708333 15.3791667,10.2875 C14.9958333,9.90416667 14.4208333,9.90416667 14.0375,10.2875 L10.875,13.45 L9.62916667,12.2041667 C9.29375,11.8208333 8.67083333,11.8208333 8.2875,12.2041667 C7.90416667,12.5875 7.90416667,13.1625 8.2875,13.5458333 L10.2041667,15.4625 C10.3958333,15.6541667 10.6354167,15.75 10.875,15.75 Z'
                                    fill='#fff'
                                    fill-rule='nonzero'
                                    opacity='0.3'
                                  />
                                  <path
                                    d='M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z'
                                    fill='#fff'
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </Tooltip>
                      )}
                      <div className='card-body' style={{paddingBottom: 60}}>
                        <div className='row mb-3'>
                          <div className='col-2 mb-3'>
                            <div className='symbol symbol-120 symbol-circle symbol-success overflow-hidden'>
                              <span className='symbol-label'>
                                <ProfilePhotoTemp
                                  fontSize={14}
                                  width={160}
                                  height={160}
                                  borderRadius={'24'}
                                  firstName={''}
                                  lastName={''}
                                  vendorName={vendorShort}
                                />
                              </span>
                            </div>
                          </div>
                          <div className='col-6 ps-5 align-items-start justify-content-center d-flex flex-column mb-3 '>
                            <div className='fw-bold text-dark-75 text-danger'>
                              {offer.vendor.name}
                            </div>
                            <div className='fw-bold' style={{color: '#666', fontSize: 10}}>
                              {offer.vendor.cityInfo.name} / {offer.vendor.stateInfo.name}
                            </div>
                            <div className='text-muted' style={{fontSize: 10, marginTop: 4}}>
                              Member Since{' '}
                              <strong>{dayjs(offer.vendor.createdOn).format('YYYY')}</strong>
                            </div>
                          </div>
                          <div className='col-4 align-items-end justify-content-center d-flex flex-column  mb-3'>
                            <StarRatingView rate={+offer.vendor.vendorScore} />
                            <div
                              className='review-line'
                              onClick={() => {
                                openReviewModal(offer)
                              }}
                            >
                              {offer.vendor.vendorScoreReviews
                                ? offer.vendor.vendorScoreReviews.length + ' Reviews'
                                : 'No Review'}
                            </div>
                          </div>
                        </div>
                        <div className='row mb-3'>
                          {offer.vendor.description && (
                            <div className='col-12'>
                              <span className='fw-bold'>About : </span>
                              {offer.vendor.description}
                            </div>
                          )}
                        </div>
                        <div className='row mb-3'>
                          <div className='col-lg-12 col-12 mb-3'>
                            <div className='row'>
                              <div className='col-lg-6 col-12 fw-bold mb-3'>
                                Requested Service :
                              </div>
                              <div className='col-lg-6 col-12 mb-3'>
                                {offer.serviceTypeInfo.name}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-6 col-12 fw-bold mb-3'>
                                Sample Checklist :{' '}
                              </div>
                              <div className='col-lg-6 col-12 mb-3'>
                                {offer.checklistInfo != undefined && (
                                  <div>{offer.checklistInfo.name.en}</div>
                                )}
                                {offer.checklistInfo == undefined && (
                                  <div className='text-danger'>No Related Checklist</div>
                                )}
                              </div>
                            </div>
                            {offer.marketplaceOfferStatusTypeId > 1 && (
                              <div className='row'>
                                <div className='col-lg-6 col-12 fw-bold mb-3'>
                                  <div className='text-danger' style={{fontSize: 14}}>
                                    Quoted Price :
                                  </div>
                                </div>
                                <div className='col-lg-6 col-12 mb-3 d-flex align-items-center'>
                                  <div style={{fontSize: 22, marginRight: 4}}>
                                    ${offer.offerPrice}
                                  </div>
                                  <div>{offer.offerType == 'hourly' ? ' / hour(s)' : ' / job'}</div>
                                </div>
                              </div>
                            )}
                            {offer.vendorNote && (
                              <div className='row'>
                                <div className='col-lg-6 col-12 fw-bold mb-3'>
                                  <div className='text-dark'>Brief :</div>
                                </div>
                                <div className='col-lg-6 col-12 mb-3 d-flex align-items-center'>
                                  <div>{offer.vendorNote}</div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='offer-action-buttons'>
                          <div className='row'>
                            {offer.marketplaceOfferStatusTypeId == 1 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-dark font-weight-bolder w-100'>
                                  Quote requested. Offer Pending.
                                </button>
                              </div>
                            )}
                            {offer.marketplaceOfferStatusTypeId == 2 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-info font-weight-bolder w-100'>
                                  Offer sent. Client Pending.
                                </button>
                              </div>
                            )}
                            {offer.marketplaceOfferStatusTypeId == 3 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-success font-weight-bolder w-100'>
                                  Offer Accepted
                                </button>
                              </div>
                            )}
                            {offer.marketplaceOfferStatusTypeId == 4 && (
                              <div className='col-12 mb-3'>
                                <button className='btn btn-outline btn-outline-danger font-weight-bolder w-100'>
                                  Offer Declined
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
            <ReviewListModal
              title={selectedOffer?.vendor.name}
              data={selectedOffer?.vendor.vendorScoreReviews}
              modalOpen={showReviews}
              handleClose={() => {
                setSelectedOffer(undefined)
                setShowReviews(false)
              }}
            />
          </>
        )}
      </div>
    </>
  )
}

export default MarketplaceRequestDetailOffers
