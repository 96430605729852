import { IService } from '../../interfaces/ServiceType';

export const REFERENCE_OPEN_VIEW = 'REFERENCE_OPEN_VIEW'

export interface OpenReferenceViewAction {
  type: typeof REFERENCE_OPEN_VIEW
  service: IService | null
}
export const REFERENCE_CLOSE_VIEW = "REFERENCE_CLOSE_WIEW"

export interface CloseReferenceViewAction {
  type: typeof REFERENCE_CLOSE_VIEW
}

export const DOCUMENT_OPEN_VIEW = 'DOCUMENT_OPEN_VIEW'

export interface OpenDocumentViewAction {
  type: typeof DOCUMENT_OPEN_VIEW
  documentTypes: any
}

export const DOCUMENT_CLOSE_VIEW = 'DOCUMENT_CLOSE_VIEW'

export interface CloseDocumentViewAction {
  type: typeof DOCUMENT_CLOSE_VIEW
}
export type ServiceDetailAction =
  | OpenReferenceViewAction
  | CloseReferenceViewAction
  | OpenDocumentViewAction
  | CloseDocumentViewAction
