import React, {useEffect} from 'react'
import {useState} from 'react'
import {ILocation, IHostPropertyInput} from '../../../../../interfaces/Property'
import ArrowButton from '../../../../../umut-components/Buttons/ArrowButton'
import AutocompleteMap from '../../../../../umut-components/Inputs/AutocompleteMap'
import Map from '../../../../../umut-components/Map/GoogleMap'

type Props = {
  location: ILocation
  pmsReferenceId?: string
  handleChangeHostProperty: (changedValues: IHostPropertyInput) => void
  currentStep?: number
  totalSteps?: number
  nextStep?: () => void
  goToStep?: (step: number) => void
  hostProperty?: IHostPropertyInput
  editMode?: boolean
  missingField?: boolean
  staticMapUrl?: string
}

const LocationStep: React.FC<Props> = ({
  missingField,
  staticMapUrl,
  location,
  currentStep,
  handleChangeHostProperty,
  nextStep,
  editMode,
  goToStep,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<ILocation>(location)
  const [placeId, setPlaceId] = useState<string | undefined>()
  const [text, setText] = useState<string>(location.address ? location.address : '')

  const onMarkerChange = (changedLocation: ILocation) => {
    setText(changedLocation.address ? changedLocation.address : '')
    setSelectedLocation({...changedLocation, suite: selectedLocation.suite})
  }

  const onSuiteChange = (e: any) => {
    setSelectedLocation({...selectedLocation, suite: e.target.value})
  }

  const handleSelectPlaceId = (e: string) => {
    setPlaceId(e)
  }

  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '')
  }

  useEffect(() => {
    handleChangeHostProperty({
      address: selectedLocation.address,
      city: selectedLocation.city,
      state: selectedLocation.state,
      suite: selectedLocation.suite,
      country: selectedLocation.country,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
      zipCode: selectedLocation.zipCode,
    })
  }, [selectedLocation])

  return (
    <>
      <form className='form w-100 mt-5'>
        <div className='row'>
          {/* begin::Form group */}
          <div className='fv-row col-lg-4'>
            {/* {editMode && (location.latitude && location.longitude) ? <img className='w-100' style={{ objectFit: 'contain' }} alt='map' src={staticMapUrl ? staticMapUrl : getStaticMap(location.latitude, location.longitude)} /> : } */}
            {
              <Map
                selectedAddress={selectedLocation.address}
                placeId={placeId}
                initialLocation={location}
                onLatLngChanged={onMarkerChange}
                initialAddress={null}
                draggable={true}
              />
            }
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row col-lg-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              Address {<span style={{color: 'red'}}>*</span>}
            </label>
            {
              <AutocompleteMap
                text={text}
                setText={setText}
                handleSelectPlaceId={handleSelectPlaceId}
                handleOnError={handleAutocompleteError}
              />
            }
            {missingField && !selectedLocation.address && (
              <>
                <strong className='text-danger'>Location is not correct!</strong>
                <br />
              </>
            )}

            <label className='form-label fs-6 fw-bolder text-dark mt-3'>Suite/Apt #</label>
            <input
              value={selectedLocation.suite}
              onChange={onSuiteChange}
              className='form-control'
              type='text'
              autoComplete='off'
              required
            />
            <label className='form-label fs-6 fw-bolder text-dark mt-3'>
              Country {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              value={selectedLocation.country}
              className='form-control'
              type='text'
              autoComplete='off'
              disabled
              required
            />
            {missingField && !selectedLocation.country && (
              <strong className='text-danger'>Missing Field</strong>
            )}
            <div className='row mt-5'>
              <div className='fv-row col-lg-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  State {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  value={selectedLocation.state}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  disabled
                  required
                />
                {missingField && !selectedLocation.state && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
              </div>
              <div className='fv-row col-lg-6'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                  City {<span style={{color: 'red'}}>*</span>}
                </label>
                <input
                  value={selectedLocation.city}
                  className='form-control'
                  type='text'
                  autoComplete='off'
                  disabled
                  required
                />
                {missingField && !selectedLocation.city && (
                  <strong className='text-danger'>Missing Field</strong>
                )}
              </div>
            </div>
            <label className='form-label fs-6 fw-bolder text-dark mt-3'>
              Zip Code {<span style={{color: 'red'}}>*</span>}
            </label>
            <input
              value={selectedLocation.zipCode}
              className='form-control'
              type='text'
              autoComplete='off'
              disabled
              required
            />
            {missingField && !selectedLocation.zipCode && (
              <strong className='text-danger'>Missing Field</strong>
            )}
          </div>
        </div>
        {editMode ? null : (
          <div className='d-flex justify-content-end flex-row w-100 mt-6'>
            <ArrowButton type='forward' func={nextStep} classNames='align-self-end' />
          </div>
        )}
      </form>
    </>
  )
}

export default LocationStep
