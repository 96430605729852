import React, { Dispatch, useState } from 'react'
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ApiCalls from '../../network/ApiCalls';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators';
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading';
import StaffDialog from './StaffDialog';
import StaffTable from './StaffTable';
import { LoginNetworkResponse } from '../../network/NetworkResponses/NetworkResponses';
import StaffEditDialog from './StaffEditDialog';
import { decryptText } from '../../utils/util';

type Props = {
}

const StaffTab: React.FC<Props> = () => {
    let { hostId, vendorId }: any = useParams();
    hostId = hostId ? decryptText(hostId) : undefined;
    vendorId = vendorId ? decryptText(vendorId) : undefined;
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const { data, isFetching, error, refetch } = useQuery(['Get Host Staffs By Id', hostId, vendorId], () => hostId ? ApiCalls.getStaffByHostId(hostId) : ApiCalls.getStaffByVendorId(vendorId), { cacheTime: 500000, refetchOnWindowFocus: false, enabled: hostId || vendorId ? true : false });

    const clickStaffAdd = () => {
        dispatch(staffActionCreators.openStaffAddDialog(refetch));
    }

    if (isFetching) {
        return <SkeletonTableLoading />
    }

    if (error) {
        return <div className='d-flex justify-content-center p-6'>Error</div>
    }

    return (
        <>
            <StaffTable data={data?.data?.data} refetch={refetch} />
            <div className='d-flex justify-content-end'>
                <button type='button' className='btn btn-success' onClick={clickStaffAdd}>Add </button>
            </div>
            <StaffDialog />
            <StaffEditDialog />
        </>
    )
}

export default StaffTab;