import React, { Dispatch, useRef } from 'react';
import { Column, DataGrid, Export, HeaderFilter, Paging, Scrolling, SearchPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { decryptText, encryptText, onExporting } from '../../utils/util';
import ProfilePhotoTemp from '../../umut-components/Icons/ProfilePhotoTemp';
import { IStaff } from '../../interfaces/Staff';
import UpdateButton from '../../umut-components/Buttons/UpdateButton';
import ViewButton from '../../umut-components/Buttons/ViewButton';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators';
import EmptyTable from '../../umut-components/Icons/EmptyTable';
import { Link } from 'react-router-dom';

type Props = {
    data: IStaff[],
    refetch: () => void
}

const StaffTable: React.FC<Props> = ({ data, refetch }) => {
    let { hostId, vendorId }: any = useParams();
    hostId = hostId ? decryptText(hostId) : undefined;
    vendorId = vendorId ? decryptText(vendorId) : undefined;
    const history = useHistory();
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const tableRef = useRef<DataGrid>(null)

    const renderProfilePhoto = (cellValue: any) => {
        return (
            <ProfilePhotoTemp profilePhotoPath={cellValue?.data?.profilePhotoPath} firstName={cellValue?.data?.firstName} lastName={cellValue?.data?.lastName} width={45} height={45} borderRadius='50%' />
        )
    }

    const renderEditButton = (cellValue: any) => {
        return (
            <UpdateButton iconWidth="20" iconHeight="20" updateFn={() => { dispatch(staffActionCreator.openStaffEditDialog(refetch, cellValue?.data)) }} />
        )
    }
    const calculateFullName = (cellValue: any) => {
        return cellValue?.firstName + " " + cellValue?.lastName;
    }
    const toStaffDetail = (cellValue: any) => {
        if (cellValue?.column?.name !== "edit-button" && cellValue?.data?.id) {
            // history.push({
            //     pathname: (hostId ? '/host-staff-details/' : `/vendor-staff-details/`) + encryptText(cellValue?.data?.id)
            // }
            // )
            window.open((hostId ? '/host-staff-details/' : `/vendor-staff-details/`) + encryptText(cellValue?.data?.id))
        }
    }

    if (data?.length === 0) {
        return <EmptyTable title='This PRO does not have any staff onboarded.' />
    }

    return (
        <div className="position-relative">
                  <div style={{ zIndex: 1 }} className="position-absolute top-15">
        <span className="fs-8 text-primary cursor-pointer"
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
         Clear Filter
        </span>
      </div>
            <DataGrid
                id="host-staffs"
                keyExpr="id"
                onRowPrepared={(e) => { e.rowElement.classList.add("data-grid-row"); }}
                dataSource={data}
                showBorders={false}
                showColumnLines={false}
                ref={tableRef}
                showRowLines={true}
                hoverStateEnabled={true}
                height={'calc(45vh - 60px)'}
                className='mt-6'
                allowColumnResizing={true}
                onCellClick={toStaffDetail}
                selection={{ mode: 'single' }}
                onExporting={(e) => onExporting(e, "Host Staff")}
            >
                <Export enabled />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Scrolling showScrollbar="always" mode="virtual" rowRenderingMode="virtual" />
                <Paging defaultPageSize={50} />
                <Summary>
                    <TotalItem cssClass='absolute-right' displayFormat="Total Staff: {0}" column='firstName' summaryType='count' />
                </Summary>

                <Column width={100} cellRender={renderProfilePhoto} cssClass='cls' />
                <Column dataField="firstName" calculateCellValue={calculateFullName} caption='Fullname' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="roleName" caption='Role' minWidth={180} dataType='string' cssClass='cls' />
                <Column dataField="email" dataType='string' width={250} cssClass='cls' />
                <Column dataField='completedJobCount' caption="#of Jobs (Completed)" dataType='string' alignment='center' minWidth={230} cssClass='cls' />
                <Column dataField='scheduledJobCount' caption="#of Jobs (Scheduled)" dataType='string' alignment='center' minWidth={230} cssClass='cls' />
                <Column dataField="phoneNumber" dataType='string' width={250} cssClass='cls' />
                <Column dataField="lastLoginOn" caption="Last Login On" width={150} cssClass='cls'/>
                <Column dataField="completedJobCount" caption="#of Job(Completed)" width={150} cssClass='cls'/>
                <Column dataField="scheduledJobCount" caption="#of Job(Scheduled)" width={150} cssClass='cls'/>

                <Column dataField="accountStatusName" caption='Status' dataType='string' width={180} cssClass='cls' />
                {/* <Column width={100} cellRender={renderViewButton} cssClass='cls' /> */}
                <Column name="edit-button" width={100} cellRender={renderEditButton} cssClass='cls' />
            </DataGrid>
            
        </div>
    )
}

export default StaffTable;