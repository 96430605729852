import { FC, useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import StaffDocument from './StaffDocument'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import DeleteDialog from '../../../umut-components/Modals/DeleteDialog'
import ApiCalls from '../../../network/ApiCalls'
import { PageTitle } from '../../../../_metronic/layout/core'

type Props = {
  vendorId: number,
  staffId: number,
}
const StaffDocuments: FC<Props> = ({ vendorId, staffId }) => {

  const { data, isLoading, refetch } = useQuery(
    ['Get Staff Documents', staffId],
    () => ApiCalls.getVendorUserDocuments(staffId, vendorId),
    { refetchOnWindowFocus: false }
  )
  const [show, setShow] = useState(false)
  const closeModal = () => setShow(false)



  return (
    <div>
      <PageTitle>User Documents</PageTitle>
      <div className='row mt-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <div className='d-flex justify-content-center p-6 fw-bold'>Loading...</div>
            ) : (
              <div className='row d-flex'>
                {data?.data?.data.length == 0 ? <EmptyTable title="No Documents" /> : data?.data?.data.map((data: any, idx: number) => (
                  <div className='col-lg-6 col-12 min-w-200px'>
                    <StaffDocument data={data} key={idx} refetch={refetch} />
                  </div>
                ))}
              </div>
            )}
          </Card.Body>
          
        </Card>
      </div>
      <DeleteDialog />
    </div>
  )
}

export default StaffDocuments
