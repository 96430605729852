import {createContext, useContext, useState} from 'react'
import ConfirmModal from '../umut-components/Modals/DisplayModal'

type ModalData = {
  image?: string
  text?: string
  title: string
}

const DialogContext = createContext({})

export const ConfirmProvider = ({children}: any) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState<ModalData | null>(null)
  const [modalSuccess, setModalSuccess] = useState<'success' | 'danger' | null>(null)
  const [extraHtml, setExtraHtml] = useState<any>(undefined)

  const openDisplayModal = (data: ModalData) => {
    setModalSuccess(null)
    setModalData(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalSuccess(null)
    setModalOpen(false)
    setModalData(null)
    setExtraHtml(null)
  }

  const showSuccessDialog = (text: string, extraHtmlInfo?: any) => {
    setModalSuccess('success')
    setModalData({text, title: 'Success'})
    setExtraHtml(extraHtmlInfo)
    setModalOpen(true)
  }

  const showFailureDialog = (text: string, extraHtmlInfo?: any) => {
    setModalSuccess('danger')
    setModalData({text, title: 'Failure'})
    setExtraHtml(extraHtmlInfo)
    setModalOpen(true)
  }

  const openLoadingModal = (text: string) => {
    setModalSuccess(null)
    setModalData({text, title: 'Loading'})
    setModalOpen(true)
    setTimeout(() => {
      setModalOpen(false)
    }, 1000)
  }

  return (
    <DialogContext.Provider
      value={{openDisplayModal, showSuccessDialog, showFailureDialog, openLoadingModal}}
    >
      {children}
      <ConfirmModal
        data={modalData}
        handleClose={closeModal}
        modalOpen={modalOpen}
        modalSuccess={modalSuccess}
        extraHtml={extraHtml}
      />
    </DialogContext.Provider>
  )
}

export default DialogContext

export const useDialog = (): any => useContext(DialogContext)
