import React, {Dispatch, FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {AxiosResponse} from 'axios'
import PropertyOfferedDialog from './VendorOffersDialog'
import {Accordion, Card, Dropdown, Modal, ModalBody} from 'react-bootstrap-v5'
import {Link} from 'react-router-dom'
import {useDialog} from '../../../../../contexts/DialogContext'
import {IHostPropertyOffer, IHostPropertyRates} from '../../../../../interfaces/Property'
import ApiCalls from '../../../../../network/ApiCalls'
import {
  IGlobalRateTypesResponse,
  IHostPropertyOfferResponse,
  IHostPropertyServiceRatesResponse,
} from '../../../../../network/NetworkResponses/NetworkResponses'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../../../redux/actionCreators/deleteDialogActionCreators'
import {DeleteActions} from '../../../../../redux/actionTypes/deleteDialogTypes'
import {HostPropertyAction} from '../../../../../redux/actionTypes/hostPropertyTypes'
import EmptyTable from '../../../../../umut-components/Icons/EmptyTable'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import DeleteDialog from '../../../../../umut-components/Modals/DeleteDialog'
import * as hostPropertyActionCreator from '../../../../../redux/actionCreators/hostPropertyActionCreators'
import {encryptText, decryptText} from '../../../../../utils/util'
import {IRateType} from '../../../../../interfaces/RateType'
import SelectInput from '../../../../../umut-components/Inputs/SelectInput'
import VendorOffersDialog from './VendorOffersDialog'

type ServiceItemProps = {
  data: IHostPropertyRates
  refetch: () => void
}

const PropertyOfferedServiceItem: React.FC<ServiceItemProps> = ({data, refetch}) => {
  const {showFailureDialog, showSuccessDialog} = useDialog()
  const [rateTypes, setRateTypes] = useState<any[]>([])
  const [offeredPrice, setOfferedPrice] = useState(data.offeredServicePrice)
  const [offeredRateType, setOfferedRateType] = useState(data.rateTypeId)
  const [showOffers, setShowOffers] = useState(false)

  const {
    data: rateTypeData,
    isFetching: rateTypesLoading,
    error: rateTypeError,
  } = useQuery<AxiosResponse<IRateType[]>>(
    ['Get Rate Types'],
    () => ApiCalls.getGlobalRateTypes(),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: true,
    }
  )

  useEffect(() => {
    if (rateTypeData?.data && rateTypeData?.data.length > 0) {
      const types: any = []
      for (const rt of rateTypeData?.data) {
        types.push({label: rt.name, value: rt.id})
      }

      setTimeout(() => {
        setRateTypes(types)
      }, 1000)
    }
  }, [rateTypeData])

  const changeOfferedRateType = (e: any) => {
    setOfferedRateType(e.target.value)
  }

  const handleCloseOfferModal = () => {
    setShowOffers(false)
  }

  const changeOfferedPrice = (e: any) => {
    setOfferedPrice(e.target.value)
  }

  const updateOfferedRate = async (offerId: number, serviceType: string) => {
    try {
      const request = {id: offerId, offeredRate: offeredPrice, rateTypeId: offeredRateType}
      await ApiCalls.updateHostPropertyOfferedRate(request)
      showSuccessDialog('The offered price for ' + serviceType + ' has been updated.')
      refetch()
    } catch (error: any) {
      showFailureDialog(error.response.data.message)
    }
  }

  return (
    <div
      className='w-100 w-sm-300px border rounded p-6 pb-0 d-flex flex-column bg-white mt-3 me-3 align-items-center position-relative'
      style={{height: 350}}
    >
      <div className='d-flex flex-column align-items-center h-50'>
        <h2 style={{fontWeight: 400}}>{data.serviceTypeName}</h2>
        <small className='text-muted text-center' style={{fontWeight: 400}}>
          {data.serviceTypeDescription}
        </small>
      </div>
      <span className='text-success text-center w-100 fs-3 mt-5 mb-3' style={{fontWeight: 'bold'}}>
        <label className='form-label'>Offer Type</label>
        <select
          className='form-control'
          onChange={(e) => {
            changeOfferedRateType(e)
          }}
          value={offeredRateType}
        >
          <option value='0'>Please select</option>
          {rateTypes.map((rt) => {
            return <option value={rt.value}>{rt.label}</option>
          })}
        </select>
        <label className='form-label mt-3'>Offered Rate ($)</label>
        <input
          type='number'
          className='form-control'
          min={1}
          max={5000}
          step={0.01}
          value={offeredPrice}
          onChange={(e) => {
            changeOfferedPrice(e)
          }}
        />
      </span>
      <div className='d-flex w-100 justify-content-between h-25 border-top'>
        {data.cleaningProviderId == 2 && (
          <button
            className='btn btn-sm text-info'
            onClick={() => {
              setShowOffers(true)
            }}
          >
            Vendor's Offers
          </button>
        )}
        <button
          className='btn btn-link btn-sm text-success me-4'
          onClick={() => {
            updateOfferedRate(data.id, data.serviceTypeName)
          }}
        >
          Save
        </button>
      </div>
      <VendorOffersDialog
        handleShow={showOffers}
        handleClose={handleCloseOfferModal}
        data={data?.offers}
        serviceType={data.serviceTypeName}
        refetch={refetch}
      />
    </div>
  )
}

const PropertyOfferedStep: React.FC = () => {
  const dispatch = useDispatch<Dispatch<HostPropertyAction>>()
  let {propertyId}: any = useParams()
  propertyId = propertyId ? decryptText(propertyId) : undefined

  const {
    data: propertyServiceRateData,
    isFetching: propertyServiceRatesLoading,
    error: propertyServiceRateError,
    refetch: propertyServiceRateRefetch,
  } = useQuery<AxiosResponse<IHostPropertyServiceRatesResponse>>(
    ['Get Property Service Rates', propertyId],
    () => ApiCalls.getHostPropertyServiceRates(propertyId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  if (propertyServiceRatesLoading) {
    return (
      <div>
        <SkeletonTableLoading />
      </div>
    )
  }

  if (propertyServiceRateError) {
    return <div>Error</div>
  }

  return (
    <div className='row'>
      {propertyServiceRateData?.data.data.length === 0 ? (
        <EmptyTable title='No offered services' />
      ) : (
        <div className='d-flex flex-row flex-wrap'>
          {propertyServiceRateData?.data.data.map((rate) => (
            <PropertyOfferedServiceItem data={rate} refetch={propertyServiceRateRefetch} />
          ))}
        </div>
      )}
    </div>
  )
}

export default PropertyOfferedStep
