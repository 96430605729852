import { IAdmins } from '../../interfaces/Admins';
import * as actions from '../actionTypes/AdminTypes';

export function openAddAdminDialog(refetch: () => void): actions.OpenAddAdminDialog {
    return {
        type: actions.OPEN_ADD_ADMIN_DIALOG,
        refetch
    }
}

export function closeAddAdminDialog(): actions.CloseAddAdminDialog {
    return {
        type: actions.CLOSE_ADD_ADMIN_DIALOG,
    }
}
export function openAdminEditDialog(refetch: () => void, admin: IAdmins): actions.OpenEditAdminDialog {
    return {
        type: actions.OPEN_ADMIN_EDIT_DIALOG,
        admin,
        refetch
    }
}
export function closeEditAdminDialog(): actions.CloseEditAdminDialog {
    return {
        type: actions.CLOSE_ADMIN_EDIT_DIALOG,
    }
}