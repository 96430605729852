import {AxiosResponse} from 'axios'
import React, {useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import AddChecklist from '../../../../../_metronic/layout/components/add-property/AddChecklist'
import HostChecklists from '../../../../../_metronic/layout/components/add-property/HostChecklists'
import {useDialog} from '../../../../contexts/DialogContext'
import {IHostPropertyCheckList} from '../../../../interfaces/Checklist'
import {IHostPropertyAdminInput} from '../../../../interfaces/Property'
import ApiCalls from '../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import ArrowButton from '../../../../umut-components/Buttons/ArrowButton'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'

type Props = {
  handleChangeHostProperty: (changedValues: IHostPropertyAdminInput) => void
  goToStep: (key: any) => void
  handleComplete: () => void
  missingField?: boolean
  property?: IHostPropertyAdminInput | null
  currentStep?: any
  cleaningProvider?: number
}

const AddPropertyChecklists: React.FC<Props> = ({
  missingField,
  handleChangeHostProperty,
  goToStep,
  handleComplete,
  property,
  currentStep,
  cleaningProvider,
}) => {
  const {
    data: checklistData,
    isLoading: checklistLoading,
    error: checklistError,
    refetch: refetchChecklists,
  } = useQuery<AxiosResponse<IHostPropertyCheckList[]>>(
    'Get Checklist By Host Id',
    () => ApiCalls.getHostMasterChecklists(property?.hostId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )
  const {
    data: masterChecklistData,
    isLoading: masterChecklistLoading,
    error: masterChecklistError,
    refetch: refetchMasterChecklists,
  } = useQuery<AxiosResponse<IHostPropertyCheckList[]>>(
    'Get Turnify Master Checklists',
    () => ApiCalls.getMasterChecklists(),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const {showFailureDialog} = useDialog()
  const [showChecklistAddModal, setShowChecklistAddModal] = useState<boolean>(false)
  const [selectedChecklist, setSelectedChecklist] = useState(0)
  const [customKeyId, setCustomKeyId] = useState(1)
  const [hasMissingField, setHasMissingField] = useState<boolean>(false)
  const [checklists, setChecklists] = useState<IHostPropertyCheckList[]>([])

  useEffect(() => {
    if (+currentStep == 3) {
      window.scrollTo(0, 0)
      refetchChecklists()
    }
  }, [currentStep])

  useEffect(() => {
    var cld = checklistData?.data
    var mcld = masterChecklistData?.data
    var list: IHostPropertyCheckList[] = []

    mcld?.forEach((cl) => {
      list.push(cl)
    })

    cld?.forEach((c) => {
      list.push(c)
    })

    setChecklists(list)
  }, [checklistData, masterChecklistData])

  const onChangeChecklist = (data: any) => {
    setSelectedChecklist(data)
  }

  const onCompleteClick = () => {
    setCustomKeyId(customKeyId + 1)
    var hasError = false
    if (checklists.length == 0) {
      hasError = true
    }

    if (selectedChecklist == 0) {
      hasError = true
    }

    if (hasError) {
      setHasMissingField(true)
    } else {
      setHasMissingField(false)

      setCustomKeyId(customKeyId + 1)
      handleChangeHostProperty({
        hostPropertyChecklist: {
          checklistId: selectedChecklist,
        },
      })
    }
  }

  const onBackStepClick = () => {
    goToStep(2)
  }

  return (
    <>
      <div className='form w-100 mt-5' id={'checklist-' + customKeyId}>
        {masterChecklistLoading && <Spinner animation='border' size='sm' />}
        {!masterChecklistLoading && (
          <>
            <div style={{minHeight: 200}}>
              {checklists.length > 0 && (
                <>
                  <HostChecklists
                    checklistData={checklists}
                    onSelectedListChanged={onChangeChecklist}
                  />
                </>
              )}
              {checklists.length == 0 && (
                <>
                  <EmptyTable type='checklist' />
                </>
              )}
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-sm btn-success'
                style={{height: '100%'}}
                onClick={() => {
                  setShowChecklistAddModal(true)
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  fill='currentColor'
                  className='bi bi-plus'
                  viewBox='0 0 16 16'
                >
                  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                </svg>
                Add New Checklist
              </button>
            </div>
          </>
        )}
        <div className='d-flex justify-content-between flex-row w-100 mt-6'>
          <ArrowButton type='back' func={onBackStepClick} />
          {hasMissingField &&
            checklists.length > 0 &&
            (selectedChecklist == null || selectedChecklist == 0) && (
              <div className='d-flex justify-content-center'>
                <strong className='text-danger'>You must select a checklist for property.</strong>
              </div>
            )}
          {hasMissingField && checklists.length == 0 && (
            <div className='d-flex justify-content-center'>
              <strong className='text-danger'>
                You must create a checklist and select it for your property.
              </strong>
            </div>
          )}
          <button type='button' className='btn btn-success' onClick={onCompleteClick}>
            Confirm
          </button>
        </div>
      </div>
      <Modal
        show={showChecklistAddModal}
        onHide={() => {
          setShowChecklistAddModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Add Checklist</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <AddChecklist
            onSuccess={() => {
              refetchChecklists()
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddPropertyChecklists
