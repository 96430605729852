import { useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import StaffDocument from './StaffDocument'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import { decryptText } from '../../../../utils/util'
import DeleteDialog from '../../../../umut-components/Modals/DeleteDialog'
import { PageTitle } from '../../../../../_metronic/layout/core'
import ApiCalls from '../../../../network/ApiCalls'
import { RedusxAppState } from '../../../../redux/reducers/rootReducer'

const StaffDocuments = () => {
  const { staffDetail } = useSelector((state: RedusxAppState) => state.staff);
  const { data, isLoading, refetch } = useQuery(
    ['Get Staff Documents', staffDetail?.staff?.userId],
    () => ApiCalls.getVendorUserDocuments(staffDetail?.staff?.userId, staffDetail?.staff?.vendorId),
    { refetchOnWindowFocus: false }
  )
  const [show, setShow] = useState(false)
  const closeModal = () => setShow(false)



  return (
    <div>
      <PageTitle>User Documents</PageTitle>
      <div className='row mt-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <div className='d-flex justify-content-center p-6 fw-bold'>Loading...</div>
            ) : (
              <div className='row gap-2 d-flex'>
                {data?.data?.data.length == 0 ? <EmptyTable title="No Documents" /> : data?.data?.data.map((data: any, idx: number) => (
                  <div className='col-lg-2 col-12 min-w-200px'>
                    <StaffDocument data={data} key={idx} refetch={refetch} />
                  </div>
                ))}
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
      <DeleteDialog />
    </div>
  )
}

export default StaffDocuments
