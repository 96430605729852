import React, {Dispatch, useState, useEffect} from 'react'
import {Dialog, DialogTitle, DialogContent} from '@material-ui/core'
import {IPaymentFee} from '../../../interfaces/PaymentFee'
import _ from 'lodash'
import ApiCalls from '../../../network/ApiCalls'
import {useDialog} from '../../../contexts/DialogContext'

type Props = {
  isVisible: boolean
  data?: IPaymentFee
  handleClose: () => void
  refetch: () => void
}

const PaymentFeeDetailDialog: React.FC<Props> = ({isVisible, data, handleClose, refetch}) => {
  const [feeData, setFeeData] = useState<IPaymentFee | undefined>()
  const {showFailureDialog, showSuccessDialog} = useDialog()

  useEffect(() => {
    setFeeData(data)
  }, [data])

  const changeFeeType = (e: any) => {
    var data = _.cloneDeep(feeData)
    if (data != undefined) {
      if (e.target.value == '0') {
        data.isPercentage = false
      } else {
        data.isPercentage = true
      }

      setFeeData(data)
    }
  }

  const changeValue = (e: any) => {
    var data = _.cloneDeep(feeData)
    if (data != undefined) {
      data.fee = +e.target.value
      setFeeData(data)
    }
  }

  const updatePaymentFees = () => {
    ApiCalls.updatePaymentFee(feeData)
      .then((d) => {
        if (d.data.success) {
          showSuccessDialog('Payment fee setting successfully updated!')
          refetch()
          handleClose()
        }
      })
      .catch((e) => {
        showFailureDialog('An error occur during updating fee!', e)
      })
  }

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth={true}
      style={{overflowY: 'unset'}}
    >
      <DialogTitle className='border-bottom d-flex justify-content-between align-items-center'>
        Edit {feeData?.title}
      </DialogTitle>
      <DialogContent style={{overflowY: 'unset'}}>
        <div className='row py-5'>
          <div className='col-12 mb-5'>
            <label>Fee Type</label>
            <select
              className='form-control'
              onChange={(e) => {
                changeFeeType(e)
              }}
            >
              <option value='0' selected={!feeData?.isPercentage}>
                Net Price
              </option>
              <option value='1' selected={feeData?.isPercentage}>
                Price Percentage
              </option>
            </select>
          </div>
          <div className='col-12 mb-5'>
            <label>Value ({feeData?.isPercentage ? '%' : '$'})</label>
            <input
              type='text'
              className='form-control'
              value={feeData?.fee}
              onChange={(e) => {
                changeValue(e)
              }}
            />
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-12'>
            <div className='d-flex justify-content-end align-items-center'>
              <button className='btn btn-link text-muted me-4' onClick={handleClose}>
                Cancel
              </button>
              <button
                className='btn btn-sm btn-danger'
                onClick={() => {
                  updatePaymentFees()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PaymentFeeDetailDialog
