import { IForceFinish } from '../../interfaces/DashboardContent'
import * as actions from '../actionTypes/finishRequestTypes'

export function openForceFinishView(forceFinishDetail:IForceFinish): actions.OpenForceFinishAction {
    return {
      type: actions.FORCEFINISH_OPEN_VIEW,
      forceFinishDetail
    }
  }
  
  export function closeForceFinishView(): actions.CloseForceFinishAction {
    return {
      type: actions.FORCEFINISH_CLOSE_VIEW,
    }
  }
  export function openRejectDialog(deleteFn: () => Promise<void>, bodyText: string, title: string): actions.OpenRejectDialog {
    return {
        type: actions.OPEN_REJECT_DIALOG,
        deleteFn,
        bodyText,
        title
    }
}

export function closeRejectDialog(): actions.CloseRejectDialog {
    return {
        type: actions.CLOSE_REJECT_DIALOG,
    }
}

