import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'

import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import ApiCalls from '../../network/ApiCalls'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import SkeletonTableLoading from '../../umut-components/Loading/SkeletonTableLoading'
import { toRequestTimeFormat, decryptText } from '../../utils/util'
import SchedulerDialog from './Tabs/VendorTab/SchedulerDialog'
import SuitableStaffsTable from './Tabs/VendorTab/SuitableStaffsTable'
import SuitableVendorsTable from './Tabs/VendorTab/SuitableVendorsTable'


type Props = {

}
const AssingmentHostModal: FC<Props> = ({ }) => {
    let { jobId }: any = useParams()
    jobId = decryptText(jobId)

    const { jobDetail }: any = useSelector((state: RedusxAppState) => state.job)

    const {data: staffs, isLoading, refetch} = useQuery(
        ['Get Host Details by Id', jobDetail?.hostId],
        () => ApiCalls.getHostById(jobDetail?.hostId),
        {cacheTime: 500000, refetchOnWindowFocus: false, enabled: jobDetail?.hostId ? true : false}
      )

    
  
    if (isLoading) {
        return (
              <SkeletonTableLoading />
        )
  }


    return (
        <>
    
            <SuitableStaffsTable data={staffs?.data} />
             
            <SchedulerDialog />
        </>
    )
}

export default AssingmentHostModal

