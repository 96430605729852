import {DataGrid} from 'devextreme-react'
import {
  Export,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Paging,
  Summary,
  TotalItem,
  Column,
} from 'devextreme-react/data-grid'
import {useEffect, useRef, useState} from 'react'
import {
  setDxFilterPlaceholder,
  onExporting,
  getDateFilter,
  encryptText,
  toConvertedDate,
  toConvertedDateTz,
} from '../../../../utils/util'
import {JobStatusType} from '../../../../enums/JobEnum'
import config from '../../../../config/config.json'
import {useHistory} from 'react-router-dom'
import {IJob} from '../../../../interfaces/Job'
import dayjs from 'dayjs'
import EmptyTable from '../../../Icons/EmptyTable'

type Props = {
  data: IJob[] | undefined
  dataType: 'past' | 'upcoming'
  propertyId: number
}

const JobHistorySectionTab: React.FC<Props> = ({data, dataType, propertyId}) => {
  const history = useHistory()
  const tableRef = useRef<DataGrid>(null)
  const [jobData, setJobData] = useState<IJob[]>([])
  useEffect(() => {
    if (data != undefined) {
      if (dataType == 'past') {
        var jobs: IJob[] = []
        data.map((d) => {
          if (dayjs().isAfter(dayjs(d.cleaningPeriodStart))) {
            jobs.push(d)
          }
        })
        setJobData(jobs)
      } else {
        var jobs: IJob[] = []
        data.map((d) => {
          if (dayjs().isBefore(dayjs(d.cleaningPeriodStart).add(1, 'day'))) {
            jobs.push(d)
          }
        })
        setJobData(jobs)
      }
    }
  }, [data])

  const displayStatusType = (cellData: any) => {
    const jobStatusType = cellData?.data?.jobStatusTypeName
    return (
      <strong
        className={`text-${
          jobStatusType === JobStatusType.COMPLETED
            ? 'success'
            : jobStatusType === JobStatusType.PENDING
            ? 'warning'
            : 'danger'
        }`}
      >
        {jobStatusType === 'Started' ? 'In Progress' : jobStatusType}
      </strong>
    )
  }

  const toJobDetail = (cellData: any) => {
    if (cellData?.data?.id) {
      history.push({
        pathname: `/job-details/` + encryptText(cellData?.data?.id),
      })
    }
  }
  const calculateScheduledStart = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledStart, cellValue?.hostPropertyCityTimeZone)
  }

  const calculateScheduledFinish = (cellValue: any) => {
    return toConvertedDate(cellValue.scheduledFinish, cellValue?.hostPropertyCityTimeZone)
  }

  const calculateStartedOn = (cellValue: any) => {
    return toConvertedDateTz(cellValue.startedOn, cellValue?.hostPropertyCityTimeZone)
  }

  const calculateFinishedOn = (cellValue: any) => {
    return toConvertedDateTz(cellValue.finishedOn, cellValue?.hostPropertyCityTimeZone)
  }

  if (jobData.length == 0) {
    return <EmptyTable title={'No ' + dataType + ' jobs found'} />
  }

  return (
    <DataGrid
      id={'jobTableProperty' + propertyId}
      keyExpr='id'
      onRowPrepared={(e) => {
        e.rowElement.classList.add('data-grid-row')
      }}
      dataSource={jobData}
      showBorders={false}
      showColumnLines={false}
      showRowLines={true}
      onCellClick={toJobDetail}
      onEditorPreparing={setDxFilterPlaceholder}
      hoverStateEnabled={true}
      ref={tableRef}
      height={'calc(55vh - 60px)'}
      allowColumnResizing={true}
      selection={{mode: 'single'}}
      className='mt-6'
      onExporting={(e) => onExporting(e, 'Job History')}
    >
      <Export enabled />
      <SearchPanel visible={true} />
      <FilterRow showOperationChooser={false} visible={true} />
      <HeaderFilter visible={true} />
      <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
      <Paging defaultPageSize={10} />
      <Summary>
        <TotalItem
          cssClass='absolute-right'
          displayFormat='Total Jobs: {0}'
          column='hostPropertyAddress'
          summaryType='count'
        />
      </Summary>

      <Column dataField='hostPropertyAddress' minWidth={450} caption='Property' cssClass='cls' />
      <Column
        dataField='jobStatusTypeName'
        alignment='center'
        caption='Status'
        cellRender={displayStatusType}
        minWidth={150}
        cssClass='cls'
      />
      <Column
        dataField='scheduledStart'
        dataType='date'
        selectedFilterOperation='between'
        format={config.date_time_format_dev_extreme}
        calculateCellValue={calculateScheduledStart}
        minWidth={150}
        cssClass='cls'
      >
        <HeaderFilter dataSource={getDateFilter('scheduledStart')} />
      </Column>
      <Column
        dataField='scheduledFinish'
        dataType='date'
        selectedFilterOperation='between'
        format={config.date_time_format_dev_extreme}
        calculateCellValue={calculateScheduledFinish}
        minWidth={150}
        cssClass='cls'
      >
        <HeaderFilter dataSource={getDateFilter('scheduledFinish')} />
      </Column>
      <Column
        dataField='startedOn'
        caption='started at'
        dataType='date'
        selectedFilterOperation='between'
        format={config.date_time_format_dev_extreme}
        calculateCellValue={calculateStartedOn}
        minWidth={150}
        cssClass='cls'
      >
        <HeaderFilter dataSource={getDateFilter('startedOn')} />
      </Column>
      <Column
        dataField='finishedOn'
        caption='finished at'
        dataType='date'
        selectedFilterOperation='between'
        format={config.date_time_format_dev_extreme}
        calculateCellValue={calculateFinishedOn}
        minWidth={150}
        cssClass='cls'
      >
        <HeaderFilter dataSource={getDateFilter('finishedOn')} />
      </Column>
    </DataGrid>
  )
}

export default JobHistorySectionTab
