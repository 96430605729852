import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap-v5'
import { VendorCreateForm } from '../../umut-components/Forms/VendorCreateForm'

type Props = {
    show: Boolean,
    handleClose: () => void
}

const VendorsCreateDialog: FC<Props> = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Vendor Create</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <VendorCreateForm />

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default VendorsCreateDialog
