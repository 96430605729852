import { Dispatch, FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators';
import { Modal, Spinner } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import { TextInput } from '../Inputs/TextInput'
import SelectInput from '../Inputs/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ApiCalls from '../../network/ApiCalls';
import { StaffOfferedRequest } from '../../network/PostRequestModels';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { NumberInput } from '../Inputs/NumberInput';
import { decryptText } from '../../utils/util';
import { IService } from '../../interfaces/ServiceType';

type Props = {
}

const StaffOfferedForm: FC<Props> = ({ }) => {
    let { hostUserId, vendorUserId }: any = useParams();
    hostUserId = hostUserId ? decryptText(hostUserId) : undefined;
    vendorUserId = vendorUserId ? decryptText(vendorUserId) : undefined;
    const { staffOfferedDialog } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [types, setTypes] = useState([])
    const { data: serviceTypes, isLoading, error } = useQuery('Offered Services Types', () => ApiCalls.getServiceTypes(), { cacheTime: 500000, refetchOnWindowFocus: false });
    const [offeredRequest, setOfferedRequest] = useState<StaffOfferedRequest>({
        unitSize: 0,
        servicePrice: staffOfferedDialog.staffOfferedService?.servicePrice,
        duration: staffOfferedDialog.staffOfferedService?.duration,
        serviceTypeId: staffOfferedDialog.staffOfferedService?.serviceTypeId ? staffOfferedDialog.staffOfferedService?.serviceTypeId : 1
    } as StaffOfferedRequest);
    const [selectedServiceDescription, setSelectedServiceDescription] = useState<string | null>(null);

    const handleSubmit = async () => {
        if (offeredRequest.duration >= 15) {
            if (offeredRequest.duration && offeredRequest.serviceTypeId) {
                try {
                    setLoading(true);
                    if (hostUserId) {
                        !staffOfferedDialog.staffOfferedService && await ApiCalls.addStaffOfferedService({ ...offeredRequest, hostUserId: parseInt(hostUserId) });
                    }
                    if (vendorUserId) {
                        !staffOfferedDialog.staffOfferedService && await ApiCalls.addStaffOfferedService({ ...offeredRequest, vendorUserId: parseInt(vendorUserId) });
                    }
                    staffOfferedDialog.staffOfferedService && await ApiCalls.updateStaffOfferedService(staffOfferedDialog.staffOfferedService.id, { ...offeredRequest });
                    setLoading(false)
                    staffOfferedDialog.refetch();
                    dispatch(staffActionCreator.closeStaffOfferedAddDialog());
                } catch (error: any) {
                    setLoading(false)
                    setErrorMessage(error?.response?.data?.message);
                }
            }
            else {
                setErrorMessage('All fields required.');
            }
        } else {
            setErrorMessage('Please enter duration more than 15 minutes');
        }

    }

    useEffect(() => {
        if (serviceTypes) {
            setTypes(serviceTypes?.data?.data.map((type: any) => ({ label: type.name, value: type.id })));
        }
    }, [serviceTypes])

    useEffect(() => {
        if (offeredRequest.serviceTypeId && serviceTypes?.data?.data) {
            const selectedService: IService = serviceTypes?.data?.data?.find((service: IService) => service.id === offeredRequest.serviceTypeId)
            setSelectedServiceDescription(selectedService.description)

        }
    }, [offeredRequest.serviceTypeId, serviceTypes])
    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>
                <div className='col-lg-12'>

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Service Type</label>
                    <SelectInput disabled={!!staffOfferedDialog.staffOfferedService?.serviceTypeId} defaultValue={offeredRequest.serviceTypeId} options={types} onValueChanged={(value) => { setOfferedRequest({ ...offeredRequest, serviceTypeId: value }) }} />
                    {selectedServiceDescription && <span className='text-muted m-1 '>{selectedServiceDescription}</span>}
                    <div></div>
                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Service Price (Optional)</label>
                    <NumberInput defaultValue={offeredRequest.servicePrice} isErrorMessageActive={false} min={1} max={1000} placeholder="Service Price" onValueChange={(text: number) => setOfferedRequest({ ...offeredRequest, servicePrice: text })} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Duration (Minutes)</label>
                    <NumberInput defaultValue={offeredRequest.duration} min={15} placeholder="Duration" onValueChange={(number: number) => setOfferedRequest({ ...offeredRequest, duration: number })} />

                    <strong className='w-100 text-danger'></strong>

                    <div className='col mt-8 '>
                        <div className='d-flex col gap-3 justify-content-end'>
                            <button type='button' className='btn btn-success ' disabled={loading} onClick={handleSubmit}>Save Service {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default StaffOfferedForm
