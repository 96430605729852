import React, {Dispatch, useState} from 'react'
import {Card} from 'react-bootstrap-v5'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useQuery} from 'react-query'
import ApiCalls from '../../../network/ApiCalls'
import SkeletonTableLoading from '../../../umut-components/Loading/SkeletonTableLoading'
import {useDispatch} from 'react-redux'
import {HostAction} from '../../../redux/actionTypes/hostTypes'
import * as hostActionCreator from '../../../redux/actionCreators/hostActionCreators'
import MyTeamSubscribersTable from './MyTeamSubscribersTable'

const MyTeamSubscribers: React.FC = () => {
  const dispatch = useDispatch<Dispatch<HostAction>>()
  const {data, isLoading, error, refetch} = useQuery(
    'Get My Team Subscribers',
    () => ApiCalls.getMyTeamSubscribers(),
    {cacheTime: 500000, refetchOnWindowFocus: true}
  )

  const clickAddButton = () => {
    dispatch(hostActionCreator.openAddHostDialog(refetch))
  }

  return (
    <>
      <PageTitle>My Team Subscribers</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <MyTeamSubscribersTable refetch={refetch} data={data?.data.data} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default MyTeamSubscribers
