import { Card } from 'react-bootstrap-v5'
import { useQuery } from 'react-query'
import ReferenceTable from './VendorReferenceTable'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import ApiCalls from '../../../../../network/ApiCalls'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import { useParams } from 'react-router-dom'
import { decryptText } from '../../../../../utils/util'

const VendorReference = () => {
  let { vendorId }: any = useParams()
  vendorId = decryptText(vendorId);
  const {
    data,
    isLoading,
    error,
    refetch: refetchReferences,
  } = useQuery(['Get Reference', vendorId], () => ApiCalls.getVendorReference(vendorId), {
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <Card>
          <Card.Title></Card.Title>
          <Card.Body>
            {isLoading ? (
              <SkeletonTableLoading />
            ) : (
              <ReferenceTable refetchReferences={refetchReferences} data={data?.data?.data} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default VendorReference
