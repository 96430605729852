import React from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useHistory } from 'react-router-dom';

type Props = {
    modalOpen: boolean,
    handleClose: () => void
}

const AddPropertyTypeSelection: React.FC<Props> = ({ handleClose, modalOpen }) => {
    const history = useHistory();

    return (
        <Modal show={modalOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <Modal.Title>Add New Property</Modal.Title>
            </ModalHeader>
            <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
                If you are using PMS for your properties (such as Guesty, Myvr, Hostaway),
                you can quickly add your properties to the system from the
                "Add Property from PMS" option. If you are not using PMS, you can register your property to the system by
                filling the information in the "Add Property Manual" option.
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={() => history.push('/add-property-pms')}>Add Property from PMS</button>
                <button className="btn btn-outline-info" onClick={() => history.push('/add-property-manual')}>Add Property Manually</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPropertyTypeSelection;