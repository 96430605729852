import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {useSelector} from 'react-redux'
import {useDialog} from '../../../../../contexts/DialogContext'
import {IVendorSubscriptionInvoice} from '../../../../../interfaces/Vendors'
import ApiCalls from '../../../../../network/ApiCalls'
import {RedusxAppState} from '../../../../../redux/reducers/rootReducer'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'

type Props = {
  visible: boolean
  invNum: any
  handleClose: () => void
}

const VendorSubscriptionsInvoiceDetail: React.FC<Props> = ({invNum, visible, handleClose}) => {
  const {user} = useSelector((state: RedusxAppState) => state.user)
  const [invoiceDetail, setInvoiceDetail] = useState<IVendorSubscriptionInvoice>()
  const [loading, setLoading] = useState(false)
  const {showFailureDialog} = useDialog()

  const {
    data: vendorDetailData,
    isLoading: vendorDetailLoading,
    error: isVendorDetailError,
    refetch: refetchVendorDetail,
  } = useQuery(['Get Vendor Detail'], () => ApiCalls.getVendorDetails(user.userSection.vendorId), {
    refetchOnWindowFocus: false,
  })

  const {
    data: invoice,
    isLoading: invoiceLoading,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useQuery(
    ['Get Vendor Subscription Invoice Detail'],
    () => ApiCalls.getVendorSubscriptionInvoiceDetail(invNum),
    {
      cacheTime: 50000,
      refetchOnWindowFocus: true,
    }
  )

  const chargeInvoice = async () => {
    try {
      setLoading(true)
      await ApiCalls.chargeSubscriptionInvoice(invNum)
      refetchInvoice()
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      showFailureDialog(error?.response?.data.message)
    }
  }

  useEffect(() => {
    if (!invoiceLoading && invoice?.data) {
      setInvoiceDetail(invoice?.data)
    }
  }, [invoice, invoiceLoading])

  const getInvoiceStatus = () => {
    if (invoiceDetail) {
      if (invoiceDetail.invoiceStatus.name == 'Paid') {
        return <button className='btn btn-sm btn-success ms-3'>Paid</button>
      }
      if (invoiceDetail.invoiceStatus.name == 'Due') {
        return <button className='btn btn-sm btn-warning ms-3'>Due</button>
      }
      if (
        invoiceDetail.invoiceStatus.name == 'Cancelled' ||
        invoiceDetail.invoiceStatus.name == 'Unpaid'
      ) {
        return (
          <button className='btn btn-sm btn-danger ms-3'>{invoiceDetail.invoiceStatus.name}</button>
        )
      }
    } else {
      return <></>
    }
  }

  return (
    <Modal show={visible} onHide={handleClose} centered size='xl'>
      <ModalHeader closeButton>
        <Modal.Title>Invoice Detail</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <div className='card'>
          <div className='card-body p-lg-20'>
            <div className='d-flex flex-column flex-xl-row'>
              <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
                <div className='mt-n1'>
                  <div className='d-flex flex-stack pb-10'>
                    <img alt='Logo' src='/media/logos/turnify-logo.png' width={160} />
                    <div>
                      {invoiceDetail?.invoiceStatus.name != 'Paid' &&
                        dayjs(invoiceDetail?.paymentDate).format('MM') == dayjs().format('MM') && (
                          <button
                            className='btn btn-sm btn-info'
                            disabled={loading}
                            onClick={() => {
                              chargeInvoice()
                            }}
                          >
                            Pay Now
                          </button>
                        )}
                      {getInvoiceStatus()}
                    </div>
                  </div>
                  <div className='m-0'>
                    <div className='fw-bold fs-3 text-gray-800 mb-8'>
                      Invoice #{invoiceDetail?.invoiceNumber}
                    </div>
                    <div className='row g-5 mb-11'>
                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Invoice Date:</div>

                        <div className='fw-bold fs-6 text-gray-800'>
                          {dayjs(invoiceDetail?.paymentDate).format('DD MMM YYYY')}
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Payment Date:</div>

                        <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                          <span className='pe-2'>
                            {dayjs(invoiceDetail?.paymentDate).format('DD MMM YYYY')}
                          </span>

                          {dayjs(invoiceDetail?.paymentDate).isAfter(dayjs()) && (
                            <span className='fs-7 text-danger d-flex align-items-center'>
                              <span className='bullet bullet-dot bg-danger me-2'></span>
                              Due in {dayjs(invoiceDetail?.paymentDate).diff(dayjs(), 'days')} days
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row g-5 mb-12'>
                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issue For:</div>
                        {vendorDetailData?.data && (
                          <>
                            <div className='fw-bold fs-6 text-gray-800'>
                              {vendorDetailData?.data?.name}
                            </div>
                            <div className='fw-semibold fs-7 text-gray-600'>
                              {vendorDetailData?.data?.phoneNumber}
                            </div>
                          </>
                        )}
                      </div>
                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Issued By:</div>
                        <div className='fw-bold fs-6 text-gray-800'>TidyHost</div>
                        <div className='fw-semibold fs-7 text-gray-600'>
                          150 Orchard Court, Blue Bell, PA 19422
                          <br />
                          concierge@turnify.com <br />
                          (833)TDY-HOST
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <div className='table-responsive border-bottom mb-9'>
                        <table className='table mb-3'>
                          <thead>
                            <tr className='border-bottom fs-6 fw-bold text-muted'>
                              <th className='min-w-175px pb-2'>Description</th>
                              <th className='min-w-70px text-end pb-2'>Quantity</th>
                              <th className='min-w-80px text-end pb-2'>Amount</th>
                              <th className='min-w-100px text-end pb-2'>Total Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {invoiceDetail?.vendorSubscriptionInvoiceItems.map((detail, index) => {
                              return (
                                <tr
                                  className='fw-bold text-gray-700 fs-5 text-end'
                                  key={'invoice-item-' + index}
                                >
                                  <td className='d-flex align-items-center pt-6'>
                                    <i className='fa fa-genderless text-danger fs-2 me-2'></i>
                                    {detail.itemType.name}
                                  </td>

                                  <td className='pt-6'>{detail.quantity}</td>
                                  <td className='pt-6'>${detail.grossAmount}</td>
                                  <td className='pt-6 text-dark fw-bolder'>
                                    ${detail.totalGrossAmount}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <div className='mw-300px'>
                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-semibold pe-10 text-gray-600 fs-7'>Subtotal:</div>

                            <div className='text-end fw-bold fs-6 text-gray-800'>
                              $ {invoiceDetail?.grossAmount}
                            </div>
                          </div>

                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-semibold pe-10 text-gray-600 fs-7'>VAT</div>

                            <div className='text-end fw-bold fs-6 text-gray-800'>
                              $ {invoiceDetail?.tax}
                            </div>
                          </div>

                          <div className='d-flex flex-stack mb-3'>
                            <div className='fw-semibold pe-10 text-gray-600 fs-7'>
                              Subtotal + VAT
                            </div>
                            <div className='text-end fw-bold fs-6 text-gray-800'>
                              $ {invoiceDetail?.netAmount}
                            </div>
                          </div>

                          <div className='d-flex flex-stack'>
                            <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>
                            <div className='text-end fw-bold fs-6 text-gray-800'>
                              $ {invoiceDetail?.netAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default VendorSubscriptionsInvoiceDetail
