import React, {useEffect, useRef} from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import {Client} from '@googlemaps/google-maps-services-js'
const debounce = require('debounce')

const containerStyle = {
  width: '100%',
  height: '400px',
}

const addressComponentTypes = {
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  COUNTY: 'administrative_area_level_2',
  CITY: 'locality',
  CITY2: 'sublocality',
  CITY3: 'postal_town',
  CITY4: 'administrative_area_level_2',
  ZIPCODE: 'postal_code',
  NEIGHBORHOOD: 'neighborhood',
}

function Map({
  onLatLngChanged,
  initialLocation,
  initialAddress,
  draggable,
  selectedAddress,
  placeId,
}) {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    language: 'EN',
  })

  const [lat, setLat] = React.useState(
    initialLocation?.latitude ? initialLocation?.latitude : 39.952583
  )
  const [lng, setLng] = React.useState(
    initialLocation?.longitude ? initialLocation?.longitude : -75.165222
  )

  const findLongName = (res, typeName) => {
    const resultList = res.results[0].address_components
    var longName = ''
    resultList.forEach((data) => {
      if (data.types.includes(typeName)) {
        longName = data.long_name
      }
    })
    return longName
  }

  const handleMarkerChange = async (e) => {
    let lat = e.latLng.lat()
    let lng = e.latLng.lng()
    setLat(lat)
    setLng(lng)
    try {
      const geocoder = new window.google.maps.Geocoder()
      const res = await geocoder.geocode({location: {lat, lng}})
      if (res) {
        const changedLocation = {
          address: e.changeSelectedAddress ? e.selectedAddress : res.results[0].formatted_address,
          zipCode: findLongName(res, addressComponentTypes.ZIPCODE),
          latitude: lat,
          longitude: lng,
          country: findLongName(res, addressComponentTypes.COUNTRY),
          state: findLongName(res, addressComponentTypes.STATE),
          city: findLongName(res, addressComponentTypes.CITY).length
            ? findLongName(res, addressComponentTypes.CITY)
            : findLongName(res, addressComponentTypes.CITY2).length
            ? findLongName(res, addressComponentTypes.CITY2)
            : findLongName(res, addressComponentTypes.CITY3).length
            ? findLongName(res, addressComponentTypes.CITY3)
            : findLongName(res, addressComponentTypes.CITY4),
        }
        onLatLngChanged(changedLocation)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const findLatLngByAddress = async () => {
    try {
      if (initialAddress && isLoaded) {
        const geocoder = new window.google.maps.Geocoder()
        const res = await geocoder.geocode({address: initialAddress})
        if (res) {
          setLat(res.results[0].geometry.location.lat())
          setLng(res.results[0].geometry.location.lng())
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const searchLocationByAddress = async (text) => {
    try {
      if (text) {
        const geocoder = new window.google.maps.Geocoder()
        const res = await geocoder.geocode({address: text})
        if (res.results.length > 0) {
          handleMarkerChange({
            latLng: {
              lat: () => res.results[0].geometry.location.lat(),
              lng: () => res.results[0].geometry.location.lng(),
            },
            changeSelectedAddress: true,
            selectedAddress: text,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const searchLocationByPlaceId = async (text) => {
    try {
      if (text) {
        const geocoder = new window.google.maps.Geocoder()
        const res = await geocoder.geocode({placeId: text})

        if (res.results.length > 0) {
          setLat(res.results[0].geometry.location.lat())
          setLng(res.results[0].geometry.location.lng())
          const changedLocation = {
            address: res.results[0].formatted_address,
            zipCode: findLongName(res, addressComponentTypes.ZIPCODE),
            latitude: res.results[0].geometry.location.lat(),
            longitude: res.results[0].geometry.location.lng(),
            country: findLongName(res, addressComponentTypes.COUNTRY),
            state: findLongName(res, addressComponentTypes.STATE),
            city: findLongName(res, addressComponentTypes.CITY).length
              ? findLongName(res, addressComponentTypes.CITY)
              : findLongName(res, addressComponentTypes.CITY2).length
              ? findLongName(res, addressComponentTypes.CITY2)
              : findLongName(res, addressComponentTypes.CITY3).length
              ? findLongName(res, addressComponentTypes.CITY3)
              : findLongName(res, addressComponentTypes.CITY4),
          }
          onLatLngChanged(changedLocation)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (placeId) {
      searchLocationByPlaceId(placeId)
    }
  }, [placeId])

  useEffect(() => {
    findLatLngByAddress()
  }, [initialAddress, isLoaded])

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={{lat, lng}} zoom={18}>
      <Marker
        position={{lat: lat, lng: lng}}
        draggable={draggable}
        onDragEnd={handleMarkerChange}
      />
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(Map)
