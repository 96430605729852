import {Dispatch, FC, useEffect, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
  ColumnHeaderFilter,
  MasterDetail,
} from 'devextreme-react/data-grid'
import {
  convertUTCDateToLocalDate,
  decryptText,
  onExporting,
  toConvertedTimeString,
  toRequestTimeFormat,
} from '../../../../utils/util'
import StaffAccordionTab from './StaffAccordionTab'
import DateTimePicker from '../../../../umut-components/Inputs/DateTimePicker'
import {useDispatch, useSelector} from 'react-redux'
import {RedusxAppState} from '../../../../redux/reducers/rootReducer'
import dayjs from 'dayjs'
import {Button, Modal, Spinner} from 'react-bootstrap-v5'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import ApiCalls from '../../../../network/ApiCalls'
import {useParams} from 'react-router-dom'
import {IReVendorStaffAssign} from '../../../../network/PostRequestModels/ReAssignRequests'
import React from 'react'
import ConfirmationDialog from './ConfirmationDialog'
import {useDialog} from '../../../../contexts/DialogContext'
import notify from 'devextreme/ui/notify'
import {JobAction} from '../../../../redux/actionTypes/jobTypes'
import * as jobActionCreator from '../../../../redux/actionCreators/jobActionCreators'
import HostStaffAccordionTab from './HostStaffAccordionTab'
import {typeOf} from 'react-is'
import EmptyTable from '../../../../umut-components/Icons/EmptyTable'
import {
  IAvailableVendorsForPropertyFromJob,
  ISaveVendorToProperty,
} from '../../../../interfaces/Vendors'
import SelectInput from '../../../../umut-components/Inputs/SelectInput'
import {TextInput} from '../../../../umut-components/Inputs/TextInput'

type Props = {
  data: any
  checkOut?: any
  refetch?: () => void
}
const SuitableVendorsTable: FC<Props> = ({data, checkOut, refetch}) => {
  if (checkOut == undefined) {
    checkOut = '10:00'
  }
  let {jobId}: any = useParams()

  const {jobDetail}: any = useSelector((state: RedusxAppState) => state.job)
  const dispatch = useDispatch<Dispatch<JobAction>>()

  jobId = jobId ?? jobDetail?.id
  const {scheduleStartTime}: any = useSelector((state: RedusxAppState) => state.assign)

  const ref = useRef<{getAssingObject: () => IReVendorStaffAssign}>()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [showTable, setShowTable] = useState<boolean>(false)
  const {showSuccessDialog} = useDialog()
  const [showAddVendorServiceModal, setShowAddVendorServiceModal] = useState<boolean>(false)
  const [jobSum, setJobSum] = useState<IAvailableVendorsForPropertyFromJob>()

  const [loading, setLoading] = useState(false)
  const [scheduleStart, setScheduleStart] = useState<string>(
    jobDetail?.scheduledStart
      ? toConvertedTimeString(
          dayjs(jobDetail?.scheduledStart).utc().format('YYYY-MM-DDTHH:mm').toString()
        )
      : toConvertedTimeString(
          dayjs(jobDetail?.cleaningPeriodStart)
            .utc()
            .format('YYYY-MM-DDT' + checkOut)
            .toString()
        )
  )

  const [errorMessage, setErrorMessage] = useState()
  const queryClient = useQueryClient()
  const {mutateAsync: scheduledJob} = useMutation(ApiCalls.scheduleJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })
  const {mutateAsync: reScheduledJob} = useMutation(ApiCalls.putReSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Get Job Detail', Number(jobId)])
      setLoading(false)
    },
  })

  const checkScheduledEndTime = () => {
    let day1 = dayjs(scheduleStart)
    let day2 = dayjs(jobDetail?.cleaningPeriodEnd)
    if (day2.diff(day1) <= 0) {
      setConfirmationModal(true)
    } else {
      sendRequest()
    }
  }

  const sendRequest = async () => {
    var scheduleTime = scheduleStart
    if (dayjs(scheduleStart).isBefore(dayjs(jobDetail?.cleaningPeriodStart))) {
      setScheduleStart(
        jobDetail?.scheduledStart
          ? dayjs(jobDetail?.scheduledStart).format('YYYY-MM-DDT' + checkOut)
          : dayjs(jobDetail?.cleaningPeriodStart).format('YYYY-MM-DDT' + checkOut)
      )
      scheduleTime = jobDetail?.scheduledStart
        ? dayjs(jobDetail?.scheduledStart).format('YYYY-MM-DDT' + checkOut)
        : dayjs(jobDetail?.cleaningPeriodStart).format('YYYY-MM-DDT' + checkOut)
    }

    try {
      if (ref.current) {
        const reAssignObject: IReVendorStaffAssign = {
          ...ref.current.getAssingObject(),
          scheduledStart: scheduleTime,
        }
        setLoading(true)
        {
          jobDetail.jobStatusType.name == 'Pending' && (await scheduledJob(reAssignObject))
        }
        {
          jobDetail.jobStatusType.name == 'Scheduled' && (await reScheduledJob(reAssignObject))
        }
        dispatch(jobActionCreator.closeJobDialog())
        showSuccessDialog('Assignment Successful.')
        setLoading(false)
      }
    } catch (error: any) {
      notify(
        {
          message: error?.response?.data?.message,
          position: {
            my: 'center bottom',
            at: 'center bottom',
          },
        },
        'warning',
        5000
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    if (checkOut) {
      setScheduleStart(
        jobDetail?.scheduledStart
          ? dayjs(jobDetail?.scheduledStart).format('YYYY-MM-DDT' + checkOut)
          : dayjs(jobDetail?.cleaningPeriodStart).format('YYYY-MM-DDT' + checkOut)
      )
    }
  }, [checkOut])

  useEffect(() => {
    setTimeout(() => {
      setShowTable(true)
    }, 2000)
  }, [])

  const openAddVendorServiceModal = () => {
    setShowAddVendorServiceModal(true)
  }

  useEffect(() => {
    if (jobDetail) {
      const data = {
        jobId: jobDetail.id,
        hostId: jobDetail.hostId,
        hostPropertyId: jobDetail.hostPropertyId,
        checklistId: jobDetail.jobServiceChecklist.hostPropertyChecklist.id,
        serviceTypeId: jobDetail.jobServiceChecklist.hostPropertyChecklist.serviceTypeId,
      }

      setJobSum(data)
    }
  }, [jobDetail])

  return (
    <>
      {showTable && data.length > 0 && (
        <>
          <VendorTable data={data} ref={ref} />

          <div className='d-flex justify-content-between align-items-center'>
            <div className='me-3 w-100 d-flex justify-content-start'>
              <button className='btn btn-info' onClick={() => openAddVendorServiceModal()}>
                + Add Vendor Service
              </button>
            </div>
            <div className='me-3 w-100 d-flex justify-content-end'>
              <div>
                <strong className='text-muted'>Schedule start time</strong>
                <DateTimePicker
                  className='h-40px'
                  dateOutOfRangeMessage='Time cannot be earlier than job start time.'
                  onChange={(date: any) => {
                    setScheduleStart(date)
                  }}
                  defaultValue={
                    jobDetail?.scheduledStart
                      ? toConvertedTimeString(
                          dayjs(jobDetail?.scheduledStart)
                            .utc()
                            .format('YYYY-MM-DDTHH:mm')
                            .toString()
                        )
                      : toConvertedTimeString(
                          dayjs(jobDetail?.cleaningPeriodStart)
                            .utc()
                            .format('YYYY-MM-DDTHH:mm')
                            .toString()
                        )
                  }
                />
                <small className='text-muted'>
                  Job starts at{' '}
                  {jobDetail?.scheduledStart
                    ? dayjs(jobDetail?.scheduledStart).utc().format('MM/DD/YYYY H:mm A')
                    : dayjs(jobDetail?.cleaningPeriodStart).utc().format('MM/DD/YYYY H:mm A')}
                </small>
              </div>
              <div className='my-auto mx-3'>
                <button
                  disabled={loading}
                  type='button'
                  onClick={() => {
                    checkScheduledEndTime()
                  }}
                  className='btn btn-success'
                >
                  ✔ Save Assignment {loading && <Spinner animation='border' size='sm' />}
                </button>
              </div>
            </div>
          </div>

          <ConfirmationDialog
            modalOpen={confirmationModal}
            modalClose={() => setConfirmationModal(false)}
            sendRequest={sendRequest}
          />
          <AddVendorServiceModal
            data={jobSum}
            visible={showAddVendorServiceModal}
            handleClose={() => {
              setShowAddVendorServiceModal(false)
            }}
            refetch={refetch}
          />
        </>
      )}
      {showTable && data.length == 0 && (
        <>
          <EmptyTable title='No Suitable Vendors' />
          <div className='d-flex justify-content-between align-items-center'>
            <div className='me-3 w-100 d-flex justify-content-start'>
              <button className='btn btn-info' onClick={() => openAddVendorServiceModal()}>
                + Add Vendor Service
              </button>
            </div>
          </div>
          <AddVendorServiceModal
            data={jobSum}
            visible={showAddVendorServiceModal}
            handleClose={() => {
              setShowAddVendorServiceModal(false)
            }}
            refetch={refetch}
          />
        </>
      )}
    </>
  )
}

export default SuitableVendorsTable

type VendorTableProps = {
  data: any
}
const VendorTable = React.memo(
  React.forwardRef(({data}: VendorTableProps, ref) => {
    return (
      <DataGrid
        id='vendorsTable'
        keyExpr='vendorId'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        hoverStateEnabled={true}
        height={'calc(64vh - 60px)'}
        selection={{mode: 'single'}}
        onExporting={(e) => onExporting(e, 'Suitable Vendors')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={50} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Suitable Vendors: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column
          dataField='vendorName'
          caption='Company Name'
          dataType='string'
          width={200}
          cssClass='cls'
        />
        <Column
          dataField='duration'
          caption='Duration'
          dataType='number'
          minWidth={250}
          cssClass='cls'
        />
        <Column dataField='price' caption='Price' dataType='number' minWidth={150} cssClass='cls' />
        <Column dataField='' dataType='number' minWidth={150} cssClass='cls' />
        <MasterDetail
          autoExpandAll={true}
          enabled={true}
          render={(data: any) => <StaffAccordionTab ref={ref} data={data} />}
        />
      </DataGrid>
    )
  })
)

type AddVendorServiceModalProps = {
  data: any
  visible: boolean
  handleClose: () => void
  refetch?: () => void
}

const AddVendorServiceModal: React.FC<AddVendorServiceModalProps> = ({
  data,
  visible,
  handleClose,
  refetch,
}) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [vendorList, setVendorList] = useState<any>([])
  const [vendorData, setVendorData] = useState<ISaveVendorToProperty>({
    ...data,
    vendorId: 0,
    duration: 0,
    offeredRate: 0,
    hostOfferedRate: 0,
  })
  const {
    data: availableVendors,
    isLoading,
    error,
    refetch: refetchVendors,
  } = useQuery(
    ['Get Available Vendors For Property', data?.hostPropertyId],
    () => ApiCalls.getAvailableVendors(data.hostPropertyId),
    {
      cacheTime: 500000,
      refetchOnWindowFocus: false,
      enabled: data?.jobId ? true : false,
    }
  )

  useEffect(() => {
    if (!isLoading && availableVendors) {
      if (availableVendors?.data?.data.length > 0) {
        const list = []
        for (const av of availableVendors?.data?.data) {
          const findVendor = list.find((l) => l.value == av.id)
          if (findVendor == undefined) {
            list.push({label: av.vendorName, value: av.id})
          }
        }

        setVendorList(list)
      }
    }
  }, [isLoading, availableVendors])

  const setVendor = (v: any) => {
    var rawData = Object.assign({}, vendorData)
    rawData.vendorId = v
    setVendorData(rawData)
  }

  const setDuration = (v: number) => {
    var rawData = Object.assign({}, vendorData)
    rawData.duration = v
    setVendorData(rawData)
  }

  const setPrice = (v: number) => {
    var rawData = Object.assign({}, vendorData)
    rawData.offeredRate = v
    setVendorData(rawData)
  }

  const setHostPrice = (v: number) => {
    var rawData = Object.assign({}, vendorData)
    rawData.hostOfferedRate = v
    setVendorData(rawData)
  }

  const saveVendorToProperty = async () => {
    setErrorMsg('')
    try {
      if (vendorData.vendorId == 0) {
        setErrorMsg('Please select a vendor')
      } else if (vendorData.duration == 0) {
        setErrorMsg('Please enter duration')
      } else if (vendorData.hostOfferedRate == 0) {
        setErrorMsg('Please enter host price')
      } else if (vendorData.offeredRate == 0) {
        setErrorMsg('Please enter vendor price')
      } else {
        await ApiCalls.saveVendorServiceToProperty(vendorData)
        if (refetch) {
          refetch()
        }
        handleClose()
      }
    } catch (error: any) {
      setErrorMsg(error?.response?.data?.message)
    }
  }

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header>Add Vendor Service</Modal.Header>
      <Modal.Body>
        <form className='form w-100'>
          <div className='row d-flex justify-content-center'>
            <div className='col-lg-12'>
              <label className='form-label fs-6 fw-bolder text-dark'>Vendor</label>
              <SelectInput
                options={vendorList}
                onValueChanged={(value) => {
                  setVendor(value)
                }}
              />

              <label className='form-label fs-6 fw-bolder text-dark mt-6'>Duration (min.)</label>
              <TextInput
                type='number'
                step={1}
                onValueChange={(text: string) => {
                  setDuration(+text)
                }}
              />

              <label className='form-label fs-6 fw-bolder text-dark mt-6'>Host Price ($)</label>
              <TextInput
                type='number'
                step={0.01}
                onValueChange={(text: string) => {
                  setHostPrice(+text)
                }}
              />

              <label className='form-label fs-6 fw-bolder text-dark mt-6'>Vendor Price ($)</label>
              <TextInput
                type='number'
                step={0.01}
                onValueChange={(text: string) => {
                  setPrice(+text)
                }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {errorMsg != '' && (
          <div className='row'>
            <span className='text-danger me-3'>{errorMsg}</span>
          </div>
        )}
        <div>
          <Button variant='secondary' onClick={handleClose} className=' me-2'>
            Close
          </Button>
          <Button variant='primary' onClick={saveVendorToProperty}>
            Save Changes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
