import React, { Dispatch, useEffect } from 'react';
import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap-v5';
import ApiCalls from '../../network/ApiCalls';
import { TextInput } from '../Inputs/TextInput';
import { useDialog } from '../../contexts/DialogContext';
import * as staffActionCreator from '../../redux/actionCreators/staffActionCreators';
import { Button } from '@material-ui/core';
import { UserEditRequest } from '../../network/PostRequestModels/UserRequest';
import { getLocationByAddress, getLocationByAddressForStaff, isPhoneValid, toAmazonUrl } from '../../utils/util';
import { useDispatch, useSelector } from 'react-redux';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import SelectInput from '../Inputs/SelectInput';
import { useQuery } from 'react-query';
import PhoneTextInput from '../Inputs/PhoneInput';
import { IStaffAddress } from '../../interfaces/Address';
import { ILocation } from '../../interfaces/Property';
import AutocompleteMap from '../Inputs/AutocompleteMap';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

type Props = {
}

const StaffEditForm: React.FC<Props> = () => {
    const { staffEditDialog } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();
    const { showSuccessDialog } = useDialog();
    const [editRequest, setEditRequest] = useState<UserEditRequest>({
        firstName: staffEditDialog.staff?.firstName,
        lastName: staffEditDialog.staff?.lastName,
        phoneNumber: staffEditDialog.staff?.phoneNumber,
    } as UserEditRequest);

    const [selectedStatus, setSelectedStatus] = useState<number | null | undefined>(staffEditDialog.staff?.accountStatusId);
    const [statusTypes, setStatusTypes] = useState<any>([])
    const [imagePreview, setImagePreview] = useState<any>(staffEditDialog.staff?.profilePhotoPath ? toAmazonUrl(staffEditDialog.staff?.profilePhotoPath) : null);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState<string>('');
    const [fullLocation, setFullLocation] = useState<IStaffAddress | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<ILocation>({ address: ' ' });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const userId = staffEditDialog.staff?.userId;
    const { data, isLoading, error } = useQuery(
        ['Get User Types'],
        () => ApiCalls.getStaffStatusTypes(),
        { cacheTime: 500000 }
    )
    const { data: staffAddress, isLoading: addressLoading, error: addressError } = useQuery(['Get Staff Address', userId], () => ApiCalls.getAddressesByUserId(userId), { onSuccess: (data: any) => setText(data.data.data?.[0]?.address) })
    const homeAddress = staffAddress?.data.data.find((address: IStaffAddress) => address.userAddressTypeId == 2)

    const handleUserEdit = async () => {
        try {
            setLoading(true);
            setErrorMessage(null);
            var formData = new FormData();
            formData.append("firstName", editRequest.firstName);
            formData.append("lastName", editRequest.lastName);
            formData.append("phoneNumber", editRequest.phoneNumber);
            formData.append("profilePhoto", editRequest.profilePhoto);
            const { firstName, lastName, phoneNumber } = editRequest;
            if (fullLocation) {
                { homeAddress ? await ApiCalls.updateAddress(Number(userId), Number(homeAddress.userAddressId), fullLocation) : await ApiCalls.createAddress(Number(userId), { ...fullLocation, userAddressTypeId: 2 }) }
            }
            if (firstName && lastName && phoneNumber && userId) {
                if (isPhoneValid(phoneNumber)) {
                    if (selectedStatus !== 0) {
                        staffEditDialog?.staff?.hostId && await ApiCalls.updateStaffStatus({ accountStatusId: selectedStatus, userId: userId }, Number(userId))
                        staffEditDialog?.staff?.vendorId && await ApiCalls.updateVendorStaffStatus({ accountStatusId: selectedStatus, userId: userId }, Number(userId))
                    }
                    await ApiCalls.editUser(formData, userId.toString());
                    setLoading(false);
                    showSuccessDialog('User Updated.');
                    staffEditDialog.refetch();
                    dispatch(staffActionCreator.closeStaffEditDialog());
                }
                else {
                    setLoading(false)
                    setErrorMessage('Phone is not valid!');
                }
            }
            else {
                setLoading(false)
                setErrorMessage('All fields are required.');
            }
        }
        catch (err: any) {
            setLoading(false);
            setErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : 'Unexpected error.');
        }
    }

    const changeImage = (e: any) => {
        try {
            setErrorMessage(null);
            var file = e.target.files[0];
            if (file.size <= 2097152) {
                setEditRequest({ ...editRequest, profilePhoto: file })
                if (file) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        setImagePreview(reader.result);
                    };
                }
                else {
                    setImagePreview(null);
                }
            }
            else {
                setErrorMessage('Max file size is 2MB.');
            }
        }
        catch (err: any) {
            setErrorMessage('File cannot be uploaded.')
        }
    }
    const handleSelectAddress = (e: string) => {
        setSelectedLocation({ ...selectedLocation, address: e });
        setText(e)
    }
    const handleAutocompleteError = () => {
        setText(selectedLocation.address ?? '');
    }

    useEffect(() => {
        if (data) {
            setStatusTypes(data?.data?.data.map((type: any) => ({ label: type.name, value: type.id })))
        }
    }, [data])

    useEffect(() => {
        getLocationByAddressForStaff(selectedLocation.address).then((i) => i && setFullLocation(i))
    }, [selectedLocation])


    useEffect(() => {
        if (staffEditDialog.staff) {
            setSelectedStatus(staffEditDialog.staff.accountStatusId);
        }
    }, [staffEditDialog])

    return (
        <form className='form w-100 mt-5'>
            <div className='row d-flex justify-content-center'>

                <div className='col-lg-6'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Select Status</label>
                    <SelectInput
                        options={statusTypes}
                        defaultValue={staffEditDialog.staff?.accountStatusId}
                        onValueChanged={(value: number) => {
                            setSelectedStatus(value)
                        }}
                    />
                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>First Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, firstName: text })} defaultValue={editRequest?.firstName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Last Name</label>
                    <TextInput onValueChange={(text: string) => setEditRequest({ ...editRequest, lastName: text })} defaultValue={editRequest?.lastName} />

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Address {<span style={{ color: "red" }}>*</span>}</label>
                    <AutocompleteMap text={text} setText={setText} handleSelectAddress={handleSelectAddress} handleOnError={handleAutocompleteError} />
                    {!selectedLocation.address || fullLocation?.zipCode == "" && <><strong className='text-danger fw-6'>Please enter a valid address</strong><br /></>}

                    <label className='form-label fs-6 fw-bolder text-dark mt-6'>Phone Number</label>
                    <PhoneTextInput defaultValue={editRequest.phoneNumber} onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} />

                    <div className='row mt-6'>
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-success' disabled={loading} onClick={handleUserEdit}>Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                        </div>
                        <div className='col-12 text-end'>
                            <strong style={{ color: 'red' }}>{errorMessage}</strong>
                        </div>
                    </div>
                </div>
                <div className='col-lg-5 d-flex align-items-center'>
                    <div className='row d-flex justify-content-center'>
                        {imagePreview ? <div className='d-flex justify-content-center'><img className='rounded' style={{ height: 200 }} alt='selected-sample' src={imagePreview} /></div> :
                            <div className="d-flex flex-column align-items-center max-w-250px">
                                <img style={{ width: 250, height: 250 }} src={toAbsoluteUrl('/media/icons/noPhoto.png')} alt="noPhoto" />
                                <strong className='text-muted text-center'>No Photo Available</strong>
                            </div>}
                        <Button
                            className='col-lg-9 mt-6'
                            variant="contained"
                            component="label"
                        >
                            Upload Photo
                            <input
                                type="file"
                                accept="image/jpeg, image/png"
                                onChange={changeImage}
                                hidden
                            />
                        </Button>
                        (Max Photo Size 2MB)
                    </div>
                </div>
            </div>
        </form>
    )
}

export default React.memo(StaffEditForm);