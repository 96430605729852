import React, {Dispatch, FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ServiceDetailAction} from '../../redux/actionTypes/serviceDetailActionType'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import * as serviceDetailActionCreators from '../../redux/actionCreators/serviceDetailActionCreators'
import {Modal} from 'react-bootstrap-v5'
import ServiceGroupForm from './ServiceGroupForm'

type Props = {
  refetchGroups: () => void
}

const ServiceGroupDialog: FC<Props> = ({refetchGroups}) => {
  const {
    serviceGroupState: {ModalOpen, ModalData},
  } = useSelector((state: RedusxAppState) => state.serviceGroupDetail)
  const showViewDispatch = useDispatch<Dispatch<ServiceDetailAction>>()
  const handleCloseModal = () => {
    showViewDispatch(serviceDetailActionCreators.closeReferenceView())
  }
  return (
    <div>
      <Modal show={ModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {ModalData ? `Edit ${ModalData.name}` : 'Add New Service Group'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ServiceGroupForm refetchGroups={refetchGroups} selectedServiceGroup={ModalData} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ServiceGroupDialog
