import React, {Dispatch} from 'react'
import {Modal} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import ProfilePhotoTemp from '../../../../../umut-components/Icons/ProfilePhotoTemp'
import ApiCalls from '../../../../../network/ApiCalls'
import {useDialog} from '../../../../../contexts/DialogContext'

type Props = {
  handleShow: boolean
  handleClose: () => void
  data: any
  serviceType: string
  refetch: () => void
}

const VendorOffersDialog: React.FC<Props> = ({
  handleShow,
  handleClose,
  data,
  serviceType,
  refetch,
}) => {
  const {showFailureDialog, showSuccessDialog} = useDialog()

  const acceptOffer = async (offer: any) => {
    try {
      const request = {id: offer.id, offerStatusTypeId: 3}
      await ApiCalls.updateHostPropertyOfferedRateOffer(request)
      showSuccessDialog('The offered price by ' + offer.vendorName + ' has been accepted.')
      refetch()
    } catch (error: any) {
      showFailureDialog(error.response.data.message)
    }
  }

  const declineOffer = async (offer: any) => {
    try {
      const request = {id: offer.id, offerStatusTypeId: 4}
      await ApiCalls.updateHostPropertyOfferedRateOffer(request)
      showSuccessDialog('The offered price by ' + offer.vendorName + ' has been declined.')
      refetch()
    } catch (error: any) {
      showFailureDialog(error.response.data.message)
    }
  }

  return (
    <Modal show={handleShow} onHide={handleClose} centered size='lg'>
      <ModalHeader closeButton>
        <Modal.Title>Vendor's Offers for {serviceType}</Modal.Title>
      </ModalHeader>
      {data.length > 0 && (
        <Modal.Body className='p-3'>
          {data.map((d: any, i: number) => {
            var vendorNameRaw = d.vendorName.split(' ')
            var vendorShort = ''
            vendorNameRaw.map((vnr: any) => {
              vendorShort += vnr[0]
            })
            return (
              <div className='vendorOfferItem' key={'vendor-offer-item-' + i}>
                <div className='row align-items-center'>
                  <div className='col-lg-5 col-12 mb-3'>
                    <div className='d-flex align-items-center'>
                      <ProfilePhotoTemp
                        fontSize={10}
                        width={40}
                        height={40}
                        borderRadius={'100px'}
                        firstName={''}
                        lastName={''}
                        vendorName={vendorShort}
                      />
                      <div className='ms-4 vendorName'>{d.vendorName}</div>
                    </div>
                  </div>
                  <div className='col-lg-7 col-12 mb-3 text-end'>
                    {d.offerStatusTypeId == 1 && (
                      <div className='row align-items-center'>
                        <div className='col-lg-4 col-12 mb-3'>Offered Price : -</div>
                        <div className='col-lg-8 col-12 mb-3 d-flex justify-content-end px-5'>
                          <div className='btn btn-sm btn-outline btn-outline-dark w-100'>
                            {d.offerStatusType}
                          </div>
                        </div>
                      </div>
                    )}
                    {d.offerStatusTypeId == 2 && (
                      <div className='row align-items-center'>
                        <div className='col-lg-4 col-12 mb-3'>
                          Offered Price : ${d.offeredServicePrice}
                        </div>
                        <div className='col-lg-8 col-12 mb-3 d-flex justify-content-end'>
                          <button
                            className='btn btn-sm btn-success me-4'
                            onClick={() => {
                              acceptOffer(d)
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className='btn btn-sm btn-danger'
                            onClick={() => {
                              declineOffer(d)
                            }}
                          >
                            Decline
                          </button>
                        </div>
                      </div>
                    )}
                    {d.offerStatusTypeId == 3 && (
                      <div className='row align-items-center'>
                        <div className='col-lg-4 col-12 mb-3'>
                          Offered Price : ${d.offeredServicePrice}
                        </div>
                        <div className='col-lg-8 col-12 mb-3 d-flex justify-content-end px-5'>
                          <div className='btn btn-sm btn-outline btn-outline-success w-100'>
                            {d.offerStatusType}
                          </div>
                        </div>
                      </div>
                    )}
                    {d.offerStatusTypeId == 4 && (
                      <div className='row align-items-center'>
                        <div className='col-lg-4 col-12 mb-3'>
                          Offered Price : ${d.offeredServicePrice}
                        </div>
                        <div className='col-lg-8 col-12 mb-3 d-flex justify-content-end px-5'>
                          <div className='btn btn-sm btn-outline btn-outline-danger w-100'>
                            {d.offerStatusType}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Modal.Body>
      )}
      {data.length == 0 && (
        <Modal.Body className='p-3'>
          <div className='alert alert-secondary'>There are no offers for {serviceType}</div>
        </Modal.Body>
      )}
    </Modal>
  )
}

export default VendorOffersDialog
