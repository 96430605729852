import React, { Dispatch, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { useParams } from 'react-router';
import { IJobNote } from '../../../../interfaces/Job';
import ApiCalls from '../../../../network/ApiCalls';
import { JobNoteRequest } from '../../../../network/PostRequestModels/JobRequests';
import { TextInput } from '../../../../umut-components/Inputs/TextInput';
import { decryptText } from '../../../../utils/util';

type Props = {
    modalOpen: boolean,
    handleClose: () => void,
    handleSuccess: () => void,
    modalType: 'job-note' | 'task-note' | 'service-note',
    modalNote: IJobNote | null
}

const NoteAddDialog: React.FC<Props> = ({ modalOpen, handleClose, handleSuccess, modalType, modalNote }) => {
    let { jobId }: any = useParams();
    jobId = decryptText(jobId);
    const [note, setNote] = useState<string | null>(modalNote?.note ?? null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSendNote = async () => {
        if (note) {
            setLoading(true);
            setErrorMessage(null);
            try {
                if (modalType === 'job-note') {
                    if (modalNote) {
                        await ApiCalls.updateJobNote(modalNote.id, note);
                    }
                    else {
                        let request: JobNoteRequest = {
                            jobId: parseInt(jobId),
                            note: note
                        }
                        await ApiCalls.addJobNote(request);
                    }
                }

                if (modalType === 'task-note' && modalNote) {
                    await ApiCalls.updateJobTaskNote(modalNote.id, note);
                }

                if (modalType === 'service-note' && modalNote) {
                    await ApiCalls.updateJobServiceNote(modalNote.id, note);
                }

                setLoading(false);
                handleSuccess();
            }
            catch (err: any) {
                setLoading(false);
                setErrorMessage(err?.response?.data?.message);
            }
        }
        else {
            setErrorMessage('Please enter note');
        }
    }

    useEffect(() => {
        if (modalNote) {
            setNote(modalNote.note);
        }
    }, [modalNote])

    return (
        <Modal show={modalOpen} onHide={handleClose} size="lg" centered>
            <ModalHeader closeButton >
                <Modal.Title >Add A Note To This Job</Modal.Title>
            </ModalHeader>

            <Modal.Body>
                <form className='form w-100 mt-5'>
                    <small className='text-muted'></small>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-12'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Note</label><br/>
                            <small className='text-muted'>Add a note for you and your staff who does this job</small>
                            <TextInput onValueChange={(text: string) => { setNote(text) }} defaultValue={modalNote?.note} />

                            <div className='row mt-6'>
                                <div className='col-12 text-end'>
                                    <button type='submit' className='btn btn-success' disabled={loading || !note} onClick={handleSendNote}>Save {loading ? <Spinner animation='border' size='sm' /> : null}</button>
                                </div>
                                <div className='col-12 text-end'>
                                    <strong style={{ color: 'red' }}>{errorMessage}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default NoteAddDialog;