import React, { Dispatch } from 'react';
import { Modal } from 'react-bootstrap-v5';
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader';
import { StaffAction } from '../../redux/actionTypes/staffActionTypes';
import { RedusxAppState } from '../../redux/reducers/rootReducer';
import * as staffActionCreators from '../../redux/actionCreators/staffActionCreators';
import UserEditForm from '../../umut-components/Forms/UserEditForm';
import { useDispatch, useSelector } from 'react-redux';
import StaffEditForm from '../../umut-components/Forms/StaffEditForm';

type Props = {
}

const StaffEditDialog: React.FC<Props> = ({ }) => {
    const { staffEditDialog } = useSelector((state: RedusxAppState) => state.staff);
    const dispatch = useDispatch<Dispatch<StaffAction>>();

    const closeModal = () => {
        dispatch(staffActionCreators.closeStaffEditDialog());
    }

    return (
        <Modal show={staffEditDialog.dialogOpen} onHide={closeModal} size='xl' centered closeButton>
            <ModalHeader closeButton>
                <Modal.Title>Staff Edit</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {staffEditDialog.dialogOpen && <StaffEditForm />}
            </Modal.Body>
        </Modal>
    )
}

export default StaffEditDialog;