import * as deleteDialogActionCreators from '../../../redux/actionCreators/deleteDialogActionCreators'
import { Dispatch, FC } from 'react'
import { useDispatch } from 'react-redux'
import { DeleteActions } from '../../../redux/actionTypes/deleteDialogTypes'
import ApiCalls from '../../../network/ApiCalls'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import { toAmazonUrl } from '../../../utils/util'

type Props = {
  refetch: () => void
  data: any
}
const StaffDocument: FC<Props> = ({ data, refetch }) => {
  const deleteDialogDispatch = useDispatch<Dispatch<DeleteActions>>()
  const handleClose = () => {
    deleteDialogDispatch(deleteDialogActionCreators.closeDeleteDialog());
  }

  return (
    <>
      <div className={`card bg-${(data.vendorUserDocumentTypeId %= 2) == 0 ? 'success' : 'primary'}`}>
        <div className='card-header border-0 d-flex justify-content-between pt-10'>
          <a href={toAmazonUrl(data.documentPath)} target="_blank">
            <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" className="bi bi-file-earmark-arrow-down" viewBox="0 0 18 18">
                <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              </svg>
            </span>
          </a>
          <div>
            <DeleteButton
              classNames='btn btn-icon btn-bg-white btn-active-color-danger btn-sm'
              iconWidth='20'
              iconHeight='20'
              deleteFn={() => {
                deleteDialogDispatch(
                  deleteDialogActionCreators.openDeleteDialog(
                    async () => {
                      await ApiCalls.deleteUserDocuments(data.id)
                      refetch()
                      handleClose()
                    },
                    `Are you sure you want to delete this document? You CAN NOT undo this operation.`,
                    'Delete Document'
                  )
                )
              }}
            />
          </div>
        </div>
        <a href={toAmazonUrl(data.documentPath)} target="_blank">
          <div className='card-body ms-6'>
            <div className='fw-bold text-inverse-danger fs-7 mt-5'>
              {data.vendorUserDocumentTypeName}
            </div>
            <div className='text-inverse-danger fw-bolder fs-2 mb-2 '>Document</div>
          </div>
        </a>
      </div>
    </>
  )
}

export default StaffDocument
