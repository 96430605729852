import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useDialog} from '../../../../../contexts/DialogContext'
import {actions} from '../../../../../modules/auth'
import ApiCalls from '../../../../../network/ApiCalls'
import {decryptText, encryptText} from '../../../../../utils/util'
import {useQuery} from 'react-query'
import {AxiosResponse} from 'axios'
import {useParams} from 'react-router-dom'
import {
  ISubscriptionPackPrice,
  ISubscriptionPacks,
  IVendorSubscriptionInvoice,
  IVendorSubscriptions,
} from '../../../../../interfaces/Vendors'
import {Button, Modal, Spinner} from 'react-bootstrap-v5'
import dayjs from 'dayjs'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import SkeletonTableLoading from '../../../../../umut-components/Loading/SkeletonTableLoading'
import {ISubscriptionPacksResponse} from '../../../../../network/NetworkResponses/NetworkResponses'
import VendorSubscriptionsInvoiceDetail from './VendorSubscriptionInvoiceDetail'

const VendorSubscription = () => {
  let {vendorId}: any = useParams()
  vendorId = decryptText(vendorId)
  const [processing, setProcessing] = useState(false)
  const [hasPack, setHasPack] = useState(false)
  const [packPrice, setPackPrice] = useState(0)
  const [packType, setPackType] = useState('monthly')
  const [packName, setPackName] = useState('Vendor Pack')
  const [packFreeStaff, setPackFreeStaff] = useState(0)
  const [currentStaffCount, setCurrentStaffCount] = useState(0)
  const [invoices, setInvoices] = useState<IVendorSubscriptionInvoice[]>()
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)
  const [cancelledLoading, setCancelledLoading] = useState(false)
  const [selectedPackType, setSelectedPackType] = useState('monthly')
  const [selectedPack, setSelectedPack] = useState<ISubscriptionPackPrice>()
  const {showFailureDialog} = useDialog()
  const [showInvoice, setShowInvoice] = useState(false)
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(0)
  const dispatch = useDispatch()

  const viewSubscriptionInvoice = (invNum: number) => {
    setSelectedInvoiceId(invNum)
    setShowInvoice(true)
  }

  const closeInvoice = () => {
    setSelectedInvoiceId(0)
    setShowInvoice(false)
  }

  const {
    data: subscriptionPacks,
    isLoading: subscriptionPacksLoading,
    error: subscriptionPacksError,
    refetch: subscriptionPacksFetching,
  } = useQuery(['Get Subscription Packs'], () => ApiCalls.getSubscriptionPacks(), {
    cacheTime: 50000,
    refetchOnWindowFocus: true,
  })

  const {
    data: subscriptionData,
    isLoading: subscriptionsLoading,
    error: subscriptionsError,
    refetch: refetchSubscription,
  } = useQuery(['Get Vendor Subscriptions'], () => ApiCalls.getVendorSubscriptions(vendorId), {
    refetchOnWindowFocus: false,
  })

  const {
    data: staffs,
    isLoading: staffsLoading,
    error: staffsError,
  } = useQuery(['Get Vendor Staffs'], () => ApiCalls.getStaffByVendorId(vendorId), {
    cacheTime: 50000,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!subscriptionPacksLoading) {
      if (subscriptionPacks && subscriptionPacks?.data?.data.length > 0) {
        const pack = findPack(
          subscriptionPacks?.data?.data[0].vendorSubscriptionPackPrices,
          selectedPackType
        )
        setSelectedPack(pack)
      }
    }
  }, [subscriptionPacksLoading, subscriptionPacks])

  useEffect(() => {
    if (!staffsLoading && staffs?.data?.data) {
      setCurrentStaffCount(staffs?.data?.data.length)
    }
  }, [staffsLoading, staffs])

  useEffect(() => {
    if (!subscriptionsLoading && subscriptionData?.data && subscriptionData?.data != '') {
      const pack =
        subscriptionData?.data?.vendorSubscriptionPack?.vendorSubscriptionPackPrices.find(
          (prc: any) => prc.type == subscriptionData?.data?.subscriptionType
        )
      setPackName(subscriptionData?.data?.vendorSubscriptionPack?.name)
      if (subscriptionData?.data?.subscriptionStatusTypeId != 5) {
        setHasPack(true)
      } else {
        setHasPack(false)
      }
      if (pack) {
        let price = 0
        subscriptionData?.data?.vendorSubscriptionItems.map((item: any) => {
          price = price + item.totalAmount
        })
        setPackType(subscriptionData?.data?.subscriptionType)
        setPackPrice(price)
        setPackFreeStaff(pack.freeStaff)
      }

      if (subscriptionData?.data?.vendorSubscriptionInvoices.length > 0) {
        let invs = subscriptionData?.data?.vendorSubscriptionInvoices.sort(function (
          a: any,
          b: any
        ) {
          var keyA = new Date(a.paymentDate),
            keyB = new Date(b.paymentDate)
          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })
        setInvoices(invs)
      }
    } else {
      setHasPack(false)
    }
  }, [subscriptionData, subscriptionsLoading])

  const findPackPrice = (prices: any, type: string) => {
    var find = prices.find((p: any) => p.type == type)
    if (find != undefined) {
      return find.subscriptionFee
    }
  }

  const findPack = (prices: any, type: string) => {
    var find = prices.find((p: any) => p.type == type)
    if (find != undefined) {
      return find
    }
  }

  const handleSubmit = async () => {
    setProcessing(true)
    try {
      const data = {
        vendorSubscriptionPackId: selectedPack?.vendorSubscriptionPackId,
        type: selectedPack?.type,
        subscriptionFee: selectedPack?.subscriptionFee,
        stripeAccountCardId: '',
      }
      await ApiCalls.subscribeByAdmin(vendorId, data)
      setProcessing(false)
      refetchSubscription()
    } catch (error) {
      setProcessing(false)
      showFailureDialog('An error occurred during the operation. Please try again.')
    }
  }

  const cancelSubscription = async () => {
    setCancelledLoading(true)
    try {
      if (subscriptionData?.data) {
        await ApiCalls.cancelSubscription(subscriptionData?.data?.id)
        setCancelledLoading(false)
        refetchSubscription()
        setTimeout(() => {
          dispatch(actions.logout())
        }, 500)
      }
    } catch (error) {
      setCancelledLoading(false)
      showFailureDialog('An error occur during cancelling the subscription')
    }
  }

  const isSelectedPack = (pack: any, type: string) => {
    if (pack.vendorSubscriptionPackPrices && pack.vendorSubscriptionPackPrices.length > 0) {
      var find = pack.vendorSubscriptionPackPrices.find(
        (p: any) =>
          p.type == type && p.vendorSubscriptionPackId == selectedPack?.vendorSubscriptionPackId
      )
      if (find != undefined) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const getInvoiceStatus = (inv: IVendorSubscriptionInvoice) => {
    if (inv) {
      if (inv.invoiceStatus.name == 'Paid') {
        return <div className='badge badge-success'>Paid</div>
      }
      if (inv.invoiceStatus.name == 'Due') {
        return <div className='badge badge-warning'>Due</div>
      }
      if (inv.invoiceStatus.name == 'Cancelled' || inv.invoiceStatus.name == 'Unpaid') {
        return <div className='badge badge-danger'>{inv.invoiceStatus.name}</div>
      }
    } else {
      return <></>
    }
  }

  if (subscriptionsLoading) {
    return (
      <div>
        <Spinner animation='border' />
      </div>
    )
  }

  return (
    <>
      {hasPack && (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-7'>
                  <h3 className='mb-2'>
                    Active until {dayjs(subscriptionData?.data?.expireDate).format('MMMM DD, YYYY')}
                  </h3>
                  <p className='fs-6 text-gray-600 fw-semibold mb-6 mb-lg-15'>
                    We will send to vendor a notification before Subscription expiration
                  </p>
                  <div className='fs-5 mb-2'>
                    <span className='text-gray-800 fw-bold me-1'>${packPrice}</span>
                    <span className='text-gray-600 fw-semibold'>
                      Per {packType == 'monthly' ? 'Month' : 'Annual'}
                    </span>
                  </div>
                  <div className='fs-6 text-gray-600 fw-semibold'>{packName}</div>
                </div>
                <div className='col-lg-5'>
                  <div className='d-flex text-muted fw-bold fs-5 mb-3'>
                    <span className='flex-grow-1 text-gray-800'>Free Staff</span>
                    <span className='text-gray-800'>
                      {currentStaffCount > packFreeStaff ? packFreeStaff : currentStaffCount} of{' '}
                      {packFreeStaff} Used
                    </span>
                  </div>
                  <div className='progress h-8px bg-light-primary mb-2'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{
                        width: `${
                          packFreeStaff > 0 ? (currentStaffCount / packFreeStaff) * 100 : 100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div className='fs-6 text-gray-600 fw-semibold mb-10'>
                    {packFreeStaff - currentStaffCount < 0 ? 0 : packFreeStaff - currentStaffCount}{' '}
                    Users remaining
                  </div>
                  {hasPack && (
                    <div className='d-flex justify-content-end pb-0 px-0'>
                      <button
                        className='btn btn-danger'
                        disabled={cancelledLoading}
                        onClick={() => {
                          setShowCancelSubscriptionModal(true)
                        }}
                      >
                        Cancel Subscription
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header card-header-stretch border-bottom border-gray-200'>
              <div className='card-title'>
                <h3 className='fw-bold m-0'>Billing History</h3>
              </div>
            </div>
            <div className='tab-content'>
              {invoices && invoices.length > 0 && (
                <div className='table-responsive'>
                  <table className='table table-row-bordered align-middle gy-4 gs-9'>
                    <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                      <tr>
                        <td className='min-w-150px'>Date</td>
                        <td className='min-w-250px'>Description</td>
                        <td className='min-w-150px'>Amount</td>
                        <td className='min-w-150px'>Status</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {invoices.map((inv: IVendorSubscriptionInvoice, index: number) => {
                        return (
                          <tr>
                            <td>{dayjs(inv.paymentDate).format('MMM D, YYYY')}</td>
                            <td>{'Invoice for ' + dayjs(inv.paymentDate).format('MMM YYYY')}</td>
                            <td>${inv.netAmount}</td>
                            <td>{getInvoiceStatus(inv)}</td>
                            <td className='text-right'>
                              <button
                                onClick={() => {
                                  viewSubscriptionInvoice(inv.id)
                                }}
                                className='btn btn-sm btn-light btn-active-light-primary'
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <Modal
            show={showCancelSubscriptionModal}
            onHide={() => {
              setShowCancelSubscriptionModal(false)
            }}
            centered
          >
            <ModalHeader closeButton>
              <Modal.Title>Cancel Subscription</Modal.Title>
            </ModalHeader>
            <Modal.Body>
              <p>
                You are about to cancel vendor subscription. After this process, vendor's current
                subscription will be canceled and all jobs records assigned to vendor will be reset.{' '}
                <span className='text-danger'>Do you approve this action?</span>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowCancelSubscriptionModal(false)}>
                Cancel
              </Button>
              <Button variant='danger' onClick={cancelSubscription}>
                Yes, approve
              </Button>
            </Modal.Footer>
          </Modal>
          {showInvoice && (
            <VendorSubscriptionsInvoiceDetail
              visible={showInvoice}
              invNum={selectedInvoiceId}
              handleClose={closeInvoice}
            />
          )}
        </>
      )}
      {!hasPack && (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body'>
              <div className='d-flex flex-column'>
                <div className='mb-13 text-center'>
                  <h1 className='fs-2hx fw-bold mb-5'>Choose A Plan</h1>
                </div>
                <div
                  className='nav-group nav-group-outline mx-auto mb-15'
                  data-kt-buttons='true'
                  data-kt-initialized='1'
                >
                  <button
                    onClick={() => {
                      setSelectedPackType('monthly')
                      const pack = findPack(
                        subscriptionPacks?.data?.data[0].vendorSubscriptionPackPrices,
                        'monthly'
                      )
                      setSelectedPack(pack)
                    }}
                    className={
                      selectedPackType == 'monthly'
                        ? 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active'
                        : 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2'
                    }
                  >
                    Monthly
                  </button>

                  <button
                    onClick={() => {
                      setSelectedPackType('annual')
                      const pack = findPack(
                        subscriptionPacks?.data?.data[0].vendorSubscriptionPackPrices,
                        'annual'
                      )
                      setSelectedPack(pack)
                    }}
                    className={
                      selectedPackType == 'annual'
                        ? 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active'
                        : 'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2'
                    }
                  >
                    Annual
                  </button>
                </div>
                {subscriptionPacksLoading && <SkeletonTableLoading />}
                {!subscriptionPacksLoading && subscriptionPacks?.data && (
                  <div className='row g-10'>
                    {subscriptionPacks?.data?.data.map((pack: any, index: number) => {
                      return (
                        <div
                          className='col-xl-6 col-md-6 col-12'
                          key={'pack-' + index}
                          onClick={() => {
                            setSelectedPack(
                              findPack(pack.vendorSubscriptionPackPrices, selectedPackType)
                            )
                          }}
                        >
                          <div
                            className={
                              isSelectedPack(pack, selectedPackType)
                                ? 'd-flex h-100 align-items-start selected-pack-price'
                                : 'd-flex h-100 align-items-start'
                            }
                          >
                            <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                              <div className='mb-7 text-center'>
                                <h1 className='text-dark mb-5 fw-bolder'>{pack.name}</h1>

                                <div className='text-center'>
                                  <span className='mb-2 text-primary'>$</span>

                                  <span
                                    className='fs-3x fw-bold text-primary'
                                    data-kt-plan-price-month='39'
                                    data-kt-plan-price-annual='399'
                                  >
                                    {findPackPrice(
                                      pack.vendorSubscriptionPackPrices,
                                      selectedPackType
                                    )}
                                  </span>

                                  <span className='fs-7 fw-semibold opacity-50'>
                                    /<span data-kt-element='period'>{selectedPackType}</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className='col-md-4 offset-md-4 col-12'>
                      <button
                        onClick={() => {
                          handleSubmit()
                        }}
                        type='button'
                        disabled={processing}
                        className='subscribe btn btn-primary btn-block w-100 rounded-sm shadow-sm mt-15'
                      >
                        {' '}
                        Subscribe {processing && <Spinner animation='border' size='sm' />}{' '}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default VendorSubscription
