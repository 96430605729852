import React, {Dispatch, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import JobDetails from '../../pages/job-details/JobDetails'
import {useParams} from 'react-router'
import {decryptText} from '../../utils/util'
import {useQuery} from 'react-query'
import {AxiosResponse} from 'axios'
import {IJobDetail} from '../../interfaces/Job'
import ApiCalls from '../../network/ApiCalls'

type Props = {
  modalOpen: boolean
  handleClose: () => void
  refetch: () => void
}

const ForceFinishDialog: React.FC<Props> = ({modalOpen, handleClose, refetch}) => {
  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  let {jobId}: any = useParams()
  jobId = decryptText(jobId)

  const acceptRequest = async () => {
    try {
      setLoading(true)
      await ApiCalls.putForceFinish(jobId)
      await refetch()
      setLoading(false)
      handleClose()
    } catch (err: any) {
      setErrorMessage(err?.response?.data?.message)
    }
  }

  return (
    <>
      <Modal show={modalOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Force Finish</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to force to finish your job?</Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-danger' onClick={handleClose}>
            Close
          </button>
          <button disabled={loading} className='btn btn-success btn-sm' onClick={acceptRequest}>
            Save Changes {loading && <Spinner animation='border' size="sm"/>}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ForceFinishDialog
