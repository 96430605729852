import React, {Dispatch, useRef, useState} from 'react'
import {
  Column,
  DataGrid,
  HeaderFilter,
  Paging,
  Scrolling,
  Selection,
  FilterRow,
  Editing,
  Export,
  SearchPanel,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {useHistory} from 'react-router-dom'
import {Modal, Button, Spinner} from 'react-bootstrap-v5'
import ModalHeader from 'react-bootstrap-v5/lib/esm/ModalHeader'
import UpdateButton from '../../../umut-components/Buttons/UpdateButton'
import {IHostPropertyCheckList} from '../../../interfaces/Checklist'
import ViewButton from '../../../umut-components/Buttons/ViewButton'
import {ChecklistEditRequest} from '../../../network/PostRequestModels/ChecklistRequests'
import notify from 'devextreme/ui/notify'
import ApiCalls from '../../../network/ApiCalls'
import {useDialog} from '../../../contexts/DialogContext'
import {useDispatch} from 'react-redux'
import {ChecklistAction} from '../../../redux/actionTypes/checklistTypes'
import * as checklistActionCreator from '../../../redux/actionCreators/checklistActionCreators'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {encryptText, onExporting, setDxFilterPlaceholder} from '../../../utils/util'
import ChecklistButton from '../../../umut-components/Buttons/ChecklistButton'
import EmptyTable from '../../../umut-components/Icons/EmptyTable'
import DeleteButton from '../../../umut-components/Buttons/DeleteButton'
import {DeleteActions} from '../../../redux/actionTypes/deleteDialogTypes'
import {
  closeDeleteDialog,
  openDeleteDialog,
} from '../../../redux/actionCreators/deleteDialogActionCreators'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import UpdateChecklistForJobsButton from '../../../umut-components/Buttons/UpdateChecklistForJobsButton'

type Props = {
  data: IHostPropertyCheckList[]
  serviceTypes: any[]
  refetchChecklists: () => void
  refreshPage: () => void
}

const checklistSchema = Yup.object().shape({
  name: Yup.string().required('Checklist name is required'),
  roleId: Yup.string().required('Checklist role is required'),
  isDefault: Yup.string().required('Checklist default option is required'),
  serviceTypeId: Yup.string().required('Checklist service type is required'),
})

var defaultChecklistValues = {
  id: '',
  name: '',
  roleId: '',
  isDefault: 'false',
  serviceTypeId: '0',
}

const ChecklistTable: React.FC<Props> = ({data, serviceTypes, refetchChecklists, refreshPage}) => {
  const history = useHistory()
  const {showSuccessDialog, showFailureDialog, openLoadingModal} = useDialog()
  const dispatch = useDispatch<Dispatch<ChecklistAction>>()
  const deleteDispatch = useDispatch<Dispatch<DeleteActions>>()
  const tableRef = useRef<DataGrid>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [showUpdateJobsModal, setShowUpdateJobsModal] = useState(false)
  const [selectedChecklist, setSelectedChecklist] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState<string | null>(null)

  const formik = useFormik({
    initialValues: defaultChecklistValues,
    validationSchema: checklistSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        updateChecklist(values)
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const updateChecklist = async (values: any) => {
    openLoadingModal('Please wait..')
    setIsUpdating(true)
    var checklistId = values.id
    var serviceTypeId = values.serviceTypeId
    var roleIdRaw = values.roleId.split('-')

    var data = {
      name: {
        en: values.name,
      },
      roleId: +roleIdRaw[1],
      isDefault: values.isDefault == 'true' ? true : false,
      serviceTypeId: +serviceTypeId,
    }

    await ApiCalls.updateChecklistMainInfo(data, checklistId)
      .then((d) => {
        setIsUpdating(false)
        if (d?.data?.success) {
          setShowEditModal(false)
          refetchChecklists()
          refreshPage()
        }
        notify(
          {
            message: d?.data?.success
              ? 'Checklist info have been successfully updated.'
              : 'An error occurred while updating checklist',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          d?.data?.success ? 'success' : 'error',
          5000
        )
      })
      .catch(() => {
        setIsUpdating(false)
        notify(
          {
            message: 'An error occurred while updating checklist',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          5000
        )
      })
  }

  const renderSectionButton = (cellValue: any) => {
    return (
      <ChecklistButton
        iconWidth='20'
        iconHeight='20'
        viewFn={() => {
          history.push({pathname: '/checklist-sections/' + encryptText(cellValue?.data?.id)})
        }}
      />
    )
  }

  const renderEditChecklistButton = (cellValue: any) => {
    const openUpdateModal = async (clData: any) => {
      var checklist = clData
      defaultChecklistValues.id = checklist?.id
      defaultChecklistValues.name = checklist?.name.en
      defaultChecklistValues.roleId = checklist?.lobRoleId + '-' + checklist?.roleId
      defaultChecklistValues.isDefault = checklist?.isDefault ? 'true' : 'false'
      defaultChecklistValues.serviceTypeId = checklist?.serviceTypeId
      setShowEditModal(true)
    }

    return (
      <button
        type='button'
        className='btn btn-sm btn-light-info'
        onClick={() => {
          openUpdateModal(cellValue?.data)
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='currentColor'
          className='bi bi-pencil'
          viewBox='0 0 16 16'
        >
          <path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z' />
        </svg>
      </button>
    )
  }

  const renderEditButton = (cellValue: any) => {
    return (
      <UpdateButton
        iconWidth='20'
        iconHeight='20'
        updateFn={() => {
          dispatch(checklistActionCreator.openChecklistEditDialog(cellValue?.data))
        }}
      />
    )
  }

  const renderDeleteButton = (cellValue: any) => {
    const deleteAction = async () => {
      try {
        await ApiCalls.deleteChecklist(cellValue?.data?.id)
        refetchChecklists()
        deleteDispatch(closeDeleteDialog())
        showSuccessDialog('Checklist removed!')
      } catch (err: any) {
        showFailureDialog(err?.response?.data?.message)
      }
    }
    const deleteButtonClick = async () => {
      deleteDispatch(
        openDeleteDialog(
          deleteAction,
          `Do you want to remove checklist ${cellValue?.data?.name?.en}?`,
          'Remove Checklist'
        )
      )
    }

    return <DeleteButton iconWidth='20' iconHeight='20' deleteFn={deleteButtonClick} />
  }

  const renderTooltip = (props: any, role: any) => (
    <Tooltip id='button-tooltip' {...props}>
      This is the default checklist for {role}
    </Tooltip>
  )

  const renderDefaultButton = (cellValue: any) => {
    return (
      <div className='d-flex justify-content-center'>
        {cellValue?.data?.isDefault ? (
          <OverlayTrigger
            placement='top'
            overlay={(props) =>
              renderTooltip(
                props,
                cellValue?.data?.serviceTypeName +
                  ' ' +
                  cellValue?.data?.roleName +
                  '/' +
                  cellValue?.data?.lobRoleName
              )
            }
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-bookmark-fill'
              viewBox='0 0 16 16'
            >
              <path d='M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z' />
            </svg>
          </OverlayTrigger>
        ) : null}
      </div>
    )
  }

  const renderUpdateJobsButton = (cellValue: any) => {
    const updateJobsButtonClick = async () => {
      setSelectedChecklist(cellValue?.data)
      setShowUpdateJobsModal(true)
    }

    return (
      <UpdateChecklistForJobsButton
        iconWidth='20'
        iconHeight='20'
        updateFn={updateJobsButtonClick}
      />
    )
  }

  const updateJobsAction = async () => {
    const data = {
      hostPropertyId: selectedChecklist.hostPropertyId,
      hostPropertyChecklistId: selectedChecklist.id,
    }

    try {
      setErrorText(null)
      setLoading(true)
      await ApiCalls.updateFutureJobsChecklistsByProperty(data)
      refetchChecklists()
      setLoading(false)
      setSelectedChecklist(null)
      setShowUpdateJobsModal(false)
      showSuccessDialog('Jobs Checklists Updated!')
    } catch (err: any) {
      setLoading(false)
      setErrorText(err?.response?.data?.message)
    }
  }

  const calculateRoleValue = (cellValue: any) => {
    return cellValue.roleName + '/' + cellValue.lobRoleName
  }

  const calculateChecklistName = (cellValue: any) => {
    return cellValue?.name?.en
      ? cellValue?.name?.en
      : cellValue?.name[Object.keys(cellValue?.name)[0]]
  }

  const hasChecklistAlternative = (serviceTypeId: number, id: number) => {
    var find = data.find((d) => d.serviceTypeId == serviceTypeId && d.id != id)
    if (find != undefined) {
      return true
    } else {
      return false
    }
  }

  if (data?.length === 0) {
    return <EmptyTable title='No Checklists' />
  }

  return (
    <div className='position-relative'>
      <div style={{zIndex: 1}} className='position-absolute top-15'>
        <span
          className='fs-8 text-primary cursor-pointer'
          onClick={() => tableRef.current?.instance.clearFilter()}
        >
          Clear Filter
        </span>
      </div>
      <DataGrid
        id='checklistTable'
        keyExpr='id'
        onRowPrepared={(e) => {
          e.rowElement.classList.add('data-grid-row')
        }}
        dataSource={data}
        showBorders={false}
        showColumnLines={false}
        showRowLines={true}
        ref={tableRef}
        hoverStateEnabled={true}
        height={'calc(55vh - 60px)'}
        allowColumnResizing={true}
        selection={{mode: 'single'}}
        onEditorPreparing={setDxFilterPlaceholder}
        onExporting={(e) => onExporting(e, 'Checklists')}
      >
        <Export enabled />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} />
        <Scrolling showScrollbar='always' mode='virtual' rowRenderingMode='virtual' />
        <Paging defaultPageSize={10} />
        <Summary>
          <TotalItem
            cssClass='absolute-right'
            displayFormat='Total Checklists: {0}'
            column='name'
            summaryType='count'
          />
        </Summary>

        <Column width={50} cellRender={renderDefaultButton} cssClass='cls' />
        <Column
          dataField='name'
          calculateCellValue={calculateChecklistName}
          caption='Checklist Name'
          minWidth={150}
          cssClass='cls'
        />
        <Column
          dataField='roleName'
          calculateCellValue={calculateRoleValue}
          caption='Role'
          minWidth={150}
          cssClass='cls'
        />
        <Column dataField='serviceTypeName' caption='Service' minWidth={150} cssClass='cls' />
        <Column width={100} cellRender={renderEditChecklistButton} cssClass='cls' />
        <Column width={100} cellRender={renderSectionButton} cssClass='cls' />
        <Column width={100} cellRender={renderUpdateJobsButton} cssClass='cls' />
        <Column width={100} cellRender={renderDeleteButton} cssClass='cls' />
      </DataGrid>
      <Modal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false)
        }}
        centered
      >
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <ModalHeader closeButton>
            <Modal.Title>Edit Checklist</Modal.Title>
          </ModalHeader>
          <Modal.Body>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Checklist Name
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Checklist name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Checklist Role</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('roleId')}
                >
                  <option value=''>Select a Role...</option>
                  <option value='1-1'>Host Admin</option>
                  <option value='1-2'>Host Manager</option>
                  <option value='1-3'>Host Cleaner</option>
                  <option value='1-4'>Host Inspector</option>
                  <option value='1-5'>Host Logistics</option>
                  <option value='1-6'>Host Maintenance</option>
                  <option value='3-9'>Vendor Admin</option>
                  <option value='3-10'>Vendor Manager</option>
                  <option value='3-11'>Vendor Cleaner</option>
                  <option value='3-12'>Vendor Inspector</option>
                  <option value='3-13'>Vendor Logistics</option>
                  <option value='3-14'>Vendor Maintenance</option>
                </select>
                {formik.touched.roleId && formik.errors.roleId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.roleId}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Checklist Service</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('serviceTypeId')}
                >
                  <option value=''>Select a Service...</option>
                  {serviceTypes.map((st) => {
                    return <option value={st.id}>{st.name}</option>
                  })}
                </select>
                {formik.touched.serviceTypeId && formik.errors.serviceTypeId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.serviceTypeId}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Is Default?</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('isDefault')}
                  disabled={
                    !hasChecklistAlternative(
                      formik.getFieldProps('serviceTypeId').value,
                      formik.getFieldProps('id').value
                    )
                  }
                >
                  <option value=''>Select a Role...</option>
                  <option value='true'>True</option>
                  <option value='false'>False</option>
                </select>
                {formik.touched.isDefault && formik.errors.isDefault && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.isDefault}</div>
                  </div>
                )}
                {!hasChecklistAlternative(
                  formik.getFieldProps('serviceTypeId').value,
                  formik.getFieldProps('id').value
                ) && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      There is no other checklist in the service type of this checklist.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-link text-danger me-3'
              onClick={() => {
                setShowEditModal(false)
              }}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || isUpdating}
            >
              <span className='indicator-label'>Update</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showUpdateJobsModal}
        onHide={() => {
          setShowUpdateJobsModal(false)
        }}
        centered
      >
        <ModalHeader closeButton>
          <Modal.Title>Update Checklist for Future Jobs</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          {'Do you want to update the checklist for future jobs at this property with ' +
            selectedChecklist?.name?.en}
        </Modal.Body>
        <Modal.Footer className='d-flex flex-column'>
          <div className='d-flex justify-content-end w-100'>
            <Button
              className='d-flex mx-5'
              variant='link'
              onClick={() => {
                setShowUpdateJobsModal(false)
              }}
            >
              Cancel
            </Button>
            <Button variant='danger' onClick={updateJobsAction} disabled={loading}>
              Update {loading ? <Spinner animation='border' size='sm' /> : null}
            </Button>
          </div>
          <div className='w-100 text-end'>
            <strong className='text-danger'>{errorText}</strong>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default React.memo(ChecklistTable)

//                 <Column width={100} cellRender={renderEditButton} cssClass='cls' />
