import React, { Dispatch, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap-v5'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useDialog } from '../../contexts/DialogContext'
import { IVendorAddress } from '../../interfaces/Address'
import { ILocation } from '../../interfaces/Property'
import ApiCalls from '../../network/ApiCalls'
import { IVendorEditRequest } from '../../network/PostRequestModels/VendorRequestForm'
import * as vendorEditActionCreators from '../../redux/actionCreators/vendorActionCreators'
import { VendorAction } from '../../redux/actionTypes/vendorTypes'
import { RedusxAppState } from '../../redux/reducers/rootReducer'
import { getLocationByAddress, isPhoneValid } from '../../utils/util'
import AutocompleteMap from '../Inputs/AutocompleteMap'
import PhoneTextInput from '../Inputs/PhoneInput'
import SelectInput from '../Inputs/SelectInput'
import { TextInput } from '../Inputs/TextInput'

type Props = {
  refetch: () => void
}

const VendorEditForm: React.FC<Props> = ({ refetch }) => {
  const { vendor }: any = useSelector((state: RedusxAppState) => state.vendor)
  const dispatch = useDispatch<Dispatch<VendorAction>>()
  const { showSuccessDialog } = useDialog()
  const [statusTypes, setStatusTypes] = useState<any>([])
  const [text, setText] = useState<string>(`${vendor?.cityName} ,${vendor?.stateName} ,${vendor.countryName}`);
  const [fullLocation, setFullLocation] = useState<IVendorAddress | null>({cityName:vendor?.cityName,stateName:vendor?.stateName,countryName:vendor?.countryName})
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({ address: ' ' });
  const [editRequest, setEditRequest] = useState({
    name: vendor?.name,
    phoneNumber: vendor?.phoneNumber,
    vendorStatusTypeId: vendor?.vendorStatusTypeId,
    website: vendor?.website,
    address: fullLocation
  })
  const [errorText, setErrorText] = useState<string | null>(null)

  const { data, isLoading, error } = useQuery(
    ['Get Vendor Types'],
    () => ApiCalls.getVendorStatusTypes(),
    { cacheTime: 500000 }
  )
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleUserEdit = async () => {
    try {
      if (editRequest.website && !isValidHttpUrl(editRequest.website)) {
        return setErrorText('Url is not valid')
      }
      setLoading(true)
      setErrorMessage(null)
      const { name, phoneNumber } = editRequest
      if (name && phoneNumber && editRequest.vendorStatusTypeId) {
        if (isPhoneValid(phoneNumber)) {
          await ApiCalls.putVendorEditStatus({ id: vendor?.id, vendorStatusTypeId: editRequest.vendorStatusTypeId }, vendor?.id)
          await ApiCalls.putVendorDetails(vendor?.id, {
            phoneNumber: editRequest.phoneNumber,
            name: editRequest.name,
            address: {
              city: fullLocation?.cityName,
              state: fullLocation?.stateName,
              country: fullLocation?.countryName,
            }
          })
          setLoading(false)
          refetch()
          dispatch(vendorEditActionCreators.CloseVendorEditDialog())
          showSuccessDialog('Vendor Updated.')
        }
        else {
          setLoading(false)
          setErrorMessage('Phone is not valid!')
        }
      } else {
        setLoading(false)
        setErrorMessage('All fields are required.')
      }
    } catch (err: any) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.message)
    }
  }
  const handleSelectAddress = (e: string) => {
    setSelectedLocation({ ...selectedLocation, address: e });
    setText(e)
  }
  const handleAutocompleteError = () => {
    setText(selectedLocation.address ?? '');
  }
  const isValidHttpUrl = (http: string) => {
    var res = http.match("^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$");
    if (res == null)
      return false;
    else
      return true;
  }

  useEffect(() => {
    if (data) {
      setStatusTypes(data?.data?.data.map((type: any) => ({ label: type.name, value: type.id })))
    }
  }, [data])

  useEffect(() => {
    getLocationByAddress(selectedLocation.address).then((i) => i && setFullLocation(i))
  }, [selectedLocation])

  return (
    <form className='form w-100 mt-5'>
      <div className='row d-flex justify-content-center'>
        <div className='col-lg-6'>
          <label className='form-label fs-6 fw-bolder text-dark'>Select Status</label>
          <SelectInput
            defaultValue={vendor?.vendorStatusTypeId}
            options={statusTypes}
            onValueChanged={(value: number) => {
              setEditRequest({ ...editRequest, vendorStatusTypeId: value })
            }}
          />

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Vendor Name</label>
          <TextInput
            onValueChange={(text: string) => setEditRequest({ ...editRequest, name: text })}
            defaultValue={editRequest?.name}
          />
          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Web Site</label>
          <TextInput
            onValueChange={(text: string) => setEditRequest({ ...editRequest, website: text })}
            defaultValue={editRequest?.website}
          />
          <label className="text-danger">{errorText}</label>

          <label className='form-label fs-6 fw-bolder text-dark mt-6'>Address {<span style={{ color: "red" }}>*</span>}</label>
          <AutocompleteMap text={text} setText={setText} handleSelectAddress={handleSelectAddress} handleOnError={handleAutocompleteError} />
          {/* {!selectedLocation.address || fullLocation?.zipCode == "" && <><strong className='text-danger fw-6'>Please enter a valid address</strong><br /></>} */}



          <label className='form-label fs-6 fw-bolder text-dark mt-2'>Phone</label>
          <PhoneTextInput defaultValue={editRequest?.phoneNumber} onChange={(text: string) => setEditRequest({ ...editRequest, phoneNumber: text })} />

          <div className='row mt-6'>

            <div className='col-12 text-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={loading}
                onClick={handleUserEdit}
              >
                Save Changes {loading ? <Spinner animation='border' size='sm' /> : null}
              </button>
            </div>
            <div className='col-12 text-end'>
              <strong style={{ color: 'red' }}>{errorMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default React.memo(VendorEditForm)
