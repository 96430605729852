import {AxiosResponse} from 'axios'
import React, {Dispatch, useEffect, useRef, useState} from 'react'
import {Button, Card, Tab, Tabs} from 'react-bootstrap-v5'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {PageTitle} from '../../../_metronic/layout/core'
import {JobStatusType} from '../../enums/JobEnum'
import {IJobDetail} from '../../interfaces/Job'
import ApiCalls from '../../network/ApiCalls'
import {openCancelJobDialog, setJobDetail} from '../../redux/actionCreators/jobActionCreators'
import {JobAction} from '../../redux/actionTypes/jobTypes'
import {RedusxAppState} from '../../redux/reducers/rootReducer'
import {decryptText, encryptText, convertUTCDateToLocalDate} from '../../utils/util'
import ForceFinishDialog from './forceFinishDialog'
import JobDetailTable from './JobDetailTable'
import IssuesTab from './Tabs/IssuesTab/IssuesTab'
import NotesTab from './Tabs/NotestTab/NotesTab'
import PhotosTab from './Tabs/PhotosTab/PhotosTab'
import PropertyTab from './Tabs/PropertyTab/PropertyTab'
import ServiceTab from './Tabs/ServiceTab/ServiceTab'
import StaffTab from './Tabs/StaffTab/StaffTab'
import TasksTab from './Tabs/TasksTab/TasksTab'
import VendorTab from './Tabs/VendorTab/VendorTab'
import AssingmentPendingVendorModal from './AssingmentPendingVendorModal'
import DeleteButton from '../../umut-components/Buttons/DeleteButton'
import JobCancelDialog from '../jobs/JobCancelDialog'
import dayjs from 'dayjs'
import AssingmentHostModal from './AssingmentHostModal'

type TabNames =
  | 'services'
  | 'photos'
  | 'notes'
  | 'tasks'
  | 'reported-issues'
  | 'property'
  | 'staff'
  | 'vendor'
  | 'client'
  | 'assign'

const JobDetails = () => {
  const dispatch = useDispatch<Dispatch<JobAction>>()

  const {jobDetail} = useSelector((state: RedusxAppState) => state.job)
  const [selectedTab, setSelectedTab] = useState<TabNames>('services')
  const [forceFinishModalOpen, setForceFinishModalOpen] = useState(false)

  const [jobEventsActive, setJobEventsActive] = useState<boolean>(false)
  const [showVendorAssign, setShowVendorAssign] = useState<boolean>(false)

  let {jobId}: any = useParams()
  jobId = decryptText(jobId)

  const jobScheduleDay = dayjs(
    convertUTCDateToLocalDate(jobDetail?.scheduledStart, jobDetail?.hostPropertyCityTimeZone)
  )
  const jobExpectedDay = dayjs(
    convertUTCDateToLocalDate(jobDetail?.cleaningPeriodStart, jobDetail?.hostPropertyCityTimeZone)
  )
  const tomorrow = dayjs().add(1, 'day')
  const isPast =
    tomorrow.diff(jobScheduleDay, 'hours') >= 0 || tomorrow.diff(jobExpectedDay, 'hours') >= 0

  const {data, isLoading, error, refetch} = useQuery<AxiosResponse<IJobDetail>>(
    ['Get Job Detail', jobId],
    () => ApiCalls.getJobDetails(jobId),
    {cacheTime: 500000, refetchOnWindowFocus: false}
  )

  const handleChangeTab = (key: any) => {
    setSelectedTab(key)
  }

  const closeTypeModal = () => {
    setForceFinishModalOpen(false)
  }
  useEffect(() => {
    if (data) {
      setJobEventsActive(
        !(
          data?.data?.jobStatusType?.name === JobStatusType.COMPLETED ||
          data?.data?.jobStatusType?.name === JobStatusType.CANCELLED
        )
      )
      dispatch(setJobDetail(data.data))
    }
    return () => {
      dispatch(setJobDetail(null))
    }
  }, [data])

  const statusBadge = () => {
    switch (data?.data?.jobStatusType?.name) {
      case 'Pending':
        return <span className='badge badge-warning me-3'>{data?.data?.jobStatusType?.name}</span>

      case 'Completed':
        return (
          <span className='badge badge-success me-3'>
            {data?.data?.isForceFinish ? 'Completed (FF)' : data?.data?.jobStatusType?.name}
          </span>
        )

      default:
        return (
          <span className='badge badge-danger me-3'>
            {data?.data?.jobStatusType?.name === 'Started'
              ? 'In Progress'
              : data?.data?.jobStatusType?.name}
          </span>
        )
    }
  }

  const getProviderName = (data: any) => {
    if (data?.cleaningProviderId == 1) {
      return 'My Team'
    }
    if (data?.cleaningProviderId == 3) {
      return 'Turnify+'
    }
    if (data?.cleaningProviderId == 2) {
      if (data?.isMarketplace) {
        if (data?.hasMpManagement) {
          return 'Marketplace+'
        } else {
          return 'Marketplace'
        }
      } else {
        return 'TurnifyPRO'
      }
    }
  }

  if (isLoading) {
    return <div className='d-flex justify-content-center p-6'>Loading...</div>
  }

  if (error) {
    return <div className='d-flex justify-content-center p-6'>Error</div>
  }

  return (
    <>
      <PageTitle>Job Detail</PageTitle>
      <div className='row g-5 g-xl-8'>
        <Card className='shadow'>
          <Card.Header className='d-flex align-items-start justify-content-center flex-column'>
            <strong className='fs-3 my-2'>
              {' '}
              <a
                href={'/properties/edit/' + encryptText(data?.data?.hostPropertyId)}
                target='_blank'
              >
                {data?.data?.hostProperty?.address}{' '}
                {data?.data?.hostProperty?.suite ? data?.data?.hostProperty?.suite : ''}{' '}
                {data?.data.isManuallyCreated && (
                  <span style={{color: '#ff5d5d', fontSize: 14}}>ⓜ</span>
                )}
              </a>{' '}
            </strong>
            <div className='w-100 d-flex justify-content-between flex-row mb-2'>
              <div className='mt-3'>
                {/* <span className='badge badge-primary badge me-3'>{jobId}</span> */}
                {statusBadge()}
                <span className='badge badge-info me-3 '>
                  {getProviderName(data?.data.hostProperty)}
                </span>
                {data?.data?.scheduledStart ? (
                  <span className='badge badge-success me-3'>
                    {convertUTCDateToLocalDate(
                      data?.data?.scheduledStart,
                      data?.data?.hostPropertyCityTimeZone
                    )}{' '}
                  </span>
                ) : (
                  <span className='badge badge-success me-3'>
                    {convertUTCDateToLocalDate(
                      data?.data?.cleaningPeriodStart,
                      data?.data?.hostPropertyCityTimeZone
                    )}{' '}
                  </span>
                )}

                {data?.data?.scheduledFinish ? (
                  <span className='badge badge-danger'>
                    {convertUTCDateToLocalDate(
                      data?.data?.scheduledFinish,
                      data?.data?.hostPropertyCityTimeZone
                    )}
                  </span>
                ) : (
                  <span className='badge badge-danger'>
                    {convertUTCDateToLocalDate(
                      data?.data?.cleaningPeriodEnd,
                      data?.data?.hostPropertyCityTimeZone
                    )}
                  </span>
                )}
              </div>
              <div className='d-flex'>
                {jobDetail?.jobStatusType?.name !== JobStatusType.CANCELLED &&
                  jobDetail?.jobStatusType?.name !== JobStatusType.COMPLETED && (
                    <div>
                      <Button
                        className='btn btn-sm btn-warning'
                        children='Cancel Job'
                        onClick={() => {
                          dispatch(openCancelJobDialog(refetch, data?.data))
                        }}
                      />
                    </div>
                  )}
                {(jobDetail?.jobStatusType?.name === JobStatusType.SCHEDULED ||
                  jobDetail?.jobStatusType?.name === JobStatusType.STARTED) &&
                  isPast && (
                    <div>
                      <button
                        onClick={() => setForceFinishModalOpen(true)}
                        type='button'
                        className='btn btn-sm btn-success'
                        style={{float: 'right', marginLeft: '15px'}}
                      >
                        Force Finish
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Tabs defaultActiveKey='services' onSelect={handleChangeTab}>
              <Tab eventKey='services' title='Services'>
                {selectedTab === 'services' && data?.data ? (
                  <ServiceTab jobDetail={data?.data} jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              <Tab eventKey='photos' title='Checklist'>
                {selectedTab === 'photos' ? <PhotosTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='notes' title='Notes'>
                {selectedTab === 'notes' ? <NotesTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='tasks' title='Tasks'>
                {selectedTab === 'tasks' ? <TasksTab jobEventsActive={jobEventsActive} /> : null}
              </Tab>
              <Tab eventKey='reported-issues' title='Reported Issues'>
                {selectedTab === 'reported-issues' ? (
                  <IssuesTab jobEventsActive={jobEventsActive} />
                ) : null}
              </Tab>
              {+data!.data!.cleaningProviderId === 2 && jobDetail?.vendorId && (
                <Tab eventKey='vendor' title='Vendor Detail'>
                  {selectedTab === 'vendor' ? <VendorTab /> : null}
                </Tab>
              )}
              <Tab eventKey='staff' title='Staff Detail'>
                {selectedTab === 'staff' && data?.data ? (
                  <StaffTab jobEventsActive={jobEventsActive} jobDetail={data?.data} />
                ) : null}
              </Tab>
              {(jobDetail?.jobStatusType?.name === JobStatusType.SCHEDULED ||
                jobDetail?.jobStatusType?.name === JobStatusType.PENDING) &&
                +data!.data!.cleaningProviderId === 2 && (
                  <Tab eventKey='assign' title='Assign'>
                    {selectedTab === 'assign' && <AssingmentPendingVendorModal />}
                  </Tab>
                )}
              {(jobDetail?.jobStatusType?.name === JobStatusType.SCHEDULED ||
                jobDetail?.jobStatusType?.name === JobStatusType.PENDING) &&
                (+data!.data!.cleaningProviderId === 1 ||
                  +data!.data!.cleaningProviderId === 3) && (
                  <Tab eventKey='assign' title='Assign'>
                    {selectedTab === 'assign' && <AssingmentHostModal />}
                  </Tab>
                )}
            </Tabs>
          </Card.Body>
          {forceFinishModalOpen && (
            <ForceFinishDialog
              modalOpen={forceFinishModalOpen}
              handleClose={closeTypeModal}
              refetch={refetch}
            />
          )}
          <JobCancelDialog />
        </Card>
      </div>
    </>
  )
}

export default JobDetails
